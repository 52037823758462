import React from 'react';
import Introduction from '@mdigital/components/dist/components/Introduction';
import { IntroductionImages , getMigrationIntroductionImages } from '../../../../assets/AppRating/base64';

const handleClose = (onClose) => (ev) => onClose(ev);

const AppRatingNewIntroduction = ({ isShown, onClose, isAndroidDirect }) => (
  <Introduction
    prerenderSteps
    show={isShown}
    finishButtonText="Get Started"
    onClose={handleClose(onClose)}>
    <Introduction.Step
      primaryText="Welcome to App Rating!"
      imageSource={IntroductionImages.Step1}
      secondayText="Drive more Store Ratings and reviews! <br /> Set up your App Ratings Prompt with the perfect targeting for your customers."
    />
    <Introduction.Step
      primaryText="Target customers at the right mobile moment"
      imageSource={IntroductionImages.Step2}
      secondayText="Prompt the right customers at the right time and place within your app with <br /> advanced targeting capabilities."
    />
    <Introduction.Step
      primaryText="Good to go!"
      imageSource={getMigrationIntroductionImages(isAndroidDirect).Step3}
      secondayText={`
        The ${isAndroidDirect ? 'Android' : 'Apple'} prompt for app ratings will be automatically displayed <br /> 
        based on ${isAndroidDirect ? 'Android' : 'Apple'} guidelines.
      `}
    />
  </Introduction>
);

export default AppRatingNewIntroduction;