import React from 'react';
import { getSingleText } from '../../../../common/utils';
import { StyledSdkIntegrationV2Body } from './StyledSdkIntegrationV2ModalBody';
import { PROPERTY_TYPES } from '../../../../common/constants';
import SdkIntergartionV2AnywhereUrlPrefix from '../SdkIntergartionV2AnywhereUrlPrefix';

const isAnywhere = (propertyType)=>propertyType === PROPERTY_TYPES.ANYWHERE;

const SdkIntegrationV2ModalBody = ({ apiToken , bodyTextareaRef , propertyType , anywhereAuthDetails }) =>(
  <StyledSdkIntegrationV2Body>
    <p className="sdk-integration-v2-body-header"> 
      <span>  
        {getSingleText(`app.topMenu.sdkV2.${isAnywhere(propertyType) ? 'anywhere.' : ''}subContent`)}
      </span>
    </p>

    {isAnywhere(propertyType) ? 
      <SdkIntergartionV2AnywhereUrlPrefix urlsPrefix={anywhereAuthDetails.urlsPrefix} /> : null }

    <p className="sdk-integration-v2-textarea-container">
      <textarea id='api-token-text-box' ref={bodyTextareaRef} spellCheck='false' 
        className="sdk-integration-v2-token-container" readOnly value={isAnywhere(propertyType) ? anywhereAuthDetails.apiToken : apiToken}/>
    </p>

  </StyledSdkIntegrationV2Body>
);

export default SdkIntegrationV2ModalBody;