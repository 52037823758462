import React from 'react';
import ResourcesSelect from '../ResourcesSelect';
import PropTypes from 'prop-types';
import UploaderView from '../UploaderView';
import DigInput from '@mdigital/components/dist/components/Input';
import { CustomCssWrapper } from './GenericCustomCssStyled';
import { checkProvision } from '../../../redux/store/store-helper';
import { PROVISIONS } from '../../../common/enums';

const GenericCustomCss = (props) => {
  const { isCustomCssUploader, ...other } = props;

  const getContent = () => {
    const isReusableResourcesLibraryProvisioned = checkProvision('reusableResourcesLibrary');
    if (isReusableResourcesLibraryProvisioned) {
      const isDHH = checkProvision(PROVISIONS.DYNAMIC_HYBRID_HOSTING);
      return (
        <div className='resources-select-container'>
          <ResourcesSelect {...other} isDisabled={props.digIsDisabled} isShowAllResources={props.showAllResources || !isDHH}/>
        </div>
      );
    } else if (isCustomCssUploader) {
      return (
        <UploaderView {...other} disabled={props.digIsDisabled} />
      );
    } else {
      return ( 
        <div className='css-url-input'>
          <DigInput {...other} validationCallback={props.urlValidation} customErrorMsg={props.urlValidationMsg} />
        </div>
      );
    }
  };

  return (
    <CustomCssWrapper>
      {getContent()}
    </CustomCssWrapper>
  );

};

GenericCustomCss.propTypes = {
  size: PropTypes.string,
  onSuccess: PropTypes.shape({ callback: PropTypes.func, args: PropTypes.arrayOf(PropTypes.string) }),
  onRemoveFile: PropTypes.shape({ callback: PropTypes.func, args: PropTypes.arrayOf(PropTypes.string) }),
  validTypes: PropTypes.arrayOf(PropTypes.string),
  inputValue: PropTypes.string,
  infoMessage: PropTypes.string,
  errMessage: PropTypes.string,
  autLabel: PropTypes.string,
  isCustomCssUploader: PropTypes.bool,
  showAllResources: PropTypes.bool,
  checkProvision: PropTypes.func,
  placeholder: PropTypes.string,
  noOptionsHeader: PropTypes.string,
  onSelect: PropTypes.func,
  resourceId: PropTypes.number,
  types: PropTypes.arrayOf(PropTypes.string),
  digPlaceholder: PropTypes.string,
  digValue: PropTypes.string,
  digOnChange: PropTypes.func,
  urlField: PropTypes.string,
  urlValidation: PropTypes.func,
  urlValidationMsg: PropTypes.string,
  digIsDisabled: PropTypes.bool,
};

export default GenericCustomCss; 