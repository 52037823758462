import React from 'react';
import PropTypes from 'prop-types';
import StyledActionBar from './StyledActionBar';
import { COLORS } from '../../../common/colors';
import { withFileUploader } from '@mdigital/components/dist/components/FileUploader';
import DigButton from '@mdigital/components/dist/components/Button';
import Search from '@mdigital/components/dist/components/Search';

const SEARCH_PLACEHOLDER = 'Search by Name or ID...';

export default function ActionBar(props) {
  const {
    title,
    actionButtonText,
    counter,
    onAction,
    onSearch,
    shouldDisableActionButton,
    shouldHideSearch,
    isButtonLoading,
    withUpload,
    uploadSettings,
    style,
  } = props;

  const ActionButton = withUpload ? withFileUploader(uploadSettings)(DigButton) : DigButton;
  return (
    <StyledActionBar style={style}>
      <div className='info'>
        <span className="title">{ title }</span>
        { counter > 0 && <span className="counter">({ counter })</span> }
      </div>
      <div className='actions'>{!shouldHideSearch && (
        <div className='search-container'>
          <Search direction='left' placeholder={SEARCH_PLACEHOLDER} onChange={onSearch} onClose={onSearch}/>
        </div>
      )}
      <i id='separator'/>
      <ActionButton digClassName='action-button'
        digAutLabel='actionButton'
        digOnClick={onAction}
        digType='link'
        digIconClassName='plus-circle'
        digIconColor={null}
        digTextColor={COLORS.GREEN}
        digTextColorHover={COLORS.TEXT_HOVER}
        digIsDisabled={shouldDisableActionButton}
        isLoading={isButtonLoading}>{actionButtonText}</ActionButton>
      </div>
    </StyledActionBar>
  );
}

ActionBar.propTypes = {
  title: PropTypes.string.isRequired,
  counter: PropTypes.number,
  onAction: PropTypes.func,
  onSearch: PropTypes.func,
  actionButtonText: PropTypes.string,
  shouldDisableActionButton: PropTypes.bool,
  shouldHideSearch: PropTypes.bool,
  isButtonLoading: PropTypes.bool,
  withUpload: PropTypes.bool,
  uploadSettings: PropTypes.object,
  style: PropTypes.shape({
    position: PropTypes.string,
    width: PropTypes.string,
  }),
};

ActionBar.defaultProps = {
  shouldDisableActionButton: false,
  shouldHideSearch: false,
  actionButtonText: 'Create',
  isLoading: false,
  withUpload: false,
  style: {
    position: 'static',
    width: 'inherit',
  },
};
