import styled from 'styled-components';
import { units, typeStyle, color } from '@m/alchemy-ui';

export const StyledFieldContainer = styled.div `
    position: relative;
    ${({ labelStyle }) => labelStyle ? typeStyle(labelStyle) : typeStyle('labelM')};
    margin-top: ${units(2)};
    max-width: 363px;
    padding-left: ${({ required }) => required ? '8px' : ''};
`;

export const StyledInputCounter = styled.span`
    text-align: right;
    padding: ${units(0.75)} ${units(2)} ${units(0.75)};
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    color: ${color('typeMeta')};
    position: absolute;
    bottom: 0;
    right: ${units(1)}
`;