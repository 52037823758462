import styled from 'styled-components';
import { units } from '@m/alchemy-theme';

export const StyledFormPublishApproverDialogBody = styled.div` {
  font-family: "Nunito",-apple-system,system-ui,BlinkMacSystemFont,Roboto,Segoe UI,Oxygen Sans,Ubuntu,Helvetica Nue,sans-serif;
  margin-top: ${units(1)};
  font-size: ${units(1.75)};
  line-height: ${units(2.5)};
  
  b {
    font-weight: bold;
  }
  ul {
    list-style: disc;
    margin-left: ${units(3)};
  }
  a {
    cursor: pointer; 
    display: inline-flex;
    margin-top: ${units(1)};
    gap: ${units(.5)};
    align-items: center;
    margin-bottom: ${units(2)};
  }
}
`;
