import React, { useContext } from 'react';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { TriggerContext } from '../../../../../index';
import {
  StyledContentContainer,
  StyledSubTitle,
  StyledTargetingSettingsContainer
} from '../../../StyledTriggerByTargeting';
import { PanelSubHeader } from '../../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../../common/utils';
import { TextPathPrefix } from '../../../index';
import TargetingField from '../../../../Shared/TargetingField';
import { Input, TextArea } from '@m/alchemy-ui';
import { TargetingActions } from '../../../Actions';

export const CustomInviteBasicFields = () => {
  const { triggerState , triggerDispatch, isReadOnly } = useContext(TriggerContext);
  return (
    <StyledTargetingSettingsContainer>
      <StyledContentContainer>
        <StyledSubTitle>
          <PanelSubHeader>{getSingleText(`${TextPathPrefix}.content`)}</PanelSubHeader>
        </StyledSubTitle>
        <TargetingField
          required={true}
          className="custom-field-margin"
          label="Title Text"
          errorMessage="Title is required"
          disabled={isReadOnly}
          render={<Input value={getNestedProperty(triggerState, 'trigger.triggerCustomInterceptContract.customInvitationTitle') || ''}
            placeholder={getSingleText(`${TextPathPrefix}.titlePlaceholder`)}
            onChange={ (e) => triggerDispatch({ type: TargetingActions.SET_TITLE_TEXT , payload : e.target.value })} />}
        />
        <TargetingField
          required={true}
          label="Description Text"
          errorMessage="Description is required"
          disabled={isReadOnly}
          render={<TextArea value={getNestedProperty(triggerState, 'trigger.triggerCustomInterceptContract.customInvitationDescription') || ''}
            placeholder={getSingleText(`${TextPathPrefix}.descriptionPlaceholder`)}
            onChange={ (e) => triggerDispatch({ type: TargetingActions.SET_DESCRIPTION_TEXT , payload : e.target.value })} />}
        />
      </StyledContentContainer>

    </StyledTargetingSettingsContainer>
  );
};