import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import AutoSuggest from '@mdigital/components/dist/components/AutoSuggest';
import BlockableRow from '../BlockableRow';
import { comparisonOptions } from '../../../../../../../common/dropdownOptions';
import { autoSuggestLabelByIdFinder } from '../../../../../../../common/utils';

const IS_ACTIVE_KEY = 'trigger.rules.MobileNumberOfAppSession.isActive';
const comparisonOptionsAutoSuggestIdFinder = autoSuggestLabelByIdFinder(comparisonOptions);

const StyledComponent = styled.div`
  display: flex;

  & > span {
    &:nth-child(1) {
      width: 130px;
      padding-right: 12px;
    }

    &:nth-child(2) {
      width: 72px;
      input {
        width: 100%;
      }
    }
  }
  & > label {
    padding: 8px 13px;
  }
`;

const TargetByNumberOfSessions = () => (
  <BlockableRow title="# of sessions" isActiveFieldName={IS_ACTIVE_KEY}>
    <StyledComponent>
      <span>

        <Field name="trigger.rules.MobileNumberOfAppSession.params.compareString" render={({ input }) => (
          <AutoSuggest
            suggestions={comparisonOptions}
            digDisableSuggest={true}
            onSuggestionSelected={({ label }) => input.onChange(label)}
            value={comparisonOptionsAutoSuggestIdFinder(input.value)}
          />
        )}/>
      </span>
      <span>
        <Field name="trigger.rules.MobileNumberOfAppSession.params.numberOfRepeats" type="number" min={0} component="input" />
      </span>
      <label>Visits</label>
    </StyledComponent>
  </BlockableRow>
);

export default TargetByNumberOfSessions;
