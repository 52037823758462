import React, { Fragment } from 'react';
import { getSingleText } from '../../../common/utils';
import lightbox from '../../assets/formDisplay/lightbox.png';

export function Lightbox() {
  return (
    <Fragment>
      <div className="display-type">
        <p className="display-type-description">
          {getSingleText('app.pages.forms.invitation.formDisplayTab.lightbox.description')}
        </p>
      </div>
      <div className="display-preview">
        <img src={lightbox} alt="preview" className="display-preview-image"/>
      </div>
    </Fragment>
  );
}