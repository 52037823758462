"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertAlchemyColorFieldToHex = void 0;
function convertAlchemyColorFieldToHex(colorString) {
    const rgbaRegex = /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*(?:\.\d+)?)\)$/;
    const hexRegex = /^#?([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/;
    // Convert RGB to HEX
    const rgbToHex = (value) => {
        const hex = value.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };
    // If the input is in the RGBA format
    const rgbaMatch = colorString.match(rgbaRegex);
    if (rgbaMatch) {
        const [r, g, b] = rgbaMatch
            .slice(1, 4)
            .map(val => parseInt(val, 10)); // Use parseInt for RGB values as they are whole numbers
        const redHex = rgbToHex(r);
        const greenHex = rgbToHex(g);
        const blueHex = rgbToHex(b);
        return `#${redHex}${greenHex}${blueHex}`;
    }
    // If the input is in the HEX format
    if (hexRegex.test(colorString)) {
        return colorString.charAt(0) === '#' ? colorString : '#' + colorString;
    }
    throw new Error('Invalid color format. Please provide either a valid RGBA or HEX value.');
}
exports.convertAlchemyColorFieldToHex = convertAlchemyColorFieldToHex;
