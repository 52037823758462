import React from 'react';
import PropTypes from 'prop-types';
import AutoSuggest from '@mdigital/components/dist/components/AutoSuggest';
import ColorPicker from '@mdigital/components/dist/components/ColorPicker';
import LinkableTextEditor from '@mdigital/components/dist/components/LinkableTextEditor';
import StyledGeneralSettings from '../../../MobileEngagementDesign/Banner/SettingsExpandableList/GeneralSettings/StyledGeneralSettings';
import FieldRow from '../../../MobileEngagementDesign/Banner/SettingsExpandableList/shared/FieldRow';
import { autoSuggestLabelByIdFinder } from '../../../../common/utils';
import fontList from '../../../../assets/font-list';
import ThankYouPromptImageUpload from './ThankYouPromptGeneralSettingsImageUpload';
import ThankYouPromptImageReusableResourceSelect from './ThankYouPromptGeneralSettingsImageReusableResourceSelect';

const parseHyperLinkValue = (value)=>value.replace(/&lta/gi,'<a').replace(/&lt\/a/gi,'</a').replace(/&gt/gi,'>');

const ThankYouPromptGeneralSettings = ({ propertyType, mutators, reusableResourcesEnabled, disabled }) => (
  <StyledGeneralSettings>
    <FieldRow required title="Title" name="trigger.mobileThankYouPromptGeneralSettingsSectionContract.textContent" errorMessage="Title is required" render={({ input }) => (
      <LinkableTextEditor maxLength={70} enableCounter value={parseHyperLinkValue(input.value)} onChange={mutators.setImagetextContent} />
    )} />
    <FieldRow title="Body" name="trigger.mobileThankYouPromptGeneralSettingsSectionContract.bodyContent" render={({ input }) => (
      <LinkableTextEditor maxLength={100} enableCounter value={parseHyperLinkValue(input.value)} 
        onChange={mutators.setImagebodyContent} placeholder="Insert description here"/>
    )}/>
    <FieldRow title="Font" name="trigger.mobileThankYouPromptGeneralSettingsSectionContract.promptContentFontType" render={({ input }) => (
      <span className="font-selection-container">
        <AutoSuggest
          digDisableSuggest
          suggestions={fontList[propertyType]}
          onSuggestionSelected={({ label }) => input.onChange(label)}
          value={autoSuggestLabelByIdFinder(fontList[propertyType])(input.value)}
        />
      </span>
    )} />
    <FieldRow title="Text Color" name="trigger.mobileThankYouPromptGeneralSettingsSectionContract.promptContentFontColor" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
    <FieldRow title="Background" name="trigger.mobileThankYouPromptGeneralSettingsSectionContract.backgroundColor" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop  {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
    {
      reusableResourcesEnabled ?
        <FieldRow name="trigger.mobileThankYouPromptImageDataContract.resourceId" render= {({ input }) => (
          <ThankYouPromptImageReusableResourceSelect {...input} mutators={mutators} isDarkModeImage={false}/>
        )}/> :
        <FieldRow name="trigger.mobileThankYouPromptImageDataContract.name" render= {( input ) => (
          <ThankYouPromptImageUpload mutators={mutators} fileName={input.input.value !== '' ? input.input.value : 'No File...'} disabled={disabled} isDarkModeImage={false}/>
        )} />
    }
  </StyledGeneralSettings>
);

ThankYouPromptGeneralSettings.propTypes = {
  propertyType : PropTypes.string,
  mutators: PropTypes.object,
  disabled: PropTypes.bool,
  reusableResourcesEnabled: PropTypes.bool,
};

ThankYouPromptGeneralSettings.defaultProps = {
  propertyType: 'mobileIOS',
  mutators: {},
  disabled: false,
  reusableResourcesEnabled: false,
};

export default ThankYouPromptGeneralSettings;