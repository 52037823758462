import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { mutateMobileBannerPosition } from '../../common/mutators';
import EngagementDesignRenderer from '../MobileEngagementDesign/EngagementDesignRenderer';
import withProvider from '../../hoc/ProviderHoc';
function mapStateToProps({ profile: { propertyType, provisioning } }) {
  return { propertyType, provisioning };
}
@withProvider()
@connect(mapStateToProps)
export default class MobileInvitationDesign extends React.Component {

  static propTypes = {
    propertyType: PropTypes.oneOf(['mobileIOS', 'mobileAndroid' , 'anywhere']),
    onModelChanged: PropTypes.func,
    initialValues: PropTypes.object,
    helpCenterLinks : PropTypes.object,
    isDarkModeEnabled : PropTypes.bool,
    isNativeAlertRevamp: PropTypes.bool,
  }

  render() {
    const { propertyType, onModelChanged, initialValues , helpCenterLinks , isDarkModeEnabled, isNativeAlertRevamp, isReadOnlyMode } = this.props;

    return (
      <Form
        initialValues={initialValues}
        onSubmit={() => null}
        mutators={{ mutateMobileBannerPosition }}
        render={({ values, errors, form: { mutators } }) => (
          onModelChanged({ values, errors }) ||
          <EngagementDesignRenderer
            customIntercept={this.props.provisioning.mobileSDKV2CustomIntercept}
            propertyType={propertyType}
            mutators={mutators}
            engagementType={values.trigger.invitationType}
            helpCenterLinks= {helpCenterLinks}
            isDarkModeEnabled={isDarkModeEnabled}
            isNativeAlertRevamp={isNativeAlertRevamp}
            isReadOnlyMode={isReadOnlyMode}
          />
        )}
      />
    );
  }
}
