import { BANNER_TYPES, ROLE_TYPES } from '../../../common/constants';
import EnablementFormNotification from '../EnablementFormNotification/EnablementFormNotification';
import DhhFreezeModeNotification from '../FreezeModeNotification/DhhFreezeModeNotification';
import ExtensionFreezeModeNotification from '../FreezeModeNotification/ExtensionFreezeModeNotification';
import { checkProvision, getPropertyType } from '../../../redux/store/store-helper';
import { PROVISIONS } from '../../../common/enums';

export const getAppNotification = (state) => {
  const { profile } = state;

  if (profile && profile.provisioning) {
    return profile.freezeMode ? handleFreezeModeNotification(profile) : handleV2UpgradeNotification(profile);
  }
  return null;
};

function handleFreezeModeNotification({ provisioning }) {
  return  {
    component: provisioning[DigProvisions.EXTENSION_ENABLED] ? ExtensionFreezeModeNotification : DhhFreezeModeNotification ,
    type: BANNER_TYPES.WARNING,
  };
}

function handleV2UpgradeNotification({ userRole }) {
  const isV2MigrationProvisioned = checkProvision(PROVISIONS.ENABLE_SELF_SERVICE_V2_MIGRATION);
  const isFormV2Provisioned = checkProvision(PROVISIONS.FORM_V2);
  const isAppropriateRole = [ROLE_TYPES.ROLE_ADMIN, ROLE_TYPES.SUPER_ADMIN].includes(userRole);
  const propertyType = getPropertyType();
  const isAnywhereProperty = propertyType === 'anywhere';

  const isShowUpgradeNotification = !isFormV2Provisioned && isV2MigrationProvisioned && isAppropriateRole && !isAnywhereProperty;

  return isShowUpgradeNotification ? {
    component: EnablementFormNotification,
    type: BANNER_TYPES.ANNOUNCEMENT,
  } : null;
}
