import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, CardFooter } from '@m/alchemy-ui';
import IconSvg from '@mdigital/components/dist/components/IconSvg';
import { StyledCardContainer } from './StyledSelectionPageCard';
import { VIEW_TYPE, TriggerContext } from '../..';
import { UseCardFormHistory } from '../hooks';

const TriggerCard = ({ cardIcon , cardBodyText, cardFooterText, triggerType }) => {
  const { addForm } = UseCardFormHistory();
  const { setViewType , formID, onClickChooseNewTriggerType } = useContext(TriggerContext);

  const onClickTriggerType = () => {
    addForm(formID, { triggerType });
    setViewType(VIEW_TYPE.TRIGGER_PAGE);
    onClickChooseNewTriggerType(triggerType);
  };

  return (
    <StyledCardContainer>
      <Card className='card-wrapper' hasHoverEffect={true} shadowDepth="moduleChrome" hasBorder width='306px' height='192px' onClick={ onClickTriggerType }>
        <CardHeader className='card-header' align='center'>
          <IconSvg width={48} height={48} icon={cardIcon}/>
        </CardHeader>
        <CardBody className='card-body'>{cardBodyText}</CardBody>
        <CardFooter className='card-footer'>{cardFooterText}</CardFooter>
      </Card>
    </StyledCardContainer>
  );
};

export default TriggerCard;

TriggerCard.propTypes = {
  cardIcon: PropTypes.any,
  cardBodyText: PropTypes.string,
  cardFooterText: PropTypes.string,
  triggerType: PropTypes.string,
};

TriggerCard.defaultProps = {
  cardIcon: null,
  cardBodyText: '',
  cardFooterText: '',
  triggerType: '',
};