import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Modal from '@mdigital/components/dist/components/Modal';
import Wizard from '@mdigital/components/dist/components/Wizard';
import { StyledAppRatingWizardModal , AppRatingHeaderSpeartor } from './StyledAppRatingWizardModal';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Targeting from './Steps/Targeting';
import Destination from './Steps/Destination';
import { ModalGlobalDataConsumer } from '../index';
import MobileEngagementDesign, { FLAVORS } from '../../../components/MobileEngagementDesign';
import { mutateMobileBannerPosition , setMobileRatingScoresIsActive } from '../../../common/mutators';
import { PROPERTY_TYPES } from '../../../../common/constants';

const SET_PROMOT_PAGE_INDEX = 0;
const DESTINATION_PAGE_INDEX = 2;

class AppRatingWizardModal extends React.Component {

  state = { currentStepIndex: SET_PROMOT_PAGE_INDEX }

  handleSubmit = (data) => {
    this.props.onSubmit(data);
    this.setStepIndex(SET_PROMOT_PAGE_INDEX);
  }

  setStepIndex = (idx) => !isNaN(idx) && this.setState({ currentStepIndex: idx })

  handleCancel = (formState) => () => {
    this.props.onClose(formState);
    this.setStepIndex(SET_PROMOT_PAGE_INDEX);
  }

  validateRequiredFields = (errors) => () => {
    if (errors.name) { return; }
    const { trigger, appRatingUrl } = errors;
    if (trigger) {
      return this.setStepIndex(SET_PROMOT_PAGE_INDEX);
    }
    if (appRatingUrl) {
      return this.setStepIndex(DESTINATION_PAGE_INDEX);
    }
  }

  isMobileDirectEnabled = (propertyType, provisions) => (
    (propertyType === PROPERTY_TYPES.IOS && provisions.mobileSDKV2AppRatingsiOSDirect) ||
    (propertyType === PROPERTY_TYPES.ANDROID && provisions['22dcr1_MPC_4800_mobileSDKV2AppRatingsAndroidDirect'])
  );

  isDestinationFeatured = (showDestinationPage, propertyType, provisions) => showDestinationPage && !this.isMobileDirectEnabled(propertyType, provisions)

  render() {



    return (
      <Modal
        show={this.props.show}
        width="1175px"
        height="776px"
        style={{ zIndex: 9999, overflow: 'auto' }}>
        <StyledAppRatingWizardModal>
          <Form
            onSubmit={this.handleSubmit}
            initialValues={this.props.initialValues}
            mutators={{ mutateMobileBannerPosition , setMobileRatingScoresIsActive }}
            render={({ handleSubmit, pristine, invalid, submitFailed, errors, values, form: { mutators } }) => (
              <form onSubmit={handleSubmit}>
                <ModalGlobalDataConsumer>{({ showDestinationPage, provisions, propertyType , helpLink , isDarkModeEnabled, isNativeAlertRevamp }) => (
                  <Wizard
                    prerenderSteps
                    currentStepIndex={this.state.currentStepIndex}
                    onStepTitleClick={this.setStepIndex}
                    hideStatusBar={this.isMobileDirectEnabled(propertyType, provisions)}>
                    <Wizard.Header>
                      <Header onCloseClick={this.handleCancel({ pristine, invalid })} />
                      { this.isMobileDirectEnabled(propertyType, provisions) && <AppRatingHeaderSpeartor /> }
                    </Wizard.Header>
                    {!this.isMobileDirectEnabled(propertyType, provisions) &&
                      <Wizard.Step title="1. Set Prompt">
                        <MobileEngagementDesign
                          propertyType={propertyType}
                          mutators={mutators}
                          engagementType={values.trigger.invitationType}
                          customInterceptSupported={provisions.mobileSDKV2CustomIntercept}
                          flavor={FLAVORS.APP_RATING}
                          helpCenterLinks={this.props.helpCenterLinks}
                          isDarkModeEnabled={isDarkModeEnabled}
                          isNativeAlertRevamp={isNativeAlertRevamp}
                          isPushNotificationsSupported={false}/>
                      </Wizard.Step>
                    }
                    <Wizard.Step title="2. Target">
                      <Targeting appRatingCustomRules={this.props.appRatingCustomRules} helpLink={helpLink}
                        isMobileDirectEnabled={this.isMobileDirectEnabled(propertyType, provisions)} mutators={mutators} />
                    </Wizard.Step>
                    { this.isDestinationFeatured(showDestinationPage, propertyType, provisions) &&
                  <Wizard.Step title="3. Destination">
                    <Destination />
                  </Wizard.Step>
                    }
                    <Wizard.Footer>
                      <Footer onCancel={this.handleCancel({ pristine, invalid })} onOkClick={this.validateRequiredFields(submitFailed && errors)} />
                    </Wizard.Footer>
                  </Wizard>
                )}</ModalGlobalDataConsumer>
              </form>
            )}
          />
        </StyledAppRatingWizardModal>
      </Modal>
    );
  }
}

AppRatingWizardModal.propTypes = {
  show: PropTypes.bool,
  initialValues: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  helpCenterLinks : PropTypes.object,
};

AppRatingWizardModal.defaultProps = {
  show: false,
  initialValues: {},
  onClose: () => null,
  onSubmit: () => null,
  helpCenterLinks : {},
};

export default AppRatingWizardModal;
