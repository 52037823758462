import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import DigCard from '@mdigital/components/dist/components/Card';

const StyledCardContainer = styled.span`
  width: 25%;
  float: left;

  &.no-action-panel-card {
    .dig-card-id {
      bottom: 0 !important;
    }
  }
`;

function renderCard(card, i) {
  return (
    <StyledCardContainer key={i} className={!card.actionPanelCollection.length && 'no-action-panel-card'}>
      <DigCard
        cardTitle={card.name}
        labels={card.labels}
        switchState={card.published}
        onSwitchToggle={this.onSwitchToggle || card.onPublishToggle}
        maxActionsInRow={4}
        actionPanelCollection={card.actionPanelCollection}
        isSpinnerActive={card.isSpinnerActive}
        cardId={card.id}
        cardObject={card}
      />
    </StyledCardContainer>
  );
}

export default function DigFormCardCollection({ cards = [],onSwitchToggle }) {
  return (
    <React.Fragment>
      <ReactTooltip place="top" type="dark" effect="solid" />
      {cards.map(renderCard, { onSwitchToggle })}
    </React.Fragment>
  );
}

DigFormCardCollection.propTypes = {
  cards: PropTypes.array,
  onSwitchToggle: PropTypes.func,
};

