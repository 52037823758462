import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import BlockableRow from '../BlockableRow';
import { parseToPositiveNumber } from '../../../../../../../common/utils/fieldValidators';

const IS_ACTIVE_KEY = 'trigger.rules.MobileAppRatingSubmitted.isActive';

const StyledElement = styled.div`
  input {
    width: 65px;
  }
`;

const TargetByPreviousAccept = () => (
  <BlockableRow
    title="Don’t prompt a visitor who accepted an App Rating prompt in the past"
    isActiveFieldName={IS_ACTIVE_KEY}
    labelWeight={3}
    inputWeight={4}>
    <StyledElement>
      <Field parse={(value) => parseToPositiveNumber(value)} name="trigger.rules.MobileAppRatingSubmitted.params.days" render={({ input }) => (
        <React.Fragment>
          <input {...input} type="number" /><label>&nbsp;&nbsp;Days</label>
        </React.Fragment>
      )} />
    </StyledElement>
  </BlockableRow>
);

export default TargetByPreviousAccept;
