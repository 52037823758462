"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupComponentsByPage = exports.withoutEmptyConditions = exports.convertAutoOpenScreenCapture = exports.convertToBuilderState = void 0;
const state_1 = require("./ConditionBuilder/state");
const convertToBuilderState = (cdSource) => {
    if (cdSource == null) {
        return {
            type: 'formula',
            logicalOperator: 'and',
            conditions: [],
        };
    }
    else {
        if (cdSource.type === 'formula') {
            return cdSource;
        }
        else {
            return {
                type: 'formula',
                logicalOperator: 'and',
                conditions: [cdSource.type == null ? Object.assign({ type: 'component' }, cdSource) : cdSource],
            };
        }
    }
};
exports.convertToBuilderState = convertToBuilderState;
const convertAutoOpenScreenCapture = (cdSource) => {
    var _a;
    if (cdSource == null) {
        return false;
    }
    else {
        if (cdSource.type === 'component' || cdSource.type === 'formula') {
            return (_a = cdSource.autoOpenScreenCapture) !== null && _a !== void 0 ? _a : false;
        }
    }
    return false;
};
exports.convertAutoOpenScreenCapture = convertAutoOpenScreenCapture;
const withoutEmptyConditions = (conditions) => {
    return conditions.reduce((previous, current) => {
        if ((0, state_1.isGroup)(current)) {
            const filteredConditions = (0, exports.withoutEmptyConditions)(current.conditions);
            if (filteredConditions.length > 0) {
                previous.push(Object.assign(Object.assign({}, current), { conditions: filteredConditions }));
            }
        }
        else {
            previous.push(current);
        }
        return previous;
    }, []);
};
exports.withoutEmptyConditions = withoutEmptyConditions;
const groupComponentsByPage = (components) => {
    const result = components.reduce((previous, element) => {
        const value = element.pageName;
        return Object.assign(Object.assign({}, previous), { [value !== null && value !== void 0 ? value : 'current']: [...(previous[value !== null && value !== void 0 ? value : 'current'] || []), element] });
    }, {});
    const pageNames = [...Object.keys(result)];
    const indexOfCurrent = pageNames.indexOf('current');
    if (indexOfCurrent > 0) { // change position to the first if exists and not first
        pageNames.splice(indexOfCurrent, 1);
        pageNames.unshift('current');
    }
    return pageNames
        .map(item => ({ page: item === 'current' ? undefined : item, components: result[item] }));
};
exports.groupComponentsByPage = groupComponentsByPage;
