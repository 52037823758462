import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledUploaderView } from './styles';
import DigButton from '@mdigital/components/dist/components/Button';
import DigInput from '@mdigital/components/dist/components/Input';
import { FileUploader } from '@mdigital/components/dist/components/FileUploader';
import { IMAGE_FILE_TYPES } from '../../../common/enums';
import { MAX_LIMIT_BYTES } from '../../../common/constants';
import { COLORS } from '../../../common/colors';

const DEFAULT_SIZE = '100%';
const VIEW_UPLOADER = {
  UPLOAD_URL: '/kma/api/upload',
  NO_FILE: 'No File',
  NO_IMAGE: 'No Image',
  FAILURE_STATUS: 'FAILURE',
  GENERAL_ERROR: 'Upload failed. The file is invalid',
};
const DEFAULT_INPUT_STYLE = { width: DEFAULT_SIZE, border: `1px solid ${COLORS.INPUT_BORDER}` };

export default class UploaderView extends Component {
  constructor(props){
    super(props);
    this.state = {
      url: VIEW_UPLOADER.UPLOAD_URL,
      isFileSelected: false,
      validTypes: [],
      inputValue: this.props.inputValue || '',
      showErr: false,
      errMsg: this.props.errMessage,
    };
  }

  componentDidMount() {
    this.setState(
      {
        validTypes: this.props.validTypes,
        isFileSelected: this.isInputValueExists() });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inputValue !== this.props.inputValue) {
      this.setState({ inputValue: this.props.inputValue, isFileSelected: this.isInputValueExists() });
    }
  }

   onSuccess = (res) => {
     if(res.resultCode === VIEW_UPLOADER.FAILURE_STATUS) {
       this.setState(
         {
           inputValue: res.file.name,
           showErr: true,
           errMsg: VIEW_UPLOADER.GENERAL_ERROR,
           isFileSelected: true,
         });
     } else {
       this.setState({ inputValue: res.name, isFileSelected: true });
       const reader = new FileReader();
       reader.onloadend = (e) => this.sendImageUrlPreview(e, this.props.onSuccess, res);
       reader.readAsDataURL(res.file);
     }
   };

  onError = (err) => err;

  isInputValueExists() {
    return this.props.inputValue.length > 0;
  }

  clearInput = () => {
    const removeCallback = this.props.onSuccess;
    this.setState({ inputValue: '', isFileSelected: false, showErr: false });
    this.execCallback(removeCallback, { name: '', url: '', uuid: '' });
  };

  sendImageUrlPreview(e, onSuccessMethod, fileobj) {
    this.setState({ showErr: this.isExceededSize(e.total) });
    if(!this.isExceededSize(e.total)) {
      const uploadObject = { name: fileobj.name, url: e.target.result, uuid: fileobj.uuid };
      this.execCallback(onSuccessMethod, uploadObject);
      return;
    }
    return;
  }

  execCallback(methodObject, object) {
    const args = methodObject.args || [];
    const callback = methodObject.callback;
    callback && callback.apply(null,[object, ...args]);
  }

  isExceededSize = (size) => size > MAX_LIMIT_BYTES;


  setMessageColor() {
    const baseClassName = 'msg-info';
    return this.state.showErr ? `${baseClassName} msg-err` : baseClassName;
  }

  render(){
    const dynamicWidthBase = { flexBasis: this.props.size };
    const uploadParams = { url: this.state.url, accept:this.state.validTypes, onResponse: this.onSuccess, onError: this.onError };
    return(
      <StyledUploaderView {...this.props} data-aut={this.props.autLabel}>
        <div style={dynamicWidthBase}>
          <div className="custom-upload">
            <FileUploader fileUploaderStyle={{ width:'48%' }} {...uploadParams}>
              <DigButton
                digAutLabel="neb-upload-file"
                digType="primary"
                text="Upload"
                digIsDisabled={this.props.disabled}
                digClassName="upload-btn">
              </DigButton>
            </FileUploader>
            <DigInput
              digAutLabel="neb-file-name"
              className="dig-input"
              digClass= "dig-input"
              digValue={this.state.inputValue}
              digPlaceholder={VIEW_UPLOADER.NO_FILE}
              digIsDisabled={true}
              parentCustomStyle={DEFAULT_INPUT_STYLE}>
            </DigInput>
          </div>
          <div data-aut="neb-message" className={this.setMessageColor()}>
            {this.state.showErr ? this.state.errMsg : this.props.infoMessage}
          </div>
        </div>
        <span className="delete-container">
          {this.state.isFileSelected && <i data-aut="neb-delete-file" onClick={this.clearInput} className="fa fa-trash font-awesome-icon clickable icon-custom-style"></i>}
        </span>
      </StyledUploaderView>
    );
  }
}

UploaderView.propTypes = {
  size: PropTypes.string,
  onSuccess: PropTypes.shape({ callback: PropTypes.func, args: PropTypes.arrayOf(PropTypes.string) }),
  onRemoveFile: PropTypes.shape({ callback: PropTypes.func, args: PropTypes.arrayOf(PropTypes.string) }),
  validTypes: PropTypes.arrayOf(PropTypes.string),
  inputValue: PropTypes.string,
  infoMessage: PropTypes.string,
  errMessage: PropTypes.string,
  autLabel: PropTypes.string,
  disabled: PropTypes.bool,
};
UploaderView.defaultProps = {
  size: DEFAULT_SIZE,
  validTypes: [IMAGE_FILE_TYPES.PNG, IMAGE_FILE_TYPES.JPG, IMAGE_FILE_TYPES.JPEG, IMAGE_FILE_TYPES.GIF],
  inputValue: '',
  infoMessage: null,
  errMessage: '',
  additionalArgs: null,
  autLabel: 'neb-upload-view',
};
