import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledSdkIntegrationV2ModalContainerComponent } from './StyledSdkIntegrationV2Modal';
import Modal from '@mdigital/components/dist/components/Modal';
import { copyToClipboard } from '../../common/utils';
import SdkIntegrationV2ModalHeader from './SdkIntegrationV2ModalHeader';
import SdkIntegrationV2ModalBody from './SdkIntegrationV2ModalBody';
import SdkIntegrationV2ModalFooter from './SdkIntegrationV2ModalFooter';
import SdkIntegrationV2ModalErrorBody from './SdkIntegrationModalErrorBody';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';

const textareaRef = React.createRef(null);
const copyTimer = 1500;
export default class SdkIntegrationV2Modal extends Component {

 static propTypes = {
   apiToken: PropTypes.string,
   sdkDocsLink : PropTypes.string,
   propertyType : PropTypes.string,
   onClose: PropTypes.func,
   anywhereAuthDetails: PropTypes.string,
 };

  static defaultProps = {
    apiToken: null,
    sdkDocsLink : null,
    propertyType : null,
    onClose: null,
    anywhereAuthDetails: null,
  }

  state = {
    isCopied : false,
  }

    copyApiTokenToClipboard = () => {
      copyToClipboard(this.props.apiToken);
      this.setState({ isCopied : true });
      this.selectApiToken();
    };

    selectApiToken = () =>{
      const input = textareaRef.current;
      input.select();
      setTimeout( () => { 
        this.setState({ isCopied: false });
        input.selectionStart = input.selectionEnd = -1;
      }, copyTimer );
    }

    render() {
      const { apiToken , sdkDocsLink , propertyType , onClose , anywhereAuthDetails } = this.props;
      return (
        <Modal show width={'496px'} onOutsideClick={onClose}>
          <StyledSdkIntegrationV2ModalContainerComponent>
            <SdkIntegrationV2ModalHeader onClose={onClose} propertyType={propertyType} />

            {apiToken ? <SdkIntegrationV2ModalBody bodyTextareaRef={textareaRef} propertyType={propertyType} apiToken={apiToken} 
              anywhereAuthDetails={getNestedProperty(anywhereAuthDetails , 'value')} /> : <SdkIntegrationV2ModalErrorBody/> }

            <SdkIntegrationV2ModalFooter isCopied={this.state.isCopied} onClose={onClose} sdkDocsLink={sdkDocsLink} 
              apiToken={apiToken} copyApiTokenToClipboard={this.copyApiTokenToClipboard}/>

          </StyledSdkIntegrationV2ModalContainerComponent>
        </Modal>            
      );
    }
}

