import styled from 'styled-components';
import { units } from '@m/alchemy-theme';

export const StyleExpirationRadio = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 363px;
  padding-left: ${units(0.5)};
`;

export const StyleExpirationOption = styled.div`
  display: flex;

  & div {
    max-width: 128px;
    min-width: 70px;
    margin-left: 4px;

    input:first-child {
      min-width: 70px;
    }
  }
`;
