import React from 'react';
import PropTypes from 'prop-types';
import ExpandableList from '@mdigital/components/dist/components/ExpandableList';
import ThankYouPromptGeneralSettings from './ThankYouPromptGeneralSettings';
import ThankYouPromptButtonSettings from './ThankYouPromptButtonSettings';
import ThankYouPromptDuration from './ThankYouPromptDuration';
import ThankYouPromptAccessibility from './ThankYouPromptAccessibility';
import ThankYouPromptDarkMode from './ThankYouPromptDarkMode';
import Toggle from '@mdigital/components/dist/components/Toggle';
import FieldRow from '../../MobileEngagementDesign/Banner/SettingsExpandableList/shared/FieldRow';
import { Field , FormSpy } from 'react-final-form';
import { StyledThankYouPromptSettingsToggle , StyledThankYouPromptSettingsTitle,
  StyledThankYouPromptSettingsHeader , StyledThankYouPromptSettingHelpLink } from './StyledThankYouPromptSettings';
import { getSingleText } from '../../../../common/utils';

const ThankYouPromptSettingsFields = ( { propertyType , disabled , mutators , submitButtonColors, reusableResourcesEnabled , isDarkModeEnabled } ) => (
  <React.Fragment>
    <ExpandableList disabled={disabled} height="470px">

      <ExpandableList.Item label="General Settings">
        <ThankYouPromptGeneralSettings
          propertyType={propertyType}
          mutators={mutators}
          disabled={disabled}
          reusableResourcesEnabled={reusableResourcesEnabled} />
      </ExpandableList.Item>

      <ExpandableList.Item label="Button" info={getSingleText('app.pages.forms.builder.formSettingsModal.thankYouPrompt.buttonInfo')}>
        <ThankYouPromptButtonSettings mutators={mutators} submitButtonColors={submitButtonColors} />
      </ExpandableList.Item>

      <ExpandableList.Item label="Duration" nonExpandable info={getSingleText('app.pages.forms.builder.formSettingsModal.thankYouPrompt.durationInfo')}>
        <ThankYouPromptDuration disabled={disabled} />
      </ExpandableList.Item>

      {isDarkModeEnabled &&
      <ExpandableList.Item label="Dark Mode Support" info={getSingleText('app.pages.forms.builder.formSettingsModal.thankYouPrompt.darkModeInfo')}>
        <ThankYouPromptDarkMode
          reusableResourcesEnabled={reusableResourcesEnabled}
          mutators={mutators}
          disabled={disabled}/> 
      </ExpandableList.Item>}

      <ExpandableList.Item label="Accessibility" info={getSingleText('app.pages.forms.builder.formSettingsModal.thankYouPrompt.accessibilityInfo')}>
        <ThankYouPromptAccessibility />
      </ExpandableList.Item>

    </ExpandableList>
  </React.Fragment>
);

const ThankYouPromptSettings = ( { propertyType , mutators , thankYouPromptHelpLink , submitButtonColors, reusableResourcesEnabled, isDarkModeEnabled, isPublished } )=> (
  <React.Fragment>
    <StyledThankYouPromptSettingsTitle>{getSingleText('app.pages.forms.builder.formSettingsModal.thankYouPrompt.title')}</StyledThankYouPromptSettingsTitle>
    <StyledThankYouPromptSettingsHeader>
      {getSingleText('app.pages.forms.builder.formSettingsModal.thankYouPrompt.thankYouPromptHeader')}
      <StyledThankYouPromptSettingHelpLink href={thankYouPromptHelpLink}>
        {getSingleText('app.pages.forms.builder.formSettingsModal.thankYouPrompt.moreInfo')}
      </StyledThankYouPromptSettingHelpLink>
    </StyledThankYouPromptSettingsHeader>
    <StyledThankYouPromptSettingsToggle disabled={isPublished}>
      <FieldRow title="Show Thank You Prompt" name="trigger.showThankYouPrompt" render={({ input }) => (
        <Toggle onToggle={() => input.onChange(!input.value)} switchState={input.value} />
      )} />
    </StyledThankYouPromptSettingsToggle>
    <FormSpy subscription={{ values: true }} render={({ values }) => (
      <Field name="trigger.showThankYouPrompt" render={() => (
        <ThankYouPromptSettingsFields
          propertyType={propertyType}
          disabled={!values.trigger.showThankYouPrompt || isPublished}
          mutators={mutators} 
          reusableResourcesEnabled={reusableResourcesEnabled}
          submitButtonColors={submitButtonColors}
          isDarkModeEnabled={isDarkModeEnabled}/> 
      )} />
    )} />
  </React.Fragment>
);

ThankYouPromptSettings.propTypes = {
  propertyType : PropTypes.string,
  mutators: PropTypes.object,
  thankYouPromptHelpLink: PropTypes.string,
  submitButtonColors : PropTypes.object,
  isDarkModeEnabled : PropTypes.bool,
  isPublished : PropTypes.bool,
};

ThankYouPromptSettings.defaultProps = {
  propertyType: 'mobileIOS',
  mutators: {},
  thankYouPromptHelpLink : '',
  submitButtonColors : {},
  isDarkModeEnabled : false,
  isPublished: false,
};

export default ThankYouPromptSettings;