"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubjectSelect = void 0;
const alchemy_ui_1 = require("@m/alchemy-ui");
const React = __importStar(require("react"));
const utils_1 = require("../../../../common/utils");
const conversions_1 = require("../conversions");
const ComponentPrefix = 'component:';
const CustomParamPrefix = 'custom-param:';
const CustomParamsText = 'app.pages.forms.builder.tabs.fieldSettings.conditionalDisplayModal.customParams';
const SelectFromListText = 'app.pages.forms.builder.tabs.fieldSettings.conditionalDisplayModal.selectFromList';
const CurrentPageText = 'app.pages.forms.builder.tabs.fieldSettings.conditionalDisplayModal.currentPage';
const FieldsOnText = 'app.pages.forms.builder.tabs.fieldSettings.conditionalDisplayModal.fieldsOn';
const pagedComponentsOptions = (pages) => {
    return pages.map((page) => {
        const labelText = (0, utils_1.getSingleText)(FieldsOnText);
        const label = labelText.replace('{{pageName}}', page.page === undefined ? (0, utils_1.getSingleText)(CurrentPageText) : page.page);
        return {
            label,
            value: `components${page.page}`,
            options: page.components.map((item) => ({
                label: item.unique_name,
                value: ComponentPrefix + item.nebUuid
            }))
        };
    });
};
const SubjectSelect = (props) => {
    const componentsList = (0, conversions_1.groupComponentsByPage)(props.components);
    let value = undefined;
    if (props.subject.type.input.value === 'custom-param') {
        value = CustomParamPrefix + props.subject.customParamName.input.value;
    }
    else if (props.subject.type.input.value === 'component') {
        value = ComponentPrefix + props.subject.nebUuid.input.value;
    }
    const handleOption = (option) => {
        const value = option.value;
        if (value.indexOf(ComponentPrefix) === 0) {
            const uuid = value.substr(ComponentPrefix.length);
            const component = props.components.filter(item => item.nebUuid === uuid)[0];
            if (component != null) {
                props.subject.type.input.onChange('component');
                props.subject.componentType.input.onChange(component.component);
                props.subject.nebUuid.input.onChange(uuid);
                props.subject.id.input.onChange(component.id);
                props.subject.customParamType.input.onChange(undefined);
                props.subject.customParamName.input.onChange(undefined);
            }
        }
        else if (value.indexOf(CustomParamPrefix) === 0) {
            const name = value.substr(CustomParamPrefix.length);
            const customParam = props.customParams.filter(item => item.name === name)[0];
            if (customParam != null) {
                props.subject.type.input.onChange('custom-param');
                props.subject.customParamType.input.onChange(customParam.type);
                props.subject.customParamName.input.onChange(customParam.name);
                props.subject.componentType.input.onChange(undefined);
                props.subject.nebUuid.input.onChange(undefined);
                props.subject.id.input.onChange(undefined);
            }
        }
        props.operator.input.onChange(undefined);
        props.value.input.onChange(undefined);
    };
    return (React.createElement(alchemy_ui_1.Field, { isFullWidth: true, status: props.subject.type.meta.error && props.subject.type.meta.touched
            ? { level: 'error', message: props.subject.type.meta.error }
            : undefined, input: React.createElement(alchemy_ui_1.Select, { disabled: props.disabled, placeholder: (0, utils_1.getSingleText)(SelectFromListText), value: value, isSearchable: true, options: [
                ...props.components.length > 0 ? pagedComponentsOptions(componentsList) : [],
                ...props.customParams.length > 0 ? [{ label: (0, utils_1.getSingleText)(CustomParamsText), value: "custom-params", options: props.customParams.map(item => ({
                            label: item.name,
                            value: CustomParamPrefix + item.name,
                        })) }] : [],
            ], onChange: (option) => handleOption(option) }) }));
};
exports.SubjectSelect = SubjectSelect;
