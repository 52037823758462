import React from 'react';
import { connect } from 'react-redux';
import { StyledAppInfoHorizontalStack, StyledProfileInfoTable } from './StyledAppAndProfileInfo';
import DigVerticalStack from '@mdigital/components/dist/components/VerticalStack';

const AppAndProfileInfo = ({ versionId, accountId, propertyId }) => (
  <DigVerticalStack gap={3} alignItems="left">
    <StyledAppInfoHorizontalStack gap={3}>
      <span>V</span>
      <span>{versionId}</span>
    </StyledAppInfoHorizontalStack>
    <StyledProfileInfoTable>
      <tbody>
        <tr>
          <td>Account ID:</td>
          <td>{accountId}</td>
        </tr>
        <tr>
          <td>Property ID:</td>
          <td>{propertyId}</td>
        </tr>
      </tbody>
    </StyledProfileInfoTable>
  </DigVerticalStack>
);
function mapStateToProps({ profile, app }) {
  return {
    versionId: app.versionId,
    accountId: profile.accountId,
    propertyId: profile.propertyId,
  };
}

export default connect(mapStateToProps)(AppAndProfileInfo);
