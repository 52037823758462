import styled from 'styled-components';
import DigHorizontalStack  from '@mdigital/components/dist/components/HorizontalStack';

export const StyledAppInfoHorizontalStack = styled(DigHorizontalStack)`
  font-size:12px;
  font-weight:300;
`;

export const StyledProfileInfoTable = styled.table`
  td:last-child {
    padding-left: 3px;
  }
  td {
    font-size:10px;
    font-weight:300;
  }
}`;