import React from 'react';
import { Field } from 'react-final-form';
import { isRequired } from '../../../common/utils/fieldValidators';
import {  StyledFormSettingsFieldContainer , StyledFormSettingsFieldLabel , StyledFormSettingsField , FormSettingsErrorMessage } from './StyledFormSettingsField';

const FormSettingsField = ({ name, title, errorMessage, required, render, className, isDisabled }) => (
  <Field parse={(value) => (value === '' ? '' : value)} validate={required && isRequired} name={name} render={({ input, meta: { error } }) => (
    <StyledFormSettingsFieldContainer className={className}>
      <StyledFormSettingsFieldLabel hasError={error} required={required}>
        <label>{title}</label>
      </StyledFormSettingsFieldLabel>
      <StyledFormSettingsField hasError={error} disabled={isDisabled}>
        {render({ input, error })}
        {error && <FormSettingsErrorMessage>{errorMessage}</FormSettingsErrorMessage>}
      </StyledFormSettingsField>
    </StyledFormSettingsFieldContainer>
  )}/>
);

export default FormSettingsField;