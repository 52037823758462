import React from 'react';
import PropTypes from 'prop-types';
import { StyledSubtitleContainer , StyledHorizontalSeparator, StyledDraftToggle } from './StyledModalSubtitle';
import FormPublishSwitcher from '../../FormPublishSwitcher';
import { ShownMode } from '../../../../common/enums';
import { DiscardChangesModal } from '../DiscardChangesModal';

const ModalSubtitle = ({ formName, draftVersion, toggleDraft, isWithToggle }) => {
  const onClickChangeDisplayedVersion = (version)=> {
    version === ShownMode.DRAFT ? changeDisplayedVersion(version) :
      DiscardChangesModal(changeDisplayedVersion, version);
  };

  const changeDisplayedVersion = (version) => {
    toggleDraft(version);
  };

  return (
    <React.Fragment>
      <StyledSubtitleContainer>
        {formName}
      </StyledSubtitleContainer>
      {isWithToggle &&
        <StyledDraftToggle>
          <FormPublishSwitcher displayedVersion={draftVersion} changeDisplayedVersion={onClickChangeDisplayedVersion}/>
        </StyledDraftToggle>
      }
      <StyledHorizontalSeparator/>
    </React.Fragment>
  );
};

export default ModalSubtitle;

ModalSubtitle.propTypes = {
  formName: PropTypes.string,
};

ModalSubtitle.defaultProps = {
  formName: '',
};