//#region imports
import React, { Fragment } from 'react';
import { getSingleText } from '../../../common/utils';
import { MultiOptionIconEnum } from './multi-option-editor.enum';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import trash from '../../assets/actions/trash.png';
import hidden from '../../assets/actions/hidden-eye.png';
import visible from '../../assets/actions/visible-eye.png';
import DigTooltip from '@mdigital/components/dist/components/Tooltip';
//#endregion imports

const WARNING_ICON = 'react/assets/actions/warning.svg';
const BASE_TEXT = 'app.pages.forms.builder.tabs.fieldSettings.hideOptions.';
const IMG_STYLE = { width: 16, height: 16, cursor: 'no-drop' };

const CustomDigToolTip = ({ tooltipName, isDisabled, style, tooltipText, children }) => (
  <DigTooltip
    name={ tooltipName }
    digDisabled={ isDisabled }
    digStyle={ style }
    digTooltip={ tooltipText }
    digElement={ () => children } />
);

const StyledCustomDigToolTip = styled(CustomDigToolTip)`
  top: 7;
  left: 10;
  position: 'absolute';
`;

const TrashIcon = ({ onClick }) => <i role='button' onClick={onClick}> <img src={trash}/> </i>;

const WarningIcon = ({ tooltipText }) => (
  <i role='button'>
    <StyledCustomDigToolTip
      isDisabled={false}
      tooltipName='alert'
      tooltipText={tooltipText}>
      <img
        src={WARNING_ICON}
        style={ IMG_STYLE } />
    </StyledCustomDigToolTip>
  </i>
);

/**
 * Business Case:
 * -  The trash icon will always be displayed independently.
 * - The warning and eye icon can be displayed independently or together (these are MEC related)
 */
const RenderIcons = (props) => {
  const {
    option, 
    deleteOption, iconToDisplay, isEntryHidden,
    isTooltipDisabled, toggleHiddenOption, isAddRemoveDisabled, 
  } = props;

  const renderTrashIcon = !isAddRemoveDisabled && <TrashIcon onClick={deleteOption()}/>;
  const renderWarningIcon = option.mecLabelChanged && <WarningIcon tooltipText={`Matching label in MEC: ${option.mecLabel}`}/>;

  const RenderIcon = () => {
    switch (iconToDisplay) {
      case MultiOptionIconEnum.Eye:
        return (
          <Fragment>
            <StyledCustomDigToolTip
              tooltipName='eye' 
              isDisabled={isTooltipDisabled}
              style={{ opacity: isTooltipDisabled ? 0.5 : 1 }}
              tooltipText={getSingleText(`${BASE_TEXT}${isEntryHidden ? 'UnHideOption' : 'hideOption'}`)}>
              <img
                onClick={toggleHiddenOption}
                src={isEntryHidden ? hidden : visible}
                style={{ ...IMG_STYLE, cursor: isTooltipDisabled ? 'no-drop' : 'pointer' }} />
            </StyledCustomDigToolTip>
            { renderWarningIcon }
          </Fragment>
        );

      case MultiOptionIconEnum.Trash:
        return renderTrashIcon;

      case MultiOptionIconEnum.Warning:
        return renderWarningIcon;

      default:
        return null;
    }
  };

  return <RenderIcon />;
};

/**
 * Business Case:
 * - The trash icon is displayed when non MEC integrated properties are used.
 * - The eye icon is displayed when MEC property + `21dcr6_MPC4143_enable_HideAlternativeOptions` provision.
 * - The warning icon is displayed when MEC property + having updated the val of an option from MEC app.
 */
function MultiOptionIcon(props) {
  const shouldDisplayTwoIcons = props.iconToDisplay === MultiOptionIconEnum.Eye && props.option.mecLabelChanged;

  return (
    <div className={`multi-option-icons ${shouldDisplayTwoIcons ? 'double-icon' : ''}`}>
      <RenderIcons {...props}/>
    </div>
  );
}

export default MultiOptionIcon;

//#region PropTypes
MultiOptionIcon.propTypes = {
  option: PropTypes.object,
  iconToDisplay: PropTypes.string,
};

CustomDigToolTip.propTypes = {
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
  tooltipName: PropTypes.string,
  tooltipText: PropTypes.string,
  children: PropTypes.element,
};

TrashIcon.propTypes = {
  onClick: PropTypes.func,
};

WarningIcon.propTypes = {
  tooltipText: PropTypes.string,
};

RenderIcons.propTypes = {
  option: PropTypes.object, 
  isEntryHidden: PropTypes.bool,
  deleteOption: PropTypes.func,
  iconToDisplay: PropTypes.string,
  isTooltipDisabled: PropTypes.bool,
  toggleHiddenOption: PropTypes.func,
  isAddRemoveDisabled: PropTypes.bool,
};
//#endregion PropTypes