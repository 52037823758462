import React, { useState } from 'react';
import { FormSpy } from 'react-final-form';
import LivePreviewLayout, { LIVE_PREVIEW_TYPE } from '../../Banner/BannerLivePreview/LivePreviewLayout';
import DarkModeSelect from '../../../DarkModeSelect';
import { StyledAlertPreview } from './StyledAlertLivePreview';
import { BannerPositionType } from '../../Banner/BannerLivePreview/BannerLivePreviewHelper';
import IOSAlertPreview from './IOSAlertPreview';
import AndroidAlertPreview from './AndroidAlertPreview';
import { PROPERTY_TYPES } from '../../../../../common/constants';

const AlertLivePreview = ({ propertyType, isDarkModeEnabled }) => {
  const [isDark , setIsDark] = useState(false);
  const isDarkEnabled = isDark && isDarkModeEnabled;

  return (
    <React.Fragment>
      {isDarkModeEnabled && <DarkModeSelect isDark={isDark} setIsDark={setIsDark}/>}
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => (
          <StyledAlertPreview propertyType={propertyType}>
            <LivePreviewLayout propertyType={propertyType} position={BannerPositionType.TOP} previewType={LIVE_PREVIEW_TYPE.ALERT} isDarkEnabled={isDarkEnabled}/>
            {propertyType === PROPERTY_TYPES.IOS ? 
              <IOSAlertPreview values={values} isDark={isDark}/> : 
              <AndroidAlertPreview values={values} isDark={isDark}/>}
          </StyledAlertPreview>
        )}/>
    </React.Fragment>
  );
};

export default AlertLivePreview;