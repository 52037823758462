import { PROPERTY_TYPES } from "../../../common/constants";

export const InitialFormTriggeringData = (formID, propertyType) => ({
  'formID':formID,
  'triggerType':'.MobileInvitationContract',
  'invitationHeadline':'Let us know what you think',
  'invitationText':'It only takes a couple of minutes and will help us improve our app',
  'provideButtonText':'Provide Feedback',
  'declineButtonText':'No Thanks',
  'laterButtonText':'Maybe Later',
  'skipInvitation':false,
  'invitationType':'BANNER',
  'triggerCustomBannerContract':{
    'invitationTitle':'Let us know what you think',
    'invitationBody':'It only takes a couple of minutes and will help us improve our app',
    'font': propertyType === PROPERTY_TYPES.IOS ? '-apple-system, BlinkMacSystemFont' : 'Roboto-Regular',
    'textColor':'#2E2E2C',
    'handleTextColor':'#cccccc',
    'backgroundColor':'#FFFFFF',
    'position':'TOP',
    'isPartial':true,
    'invitationTimeout':5000,
    'customBannerButtonsSettingsContract': {
      'buttonsDisplay': false,
      'acceptButtonText': 'Provide Feedback',
      'acceptButtonTextColor': '#FFFFFF',
      'acceptButtonBackgroundColor': '#121826',
      'closeButtonColor': '#7B7A7A',
    }
  },
  'triggerCustomLocalNotificationContract': {
    'title': 'Let us know what you think',
    'body': 'it only takes a couple of minutes and will help us improve our app',
    'appName': '',
    'expiration': {
      "value": 30,
      "unit": 'SECONDS',
      "isExpired": false
    }
  },
  "triggerCustomInterceptContract": {
    'customInvitationTitle': 'Let us know what you think',
    'customInvitationDescription': 'It only takes a couple of minutes and will help us improve our app'
  },
  "triggerScope": 'Foreground',
  'rules':{
    'MobileFrequency':
      {
        'params':
          {
          'frequency':30,
          'type':'INVITE_PERCENTAGE_OF_VISITORS',
          },
        'type':'MobileFrequency',
        'isActive':true,
      },
    'MobileTimeInForeground':
      {
          'params':
          {
            'compareString':'greaterThan',
            'seconds':30,
          },
          'type':'MobileTimeInForeground',
          'isActive':true, 
      },
      'MobileAppxDelay':
      {
          'params':
          {
            'seconds':10,
          },
          'type':'MobileAppxDelay',
          'isActive':true, 
      },
  },
});

export const TriggerByCodeInitialValues = (formID) => ({
  formID,
  'triggerType': '.CodeContract',
  'rules': {},
  'displayType': 'lightbox',
  'displayDirection': 'centerRight'
});

export const BANNER_INITIAL_COLOR_VALUES = {
    textColor: '#2E2E2C',
    backgroundColor: '#FFFFFF',
  customBannerButtonsSettingsContract: {
      acceptButtonTextColor: '#FFFFFF',
      acceptButtonBackgroundColor: '#121826',
      closeButtonColor: '#7B7A7A',
  },
  darkMode: {
      background: '#2E2E2C',
      text: '#ffffff',
      button: '#1C1C1E',
      buttonText: '#409CFF',
      closeButton: '#ffffff'
  }
};