import React,{ Component } from 'react';
import DigTable from '@mdigital/components/dist/components/Table';
import { connect } from 'react-redux';
import { getSingleText } from '../../../common/utils';
import withProvider from '../../hoc/ProviderHoc';
import { bind } from '@mdigital/components/dist/decorators';
import PropTypes from 'prop-types';

function mapStateToModalProps(state){
  return {
    // the state defined by the reducers
    customParameters : state.customParameters,
    customParametersActions: state.customParametersActions,
    provisions: state.profile.provisioning,
    propertyType: state.property.propertyType,
    allEngagements: state.allEngagements,
    allFormsEngagements: state.allFormsEngagements,
    allAppRatingsEngagements: state.allAppRatingsEngagements,
  };
}

@withProvider()
@connect(mapStateToModalProps)
export default class DigCustomParametersContainer extends Component {

  constructor(props){
    super(props);
    this.tooltips = getSingleText('app.pages.administration.customParams.tooltips');
  }
  @bind
  checkProvision(provision) {
    const provisions = this.props.provisions;
    return !!provisions && (provisions[provision] === true || provisions[provision] === 'true');
  }

  originNameId = 'OriginNameId';
  @bind
  getCustomParamsColumns(){

    let columns = [
      { label: 'Parameter Name', tooltip: this.tooltips.parameterName },
      { label: 'Parameter Type', tooltip: this.tooltips.parameterType },
      { label: `${this.props.propertyType === PropertyTypes.WEBSITE ? 'Origin ' : ''}Type`,    tooltip: this.tooltips.originType },
      { id: this.originNameId, label: 'Origin Name',    tooltip: this.tooltips.originName, isHidden:this.shouldHideOriginName() },
      { label: 'Attach Forms',   tooltip: this.tooltips.attachForm }
    ];
    return columns;
  }
  @bind
  shouldHideOriginName() {
    return this.checkProvision(DigProvisions.MOBILE_SDKV2_TABLE_HIDE_CUSTOM_PARAMETER_ORG_NAME)
    && !(this.props.propertyType === PropertyTypes.WEBSITE)
    ;
  }

  isDisabledForDelete(customParam) {
    const isInUseInAnyForm = customParam.customParameterPerForms
      .reduce((accu, curr) => accu || curr.isCustomParamInUse, false);
    return customParam.isIdentifier || isInUseInAnyForm;
  }

  getEngagementByType() {
    return {
      [CustomParamsActionsIds.ALL_PUBLISHED_FORMS]: this.props.allFormsEngagements,
      [CustomParamsActionsIds.ALL_PUBLISHED_RATINGS]: this.props.allAppRatingsEngagements,
      [CustomParamsActionsIds.All_PUBLISHED_FORMS_AND_RATINGS]: [...this.props.allFormsEngagements, ...this.props.allAppRatingsEngagements],
    };
  }

  isAppRatingDraftEnabled() {
    return this.checkProvision(DigProvisions.ENABLE_APP_RATING_DRAFTS);
  }

  isFormDraftEnabled() {
    return this.checkProvision(DigProvisions.ENABLE_SURVEY_DRAFTS);
  }

  // arriving here only if SurveyDraft.
  // render correct label which counts related drafts,
  // and when with publishedGrouping (-4/ -5 / -6) also counts all published of the relevant type
  getPublishedAndDraftsCount(customParams, engagementByType) {
    let attachedPublishedCount = 0;
    let attachedDraftsCount = 0;
    const isFormRelatedPublished = (form) => { return engagementByType[CustomParamsActionsIds.All_PUBLISHED_FORMS_AND_RATINGS].find((engagement) => engagement.id === form.id); };
    const getAllRelatedPublished = (form) => engagementByType[form.id];
    for (let form of customParams.customParameterPerForms) {
      switch (form.id) {
        case CustomParamsActionsIds.ALL_PUBLISHED_FORMS:
        case CustomParamsActionsIds.ALL_PUBLISHED_RATINGS:
        case CustomParamsActionsIds.All_PUBLISHED_FORMS_AND_RATINGS:
          attachedPublishedCount = getAllRelatedPublished(form);
          break;
        default:
          if (isFormRelatedPublished(form)) {
            attachedPublishedCount += 1;
          } else {
            attachedDraftsCount += 1;
          }
      }
    }
    return {
      attachedPublishedCount,
      attachedDraftsCount,
    };
  }

  getGroupLabel = (customParam) => {
    const isGroupAndSingleParams = customParam.customParameterPerForms.length > 1;
    const draftsCount = customParam.customParameterPerForms.length - 1;
    switch (customParam.customParameterPerForms[0].id) {
      case CustomParamsActionsIds.ALL_FORMS:
        return isGroupAndSingleParams && this.isFormDraftEnabled() ? `All Forms, ${draftsCount} Draft${draftsCount > 1 ? 's' : ''}` : 'All Forms';
      case CustomParamsActionsIds.ALL_RATING:
        return isGroupAndSingleParams && this.isAppRatingDraftEnabled() ? `All App Ratings, ${draftsCount} Draft${draftsCount > 1 ? 's' : ''}` : 'All App Ratings';
      case CustomParamsActionsIds.ALL_FORMS_AND_RATINGS:
        return 'All Forms & App Ratings';
    }
  }


  @bind
  formatAttachedFormsString(draftsCount, publishedCount) {
    return `${publishedCount > 0 ? (publishedCount + ' Published') : ''}` +
      `${publishedCount > 0 && draftsCount > 0 ? ', ' : ''}` +
      `${draftsCount > 0 ? draftsCount + ` Draft${draftsCount > 1 ? 's' : ''}` : ''} checked`;
  }
  @bind
  formatRows() {
    let customParameters;
    const isSurveyDraftEnabled = this.checkProvision(DigProvisions.ENABLE_SURVEY_DRAFTS);
    if (isSurveyDraftEnabled) {
      customParameters = this.props.customParameters;
    } else {
      customParameters = this.props.customParameters.map((cp) => {
        cp.customParameterPerForms = cp.customParameterPerForms.filter((cpPerForm) => cpPerForm.id !== CustomParamsActionsIds.ALL_PUBLISHED_FORMS);
        return cp;
      });
    }
    return customParameters
      .map((customParam) => {
        const attachForm = () => {
          const allAttachedFormsLength = customParam.customParameterPerForms.length;
          if (allAttachedFormsLength === 0) {
            return 'None';
          }
          const hasGroupingLabel = this.getGroupLabel(customParam);
          if (hasGroupingLabel) {
            return hasGroupingLabel;
          }
          const id = customParam.customParameterPerForms[0].id;
          if (id === CustomParamsActionsIds.ALL_PUBLISHED_RATINGS && !this.isAppRatingDraftEnabled() &&  customParam.customParameterPerForms.length === 1) {
            return 'None';
          }
          if (!isSurveyDraftEnabled) {
            return `${allAttachedFormsLength} checked`;
          } else {

            const engagementByType = this.getEngagementByType();
            const { attachedPublishedCount, attachedDraftsCount } = this.getPublishedAndDraftsCount(customParam, engagementByType, id);
            const publishedGroupsIds = [CustomParamsActionsIds.ALL_PUBLISHED_FORMS, CustomParamsActionsIds.ALL_PUBLISHED_RATINGS, CustomParamsActionsIds.All_PUBLISHED_FORMS_AND_RATINGS];
            return publishedGroupsIds.indexOf(id) > -1 ?
              this.formatAttachedFormsString(attachedDraftsCount , engagementByType[id].length) :
              this.formatAttachedFormsString(attachedDraftsCount, attachedPublishedCount);
          }
        };


        let rowValues = [{ value: customParam.name }, { value: customParam.type }, { value: customParam.source }, {
          value: customParam.source_name,
          columnId: this.originNameId,
        }, { value: attachForm() }];

        return {
          id: customParam.id,
          isLocked: !!customParam.isIdentifier,
          lockTooltip : this.tooltips.disableCustomParamTooltip,
          values: rowValues,
          rowObject: customParam,
          actions: [
            {
              icon: 'pencil',
              callback: this.props.customParametersActions.onEdit,
              autLabel: 'dig-row-edit',
              isShowOnLock: false,
            },
            {
              icon: 'trash',
              callback: this.props.customParametersActions.onDelete,
              isDisabled: this.isDisabledForDelete(customParam),
              tooltip: this.isDisabledForDelete(customParam) ? this.tooltips.disableCpDeletionTooltip : '',
              autLabel: 'dig-row-delete',
              isShowOnLock: false,
            }
          ],
        };
      });
  }

  render() {
    return (
      <DigTable
        digColumns={this.getCustomParamsColumns()}
        digRows={this.formatRows()} />
    );
  }
}


DigCustomParametersContainer.propTypes = {
  store: PropTypes.object,
};

