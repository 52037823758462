import React from 'react';
import Wizard from '@mdigital/components/dist/components/Wizard';
import SelectionGroup from '@mdigital/components/dist/components/SelectionGroupV2';
import PropTypes from 'prop-types';
import { getSingleText } from '../../../common/utils';
import { ACTIVE_BLUE } from '../../common/colors';
import { FORM_DISPLAYS, PROVISIONS } from '../../../common/enums';
import { StyledFormDisplay } from './StyledFormDisplay';
import { Lightbox } from './Lightbox';
import { AnimatedDisplay } from './AnimatedDisplay';
import { PopupWindow } from './PopupWindow';
import { checkProvision } from '../../../redux/store/store-helper';

FormDisplay.propTypes = {
  triggerType: PropTypes.string,
  sharedDomain: PropTypes.string,
  displayType: PropTypes.string,
  displayDirection: PropTypes.string,
  isSelfHosted: PropTypes.bool,
  selfHostedUrl: PropTypes.string,
  isReadOnlyMode: PropTypes.bool,
  onDirectionChange: PropTypes.func,
  onTypeChange: PropTypes.func,
  onHostChange: PropTypes.func,
  onHostedUrlChange: PropTypes.func,
};

function createTypeSelectedHandler(callback) {
  return ({ target: { value } }) => callback({ id: value });
}

export default function FormDisplay(props) {
  const isNoFormPopupEnabled = checkProvision(PROVISIONS.NO_FORM_POPUP);

  const SUPPORTED_DISPLAY_TYPES = [
    FORM_DISPLAYS.LIGHTBOX,
    FORM_DISPLAYS.ANIMATED
  ].concat(isNoFormPopupEnabled ? [] : FORM_DISPLAYS.POPUP);

  const currentStepIndex = SUPPORTED_DISPLAY_TYPES.findIndex((type) => type === props.displayType);

  return (
    <StyledFormDisplay>
      <p className="sans-md-bold">
        {getSingleText('app.pages.forms.invitationAndButton.formDisplayTab.label')}
      </p>
      <Wizard prerenderSteps currentStepIndex={currentStepIndex}
        hideStatusBar={true}>

        <Wizard.Header>
          <SelectionGroup
            disabled={props.isReadOnlyMode}
            fontWeight="lighter"
            activeColor={ACTIVE_BLUE}
            width="405"
            height="30"
            value={props.displayType}
            onChange={createTypeSelectedHandler(props.onTypeChange)}>
            {SUPPORTED_DISPLAY_TYPES.map((type) => (
              <SelectionGroup.Option 
                key={type}
                value={type}>{
                  getSingleText(`app.pages.forms.builder.formSettingsModal.basic.displayType.${type}`)
                }
              </SelectionGroup.Option>
            )
            )}
          </SelectionGroup>
        </Wizard.Header>

        <Wizard.Step>
          <Lightbox/>
        </Wizard.Step>

        <Wizard.Step>
          <AnimatedDisplay triggerType={props.triggerType}
            displayDirection={props.displayDirection}
            onDirectionChange={props.onDirectionChange}/>
        </Wizard.Step>

        {
          !isNoFormPopupEnabled && (
            <Wizard.Step>
              <PopupWindow sharedDomain={props.sharedDomain}
                isSelfHosted={props.isSelfHosted}
                selfHostedUrl={props.selfHostedUrl}
                onHostedUrlChange={props.onHostedUrlChange}
                onHostChange={props.onHostChange}/>
            </Wizard.Step>
          )
        }

      </Wizard>
    </StyledFormDisplay>
  );
}