import React, { useState } from 'react';
import {
  UpgradeModalOptionsContainer,
  SelectWrapper,
  StyledUpgradeModalSubTitle,
  StyledUpgradeModalTitle,
  StyledSubHeader
} from './FormV2EnablementUpgradeModalStyled';
import { getSingleText, isValidURL } from '../../../common/utils';
import { Radio, RadioGroup, Input, Field, Select, Fieldset } from '@m/alchemy-ui';
import { checkProvision, checkSetting } from '../../../redux/store/store-helper';
import FormV2EnablementFileUploader from './FormV2EnablementFileUploader';

const CssUpgradeOptions = {
  NoCss: 'NO_CSS',
  TestingCss: 'TESTING_CSS',
  NewCss: 'NEW_CSS',
};

const options = [
  {
    cssUpgradeOption: CssUpgradeOptions.NoCss,
    title: getSingleText('app.topMenu.sdkV2.upgradeModal.noCustomCSS'),
    subTitle: getSingleText('app.topMenu.sdkV2.upgradeModal.noCustomCSSSubTitle'),
  },
  {
    cssUpgradeOption: CssUpgradeOptions.TestingCss,
    title: getSingleText('app.topMenu.sdkV2.upgradeModal.useCustomCSS'),
    subTitle: getSingleText('app.topMenu.sdkV2.upgradeModal.useCustomCSSSubTitle'),
  },
  {
    cssUpgradeOption: CssUpgradeOptions.NewCss,
    title: getSingleText('app.topMenu.sdkV2.upgradeModal.insertCustomCSS'),
    subTitle: getSingleText('app.topMenu.sdkV2.upgradeModal.insertCustomCSSSubTitle'),
  }
];

const initialState = {
  action: 'ENABLE',
  cssUpgradeOption: 'NO_CSS',
  customCssResource: null,
  customCssUrl: '',
};

//#region sub components
const CustomCssUrl = (props) => {
  const { disabled, value = '', onUrlChange } = props;
  return (
    <Input disabled={disabled}
					 style={{ width: 502 }}
					 placeholder={getSingleText('app.topMenu.sdkV2.upgradeModal.customCssUrlPlaceholder')}
					 value={value}
					 onChange={onUrlChange}/>
  );
};

const UpgradeOptions = (props) => {
  const { selected, options, onOptionChange } = props;
  return (
    <UpgradeModalOptionsContainer>
      <Fieldset label="Choose the visual upgrade option">
        <RadioGroup selection={selected} onChange={onOptionChange}>
          {({ isSelected, handleChange }) =>
            options.map(({ cssUpgradeOption, title, subTitle }) => {
              const Label = () => (
                <React.Fragment>
                  <p>{title}</p>
                  <StyledSubHeader>{subTitle}</StyledSubHeader>
                </React.Fragment>
              );

              return <Radio key={cssUpgradeOption}
                label={<Label/>}
                checked={isSelected(cssUpgradeOption)}
                onChange={() => handleChange(cssUpgradeOption)}/>;
            })
          }
        </RadioGroup>
      </Fieldset>
    </UpgradeModalOptionsContainer>
  );
};
//#endregion Sub Components

//#region helper functions
function checkValidity(values, isResourceLibraryProvisioned, isCustomCssUploadEnabled) {
  const { cssUpgradeOption, customCssResource, customCssUrl } = values;
  let error;

  if (cssUpgradeOption !== 'NEW_CSS') {return null;}

  const isCustomCssUrl = !isResourceLibraryProvisioned && !isCustomCssUploadEnabled;
  switch (true) {

    case isResourceLibraryProvisioned && !customCssResource:
      error = 'Please select a CSS file';
      break;

    case isCustomCssUploadEnabled && !customCssResource:
      error = 'Please upload a CSS file';
      break;

    case isCustomCssUrl && !customCssUrl:
      error = 'This field is required';
      break;

    case isCustomCssUrl && !isValidURL(customCssUrl, true):
      error = 'Please enter a valid URL which starts with HTTPS';
      break;

    default:
      break;
  }

  return error ? { level: 'error', message: error } : null;
}

const transformResourceToOptionItem = (resource) => ({ label: resource.fileName, value: resource.id });
//#endregion helper functions

//#region main component
export default function FormV2EnablementModal(props) {
  const { reusableCssResources } = props;

  const isCustomCssUploadEnabled = checkProvision('customCssUpload') && checkSetting('customCssUpload');
  const isResourceLibraryProvisioned = checkProvision('reusableResourcesLibrary');

  const [payload, updatePayload] = useState(initialState);
  const [validation, setValidity] = useState(null);

  let resourcesOptions;

  if (isResourceLibraryProvisioned) {
    resourcesOptions = reusableCssResources.map(transformResourceToOptionItem);
  }

  //#region handlers
  const handleUpgradeOptionChange = (cssUpgradeOption) => {
    updatePayload({ ...initialState, cssUpgradeOption });
    if (validation) {
      setValidity(null);
    }
  };

  const handleUrlChange = (e) => updatePayload({ ...payload, customCssUrl: e.target.value });

  const handleReusableResourceChange = ({ value }) => {
    const { id, url, fileName, fileType } = reusableCssResources.find(({ id }) => id === value);
    updatePayload({
      ...payload,
      customCssResource: {
        url,
        name: fileName + '.' + fileType,
        uuid: null,
        resourceId: id,
      },
    });
  };

  const handleFileUpload = (fileData) => {
    updatePayload({
      ...payload,
      customCssResource: fileData ? { ...fileData } : null,
    });
  };
  //#endregion handlers

  const getNewCssSource = function(disabled) {
    switch (true) {

      case isResourceLibraryProvisioned:
        const isInvalid = !disabled && !customCssUrl;
        const { resourceId = '' } = payload.customCssResource || {};
        return (
          <SelectWrapper>
            <Select value={resourceId}
              defaultValue=""
              disabled={disabled}
              palette="interactiveDestructive"
              isFullWidth
              hasStatus={isInvalid}
              options={resourcesOptions}
              placeholder='Select CSS file from library'
              onChange={handleReusableResourceChange}/>
          </SelectWrapper>
        );

      case isCustomCssUploadEnabled:
        return <FormV2EnablementFileUploader disabled={disabled} onFileUpload={handleFileUpload}/>;

      default:
        return <CustomCssUrl disabled={disabled} value={customCssUrl} onUrlChange={handleUrlChange}/>;
    }
  };

  const { customCssUrl, cssUpgradeOption } = payload;
  const isNewCSS = cssUpgradeOption === CssUpgradeOptions.NewCss;

  return props.render({
    height: isCustomCssUploadEnabled ? 540 : 500,
    body: (
      <React.Fragment>
        <StyledUpgradeModalTitle>
          {getSingleText('app.topMenu.sdkV2.upgradeModal.title')}
        </StyledUpgradeModalTitle>
        <br/>
        <StyledUpgradeModalSubTitle>
          {getSingleText('app.topMenu.sdkV2.upgradeModal.subTitle')}
        </StyledUpgradeModalSubTitle>
        <br/>

        <UpgradeOptions selected={cssUpgradeOption} onOptionChange={handleUpgradeOptionChange} options={options}/>

        <Field style={{ width: '100%', paddingLeft: 20, marginTop: !isCustomCssUploadEnabled ? 10 : 0 }}
							 status={validation}
							 input={getNewCssSource(!isNewCSS)}/>
      </React.Fragment>
    ),
    submit: {
      title: 'Upgrade now',
      shouldAutoClose: false,
      callback: () => {
        const status = checkValidity(payload, isResourceLibraryProvisioned, isCustomCssUploadEnabled);
        setValidity(status);

        if (!status) {
          props.submit(payload);
        }
      },
    },
  });
}
//#endregion main component
