import React from 'react';
import LinearProgressBar from '@mdigital/components/dist/components/LinearProgressBar';
import StyledContainer, { StyleTitle, StyleCounter, ComponentsBankBlocker, StyledTextsRow, ProgressBarContainer } from './StyledFormQuestionCounterPanel';
import { COLORS } from '../../../common/colors';

const FormQuestionCounterPanel = ({ max, count }) => {
  const isLimitReached = count >= max;
  return (
    <StyledContainer>
      <StyledTextsRow>
        <StyleTitle>
          Questions limit {isLimitReached && 'reached!'}
        </StyleTitle>
        <StyleCounter counterAtMax={isLimitReached}>
          <b>{count}</b>/{max} questions
        </StyleCounter>
      </StyledTextsRow>
      <ProgressBarContainer>
        <LinearProgressBar
          max={max}
          value={count}
          height="4px"
          color={isLimitReached ? COLORS.ERROR : COLORS.ACTIVE_BLUE}/>
      </ProgressBarContainer>
      <ComponentsBankBlocker shouldBlock={isLimitReached} />
    </StyledContainer>
  );
};

export default FormQuestionCounterPanel;
