import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../../../common/colors';
import { flex } from '../../../common/styled-mixins';

import DigButton from '@mdigital/components/dist/components/Button';
import Search from '@mdigital/components/dist/components/Search';
import IconSvg from '@mdigital/components/dist/components/IconSvg';

import gridIcon from '../../../resources/assets/neb-icons/svg/grid.svg';
import listIcon from '../../../resources/assets/neb-icons/svg/list.svg';
import RefreshButton from './RefreshButton';

const ICON_HEIGHT = 16;
const GRID_ICON_WIDTH = 16;
const LIST_ICON_WIDTH = 18;
const SEARCH_PLACEHOLDER = 'Search by Name or ID...';
const CREATE_FORM_LABEL = 'New Form';
const TITLE = 'Forms';

const FormViewTypesProperties = [
  { type: FormViewTypes.LIST, icon: listIcon, tooltip: 'Switch to List View', width: LIST_ICON_WIDTH, height: ICON_HEIGHT },
  { type: FormViewTypes.GRID, icon: gridIcon, tooltip: 'Switch to Card View', width: GRID_ICON_WIDTH, height: ICON_HEIGHT }
];

const FormViewActionBarComponent = styled.div`
  display: flex;
  height: 52px;
  min-width: 0;
  border-bottom: 1px solid ${COLORS.BORDER};
  .info{
    margin-left: 21px;
    min-width: 0;
    ${flex(1, 'flex-start')}
    .title{
      color: ${COLORS.TEXT_TITLE};
      font-size: 22px;
      overflow: hidden;
    	min-width: 0;
    	text-overflow: ellipsis;
    	white-space: nowrap;
    }
    .counter{
      color: ${COLORS.TEXT_SUBTITLE};
      opacity: .5;
      font-size: 16px;
      margin-left: 9px;
    }
  }
  .actions {
    .search-container{
      padding-right: 10px;
      .input-wrapper{
        width: 100%;
      }
    }
    
    #separator{
      border-right: 1px solid ${COLORS.BORDER};
      width: 1px;
      height: 100%;
    }
    ${flex(0, 'flex-end')}
    .action-toggle-view{
      cursor: pointer;
      position: relative;
      width: 61.5px;
      height: 50px;
      display: inline-block;
      top: 1px;
      svg {
        right: 20px;
        top: 17.5px;
        position: absolute;
      }
    }
    .action-form-button {
      height: 100%;
      padding: 8px 20px;
      font-weight: normal;
      text-decoration: none;
     .font-awesome-icon {
        margin-right: 5px;
      }
    }
    
    #btn-link.action-form-button .fa-plus-circle{
        color: ${COLORS.GREEN};
    }
  }
`;

function getViewTypeProperties(currentViewType) {
  const currentIndex = FormViewTypesProperties.findIndex(({ type }) => type === currentViewType);
  return FormViewTypesProperties[currentIndex];
}

export default function FormViewActionBar(
  {
    title = TITLE,
    counter,
    onViewChange,
    viewType,
    onCreate,
    onSearch,
    isDisableCreateForm = false,
    isHideSearch = false,
    isFormFoldersEnabled,
    isFormRefreshVisible,
    refreshConfig,
  }) {

  const onClick = onViewChange.bind(null, { viewType : viewType });
  
  return (
    <FormViewActionBarComponent>
      <div className='info'>
        <span className="title">{ title }</span>
        { counter && <span className="counter">{ counter }</span> }
      </div>
      <div className='actions'>
        {
          !isHideSearch && (
            <div className={isFormFoldersEnabled ? 'search-container' : ''}>
              <Search direction='left' placeholder={SEARCH_PLACEHOLDER} onChange={onSearch} onClose={onSearch}/>
            </div>
          )
        }

        { !isFormFoldersEnabled && <IconSvg onClick={onClick} className='action-toggle-view' tooltipProps={{ position: 'bottom' }} {...getViewTypeProperties(viewType)} /> }
        {isFormRefreshVisible &&
        <RefreshButton
          refreshConfig={refreshConfig}/>}
        
        <i id='separator'/>
        <DigButton digClassName='action-form-button'
          digAutLabel='addNewForm'
          digOnClick={onCreate}
          digType='link'
          digIconClassName='plus-circle'
          digIconColor={null}
          digTextColor={COLORS.GREEN}
          digTextColorHover={COLORS.TEXT_HOVER}
          digIsDisabled={isDisableCreateForm}>{CREATE_FORM_LABEL}</DigButton>
      </div>
    </FormViewActionBarComponent>
  );
}

FormViewActionBar.propTypes = {
  title: PropTypes.string,
  counter: PropTypes.string,
  refreshConfig: PropTypes.shape({
    isDisableRefreshForm: PropTypes.bool,
    isPollingFormsRefresh: PropTypes.bool,
    areAllItemsChecked: PropTypes.bool,
    selectedCount: PropTypes.number,
    setShowRefreshConfirmationModal: PropTypes.func,
  }),
  isFormRefreshVisible: PropTypes.bool,
  onViewChange: PropTypes.func,
  viewType: PropTypes.string,
  viewOptions: PropTypes.array,
  onCreate: PropTypes.func,
  isDisableCreateForm: PropTypes.bool,
  isHideSearch: PropTypes.bool,
  isFormFoldersEnabled: PropTypes.bool,
};
