export default {
  TOP:{
    Drawer: {
      BodyWrapper:{
        right: '120px',
        top: '42px',
        width: '389px',
        height: '130px',
        borderRadius : '10px',
      },
      previewTitle:{
        left: '52px',
        top: '47px',
      },
      previewBody:{
        top: '52px',
        left: '47px',
      },
      iconClose :{
        top: '51px',
        right: '25px',
      },
      buttonWrapper:{
        height : '50px',
        bottom : '0px',
      },
    },
    ParitalDrawer :{
      BodyWrapper:{
        right: '141px',
        top: '100px',
        width: '347px',
        height: '96px',
        borderRadius : '10px',
      },
      previewTitle:{
        left: '29px',
        top: '12px',
      },
      previewBody:{
        top: '17px',
        left: '25px',
      },
      iconClose :{
        top: '15px',
        right: '13px',
      },
      buttonWrapper:{
        height : '50px',
        bottom : '0px',
      },
    }, 
  },
  BOTTOM:{
    Drawer :{
      BodyWrapper:{
        right: '120px',
        bottom: '34px',
        width: '389px',
        height: '123px',
        borderRadius : '10px',
      },
      previewTitle:{
        left: '52px',
        top: '12px',
      },
      previewBody:{
        top: '16px',
        left: '46px',
      },
      iconClose :{
        top: '17px',
        right: '25px',
      },
      buttonWrapper:{
        height : '85px',
        bottom : '26px',
      },
    },
    ParitalDrawer :{
      BodyWrapper:{
        right: '141px',
        bottom: '75px',
        width: '347px',
        height: '96px',
        borderRadius : '10px',
      },
      previewTitle:{
        left: '29px',
        top: '12px',
      },
      previewBody:{
        top: '15px',
        left: '23px',
      },
      iconClose :{
        top: '15px',
        right: '13px',
      },
      buttonWrapper:{
        height : '50px',
        bottom : '0px',
      },
    }, 
  },
};