import React, { useEffect, useContext } from 'react';
import { TriggerContext } from '../../../../../../..';
import TargetingBlockRow from '../../../TargetingBlockRow';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { Select } from '@m/alchemy-ui';
import { TargetingActions } from '../../../../../Actions';
import { isRuleExists } from '../../../../../../../helpers';
import { useSelector } from 'react-redux';
import { TextPathPrefix } from '../../../../..';
import { getSingleText } from '../../../../../../../../../../common/utils';
import useVersionValidation from '../hooks/useVersionValidation';

const TargetByCustomRule = () => {
  const { triggerState, triggerDispatch, isReadOnly } = useContext(TriggerContext);
  const availableRules = useSelector((state) => state.ruleEngine.availableRules);
  const [hasError] = useVersionValidation('trigger.rules.MobileGenericRule');

  useEffect(() => {
    isRuleExists(triggerState, 'trigger.rules.MobileGenericRule.id') ? 
      triggerDispatch({ type: TargetingActions.SET_TARGET_BY_CUSTOM_RULE_IS_ACTIVE , payload : true }) :
      triggerDispatch({ type: TargetingActions.INIT_TARGET_BY_CUSTOM_RULE_PARAMS , payload : {} } );
  },[]);

  const formatCustomRule = () => {
    return availableRules.map(({ id , name }) =>  ({ value: id , label : name }));
  };

  const dispatchCustomRule = (option) => {
    triggerDispatch({ type: TargetingActions.SET_TARGET_BY_CUSTOM_RULE_ID , payload : option.value });
    triggerDispatch({ type: TargetingActions.SET_TARGET_BY_CUSTOM_RULE_NAME , payload : option.label });
  };

  const handleCheckBoxChange = (e) => {
    const isChecked = e.target.checked;
    triggerDispatch({ type: TargetingActions.SET_TARGET_BY_CUSTOM_RULE_IS_ACTIVE , payload : isChecked } );
  };

  return (
    <TargetingBlockRow
      checkboxLabel="Custom rule"
      isFullWidth
      checked={getNestedProperty(triggerState , 'trigger.rules.MobileGenericRule.isActive')}
      tooltipText={getSingleText(`${TextPathPrefix}.tooltip.customRule`)}
      disabled={isReadOnly}
      hasError={hasError}
      errorMessage={getSingleText(`${TextPathPrefix}.errors.required`)}
      onCheckboxChange={handleCheckBoxChange }>
      <Select
        isFullWidth
        placeholder="Please select rule"
        value={getNestedProperty(triggerState , 'trigger.rules.MobileGenericRule.params.ruleId')}
        options={formatCustomRule()}
        required={getNestedProperty(triggerState , 'trigger.rules.MobileGenericRule.isActive')}
        onChange={(option) => dispatchCustomRule(option)}/>
    </TargetingBlockRow>
  );
};

export default TargetByCustomRule;
