import React from 'react';
import { Field } from 'react-final-form';
import StyledHeader from './StyledHeader';
import { isRequired } from '../../../../../common/utils/fieldValidators';

const focusOnFirstRefAvailable = (visited) => (ref) => ref && !visited && ref.focus();

const Header = (props) => (
  <StyledHeader>
    <div className="wizard-modal-header-content">
      <Field
        name="name"
        validate={isRequired}
        render={({ input, meta }) => (
          <div>
            <input
              type="text"
              maxLength="50"
              className={meta.error && meta.touched && 'has-error'} {...input}
              placeholder="Untitled App Rating"
              ref={focusOnFirstRefAvailable(meta.visited)} />
          </div>
        )}
      />
    </div>
    <button className="close-button" type="button" onClick={props.onCloseClick}>&times;</button>
  </StyledHeader>
);

export default Header;