import styled from 'styled-components';
import ComingSoon from './ComingSoon.ttf'
import RobotoLight from './Roboto-Light.ttf'
import RobotoMedium from './Roboto-Medium.ttf'
import RobotoThin from './Roboto-Thin.ttf'
import RobotoBlack from './Roboto-Black.ttf'
import RobotoCondensedLight from './RobotoCondensed-Light.ttf'
import RobotoCondensedRegular from './RobotoCondensed-Regular.ttf'
import CarroisGothicSCRegular from './CarroisGothicSC-Regular.ttf'
import CutiveMonoRegular from './CutiveMono-Regular.ttf'
import NotoSerifRegular from './NotoSerif-Regular.ttf'
import RobotoRegular from './Roboto-Regular.ttf'

export const MobileAndroidCustomFonts = styled.div `

@font-face {
    font-family: 'sans-serif-light'; 
    src: url(${RobotoLight}) format('truetype');
    font-display: block;
}
@font-face {
    font-family: 'sans-serif-medium'; 
	src: url(${RobotoMedium}) format('truetype');
}
@font-face {
    font-family: 'sans-serif-thin'; 
	src: url(${RobotoThin}) format('truetype');
}
@font-face {
    font-family: 'sans-serif-black'; 
	src: url(${RobotoBlack}) format('truetype');
}
@font-face {
    font-family: 'sans-serif-condensed-light'; 
	src: url(${RobotoCondensedLight}) format('truetype');
}
@font-face {
    font-family: 'sans-serif-condensed'; 
	src: url(${RobotoCondensedRegular}) format('truetype');
}
@font-face {
    font-family: 'sans-serif-smallcaps'; 
	src: url(${CarroisGothicSCRegular}) format('truetype');
}
@font-face {
    font-family: 'serif-monospace'; 
	src: url(${CutiveMonoRegular}) format('truetype');
}
@font-face {
    font-family: 'serif'; 
	src: url(${NotoSerifRegular}) format('truetype');
}
@font-face {
    font-family: 'casual'; 
	src: url(${ComingSoon}) format('truetype');
}

@font-face {
    font-family: 'Roboto-Regular'; 
    src: url(${RobotoRegular}) format('truetype');
}

`