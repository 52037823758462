import React from 'react';
import Wizard from '@mdigital/components/dist/components/Wizard';
import IconSvg from '@mdigital/components/dist/components/IconSvg';
import DiamondBottomSvg from '../../assets/Login/loginDiamondBottom.svg';
import DiamondTopSvg from '../../assets/Login/loginDiamondTop.svg';
import { StyledLogin } from './StyledLogin';
import LoginScreen from './Views/LoginScreen';
import ForgotPassword from './Views/ForgotPassword';
import { LoginHeader } from './Views/LoginHeader';
import withProvider from '../../hoc/ProviderHoc';
import { connect } from 'react-redux';
import * as authActions from '../../../redux/actions/auth.actions';

export const LOGIN_VIEWS = {
  LOGIN : 0,
  FORGOT_PASSWORD : 1,
};

const DIAMOND_COLOR = '#5d6cce';
const DIAMOND_TOP_WIDTH = 196;
const DIAMOND_TOP_HEIGHT = 140;
const DIAMOND_BOTTOM_WIDTH = 350;
const DIAMOND_BOTTOM_HEIGHT = 312;
const DiamondTop = () => <IconSvg className='md-login-diamond-top' icon={DiamondTopSvg} width={DIAMOND_TOP_WIDTH} height={DIAMOND_TOP_HEIGHT} isStatic={true} color={DIAMOND_COLOR}/>;
const DiamondBottom = () => <IconSvg className='md-login-diamond-bottom' icon={DiamondBottomSvg} width={DIAMOND_BOTTOM_WIDTH} height={DIAMOND_BOTTOM_HEIGHT} isStatic={true} color={DIAMOND_COLOR}/>;

@withProvider()
class Login extends React.Component{
  state = { currentStepIndex: LOGIN_VIEWS.LOGIN, shouldShowError: false };

  handleStepChange = (stepIndex) => {
    this.setState({ currentStepIndex: stepIndex, shouldShowError: false });
    this.props.dispatch(authActions.cleanLoginError());
  };

  validateRequiredFields = (Fields) => Object.values(Fields).reduce((accu, current) => accu && !!current, true);

  cleanErrorMessage(){
    this.setState({ shouldShowError: false });
    this.props.dispatch(authActions.cleanLoginError());
  }

  handleSubmit = (fieldsValues, errorMessage, action) => {
    if (this.validateRequiredFields(fieldsValues)) {
      this.props.dispatch(action(fieldsValues));
      this.cleanErrorMessage();
    } else {
      this.props.dispatch(authActions.loginError(errorMessage));
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.loginError &&
       (this.props.loginError !== prevProps.loginError || !this.state.shouldShowError)) {
      this.setState({ shouldShowError: true, errorMessage: this.props.loginError });
    }
  }

  render() {
    return (
      <StyledLogin>
        { this.state.shouldShowError &&
          <div className="md-login-error md-sans-semibold"> { this.state.errorMessage } </div>
        }
        <DiamondTop/>
        <Wizard currentStepIndex={this.state.currentStepIndex} hideStatusBar={true}>
          <Wizard.Header>
            <LoginHeader/>
          </Wizard.Header>

          <Wizard.Step>
            <LoginScreen onForgotClick={this.handleStepChange} onSubmit={this.handleSubmit}/>
          </Wizard.Step>

          <Wizard.Step>
            <ForgotPassword onBackClick={this.handleStepChange} onSubmit={this.handleSubmit} />
          </Wizard.Step>
        </Wizard>
        <DiamondBottom/>
      </StyledLogin>
    );
  }
}

function mapStateToProps(state) {
  const { auth: { loginError } } = state;
  return {
    loginError,
  };
}

export default connect(mapStateToProps)(Login);