import styled from 'styled-components';
import { COLORS } from '../../../common/colors';
import { ellipsis } from '../../../common/styled-mixins';

export default styled.main`
height: 242px;
overflow-y: auto;
padding: 21px 20px;
table {
  width: 100%;
  th {
    color: ${COLORS.TEXT_TITLE};
    font-size: 12px;
    &:nth-child(2) {
      width: 83%;
    }
  }
  td {
    color: ${COLORS.TEXT_SUBTITLE};
    font-size: 14px;
    padding-top: 12px;
    &.with-ellipsis {
      ${ellipsis()}
      max-width: 0; // force table cell to overflow with ellipsis
    }
  }
}
`;