"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOperatorLabel = exports.isMultiOperator = exports.isNoValueOperator = exports.getOperatorsByType = exports.getOperatorsByComponentType = exports.booleanOperators = exports.mediaCaptureOperators = exports.screenCaptureOperators = exports.gradingOperators = exports.dateOperators = exports.checkboxOperators = exports.selectOperators = exports.stringOperators = exports.operators = void 0;
const utils_1 = require("../../../common/utils");
const types_1 = require("./types");
exports.operators = {
    equals: 'Equals',
    notEquals: 'Does Not Equal',
    contains: 'Contains',
    notContains: 'Does Not Contain',
    startWith: 'Starts With',
    endWith: 'Ends With',
    earlier: 'Earlier Than',
    later: 'Later Than',
    greater: 'Greater Than',
    smaller: 'Smaller Than',
    hasValue: 'Has Value',
    hasNoValue: 'Has No Value',
    regexp: 'Regexp',
    anyOf: 'Any of',
};
exports.stringOperators = [
    exports.operators.equals,
    exports.operators.notEquals,
    exports.operators.contains,
    exports.operators.notContains,
    exports.operators.startWith,
    exports.operators.endWith,
    exports.operators.hasValue,
    exports.operators.hasNoValue,
    exports.operators.regexp,
];
exports.selectOperators = [
    exports.operators.equals,
    exports.operators.notEquals,
    exports.operators.hasValue,
    exports.operators.hasNoValue,
];
exports.checkboxOperators = [
    exports.operators.equals,
    exports.operators.notEquals,
    exports.operators.contains,
    exports.operators.notContains,
    exports.operators.hasValue,
    exports.operators.hasNoValue,
    exports.operators.anyOf,
];
exports.dateOperators = [
    exports.operators.equals,
    exports.operators.notEquals,
    exports.operators.earlier,
    exports.operators.later,
    exports.operators.hasValue,
    exports.operators.hasNoValue,
];
exports.gradingOperators = [
    exports.operators.equals,
    exports.operators.notEquals,
    exports.operators.greater,
    exports.operators.smaller,
    exports.operators.hasValue,
    exports.operators.hasNoValue,
];
exports.screenCaptureOperators = [
    exports.operators.hasValue,
    exports.operators.hasNoValue,
];
exports.mediaCaptureOperators = [
    exports.operators.hasValue,
    exports.operators.hasNoValue,
];
exports.booleanOperators = [
    exports.operators.equals,
    exports.operators.hasValue,
    exports.operators.hasNoValue,
];
const typeToOperatorMap = {
    'Number': exports.gradingOperators,
    'Text': exports.stringOperators,
    'Datetime': exports.dateOperators,
    'Boolean': exports.booleanOperators,
};
const componentTypeToOperatorMap = {
    [types_1.FormComponents.TEXT_INPUT]: exports.stringOperators,
    'urlInput': exports.stringOperators,
    [types_1.FormComponents.EMAIL_INPUT]: exports.stringOperators,
    [types_1.FormComponents.TEXT_AREA]: exports.stringOperators,
    [types_1.FormComponents.CHECKBOX]: exports.checkboxOperators,
    [types_1.FormComponents.SELECT]: exports.selectOperators,
    [types_1.FormComponents.RADIO]: exports.selectOperators,
    'date': exports.dateOperators,
    [types_1.FormComponents.GRADING1TO5]: exports.gradingOperators,
    [types_1.FormComponents.GRADING1TO7]: exports.gradingOperators,
    [types_1.FormComponents.GRADING1TO10]: exports.gradingOperators,
    [types_1.FormComponents.GRADING0TO10]: exports.gradingOperators,
    [types_1.FormComponents.NPS]: exports.gradingOperators,
    [types_1.FormComponents.SCREEN_CAPTURE]: exports.screenCaptureOperators,
    [types_1.FormComponents.MEDIA_CAPTURE]: exports.mediaCaptureOperators,
};
const getOperatorsByComponentType = function (type) {
    return !!type && !!componentTypeToOperatorMap[type] ? componentTypeToOperatorMap[type] : [];
};
exports.getOperatorsByComponentType = getOperatorsByComponentType;
const getOperatorsByType = function (type) {
    return !!type && !!typeToOperatorMap[type] ? typeToOperatorMap[type] : [];
};
exports.getOperatorsByType = getOperatorsByType;
const isNoValueOperator = (operator) => {
    return operator === exports.operators.hasValue || operator === exports.operators.hasNoValue;
};
exports.isNoValueOperator = isNoValueOperator;
const isMultiOperator = (operator) => {
    return operator === exports.operators.anyOf || operator === exports.operators.contains;
};
exports.isMultiOperator = isMultiOperator;
const TextPathPrefix = `app.pages.forms.builder.tabs.fieldSettings.conditionalDisplayModal`;
const OperatorLabels = {
    [exports.operators.equals]: `${TextPathPrefix}.equals`,
    [exports.operators.notEquals]: `${TextPathPrefix}.notEquals`,
    [exports.operators.greater]: `${TextPathPrefix}.greater`,
    [exports.operators.smaller]: `${TextPathPrefix}.smaller`,
    [exports.operators.hasValue]: `${TextPathPrefix}.hasValue`,
    [exports.operators.contains]: `${TextPathPrefix}.contains`,
    [exports.operators.notContains]: `${TextPathPrefix}.notContains`,
    [exports.operators.startWith]: `${TextPathPrefix}.startWith`,
    [exports.operators.endWith]: `${TextPathPrefix}.endWith`,
    [exports.operators.regexp]: `${TextPathPrefix}.regex`,
    [exports.operators.earlier]: `${TextPathPrefix}.earlier`,
    [exports.operators.later]: `${TextPathPrefix}.later`,
    [exports.operators.hasNoValue]: `${TextPathPrefix}.hasNoValue`,
    [exports.operators.anyOf]: `${TextPathPrefix}.anyOf`,
};
const getOperatorLabel = (operator) => {
    return (0, utils_1.getSingleText)(OperatorLabels[operator]);
};
exports.getOperatorLabel = getOperatorLabel;
