import React from 'react';
import StyledAlert from './StyledAlert';
import AlertInputComponent from './AlertInputComponent';
import { getEngagementTextByFlavorAndType } from '../utils';
import { ENGAGEMENT_TYPES } from '../index';
import Input from '@mdigital/components/dist/components/Input';
import Textarea from '@mdigital/components/dist/components/Textarea';

const getFieldTitle = (field, flavor) => getEngagementTextByFlavorAndType(field, flavor, ENGAGEMENT_TYPES.ALERT);

const AlertPropmetSettings = ({ flavor, isAnywhereProperty = false, isNativeAlertRevamp, isReadOnlyMode }) => (
  <StyledAlert className="alert-propmpt-settings-section">
    <AlertInputComponent
      name="trigger.invitationHeadline"
      title={getFieldTitle('title', flavor)}
      required={!isAnywhereProperty}
      errorMessage="Title is Required"
      isNativeAlertRevamp={isNativeAlertRevamp}
      disabled={isReadOnlyMode}
      render={({ input }) => (
        <Input digValue={input.value} digOnChange={input.onChange}/>
      )} />

    <AlertInputComponent
      name="trigger.invitationText"
      title={getFieldTitle('body', flavor)}
      required={!isAnywhereProperty}
      errorMessage="Body is Required"
      isNativeAlertRevamp={isNativeAlertRevamp}
      disabled={isReadOnlyMode}
      render={({ input }) => (
        <Textarea digClass='alert-revamp-textarea' {...input}/>
      )} />

    <AlertInputComponent
      name="trigger.provideButtonText"
      title={getFieldTitle('accept', flavor)}
      required={!isAnywhereProperty}
      errorMessage="Rate App Option is Required"
      isNativeAlertRevamp={isNativeAlertRevamp}
      disabled={isReadOnlyMode}
      render={({ input }) => (
        <Input digValue={input.value} digOnChange={input.onChange}/>
      )} />

    <AlertInputComponent
      name="trigger.declineButtonText"
      title={getFieldTitle('decline', flavor)}
      required={!isAnywhereProperty}
      errorMessage="Decline Option is Required"
      isNativeAlertRevamp={isNativeAlertRevamp}
      disabled={isReadOnlyMode}
      render={({ input }) => (
        <Input digValue={input.value} digOnChange={input.onChange}/>
      )} />

    <AlertInputComponent
      name="trigger.laterButtonText"
      title={getFieldTitle('maybeLater', flavor)}
      required={!isAnywhereProperty}
      errorMessage="Maybe Later Option is Required"
      isNativeAlertRevamp={isNativeAlertRevamp}
      disabled={isReadOnlyMode}
      render={({ input }) => (
        <Input digValue={input.value} digOnChange={input.onChange}/>
      )} />
  </StyledAlert>
);


export default AlertPropmetSettings;
