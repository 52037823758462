import styled from 'styled-components';
import { COLORS } from '../../../common/colors';

export default styled.header`
height: 63px;
border-bottom: 1px solid ${COLORS.TABLE.ROW.BORDER};
i {
  color: ${COLORS.ACTION.DEFAULT};
  font-style: normal;
  font-size: 21px;
  margin: -3px 9px 0 0;
  font-weight: bold;
  cursor: pointer;
  float: right;
  &:after {
    content: "×";
  };
  &:hover {
    color: ${COLORS.ACTION.HOVER};
  }
}
h3 {
  color: ${COLORS.PRIMARY_TEXT};
  padding: 20px 0 0 20px;
}
`;