import styled from 'styled-components';
import { COLORS } from '../../../common/colors';

export const StyledDarkModeSelect = styled.div`
    position: relative;
    left: 464px;
    display: flex;
    height: 32px;
    width: 111px;
    margin-bottom: 16px;
    cursor: pointer;
    .dark-mode-select:first-child{
          border-radius: 3px 0px 0px 3px;
     } 
     .dark-mode-select:last-child{
          border-radius: 0px 3px 3px 0px;
     }
`;

export const StyledDarkModeSelectIcon = styled.div`
    width: 55px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.isActive ? COLORS.DARK_MODE_BLACK_BACKGROUND : COLORS.DARK_MODE_LIGHT_BACKGROUND};

    svg{
         margin-top: 2px;
    }
`;