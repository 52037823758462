import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import BlockableRow from '../BlockableRow';

const IS_ACTIVE_KEY = 'trigger.rules.MobileFrequency.isActive';

const StyledElement = styled.div`

  input {
    width: 72px;
  }
  & > label {
    padding: 8px 13px;
  }
`;

const TargetByPercentile = () => (
  <BlockableRow title="Invite" isActiveFieldName={IS_ACTIVE_KEY}>
    <StyledElement>
      <span>
        <Field name="trigger.rules.MobileFrequency.params.frequency" type="number" component="input" />
      </span>
      <label>% Visitors</label>
    </StyledElement>
  </BlockableRow>
);

export default TargetByPercentile;
