import React from 'react';
import { getSingleText } from '../../../../common/utils';
import { StyledSdkIntegrationV2ErrorBody } from '../SdkIntegrationModalErrorBody/StyledSdkIntegrationModalErrorBody';


const SdkIntegrationV2ModalErrorBody = () =>(
  <StyledSdkIntegrationV2ErrorBody>
    <p className="sdk-integration-v2-error-body-header" >
      <i className="neb-icon-alert sdk-integration-v2-error-body-icon"/>
      <span>{ getSingleText('app.topMenu.sdkV2.modalErrorHeader') }</span>
    </p>
    <p className="sdk-integration-v2-error-body-content"> 
      <span> { getSingleText('app.topMenu.sdkV2.modalErrorContent') } </span>
    </p>
  </StyledSdkIntegrationV2ErrorBody>
);

export default SdkIntegrationV2ModalErrorBody;