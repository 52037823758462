import React, { useContext } from 'react';
import { CancelXIcon } from '@m/alchemy-ui';
import { StyledBannerPreviewContainer, StyledBannerPreviewTitle, StyledBannerPreviewBody,
  StyledBannerPreviewButtonWrapper, StyledBannerPreviewButtonText } from './StyledBannerPreview';
import { bannerPreviewPositions, buttonWrapperPosition, getBannerTheme } from './BannerPreviewSettings';
import { TriggerContext } from '../../../../..';
import AlchemyAndroidFonts from '../../../../../../../../resources/assets/neb-icons/fonts/BannerPreviewFonts/mobileAndroid/AlchemyAndroidFonts';
import { getPropertyType } from '../../../../../../../../redux/store/store-helper';

export const BannerTypes = {
  PARTIAL: 'Partial',
  DRAWER: 'Drawer',
};

export const BannerPosition = {
  TOP : 'TOP',
  BOTTOM : 'BOTTOM',
};

const BannerPreview = ({ isDarkEnabled }) => {
  const { triggerState } = useContext(TriggerContext);
  const propertyType = getPropertyType();
  const {
    trigger: {
      triggerCustomBannerContract: {
        invitationTitle,
        invitationBody,
        position,
        font,
        isPartial,
        customBannerButtonsSettingsContract:{
          buttonsDisplay,
          acceptButtonText,
        },
      },
    } } = triggerState;
  const bannerPositions = bannerPreviewPositions[propertyType][isPartial ? BannerTypes.PARTIAL : BannerTypes.DRAWER ];
  const buttonPositions = buttonWrapperPosition[isPartial ? BannerTypes.PARTIAL : BannerTypes.DRAWER];
  const theme = getBannerTheme(triggerState, isDarkEnabled);
  return (
    <StyledBannerPreviewContainer position={position} isPartial={isPartial} bannerPositions={bannerPositions} propertyType={propertyType} theme={theme}>
      <AlchemyAndroidFonts/>
      <StyledBannerPreviewTitle position={position} isPartial={isPartial} theme={theme} font={font}>
        {invitationTitle}
      </StyledBannerPreviewTitle>
      <StyledBannerPreviewBody theme={theme} font={font}>
        {invitationBody}
      </StyledBannerPreviewBody>
      {buttonsDisplay && 
      <React.Fragment>
        <StyledBannerPreviewButtonWrapper position={position} isPartial={isPartial} buttonPositions={buttonPositions} propertyType={propertyType} theme={theme}>
          <StyledBannerPreviewButtonText theme={theme} font={font}>
            {acceptButtonText}
          </StyledBannerPreviewButtonText>
        </StyledBannerPreviewButtonWrapper>
        <CancelXIcon size={16}/>
      </React.Fragment>
          
      }
    </StyledBannerPreviewContainer>
  );
};
export default BannerPreview;