import styled from 'styled-components';
import { COLORS } from '../../../common/colors';


export const StyledSdkIntegrationV2ModalContainerComponent = styled.div`
    background-color: ${COLORS.WHITE};
    border-radius: 5px;
    color: ${COLORS.PRIMARY_TEXT};
    line-height: 20px;
    font-family: Open Sans;


`;