import React from 'react';
import PropTypes from 'prop-types';
import withProvider from '../../hoc/ProviderHoc/ProviderHoc';
import { connect, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import BasicSettings from './BasicSettings';
import SubmitButtonSettings from './SubmitButtonSettings';
import FormHeaderSettings from './FormHeaderSettings';
import { setFormViewType } from '../../common/mutators';
import { checkProvision } from '../../../redux/store/store-helper';
import { PROVISIONS } from '../../../common/enums';

const FormBasicSettings = ({ onModelChanged , getInitialValues, isDarkModeEnabled }) => {
  const isSurveyDraftEnabled =  checkProvision(PROVISIONS.ENABLE_SURVEY_DRAFTS);
  const { isPublished } = useSelector((state) => state.forms);
  const isSurveyFormPublished = isSurveyDraftEnabled && isPublished;
  
  return (
    <Form
      initialValues={getInitialValues()}
      onSubmit={() => null}
      mutators={{
        setFormViewType,
      }}
      render={({ values, errors , form: { mutators } }) => (
        onModelChanged({ values, errors }) ||
         <div>
           <BasicSettings values={values} mutators={mutators} isPublished={isSurveyFormPublished}/>
           <SubmitButtonSettings isPublished={isSurveyFormPublished}/>
           <FormHeaderSettings isDarkModeEnabled={isDarkModeEnabled} isPublished={isSurveyFormPublished}/>
         </div> 
      )} 
    />
  );
};

export default withProvider()(
  connect()(FormBasicSettings)
);


FormBasicSettings.propTypes = {
  getInitialValues: PropTypes.func,
  onModelChanged: PropTypes.func,
};

FormBasicSettings.defaultProps = {
  getInitialValues : () => {},
  onModelChanged: () => {},
};