import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';


export const StyledSdkIntegrationV2Body = styled.div `
    padding: 20px 20px 0px 20px;
    font-size: 14px;

    .sdk-integration-v2-body-header{
        margin-bottom : 15px;
    }
    .sdk-integration-v2-textarea-container{
        margin: 0px;
    }
    .sdk-integration-v2-token-container{
        font-family: monospace;
        width: 452px;
        height: 192px;
        background: ${COLORS.LIGHT_GREY}; 
        border-radius: 3px;
        padding: 10px 32px 10px 16px;
        font-size: 12px;
        overflow-wrap: break-word;
        overflow-x: auto;
        border : none;
        resize: none;
        outline: none;
    }

`;