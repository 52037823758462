import React from 'react';
import { StyledThankYouPromptLivePreviewHeader , StyledThankYouPromptLivePreviewHeaderText 
  , StyledThankYouPromptLivePreviewHeaderButton } from './/StyledThankYouPromptLivePreviewHeader';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';

const ThankYouPromptLivePreviewHeader = ( { headerContract , propertyType, isDarkEnabled } ) => (
  <StyledThankYouPromptLivePreviewHeader titleBackgroundColor={getNestedProperty(headerContract,'titleBackgroundColor')} 
    borderRadius={propertyType === 'mobileIOS' ? '20px' : '0px'} isDarkEnabled={isDarkEnabled}>
    <StyledThankYouPromptLivePreviewHeaderText titleTextColor={getNestedProperty(headerContract,'titleTextColor')} >
      {getNestedProperty(headerContract,'title')}
    </StyledThankYouPromptLivePreviewHeaderText>
    <StyledThankYouPromptLivePreviewHeaderButton direction={propertyType === 'mobileIOS' ? '314px' : '17px'} 
      className="neb-icon-Close banner-preview-icon-close"
      isDarkEnabled={isDarkEnabled}/>
  </StyledThankYouPromptLivePreviewHeader>
);

export default ThankYouPromptLivePreviewHeader;