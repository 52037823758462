import ConfirmationModal from '../../modals/ConfirmationModal';
import React, { Component } from 'react';
import { getSingleText } from '../../../common/utils';

export default class ConfirmPackageModal extends Component{ 
  render(){
    const textProps = {
      title: getSingleText('app.pages.packages.confirmModal.bodyQuestion'),
      description: <React.Fragment>{getSingleText('app.pages.packages.confirmModal.warningStart')} 
        <span style={{ marginLeft:5, display: 'inline-block', color: '#D43F3A' }}>{' ' + getSingleText('app.pages.packages.confirmModal.warningEnd')}</span>
      </React.Fragment>,
    };

    const modalProps = {
      title: getSingleText('app.pages.packages.confirmModal.header'),
      okButtonText: getSingleText('app.pages.packages.confirmModal.approve'),
    };

    return (
      <ConfirmationModal {...textProps} modalProps={modalProps} {...this.props} />
    );
  }
}
