import styled from 'styled-components';
import { COLORS } from '../../../../../../common/colors';

export const StyledLivePreviewDeviceImg = styled.img`
    height : ${(props) => props.layoutPositionSettings.liveImg.height};
    width : 405px;
    position: relative;
    bottom: ${(props) => props.layoutPositionSettings.liveImg.bottom};
    left: 82px;
    z-index: 10;
`;

export const StyledLivePreviewBackground = styled.div`
    box-shadow: 0px 2px 3px ${COLORS.LIVE_PREVIEW.SHADOW};
    border-radius: 3px;
    background-color: ${COLORS.LIVE_PREVIEW.LIGHT_GRAY};
    height: ${(props) => props.layoutPositionSettings.background.height};
    width: 571px;
    z-index : 1;
`;

export const StyledLivePreviewDeviceBackgroundImg = styled.div`
    position: relative;
    z-index: 1;
    width: ${(props) => props.layoutPositionSettings.liveDeviceBackground.width};
    height: ${(props) => props.layoutPositionSettings.liveDeviceBackground.height};
    right: ${(props) => props.layoutPositionSettings.liveDeviceBackground.right};
    bottom: ${(props) => props.layoutPositionSettings.liveDeviceBackground.bottom};
    ${({ previewType, isDarkEnabled }) => previewType === 'alert' && isDarkEnabled  && 'background-color: #5B5B5B;'}
    ${({ previewType, isDarkEnabled }) => previewType === 'alert' && !isDarkEnabled  && 'background-color: rgb(0,0,0,0.4);'}
    ${({ previewType, isDarkEnabled }) => previewType !== 'alert' && isDarkEnabled  && `background-color: ${COLORS.DARK_MODE_BLACK_BACKGROUND};`}
    ${({ previewType, isDarkEnabled }) => previewType !== 'alert' && !isDarkEnabled  && `background-color: ${COLORS.BANNER_PREVIEW_BACKGROUND};`}
    border-radius: ${(props) => props.position === 'TOP' ? '6px 6px 0px 0px;' : ''};
`;