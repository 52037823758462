import iOSStep1 from './step1_ios.base64'
import iOSStep2 from './step2_ios.base64'
import iOSStep3 from './step3_ios.base64'
import AndroidStep1 from './step1_android.base64'
import AndroidStep2 from './step2_android.base64'
import AndroidStep3 from './step3_android.base64'

const iOSImages = { Step1: iOSStep1, Step2: iOSStep2, Step3: iOSStep3 }
const androidImages = { Step1: AndroidStep1, Step2: AndroidStep2, Step3: AndroidStep3 }

export default isAndroid => isAndroid ? androidImages : iOSImages