import React, { useContext } from 'react';
import { TriggerContext } from '../../../../..';
import { getPropertyType } from '../../../../../../../../redux/store/store-helper';
import { StyledLNPreviewContainer, StyledLNAppNameContainer, StyledLNPreviewAppName, StyledLNPreviewImage, 
  StyledLNPreviewTitle , StyledLNPreviewBody } from './StyledLocalNotificationPreview';
import { getSingleText } from '../../../../../../../../common/utils'; 
import { TextPathPrefixLocalNotification } from '../../../TargetingTypes/LocalNotification';

export const LocalNotificationPreview = ({ isDarkEnabled }) => {
  const { triggerState } = useContext(TriggerContext);
  const propertyType = getPropertyType();
  const {
    trigger: {
      triggerScope,
      triggerCustomLocalNotificationContract: {
        title,
        body,
        appName,
        appIcon,
      },
    } } = triggerState;
  return (
    <StyledLNPreviewContainer propertyType={propertyType} triggerScope={triggerScope} isDarkEnabled={isDarkEnabled}>
      <StyledLNAppNameContainer>
        {appIcon && appIcon.resourceId && <StyledLNPreviewImage src={appIcon.url} />}
        <StyledLNPreviewAppName>
          {appName || getSingleText(`${TextPathPrefixLocalNotification}.livePreview.defaultAppName`)}
        </StyledLNPreviewAppName>
      </StyledLNAppNameContainer>
      <StyledLNPreviewTitle>{title}</StyledLNPreviewTitle>
      <StyledLNPreviewBody>{body}</StyledLNPreviewBody>
    </StyledLNPreviewContainer>
  );
};