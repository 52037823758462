import React from 'react';
import {
  StyledCustomInterceptImage,
  StyledPreviewCustomInterceptContainer
} from '../StyledTargetingPreview';
import { ThemeProvider } from '@m/alchemy-ui';
const customIntercept = '/react/assets/TriggeringModal/Preview/CustomIntercept/CustomIntercept.svg';

const CustomInterceptPreview = () => (
  <ThemeProvider colorScheme="alchemyPanel">
    <StyledPreviewCustomInterceptContainer>
      <StyledCustomInterceptImage  src={ customIntercept }  />
    </StyledPreviewCustomInterceptContainer>
  </ThemeProvider>
);

export default CustomInterceptPreview;