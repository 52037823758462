import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import AutoSuggest from '@mdigital/components/dist/components/AutoSuggest';
import BlockableRow from '../BlockableRow';
import { timeInSessioncomparisonOptions } from '../../../../../../../common/dropdownOptions';
import { autoSuggestLabelByIdFinder } from '../../../../../../../common/utils';

const IS_ACTIVE_KEY = 'trigger.rules.MobileTimeInForeground.isActive';
const comparisonOptionsAutoSuggestIdFinder = autoSuggestLabelByIdFinder(timeInSessioncomparisonOptions);

const StyledComponent = styled.div`
  display: flex;

  & > span {
    &:nth-child(1) {
      width: 130px;
      padding-right: 12px;
    }

    &:nth-child(2) {

      input {
        width: 72px;
      }
    }

  }
  & > label {
    padding: 8px 13px;
  }
`;

const TargetBySessionDuration = () => (
  <BlockableRow title="Time in session" isActiveFieldName={IS_ACTIVE_KEY}>
    <StyledComponent>
      <span>
        <Field name="trigger.rules.MobileTimeInForeground.params.compareString" render={({ input }) => (
          <AutoSuggest
            suggestions={timeInSessioncomparisonOptions}
            digDisableSuggest={true}
            onSuggestionSelected={({ label }) => input.onChange(label)}
            value={comparisonOptionsAutoSuggestIdFinder(input.value)}
          />
        )}/>
      </span>
      <span>
        <Field name="trigger.rules.MobileTimeInForeground.params.seconds" type="number" component="input" />
      </span>
      <label>Sec</label>
    </StyledComponent>
  </BlockableRow>
);

export default TargetBySessionDuration;
