import { ModalsService } from '@mdigital/components/dist/utils/modals';
import { getSingleText } from '../../../../common/utils';

const TextPathPrefix = 'app.pages.forms.editWarn';

export const EditingLockedForm = ({ onContinue }) => {
  return ModalsService.showConfirmation({
    title: getSingleText(`${TextPathPrefix}.lockWarnTitle`),
    type: 'danger',
    description: getSingleText(`${TextPathPrefix}.lockWarnMessages.targeting`),
    close: {
      title: 'No',
    },
    confirm: {
      title: 'Yes',
      callback: () => {
        ModalsService.closeModals();
        onContinue();
      },
    },
  });
};
