import { TargetingActions } from '../Actions';
import { TARGETING_TYPES } from '../TargetingTypes';
export const TargetingReducer = (state , action) => {
  switch(action.type){
    case TargetingActions.INIT:
      return { ...state , trigger : action.payload };
    case TargetingActions.INIT_THEME:
      return { ...state , theme: action.payload };
    case TargetingActions.SET_TITLE_TEXT:
      switch (state.trigger.invitationType) {
        case  TARGETING_TYPES.BANNER:
          return { ...state , trigger: { ...state.trigger, triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, invitationTitle: action.payload  } } };
        case TARGETING_TYPES.CUSTOM_INTERCEPT:
          return { ...state,  trigger: { ...state.trigger, triggerCustomInterceptContract: { ...state.trigger.triggerCustomInterceptContract, customInvitationTitle: action.payload  } } };
        case TARGETING_TYPES.ALERT:
          return { ...state,  trigger: { ...state.trigger, invitationHeadline: action.payload } };
        case TARGETING_TYPES.LOCAL_NOTIFICATION:
          return { ...state, trigger: { ...state.trigger, triggerCustomLocalNotificationContract: { ...state.trigger.triggerCustomLocalNotificationContract, title: action.payload  } } };
      }
      break;
    case TargetingActions.SET_DESCRIPTION_TEXT:
      switch (state.trigger.invitationType) {
        case TARGETING_TYPES.BANNER:
          return { ...state , trigger: { ...state.trigger, triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, invitationBody: action.payload  } } };
        case TARGETING_TYPES.CUSTOM_INTERCEPT:
          return { ...state,  trigger: { ...state.trigger, triggerCustomInterceptContract: { ...state.trigger.triggerCustomInterceptContract, customInvitationDescription: action.payload  } } };
        case TARGETING_TYPES.ALERT:
          return { ...state,  trigger: { ...state.trigger, invitationText: action.payload } };
        case TARGETING_TYPES.LOCAL_NOTIFICATION:
          return { ...state, trigger: { ...state.trigger, triggerCustomLocalNotificationContract: { ...state.trigger.triggerCustomLocalNotificationContract, body: action.payload  } } };
      }
      break;
      //Banner Reducers
    case TargetingActions.SET_INVITATION_TYPE:
      return { ...state , trigger: { ...state.trigger, invitationType: action.payload } };
    case TargetingActions.SET_FONT:
      return { ...state , trigger: { ...state.trigger , triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, font: action.payload } } };
    case TargetingActions.SET_TEXT_COLOR:
      return { ...state , trigger: { ...state.trigger , triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, textColor: action.payload } } };
    case TargetingActions.SET_BACKGROUND:
      return { ...state , trigger: { ...state.trigger , triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, backgroundColor: action.payload } } };
    case TargetingActions.SET_BANNER_POSITION:
      const [position, isPartial] = action.payload.split('.');
      return { ...state , trigger: { ...state.trigger , triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, position: position , isPartial: isPartial === 'PARTIAL' } } };
    case TargetingActions.SET_BUTTON_DISPLAY:
      return { ...state , trigger: { ...state.trigger , triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, customBannerButtonsSettingsContract: { ...state.trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract , buttonsDisplay: action.payload } } } };
    case TargetingActions.SET_ACCEPT_BUTTON_TEXT:
      return { ...state , trigger: { ...state.trigger , triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, customBannerButtonsSettingsContract: { ...state.trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract , acceptButtonText: action.payload } } } };
    case TargetingActions.SET_ACCEPT_BUTTON_TEXT_COLOR:
      return { ...state , trigger: { ...state.trigger , triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, customBannerButtonsSettingsContract: { ...state.trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract , acceptButtonTextColor: action.payload } } } };
    case TargetingActions.SET_ACCEPT_BUTTON_BACKGROUND_COLOR:
      return { ...state , trigger: { ...state.trigger , triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, customBannerButtonsSettingsContract: { ...state.trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract , acceptButtonBackgroundColor: action.payload } } } };
    case TargetingActions.SET_CLOSE_BUTTON_COLOR:
      return { ...state , trigger: { ...state.trigger , triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, customBannerButtonsSettingsContract: { ...state.trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract , closeButtonColor: action.payload } } } };
    case TargetingActions.SET_IS_BANNER_STICKY:
      return { ...state , trigger: { ...state.trigger , triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, isSticky: action.payload } } };
    case TargetingActions.SET_BANNER_INVITATION_TIMEOUT:
      const formattedTime = action.payload * 1000;
      return { ...state , trigger: { ...state.trigger , triggerCustomBannerContract: { ...state.trigger.triggerCustomBannerContract, invitationTimeout: formattedTime } } };
    case TargetingActions.SET_DARK_MODE_BACKGROUND:
      return { ...state , theme: { ...state.theme, themeData : { ...state.theme.themeData , dark : { ...state.theme.themeData.dark , palette : { ...state.theme.themeData.dark.palette , core : { ...state.theme.themeData.dark.palette.core , canvas : action.payload } } } } }  };
    case TargetingActions.SET_DARK_MODE_TEXT:
      return { ...state , theme: { ...state.theme, themeData : { ...state.theme.themeData , dark : { ...state.theme.themeData.dark , palette : { ...state.theme.themeData.dark.palette , typography : { ...state.theme.themeData.dark.palette.typography , headline : action.payload } } } } } };
    case TargetingActions.SET_DARK_MODE_BUTTON_BACKGROUND:
      return { ...state , theme: { ...state.theme, themeData : { ...state.theme.themeData , dark : { ...state.theme.themeData.dark , palette : { ...state.theme.themeData.dark.palette , action : { ...state.theme.themeData.dark.palette.action , primary : action.payload } } } } } };
    case TargetingActions.SET_DARK_MODE_BUTTON_TEXT:
      return { ...state , theme: { ...state.theme, themeData : { ...state.theme.themeData , dark : { ...state.theme.themeData.dark , palette : { ...state.theme.themeData.dark.palette, typography : { ...state.theme.themeData.dark.palette.typography , action : { ...state.theme.themeData.dark.palette.typography.action , primary: action.payload } } } } } } };
    case TargetingActions.SET_DARK_MODE_CLOSE_BUTTON:
      return { ...state , theme: { ...state.theme, themeData : { ...state.theme.themeData , dark : { ...state.theme.themeData.dark , palette : { ...state.theme.themeData.dark.palette , action : { ...state.theme.themeData.dark.palette.action , auxiliary : action.payload } } } } } };
      //Custom Invite Reducers
    case TargetingActions.SET_CUSTOM_PROVIDE_FEEDBACK_BUTTON_TEXT:
      return { ...state,  trigger: { ...state.trigger, triggerCustomInterceptContract: { ...state.trigger.triggerCustomInterceptContract, customProvideButtonText: action.payload  } } };
    case TargetingActions.SET_CUSTOM_LATER_BUTTON_TEXT:
      return { ...state,  trigger: { ...state.trigger, triggerCustomInterceptContract: { ...state.trigger.triggerCustomInterceptContract, customLaterButtonText: action.payload  } } };
    case TargetingActions.SET_CUSTOM_DECLINE_BUTTON_TEXT:
      return { ...state,  trigger: { ...state.trigger, triggerCustomInterceptContract: { ...state.trigger.triggerCustomInterceptContract, customDeclineButtonText: action.payload  } } };
      // Alert Reducers
    case TargetingActions.SET_PROVIDE_BUTTON_TEXT:
      return { ...state,  trigger: { ...state.trigger, provideButtonText: action.payload } };
    case TargetingActions.SET_LATER_BUTTON_TEXT:
      return { ...state,  trigger: { ...state.trigger, laterButtonText: action.payload } };
    case TargetingActions.SET_DECLINE_BUTTON_TEXT:
      return { ...state,  trigger: { ...state.trigger, declineButtonText: action.payload } };
      // Local notification 
    case TargetingActions.SET_LARGE_ICON:
      return { ...state, trigger: { ...state.trigger, triggerCustomLocalNotificationContract: { ...state.trigger.triggerCustomLocalNotificationContract, image: action.payload  } } };
    case TargetingActions.SET_IS_EXPIRED:
      return { ...state, trigger: { ...state.trigger, triggerCustomLocalNotificationContract: { ...state.trigger.triggerCustomLocalNotificationContract, expiration: { ...state.trigger.triggerCustomLocalNotificationContract.expiration , isExpired : action.payload } } } };
    case TargetingActions.SET_EXPIRATION_UNIT:
      return { ...state, trigger: { ...state.trigger, triggerCustomLocalNotificationContract: { ...state.trigger.triggerCustomLocalNotificationContract, expiration: { ...state.trigger.triggerCustomLocalNotificationContract.expiration , unit : action.payload } } } };
    case TargetingActions.SET_EXPIRATION_VALUE:
      return { ...state, trigger: { ...state.trigger, triggerCustomLocalNotificationContract: { ...state.trigger.triggerCustomLocalNotificationContract, expiration: { ...state.trigger.triggerCustomLocalNotificationContract.expiration , value : action.payload } } } };
    case TargetingActions.SET_APP_NAME:
      return { ...state, trigger: { ...state.trigger, triggerCustomLocalNotificationContract: { ...state.trigger.triggerCustomLocalNotificationContract, appName: action.payload  } } };
    case TargetingActions.SET_APP_LOGO:
      return { ...state, trigger: { ...state.trigger, triggerCustomLocalNotificationContract: { ...state.trigger.triggerCustomLocalNotificationContract, appIcon: action.payload  } } };
      //Rules Reducers
    case TargetingActions.SET_MOBILE_FREQUENCY_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileFrequency: { ...state.trigger.rules.MobileFrequency , isActive: action.payload } } } };
    case TargetingActions.SET_MOBILE_FREQUENCY_RULE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileFrequency: { ...state.trigger.rules.MobileFrequency , params : { ...state.trigger.rules.MobileFrequency.params, frequency: action.payload } } } } };
    case TargetingActions.SET_MOBILE_FREQUENCY_TYPE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileFrequency: { ...state.trigger.rules.MobileFrequency , params : { ...state.trigger.rules.MobileFrequency.params, type: action.payload } } } } };
    case TargetingActions.SET_TARGET_BY_NUMBER_OF_SESSION_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileNumberOfAppSession: { ...state.trigger.rules.MobileNumberOfAppSession , isActive: action.payload } } } };
    case TargetingActions.SET_TARGET_BY_NUMBER_OF_SESSION_COMPARE_STRING:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileNumberOfAppSession: { ...state.trigger.rules.MobileNumberOfAppSession , params : { ...state.trigger.rules.MobileNumberOfAppSession.params, compareString: action.payload } } } } };
    case TargetingActions.SET_TARGET_BY_NUMBER_OF_SESSION:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileNumberOfAppSession: { ...state.trigger.rules.MobileNumberOfAppSession , params : { ...state.trigger.rules.MobileNumberOfAppSession.params, numberOfRepeats: action.payload } } } } };
    case TargetingActions.SET_TARGET_BY_TIME_IN_FOREGROUND_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileTimeInForeground: { ...state.trigger.rules.MobileTimeInForeground , isActive: action.payload } } } };
    case TargetingActions.SET_TARGET_BY_TIME_IN_FOREGROUND_COMPARE_STRING:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileTimeInForeground: { ...state.trigger.rules.MobileTimeInForeground , params : { ...state.trigger.rules.MobileTimeInForeground.params, compareString: action.payload } } } } };
    case TargetingActions.SET_TARGET_TIME_IN_FOREGROUND:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileTimeInForeground: { ...state.trigger.rules.MobileTimeInForeground , params : { ...state.trigger.rules.MobileTimeInForeground.params, seconds: action.payload } } } } };
    case TargetingActions.SET_TARGET_BY_APP_VERSION_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileAppVersion: { ...state.trigger.rules.MobileAppVersion , isActive: action.payload } } } };
    case TargetingActions.SET_TARGET_BY_APP_VERSION:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileAppVersion: { ...state.trigger.rules.MobileAppVersion , params : { ...state.trigger.rules.MobileAppVersion.params, versions: action.payload } } } } };
    case TargetingActions.INIT_TARGET_BY_APP_VERSION_PARAMS:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileAppVersion: { ...state.trigger.rules.MobileAppVersion , params : {} } } } };
    case TargetingActions.SET_TARGET_BY_OS_VERSION_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileOsVersion: { ...state.trigger.rules.MobileOsVersion , isActive: action.payload } } } };
    case TargetingActions.SET_TARGET_BY_OS_VERSION:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileOsVersion: { ...state.trigger.rules.MobileOsVersion , params : { ...state.trigger.rules.MobileOsVersion.params, versions: action.payload } } } } };
    case TargetingActions.INIT_TARGET_BY_OS_VERSION_PARAMS:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileOsVersion: { ...state.trigger.rules.MobileOsVersion , params : {} } } } };
    case TargetingActions.SET_TARGET_BY_CUSTOM_RULE_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileGenericRule: { ...state.trigger.rules.MobileGenericRule , isActive: action.payload } } } };
    case TargetingActions.INIT_TARGET_BY_CUSTOM_RULE_PARAMS:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileGenericRule: { ...state.trigger.rules.MobileGenericRule , params : action.payload } } } };
    case TargetingActions.SET_TARGET_BY_CUSTOM_RULE_ID:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileGenericRule: { ...state.trigger.rules.MobileGenericRule , params : { ...state.trigger.rules.MobileGenericRule.params, ruleId: action.payload } } } } };
    case TargetingActions.SET_TARGET_BY_CUSTOM_RULE_NAME:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileGenericRule: { ...state.trigger.rules.MobileGenericRule , params : { ...state.trigger.rules.MobileGenericRule.params, name: action.payload } } } } };
    case TargetingActions.INIT_MOBILE_DONT_INVITE_BY_DECLINED:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileDontInviteOnDeclined: { ...state.trigger.rules.MobileDontInviteOnDeclined , params : action.payload } } } };
    case TargetingActions.SET_MOBILE_DONT_INVITE_BY_DECLINED_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileDontInviteOnDeclined: { ...state.trigger.rules.MobileDontInviteOnDeclined , isActive :  action.payload } } } };
    case TargetingActions.SET_MOBILE_DONT_INVITE_BY_DECLINED_DAYS:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileDontInviteOnDeclined: { ...state.trigger.rules.MobileDontInviteOnDeclined , params : { ...state.trigger.rules.MobileDontInviteOnDeclined.params, days: action.payload } } } } };
    case TargetingActions.INIT_MOBILE_DONT_INVITE_BY_SUBMITTED:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileDontInviteOnSubmitted: { ...state.trigger.rules.MobileDontInviteOnSubmitted , params : action.payload } } } };
    case TargetingActions.SET_MOBILE_DONT_INVITE_BY_SUBMITTED_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileDontInviteOnSubmitted: { ...state.trigger.rules.MobileDontInviteOnSubmitted , isActive :  action.payload } } } };
    case TargetingActions.SET_MOBILE_DONT_INVITE_BY_SUBMITTED_DAYS:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileDontInviteOnSubmitted: { ...state.trigger.rules.MobileDontInviteOnSubmitted , params : { ...state.trigger.rules.MobileDontInviteOnSubmitted.params, days: action.payload } } } } };
    case TargetingActions.INIT_MOBILE_LAST_TIME_IN_FOREGROUND:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileLastTimeInForeground: { ...state.trigger.rules.MobileLastTimeInForeground , params :  action.payload } } } };
    case TargetingActions.SET_MOBILE_LAST_TIME_IN_FOREGROUND_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileLastTimeInForeground: { ...state.trigger.rules.MobileLastTimeInForeground , isActive :  action.payload } } } };
    case TargetingActions.SET_MOBILE_LAST_TIME_IN_FOREGROUND_COMPARE_STRING:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileLastTimeInForeground: { ...state.trigger.rules.MobileLastTimeInForeground , params : { ...state.trigger.rules.MobileLastTimeInForeground.params, compareString: action.payload } } } } };
    case TargetingActions.SET_MOBILE_LAST_TIME_IN_FOREGROUND_SECONDS:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileLastTimeInForeground: { ...state.trigger.rules.MobileLastTimeInForeground , params : { ...state.trigger.rules.MobileLastTimeInForeground.params, seconds: action.payload } } } } };
    case TargetingActions.INIT_MOBILE_APPROXIMATE_DELAY:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileAppxDelay: { ...state.trigger.rules.MobileAppxDelay , params :  action.payload } } } };
    case TargetingActions.SET_MOBILE_APPROXIMATE_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileAppxDelay: { ...state.trigger.rules.MobileAppxDelay , isActive :  action.payload } } } };
    case TargetingActions.SET_MOBILE_APPROXIMATE_SECONDS:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileAppxDelay: { ...state.trigger.rules.MobileAppxDelay , params : { ...state.trigger.rules.MobileAppxDelay.params, seconds: action.payload } } } } };
    case TargetingActions.INIT_MOBILE_DIDNT_VISIT_YOUR_APP:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileDidntVisitApp: { ...state.trigger.rules.MobileDidntVisitApp , params :  action.payload } } } };
    case TargetingActions.SET_MOBILE_DIDNT_VISIT_YOUR_APP_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileDidntVisitApp: { ...state.trigger.rules.MobileDidntVisitApp , isActive :  action.payload } } } };
    case TargetingActions.SET_MOBILE_DIDNT_VISIT_YOUR_APP_SECONDS:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, MobileDidntVisitApp: { ...state.trigger.rules.MobileDidntVisitApp , params : { ...state.trigger.rules.MobileDidntVisitApp.params, days: action.payload } } } } };
    case TargetingActions.INIT_TARGET_BY_OCQ_RULES:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, OmniChannelRules: { ...state.trigger.rules.OmniChannelRules , params : action.payload } } } };
    case TargetingActions.SET_TARGET_BY_OCQ_RULES_IS_ACTIVE:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, OmniChannelRules: { ...state.trigger.rules.OmniChannelRules , isActive :  action.payload } } } };
    case TargetingActions.SET_TARGET_BY_OCQ_RULES:
      return { ...state , trigger: { ...state.trigger , rules: { ...state.trigger.rules, OmniChannelRules: { ...state.trigger.rules.OmniChannelRules , params : { ...state.trigger.rules.OmniChannelRules.params, rules: action.payload } } } } };
    default:
    case TargetingActions.SET_TRIGGER_SCOPE:
      return { ...state,  trigger: { ...state.trigger, triggerScope: action.payload } };
  }
};