import { getNestedProperty } from '@mdigital/common/dist/utils/object';

export const bannerPreviewPositions = {
  mobileIOS: {
    Partial: {
      minHeight: '98px',
      width : '346px',
      top: '65px',
      bottom: '50px',
      left: '28px',
    },
    Drawer: {
      minHeight: '132px',
      width : '375px',
      top: '15px',
      bottom: '18px',
      left: '14px',
    },
  },

  mobileAndroid: {
    Partial: {
      minHeight: '98px',
      width : '346px',
      top: '62px',
      bottom: '60px',
      left: '25px',
    },
    Drawer: {
      minHeight: '132px',
      width : '375px',
      top: '14px',
      bottom: '26px',
      left: '10px',
    },
  },
};

export const buttonWrapperPosition = {
  Partial: {
    minHeight: '50px',
    width : '346px',
    right: '32px',
    top: '16px',
  },
  Drawer: {
    minHeight: '79px',
    width : '375px',
    right: '32px',
    top: '16px',
  },
};

export const getBannerTheme = (triggerState, isDarkEnabled) => ({
  textColor: `${isDarkEnabled ? getNestedProperty(triggerState , 'theme.themeData.dark.palette.typography.headline') : getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.textColor')}`,
  backgroundColor: `${isDarkEnabled ? getNestedProperty(triggerState , 'theme.themeData.dark.palette.core.canvas') : getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.backgroundColor')}`,
  closeButtonColor: `${isDarkEnabled ? getNestedProperty(triggerState , 'theme.themeData.dark.palette.action.auxiliary') : getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.closeButtonColor')}`,
  acceptButtonTextColor: `${isDarkEnabled ? getNestedProperty(triggerState , 'theme.themeData.dark.palette.typography.action.primary') : getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonTextColor')}`,
  acceptButtonBackgroundColor: `${isDarkEnabled ? getNestedProperty(triggerState , 'theme.themeData.dark.palette.action.primary') : getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonBackgroundColor')}`,
});