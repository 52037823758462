import styled from 'styled-components';

export const StyledCustomInterceptContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledCustomInterceptHeader = styled.span`
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
`;

export const StyledTextSettingsDescription = styled.p`
   margin-bottom: 35px;
`;