import React from 'react';
import ViewTemplate from '@mdigital/components/dist/components/ViewTemplate';
import { StyledAccessDeniedContainer } from './StyledAccessDenied';
import { getSingleText } from '../../../common/utils';

export default function AccessDenied() {

  const  props = {
    imageSettings:{ src: '../../../assets/images/no-permission.svg', style: { width:96, height:96, marginBottom: 25 } },
    title: getSingleText('app.pages.accessDenied.title'),
    subTitle: getSingleText('app.pages.accessDenied.subTitle'),
  };

  return <StyledAccessDeniedContainer id="access-denied">
    <ViewTemplate viewWidth="600px" viewHeight="200px"{...props}/>
  </StyledAccessDeniedContainer>;
}