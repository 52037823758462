import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import AutoSuggest from '@mdigital/components/dist/components/AutoSuggest';
import StyledTargetingByRating from './StyledTargetingByRating';
import BlockableRow from '../BlockableRow';
import { ratingComparisonOptions, timeUnits } from '../../../../../../../common/dropdownOptions';
import { autoSuggestLabelByIdFinder } from '../../../../../../../common/utils';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';

const IS_ACTIVE_KEY = 'trigger.rules.MobileRatingScores.isActive';

const required = (ratingScoreTargeting) => (value) => {
  const isActive = getNestedProperty(ratingScoreTargeting, 'isActive');
  return isActive && !value ? 'Required' : undefined;
};

const ratingTypes = [
  { id: 'Feedback Submit', label: 'FEEDBACK_SUBMITTED' },
  { id: 'NPS rating', label: 'NPS' },
  { id: 'CSAT rating', label: 'CSAT' }
];

const ratingTypeAutoSuggestIdFinder = autoSuggestLabelByIdFinder(ratingTypes);
const timeUnitsAutoSuggestIdFinder = autoSuggestLabelByIdFinder(timeUnits);
const comparisonOptionsAutoSuggestIdFinder = autoSuggestLabelByIdFinder(ratingComparisonOptions);

const isFeedbackSubmittedActive = (ratingScoreTargeting) => 
  getNestedProperty(ratingScoreTargeting, 'params.ratingType') === 'FEEDBACK_SUBMITTED';

const RatingComparisonSection = ({ ratingScoreTargeting }) => (
  !isFeedbackSubmittedActive(ratingScoreTargeting) && 
  <React.Fragment>
    <span>
      <Field validate={required(ratingScoreTargeting)} name="trigger.rules.MobileRatingScores.params.compareString" render={({ input }) => (
        <AutoSuggest 
          suggestions={ratingComparisonOptions}
          digDisableSuggest={true}
          onSuggestionSelected={({ label }) => input.onChange(label)}
          value={comparisonOptionsAutoSuggestIdFinder(input.value)}
        />
      )} />
    </span>
    {getNestedProperty(ratingScoreTargeting, 'params.compareString') !== 'hasValue' &&
      <span>
        <Field validate={required(ratingScoreTargeting)} name="trigger.rules.MobileRatingScores.params.ratingValue" type="number" min="0" component="input" />
      </span>
    }
  </React.Fragment>
);

const TargetByRating = ({ mutators }) => (
  <BlockableRow title="User has provided" isActiveFieldName={IS_ACTIVE_KEY} setIsActive={mutators.setMobileRatingScoresIsActive}>
    <FormSpy render={({ values: { trigger: { rules: { MobileRatingScores } } } }) => (
      <StyledTargetingByRating isFeedbackSubmittedSelected={isFeedbackSubmittedActive(MobileRatingScores)}>    
        <div>
          <span>
            <Field validate={required(MobileRatingScores)} name="trigger.rules.MobileRatingScores.params.ratingType" render={({ input }) => (
              <AutoSuggest 
                suggestions={ratingTypes}
                digDisableSuggest={true}
                onSuggestionSelected={({ label }) => input.onChange(label)}
                value={ratingTypeAutoSuggestIdFinder(input.value)}
              />
            )} />
          </span>
          <RatingComparisonSection ratingScoreTargeting={MobileRatingScores} />
        </div>
        <div className="target-by-rating-time-frame">
          <label>In last</label>
          <span className="target-by-rating-time-frame-value">
            <Field validate={required(MobileRatingScores)} name="trigger.rules.MobileRatingScores.params.period.quantity" type="number" min="0" component="input" />
          </span>
          <span className="target-by-rating-time-frame-units">
            <Field validate={required(MobileRatingScores)} name="trigger.rules.MobileRatingScores.params.period.unit" render={({ input }) => (
              <AutoSuggest 
                suggestions={timeUnits}
                digDisableSuggest={true}
                onSuggestionSelected={({ label }) => input.onChange(label)}
                value={timeUnitsAutoSuggestIdFinder(input.value)}
              />
            )} />
          </span>
        </div>
      </StyledTargetingByRating>
    )} />
  </BlockableRow>
);

export default TargetByRating;