import React from 'react';
import PropTypes from 'prop-types';
import StyledFooter from './StyledFooter';
import { FormSpy } from 'react-final-form';
import { SSO_HELP_LINK } from '../../../../../../common/constants';

const isDisabled = (errors)=>Object.keys(errors).length > 0;

const Footer = ({ onCancel, onOkClick }) => (
  <StyledFooter>
    <FormSpy
      subscription={{ errors: true }}
      render={({ errors }) =>
        <React.Fragment>
          <span className="wizard-modal-footer-content">
            <a className="wizard-modal-footer-help-link" href={window.authManager.isSSO ? SSO_HELP_LINK : 'http://docs.kampyle.com/m/65562/l/1036968-app-rating-prompts'}>
              <i className="fa fa-question-circle" />&nbsp;Help
            </a>
          </span>
          <span className="wizard-modal-footer-buttons">
            <button type="button" onClick={onCancel} className="btn btn-default">Cancel</button>
            <button disabled={isDisabled(errors)} type="submit" onClick={onOkClick} className="btn btn-primary">Save</button>
          </span>
        </React.Fragment>
      } />
  </StyledFooter>
);

Footer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOkClick: PropTypes.func.isRequired,
};

export default Footer;