import React from 'react';
import { StyledAndroidAlertPreviewBodyWrapper, StyledAndroidInvitationHeadline, StyledAndroidInvitationText,
  StyledAndroidProvideButtonText, StyledAndroidAlertButton } from './StyledAlertLivePreview';

const AndroidAlertPreview = ({ values, isDark }) => {
  const {
    trigger: {
      invitationHeadline,
      invitationText,
      provideButtonText,
      declineButtonText,
      laterButtonText,
    },
  } = values;
  return (
    <StyledAndroidAlertPreviewBodyWrapper isDark={isDark}>
      <StyledAndroidInvitationHeadline isDark={isDark}>{invitationHeadline}</StyledAndroidInvitationHeadline>
      <StyledAndroidInvitationText isDark={isDark}>{invitationText}</StyledAndroidInvitationText>
      <StyledAndroidProvideButtonText isDark={isDark}>{provideButtonText}</StyledAndroidProvideButtonText>
      <StyledAndroidAlertButton isDark={isDark}>{declineButtonText}</StyledAndroidAlertButton>
      <StyledAndroidAlertButton isDark={isDark}>{laterButtonText}</StyledAndroidAlertButton>
    </StyledAndroidAlertPreviewBodyWrapper>
  );
};

export default AndroidAlertPreview;