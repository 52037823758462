import React from 'react';
import { getSingleText } from '../../../../common/utils';
import { StyledSdkIntegrationV2Header , StyledsdkIntegrationV2HeaderButton  } from './StyledSdkIntegrationV2ModalHeader';
import { PROPERTY_TYPES } from '../../../../common/constants';

const SdkIntegrationV2ModalHeader = ({ onClose , propertyType }) =>(
  <StyledSdkIntegrationV2Header>
    <h3 className="sdk-integration-v2-modal-header">
      {getSingleText(`app.topMenu.sdkV2.${propertyType === PROPERTY_TYPES.ANYWHERE ? 'anywhere.' : ''}title`)}
    </h3>
    <StyledsdkIntegrationV2HeaderButton>
      <i className="neb-icon-Close" onClick={ onClose }/>
    </StyledsdkIntegrationV2HeaderButton> 
  </StyledSdkIntegrationV2Header>
); 

export default SdkIntegrationV2ModalHeader;