"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
class Intercom extends react_1.Component {
    constructor(props) {
        super(props);
        this.scriptWrapper = null;
        this.scriptMounted = false;
    }
    componentDidMount() {
        window.intercomSettings = Object.assign({}, window.profileData);
        this.initIntercomComponent();
    }
    initIntercomComponent() {
        const { app } = this.props;
        const script = `(function(){
        var w=window;
        var ic=w.Intercom;
        if(typeof ic==="function") {
          ic('reattach_activator');
          ic('update',w.intercomSettings);
        } else {
          var d=document;
          var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};
            w.Intercom=i;var l=function(){var s=d.createElement('script');
            s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${app.intercomId || ''}';
            var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};
            l();
        }})();`;
        if (window.intercomSettings && window.intercomSettings.company) {
            window.intercomSettings.app_id = app.intercomId || '';
            window.intercomSettings.company.data_center = app.dataCenter || '';
        }
        if (this.scriptWrapper && !this.scriptMounted) {
            const scriptTag = document.createElement('script');
            scriptTag.innerHTML = script;
            this.scriptWrapper.appendChild(scriptTag);
            this.scriptMounted = true;
        }
    }
    componentWillUnmount() {
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
    }
    render() {
        const { app } = this.props;
        const shouldIntercomRender = app.intercomId && app.dataCenter;
        if (!shouldIntercomRender) {
            return null;
        }
        return (react_1.default.createElement("div", { id: 'intercomWrapper', ref: (el) => this.scriptWrapper = el }));
    }
}
exports.default = Intercom;
