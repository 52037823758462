import { RadioIconButton, RadioIconButtonGroup } from '@m/alchemy-ui';
import React from 'react';
import { getSingleText } from '../../../../../common/utils';
import { TRIGGER_TYPE } from '../../index';
import { SwitchAppModeModal } from '../../SwitchAppModeModal';
import { StyledCustomRadioIconButton, StyledLeftPanelHeader } from './SharedMobileTriggeringStyling';
import SharedLeftPanelDescription from './SharedLeftPanelDescription';

const SharedLeftPanel = ({ items, selection , setSelection, triggerType, disabled }) => {
  const TextPathPrefix = 'app.pages.forms.triggeringModal';
  const PanelHeader = `${TextPathPrefix}.${triggerType}.leftPanelHeader`;

  const handleRadioButtonChange = (item) => {
    triggerType === TRIGGER_TYPE.TRIGGER_BY_TARGETING ?
      SwitchAppModeModal(item.value, setSelection) : 
      setSelection(item.value);
  };

  return (
    <React.Fragment>
      <StyledLeftPanelHeader>{getSingleText(PanelHeader)}</StyledLeftPanelHeader>
      <StyledCustomRadioIconButton>
        <RadioIconButtonGroup
          layout="grid"
          gridColumns={2}
          selection={selection}
        >
          {({ isSelected }) =>
            items.map((item) => (
              <RadioIconButton className='md-radio-icon-button'
                key={item.value}
                label={item.label}
                icon={<item.Icon/>}
                checked={isSelected(item.value)}
                disabled={disabled}
                onChange={ () => handleRadioButtonChange(item) }
              />
            ))
          }
        </RadioIconButtonGroup>
      </StyledCustomRadioIconButton>
      <SharedLeftPanelDescription triggerType={triggerType}/>
    </React.Fragment>
  );
};

export default SharedLeftPanel;