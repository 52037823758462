import styled from 'styled-components';
import { Z_INDEX_MAP } from '../../../../../../common/enums';
import { units, color } from '@m/alchemy-ui';
import { PROPERTY_TYPES } from '../../../../../../common/constants';

export const StyledPreviewContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  ${({ position }) => position === 'TOP' ? 'height: calc(100vh - 400px);' : ''};
  ${({ position }) => position === 'BOTTOM' ? 'bottom: 0;' : ''};
`;

export const StyledCustomInterceptImage = styled.img`
  z-index: ${Z_INDEX_MAP.FIRST_LAYER};
  position: relative;
  top: 0;
  left: 0;
	width: 288px;
	height: 192px;
  border-radius: ${units(2)};
`;

export const StyledPreviewCustomInterceptContainer = styled.div`
	position: relative;
	top: 0;
	left: 0;
	background: ${color('container')};
	margin: 0 0 ${units(4)};
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius:  ${units(2)};
`;

export const StyledDeviceImage = styled.img`
  z-index: ${Z_INDEX_MAP.FIRST_LAYER};
  position: relative;
  top: 0;
  left: 0;
`;

export const StyledBackgroundImage = styled.img`
  position: absolute;
  top : ${({ backgroundPosition, position })=> position === 'TOP' && backgroundPosition.top};
  bottom : ${({ backgroundPosition, position })=> position === 'BOTTOM' && backgroundPosition.bottom};
  left:  ${({ backgroundPosition })=>  backgroundPosition.left};
`;

export const StyledAlertBackground = styled.div`
    background: ${color('contrast')};
    opacity: 0.2;
    height: 532px;
    width: 375px;
    position: absolute;
    top: 14px;
    right: ${({ propertyType }) => propertyType === PROPERTY_TYPES.IOS ? '14px' : '13px'};
    border-radius: ${units(1)} ${units(1)} 0 0;
`;

export const bannerBackgroundPosition = {
  mobileIOS: {
    top: '14px',
    bottom: '20px',
    left: '14px',
  },
  mobileAndroid: {
    top: '14px',
    bottom: '20px',
    left: '10px',
  },
};