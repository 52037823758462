"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertErrorToErrorMessages = void 0;
function convertErrorToErrorMessages(err) {
    if (err == null) {
        return [];
    }
    const messages = err.resultMessage != null ? [err.resultMessage] : [];
    const additionalInfo = err.kampyleAdditionalInfo;
    return Array.isArray(additionalInfo) ? messages.concat(...additionalInfo) : (additionalInfo != null ? messages.concat(additionalInfo) : messages);
}
exports.convertErrorToErrorMessages = convertErrorToErrorMessages;
