import React, { useContext } from 'react';
import { TriggerContext } from '../../../../../..';
import { Tooltip, HelpIcon, Radio, RadioGroup, Input } from '@m/alchemy-ui';
import { StyledDurationHeader, StyledDurationRadio } from './StyledDuration';
import { PanelSubHeader, Subtitle } from '../../../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../../../common/utils';
import { TargetingActions } from '../../../../Actions';
import { TextPathPrefix } from '../../../..';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';

const RadioItem = {
  ALWAYS : 'Always',
  DISAPPEAR_AFTER: 'Disappear after',
};

const Duration = () => {
  const durationRadioItems = [RadioItem.DISAPPEAR_AFTER, RadioItem.ALWAYS];
  const { triggerState , triggerDispatch, isReadOnly } = useContext(TriggerContext);
  const isSticky = getNestedProperty(triggerState , 'trigger.triggerCustomBannerContract.isSticky');
  const selection = isSticky ? durationRadioItems[1] : durationRadioItems[0];

  const formatTimeValue = (time) => {
    return time / 1000;
  };

  return (
    <React.Fragment>
      <StyledDurationHeader>
        <PanelSubHeader>{getSingleText(`${TextPathPrefix}.durationHeader`)}</PanelSubHeader>
        <Tooltip
          trigger={
            <HelpIcon aria-label="Information Icon" size="12" palette="structural" />
          }
          placement="right"
          contents={getSingleText(`${TextPathPrefix}.durationTooltip`)}
        />
      </StyledDurationHeader>
      <RadioGroup selection={selection} onChange={ ()=> triggerDispatch({ type : TargetingActions.SET_IS_BANNER_STICKY , payload : !isSticky })}>
        {({ isSelected, handleChange }) =>
          durationRadioItems.map((item, index) => (
            <StyledDurationRadio key={index}>
              <Radio
                key={item}
                label={item}
                disabled={isReadOnly}
                checked={isSelected(item)}
                onChange={() => handleChange(item)}
              />
              {item === RadioItem.DISAPPEAR_AFTER  &&
                <React.Fragment>
                  <Input type="number" min='1' max='60'
                    disabled={getNestedProperty(triggerState , 'trigger.triggerCustomBannerContract.isSticky') || isReadOnly}
                    value={formatTimeValue(getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.invitationTimeout'))}
                    onChange={ (e)=> triggerDispatch({ type : TargetingActions.SET_BANNER_INVITATION_TIMEOUT , payload : e.target.value })}/>
                  <Subtitle fontColor="typeBody">{getSingleText(`${TextPathPrefix}.sec`)}</Subtitle>
                </React.Fragment>
              }
            </StyledDurationRadio>
          ))
        }
      </RadioGroup>
    </React.Fragment>
  );
};

export default Duration;