import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '@mdigital/components/dist/components/Modal';
import QRCode from '@mdigital/components/dist/components/QRCode';
import PreviewURLField from './PreviewURLField';
import StyledModalContainer, {
  StyledMobilePreviewModalHeader,
  StyledMobilePreviewModalBody,
  StyledMobilePreviewModalQRCodeContainer,
  StyledCloseButton,
  StyledModalTitle,
  StyledModalBodySplitter,
  StyledSplitterTitle,
  StyledHorizontalLine,
  StyledMobilePreviewModalDescription
} from './StyledMobilePreviewIntegrationModal';
import withProvider from '../../hoc/ProviderHoc';
import { setMobilePreviewModalShown } from '../../../redux/actions/mobilePreview.actions';
import { PROPERTY_TYPES } from '../../../common/constants';

const mapStateToProps = ({ mobilePreview, property: { propertyType } }) => ({ ...mobilePreview, propertyType });

const URLS = {
  [PROPERTY_TYPES.ANDROID]: {
    storeURL: '#android-store-url',
    helpCenterURL: '#android-help-center-url',
  },
  [PROPERTY_TYPES.IOS]: {
    storeURL: '#ios-store-url',
    helpCenterURL: '#ios-help-center-url',
  },
};

const PreviewModalDescription = ({ propertyType }) => {
  const { storeURL, helpCenterURL } = (URLS[propertyType] || {});
  return (
    <StyledMobilePreviewModalDescription>
      To preview survey on mobile device, download Preview App from the <a href={storeURL}>App Store</a>.
      Login with the below link or scan QR Code. <a href={helpCenterURL}>Learn more</a>
    </StyledMobilePreviewModalDescription>
  );
};

@withProvider()
@connect(mapStateToProps)
export default class MobilePreviewIntegrationModal extends React.Component {

  static propTypes = {
    integrationURL: PropTypes.string,
    isModalShown: PropTypes.bool,
    dispatch: PropTypes.func,
  }

  handeCloseButtonClick = () => this.props.dispatch(setMobilePreviewModalShown(false));

  render() {
    return (
      <Modal width="500px" show={this.props.isModalShown}>
        <StyledModalContainer>
          <StyledMobilePreviewModalHeader>
            <StyledModalTitle>
              Preview on Mobile Device
            </StyledModalTitle>
            <StyledCloseButton onClick={this.handeCloseButtonClick}>&times;</StyledCloseButton>
          </StyledMobilePreviewModalHeader>
          <StyledMobilePreviewModalBody>
            <PreviewModalDescription propertyType={this.props.propertyType} />
            <PreviewURLField urlData={this.props.integrationURL} />
            <StyledModalBodySplitter>
              <StyledHorizontalLine />
              <StyledSplitterTitle>Or Scan QR Code</StyledSplitterTitle>
              <StyledHorizontalLine />
            </StyledModalBodySplitter>
            <StyledMobilePreviewModalQRCodeContainer>
              <QRCode cellSize={2} data={this.props.integrationURL} />
            </StyledMobilePreviewModalQRCodeContainer>
          </StyledMobilePreviewModalBody>
        </StyledModalContainer>
      </Modal>
    );
  }
}

