import { getSingleText } from '../../../../common/utils';
import CodeTriggerIcon from '../../../assets/TriggeringModal/CodeTrigger.svg';
import TargetingTriggerIcon from '../../../assets/TriggeringModal/TargetingTrigger.svg';

export const triggersByProperty = {
  mobileIOS: {
    triggerByCode: {
      cardIcon: CodeTriggerIcon ,
      cardBodyText: getSingleText('app.pages.forms.triggeringModal.selectionPage.triggerByCodeHeader'),
      cardFooterText: getSingleText('app.pages.forms.triggeringModal.selectionPage.triggerByCodeContent'),   
    },
    triggerByTargeting: {
      cardIcon: TargetingTriggerIcon,
      cardBodyText: getSingleText('app.pages.forms.triggeringModal.selectionPage.triggerByTargetingHeader'),
      cardFooterText: getSingleText('app.pages.forms.triggeringModal.selectionPage.triggerByTargetingContent'),
    },
  },
  mobileAndroid: {
    triggerByCode: {
      cardIcon: CodeTriggerIcon ,
      cardBodyText: getSingleText('app.pages.forms.triggeringModal.selectionPage.triggerByCodeHeader'),
      cardFooterText: getSingleText('app.pages.forms.triggeringModal.selectionPage.triggerByCodeContent'),   
    },
    triggerByTargeting: {
      cardIcon: TargetingTriggerIcon,
      cardBodyText: getSingleText('app.pages.forms.triggeringModal.selectionPage.triggerByTargetingHeader'),
      cardFooterText: getSingleText('app.pages.forms.triggeringModal.selectionPage.triggerByTargetingContent'),
    },
  },
  website: {

  },

};