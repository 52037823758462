import React, { useEffect, useState } from 'react';
import DigTitle from '@mdigital/components/dist/components/Title';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { LabelWrapper, RadioButtonWrapper } from './NewQuestionTypeFormStyled';
import FieldMappingView from '../FieldMappingView/FieldMappingView';
import RadioButton from '../components/ReusableFieldRadioButton/RadioButton';
import ReusableFieldForm from '../components/ReusableFieldForm/ReusableFieldForm';
import { getNgService, getSingleText } from '../../../../common/utils';
import { checkProvision } from '../../../../redux/store/store-helper';

const titleStyle = {
  fontSize: 14,
  color: '#5B5B60',
  letterSpacing: 0,
  font: 'normal normal 600 14px/16px Open Sans',
  fontWeight: 600,
};

const subTitleStyle = {
  fontSize: 14,
  color: '#595959',
  letterSpacing: 0,
  font: 'normal normal normal 14px/16px Open Sans',
};

const NEW_FIELD = 'newField';
export const EXISTING_FIELD = 'existingField';

export default function NewQuestionTypeForm({
  customStyle = {},
  baseTextPath,
  ecFields,
  handleSetFieldState,
  fieldState,
  handleSetFieldObj,
  fieldNameRequire,
  setIsApplyValid = () => {},
  isAnEcBrokenField,
  explicitFieldDetach,
}) {
  const [selectedRadioButton, setSelectedRadioButton] = useState(null);


  useEffect(()=>{
    initialForm();
  },[]);

  const radioButtons = [
    {
      withSelect: true,
      title: getSingleText(`${baseTextPath}reuseExistingTitle`),
      subTitle: getSingleText(`${baseTextPath}reuseExistingSubTitle`),
      onChange: () => handleRadioButtonsChange(EXISTING_FIELD, true),
      digRadioButtons: [{ digLabel: '', digValue: EXISTING_FIELD }],
    },
    {
      title: getSingleText(`${baseTextPath}createNewTitle`),
      subTitle: getSingleText(`${baseTextPath}createNewSubTitle`),
      onChange: () => handleRadioButtonsChange(NEW_FIELD, false),
      digRadioButtons: [{ digLabel: '', digValue: NEW_FIELD }],
    }
  ];

  function initialForm() {
    if(fieldState.isExistingField) {
      setSelectedRadioButton(EXISTING_FIELD);
      setIsApplyValid(true);
    }else if (fieldState.isExistingField !== undefined) {
      setSelectedRadioButton(NEW_FIELD);
      setIsApplyValid(false);
    }
  }

  function handleRadioButtonsChange(value, shouldRequireValidation) {
    setSelectedRadioButton(value);
    setIsApplyValid(shouldRequireValidation);
    const initialState =  { ...fieldState, ecKey: '', ecFriendlyName: '', isMarkedReusable: false };
    handleSetFieldObj(initialState);
  }

  function renderRadioButtons() {
    let suggestedFields = ecFields;

    if (checkProvision(DigProvisions.REUSABLE_FIELDS_PREVENT_REPEAT_USAGE)) {
      const alreadyUsedFields = getNgService(($rootScope) => {
        const pages = getNestedProperty($rootScope, 'globalSelectedForm.pages', []);
        return pages.reduce((formObj, page) => ({
          ...formObj,
          ...page.dynamicData.reduce((pageObj, item) => ({
            ...pageObj,
            [item.ecKey]: true,
          }), {}),
        }), {});
      });
  
      suggestedFields = ecFields.filter((field) => {
        return !alreadyUsedFields[field.ecKey];
      });
    }

    return radioButtons.map((item, index) => <RadioButtonWrapper key={index} index={index} style={index === 0 ? customStyle.radioButtonWrapper : null}>
      <RadioButton
        selectOptions={suggestedFields}
        fieldState={fieldState}
        handleSetFieldState={handleSetFieldState}
        handleSetFieldObj={handleSetFieldObj}
        setSelectedRadio={setSelectedRadioButton}
        selectedRadio={selectedRadioButton}
        labelStyle={{ marginLeft: -10 }}
        customStyle={customStyle}
        {...item}
      />
    </RadioButtonWrapper>);
  }

  const subtitle = getSingleText(`${baseTextPath}reportFieldMappingSubTitle`);

  return (
    <div>
      {isAnEcBrokenField ?
        <React.Fragment>
          <FieldMappingView
            fieldState={fieldState}
            baseTextPath={baseTextPath}
            isAnEcBrokenField={isAnEcBrokenField}
            explicitFieldDetach={explicitFieldDetach}
          />
          <hr /> 
        </React.Fragment>
        : 
        <React.Fragment>
          <LabelWrapper>
            <DigTitle
              digLabel={getSingleText(`${baseTextPath}reportFieldMapping`)}
              additionalDigLabelStyle={titleStyle}
              digTooltip={getSingleText(`${baseTextPath}titleTooltip`)}
            />
            <span className='required-text'>*</span>
          </LabelWrapper>
          <LabelWrapper>
            <DigTitle digLabel={subtitle} additionalDigLabelStyle={subTitleStyle} />
          </LabelWrapper>
          {renderRadioButtons()}
          {selectedRadioButton === NEW_FIELD && 
            <ReusableFieldForm
              customStyle={customStyle}
              baseTextPath={baseTextPath}
              handleSetFieldObj={handleSetFieldObj}
              handleSetFieldState={handleSetFieldState}
              fieldState={fieldState}
              fieldNameRequire={fieldNameRequire}
              setIsApplyValid={setIsApplyValid}
              explicitFieldDetach={explicitFieldDetach}
            />
          }
        </React.Fragment>
      }
    </div>
  );
}

