import styled from 'styled-components';
import { WARNING_BACKGROUND, WARNING_BORDER, WARNING_TEXT } from '../../common/colors.js';

export const StyledNotificationWrapper =  styled.div`
  z-index: 1051;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 58px;
  background-color: ${WARNING_BACKGROUND};
  border-top: ${({ type }) => type === 'WARNING' ? 'solid' : 'none'};
  border-color: ${WARNING_BORDER};
  color: ${WARNING_TEXT};
  font-family: Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    margin-right: 7px;
    svg {
      width: 30px;
    }
  }
`;
