import React from 'react';
import PropTypes from 'prop-types';
import withProvider from '../../hoc/ProviderHoc/ProviderHoc';
import { connect } from 'react-redux';
import { StyledEngagementPageLeftSide,StyledEngagementPageRightSide } from '../MobileEngagementDesign/StyledMobileEngagementDesign';
import { StyledThankYouPrompt } from './StyledThankYouPrompt';
import ThankYouPromptSettings from './ThankYouPromptSettings';
import { setImageDataValues , setImagetextContent ,setImagebodyContent , setButtonTextColor , setButtonColor, 
  setThankYouPromptImageResourceData, setDarkImageDataValues, setThankYouPromptDarkImageResourceData } from '../../common/mutators';
import { Form } from 'react-final-form';
import LivePreview from './ThankYouPromptLivePreview';
import { checkProvision } from '../../../redux/store/store-helper';
import { PROVISIONS } from '../../../common/enums';

function mapStateToProps({ forms: { isPublished } }) {
  return { isPublished };
}
@withProvider()
@connect(mapStateToProps)
export default class ThankYouPrompt extends React.Component {
  render() {
    const {
      initialValues,
      onModelChanged,
      propertyType,
      thankYouPromptHelpLink,
      submitButtonColors,
      thankYouPromptHeaderContract,
      isPoweredByEnable,
      isFormV2Enabled,
      isReusableResourcesEnabled,
      isDarkModeEnabled,
      darkHeaderContract,
      isPublished,
    } = this.props;
    const isSurveyDraftEnabled =  checkProvision(PROVISIONS.ENABLE_SURVEY_DRAFTS);
    const isSurveyFormPublished = isSurveyDraftEnabled && isPublished;
    return (
      <Form
        initialValues={initialValues}
        onSubmit={() => null}
        mutators={{
          setImageDataValues,
          setImagetextContent,
          setImagebodyContent,
          setButtonTextColor,
          setButtonColor,
          setThankYouPromptImageResourceData,
          setDarkImageDataValues,
          setThankYouPromptDarkImageResourceData,
        }}
        render={({ values, errors , form: { mutators } }) => (
          onModelChanged({ values, errors }) || 
            <StyledThankYouPrompt>
              <StyledEngagementPageLeftSide>
                <ThankYouPromptSettings
                  propertyType={propertyType}
                  mutators={mutators}
                  thankYouPromptHelpLink={thankYouPromptHelpLink}
                  reusableResourcesEnabled={isReusableResourcesEnabled}
                  submitButtonColors={submitButtonColors}
                  isDarkModeEnabled={isDarkModeEnabled}
                  isPublished={isSurveyFormPublished}/>
              </StyledEngagementPageLeftSide>
              <StyledEngagementPageRightSide>
                <LivePreview
                  propertyType={propertyType}
                  headerContract={thankYouPromptHeaderContract}
                  isPoweredByEnable={isPoweredByEnable}
                  isFormV2Enabled={isFormV2Enabled}
                  isDarkModeEnabled={isDarkModeEnabled}
                  darkHeaderContract={darkHeaderContract}
                  isPublished={isSurveyFormPublished}/>
              </StyledEngagementPageRightSide>
            </StyledThankYouPrompt>
        )} 
      />
    );
  }
}


ThankYouPrompt.propTypes = {
  initialValues: PropTypes.object,
  onModelChanged: PropTypes.func,
  propertyType : PropTypes.string,
  thankYouPromptHelpLink : PropTypes.string,
  submitButtonColors : PropTypes.object,
  thankYouPromptHeaderContract: PropTypes.object,
  isPoweredByEnable: PropTypes.bool,
  isFormV2Enabled: PropTypes.bool,
  isReusableResourcesEnabled: PropTypes.bool,
  isDarkModeEnabled : PropTypes.bool,
  darkHeaderContract: PropTypes.object,
};

ThankYouPrompt.defaultProps = {
  initialValues : {},
  onModelChanged: {},
  propertyType: 'mobileIOS',
  thankYouPromptHelpLink : '',
  submitButtonColors: {},
  thankYouPromptHeaderContract: {},
  isPoweredByEnable: false,
  isFormV2Enabled: false,
  isReusableResourcesEnabled: false,
  isDarkModeEnabled : false,
  darkHeaderContract: {},
};