export const comparisonOptions = [
  { id: 'Greater Than', label: 'greaterThan' },
  { id: 'Smaller Than', label: 'smallerThan' },
  { id: 'Equals', label: 'equals' }
];

export const timeInSessioncomparisonOptions = [
  { id: 'Greater Than', label: 'greaterThan' },
  { id: 'Smaller Than', label: 'smallerThan' }
];

export const ratingComparisonOptions = comparisonOptions.concat({
  id: 'Has Value',
  label: 'hasValue',
});

export const timeUnits = [
  { id: 'Minutes', label: 'Minutes' },
  { id: 'Hours', label: 'Hours' },
  { id: 'Days', label: 'Days' }
];

export const newTriggerModalComparisonOptions = [
  { value: 'greaterThan', label: 'More than' },
  { value: 'smallerThan', label: 'Less than' },
  { value: 'equals', label: 'Exactly' }
];
