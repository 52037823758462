import React from 'react';
import { Header, SubHeader, Subtitle, PanelSubHeader } from '../../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../../common/utils';
import { TextPathPrefix } from '../../..';
import { StyledTargetingSettings, StyledTargetingSettingsBody } from '../Targeting/StyledTargeting';
import { Separator } from '../Targeting/StyledTargeting';
import { checkProvision } from '../../../../../../../../redux/store/store-helper';
import { PROVISIONS } from '../../../../../../../../common/enums';
import TargetByDeclined from './QuarantineRules/TargetByDeclined';
import TargetBySubmitted from './QuarantineRules/TargetBySubmitted';
import TargetByOCQRules from './QuarantineRules/TargetByOCQRules';

const Quarantine = () => {
  const isOCQEnabled = checkProvision(PROVISIONS.MOBILE_SDKV2_OMNI_CHANNEL_QUARANTINE);
  return (
    <StyledTargetingSettings>
      <Header>{getSingleText(`${TextPathPrefix}.quarantineHeader`)}</Header>
      <StyledTargetingSettingsBody>
        <SubHeader marginBottom={0.5}>{getSingleText(`${TextPathPrefix}.quarantineSubHeader`)}</SubHeader>
        <Subtitle marginBottom={3}>{getSingleText(`${TextPathPrefix}.quarantineBody`)}</Subtitle>
        <PanelSubHeader>{getSingleText(`${TextPathPrefix}.whoNotToTrigger`)}</PanelSubHeader>
        <TargetBySubmitted/>
        <TargetByDeclined/>
        { isOCQEnabled &&
        <React.Fragment>
          <Separator/>
          <PanelSubHeader>{getSingleText(`${TextPathPrefix}.OCQRulesTitle`)}</PanelSubHeader>
          <TargetByOCQRules/> 
          <Separator/>
        </React.Fragment>
        }
      </StyledTargetingSettingsBody>
    </StyledTargetingSettings>
  );
};

export default Quarantine;