import React, { useContext } from 'react';
import { Separator } from '../../StyledTriggerByTargeting';
import GeneralSettings from './BannerSettings/GeneralSettings';
import ButtonSettings from './BannerSettings/ButtonSettings';
import Duration from './BannerSettings/Duration';
import DarkMode from './BannerSettings/DarkMode';
import { BannerBasicFields } from './BannerSettings/BannerBasicFields';
import { TriggerContext } from '../../../..';
export const BANNER_OPTIONS = [{
  value: 'TOP.PARTIAL',
  label: 'Top partial drawer',
},{
  value: 'BOTTOM.PARTIAL',
  label: 'Bottom partial drawer',
},{
  value: 'TOP',
  label: 'Top drawer',
},{
  value: 'BOTTOM',
  label: 'Bottom drawer',
}
];

const Banner = () => {
  const { isDarkModeEnabled } = useContext(TriggerContext);
  return (
    <React.Fragment>
      <BannerBasicFields />
      <GeneralSettings/>
      <Separator/>
      <ButtonSettings/>
      <Separator/>
      <Duration/>
      {isDarkModeEnabled && <DarkMode/>}
    </React.Fragment>
  );
};

export default Banner;