import styled from 'styled-components';
import { COLORS } from '../../../common/colors';
import { flex } from '../../../common/styled-mixins';

export default styled.div`
  position: ${(props) => props.position};
  width: ${(props) => props.width};
  background-color: ${COLORS.WHITE};
  display: flex;
  height: 52px;
  min-width: 0;
  border-bottom: 1px solid ${COLORS.BORDER};
  .info{
    margin-left: 21px;
    min-width: 0;
    ${flex(1, 'flex-start')}
    .title{
      color: ${COLORS.TEXT_TITLE};
      font-size: 22px;
      overflow: hidden;
    	min-width: 0;
    	text-overflow: ellipsis;
    	white-space: nowrap;
    }
    .counter{
      color: ${COLORS.TEXT_SUBTITLE};
      opacity: .5;
      font-size: 16px;
      margin-left: 9px;
    }
  }
  .actions {
    .search-container{
      padding-right: 10px;
    }

    #separator{
      border-right: 1px solid ${COLORS.BORDER};
      width: 1px;
      height: 100%;
    }
    ${flex(0, 'flex-end')}
    .action-toggle-view{
      cursor: pointer;
      position: relative;
      width: 61.5px;
      height: 50px;
      display: inline-block;
      top: 1px;
      svg {
        right: 20px;
        top: 17.5px;
        position: absolute;
      }
    }
    .action-button {
      height: 100%;
      padding: 8px;
      min-width: 132px;
      font-weight: normal;
      text-decoration: none;
     .fa-plus-circle {
        margin-right: 5px;
      }
    }

    #btn-link.action-button .fa-plus-circle{
        color: ${COLORS.GREEN};
    }
  }
`;
