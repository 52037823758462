import React, { useRef } from 'react';
import Button from '@mdigital/components/dist/components/Button';
import { StyledPreviewURLField } from './StyledMobilePreviewIntegrationModal';
import { copyToClipboard } from '../../common/utils';

const PreviewURLField = ({ urlData }) => {
  const initialButtonText = 'Copy link';
  const afterCopyButtonText = 'Copied';
  const [buttonText, setButtonText] = React.useState(initialButtonText);
  const spRef = useRef(null);

  const markText = () => {
    const range = document.createRange();
    range.selectNodeContents(spRef.current);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    return () => {
      selection.collapseToStart();
    };
  };

  const handleButtonClick = () => {
    copyToClipboard(urlData);
    setButtonText(afterCopyButtonText);
    const unmarkText = markText();
    setTimeout(() => {
      setButtonText(initialButtonText);
      unmarkText();
    }, 2000);
  };

  return (
    <StyledPreviewURLField>
      <span ref={spRef}>{urlData}</span>
      <Button
        digType="primary"
        digClassName="mobile-preview-modal-copy-link-button"
        digOnClick={handleButtonClick}
      >
        {buttonText}
      </Button>
    </StyledPreviewURLField>
  );
};

export default PreviewURLField;