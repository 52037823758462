import styled from 'styled-components';

export const StyledEngagementPageLeftSide = styled.div`
  flex: 6;
  display: flex;
  padding-right: 30px;
  flex-direction: column;
`;

export const StyledEngagementPageRightSide = styled.div`
  flex: 9;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
`;

export const StyledEngagementTypeSelectionSection = styled.div`
  width: 100%;
  p {
    margin-top: 18px;
  }
`;

export const StyledBannerSettingsExpandableList = styled.div`
  margin-top: 40px;
`;

export default styled.div`
  display: flex;
  padding: 40px 40px 0;
  height: 618px;
  input, textarea, select {
    &:focus {
      outline: none;
    }
  }
`;