import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';

export default styled.div`
  height: 88vh;
  overflow-y: auto;
  padding: 10px 0 0 20px;

  .dig-card-header {
    .digLabel {
      position: absolute;
      top: 2px;
    }

    .digToggle {
      margin: 8px 2px;
    }
  }

  .svg-dark-mode{
      svg{
        height: 23px;
        width: 23px;
        top: -4px;
        left: -4px;
      }
    }
  
`;

export const StyledEmptyState = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50px;
  bottom: 0;
  right: 0;
  left: 0;

  & > div {
    display: flex;
    justify-content: center;
    flex-flow: column;
    & > span {
      display: flex;
      justify-content: center;

      p.empty-state-title-text {
        font-size: 22px;
        margin-top: 40px;
        color: ${COLORS.DARK_GREY};
      }

      p.empty-state-body-text {
        font-size: 16px;
        margin-bottom: 18px;
        color: ${COLORS.BIN_COLOR};
      }

      button {
        font-size: 14px !important;
      }
    }
  }
`;