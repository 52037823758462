import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mdigital/components/dist/components/Checkbox';
import StyledCodeTriggerPage, {
  StyledDescriptionContainer,
  StyledDescriptionText,
  StyledHelpLinkText,
  StyledIllustrationImageContainer,
  StyledCheckBox,
  PreloadedStyled,
  NoticePreloadedStyled,
  StyledPreloadUnavailableMessage
} from './StyledCodeTriggerPage';
import { CodeIllustrationImage } from '../../assets/CodeTrigger/base64';
import { getSingleText } from '../../../common/utils';

function isPreloadAvailable(formId, preloadedForm) {
  if (!preloadedForm) { return true; }
  return formId === preloadedForm.id;
}

const PreloadCheckboxSection = ({ values, onCheckboxToggle }) => (
  <React.Fragment>
    <span>
      <b>{getSingleText('app.pages.forms.triggerByCode.headerIsPreloadedText')}</b>
    </span>
    <StyledCheckBox>
      <Checkbox
        label={getSingleText('app.pages.forms.triggerByCode.isPreloadedLabel')}
        digIsChecked={values.isPreloaded}
        handleCheckboxChange={(isPreloaded) => onCheckboxToggle({ isPreloaded })}
      />
    </StyledCheckBox>
    <NoticePreloadedStyled>
      <span>{getSingleText('app.pages.forms.triggerByCode.extraDataText')}</span>
    </NoticePreloadedStyled>
  </React.Fragment>
);

const PreloadUnavailableSection = ({ preloadedForm }) => (
  <React.Fragment>
    <span>
      <b>{getSingleText('app.pages.forms.triggerByCode.headerIsPreloadedTextUnavailable')}</b>
    </span>
    <StyledPreloadUnavailableMessage>
      <span>{getSingleText('app.pages.forms.triggerByCode.preloadFormUnavailableMessage')}</span>
    </StyledPreloadUnavailableMessage>
    <StyledPreloadUnavailableMessage>
      <span>Currently enabled on (<b>{preloadedForm.name}</b>), <b>Form ID {preloadedForm.id}</b></span>
    </StyledPreloadUnavailableMessage>
  </React.Fragment>
);

const CodeTriggerPage = ({ helpLink, onModelChanged, values, preloadedForm }) => (
  <StyledCodeTriggerPage>
    <StyledDescriptionContainer>
      <StyledDescriptionText>
        <span>{getSingleText('app.pages.forms.triggerByCode.uiCaption')}</span>
      </StyledDescriptionText>
      <StyledHelpLinkText>
        {helpLink && (
          <span>
            {getSingleText('app.pages.forms.triggerByCode.learnMoreText')}
						&nbsp;
            <a target="_blank" href={helpLink}>
              {getSingleText('app.pages.forms.triggerByCode.helpCenter')}
            </a>
          </span>
        )}
      </StyledHelpLinkText>
      <PreloadedStyled>
        {isPreloadAvailable(values.formId, preloadedForm) ? 
          <PreloadCheckboxSection values={values} onCheckboxToggle={onModelChanged} /> : 
          <PreloadUnavailableSection preloadedForm={preloadedForm} />
        }
      </PreloadedStyled>
    </StyledDescriptionContainer>

    <StyledIllustrationImageContainer>
      <img src={CodeIllustrationImage} />
    </StyledIllustrationImageContainer>
  </StyledCodeTriggerPage>
);

CodeTriggerPage.propTypes = {
  helpLink: PropTypes.string,
  onModelChanged: PropTypes.func,
  values: PropTypes.shape(PropTypes.any),
  preloadedForm: PropTypes.shape(PropTypes.any),
};

export default CodeTriggerPage;
