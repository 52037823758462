import React from 'react';
import { StyledSelectionPageContainer, StyledSelectionPageText, StyledSelectionPageHeader, StyledSelectionPageSubHeader } from './StyledSelectionPage';
import { CardGroup } from '@m/alchemy-ui';
import { getSingleText } from '../../../../common/utils';
import TriggerCard  from './TriggerCard';
import { triggersByProperty } from './triggerOptions';
import { getPropertyType } from '../../../../redux/store/store-helper';

const SelectionPage = () => {
  const propertyType = getPropertyType();
  const triggerOptions = triggersByProperty[propertyType];
  return (
    <StyledSelectionPageContainer>
      <StyledSelectionPageText>
        <StyledSelectionPageHeader>{getSingleText('app.pages.forms.triggeringModal.selectionPage.selectionPageHeader')}</StyledSelectionPageHeader>
        <StyledSelectionPageSubHeader>{getSingleText('app.pages.forms.triggeringModal.selectionPage.selectionPageDescription')}</StyledSelectionPageSubHeader>
      </StyledSelectionPageText>
      <CardGroup height="450px" className='card-group'>
        {Object.keys(triggerOptions).map((triggerType,index)=>{
          return (
            <TriggerCard
              key={index}
              cardIcon={triggerOptions[triggerType].cardIcon} 
              cardBodyText={triggerOptions[triggerType].cardBodyText} 
              cardFooterText={triggerOptions[triggerType].cardFooterText}
              triggerType={triggerType}
            />
          );
        })}
      </CardGroup>
    </StyledSelectionPageContainer>
  );
};

export default SelectionPage;