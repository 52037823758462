import styled from 'styled-components';
import { units, typeStyle, color } from '@m/alchemy-ui';

export const StyledTriggerByCodeContainer = styled.div`
    display: flex;
`;

export const StyledLeftPanel = styled.div`
    flex: 1;
    background-color: ${color('hint', { palette : 'illustrativeSteelGrey' })};;
    display: flex;
    flex-direction: column;
    max-height: 74vh;
`;

export const StyledLeftPanelHeader = styled.span`
    margin: ${units(2)} 0 ${units(2)} ${units(3)};
    ${typeStyle('subHeaderS')}
`;

export const StyledLeftPanelTextContainer = styled.div`
    padding: ${units(3)} ${units(3)} ${units(3)} ${units(3)};
`;

export const StyledRightContainer = styled.div`
    display: flex;
    flex: 4;
`;

export const StyledCustomRadioIconButton = styled.div`
    padding: 0 ${units(3)} 0 ${units(3)};
    .md-radio-icon-button{
        & span {
            font-size : 12px;
        }
        & svg {
            width : 28px;
            height : 36px;
        }
    }
`;