import React from 'react';
import { FormSpy } from 'react-final-form';
import styled from 'styled-components';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';

const StyledRowBlocker = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, .5);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  cursor: not-allowed;
`;

const RowBlocker = ({ valueToWatch }) => (
  <FormSpy subscription={{ values: true }} render={({ values }) => (
    <React.Fragment>
      {!getNestedProperty(values, valueToWatch) && <StyledRowBlocker />}
    </React.Fragment>
  )} />
);

export default RowBlocker;