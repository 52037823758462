import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from '@mdigital/components/dist/components/IconSvg';
import WarningSvg from '../../assets/General/warning.svg';

export default function WarningSign(props) {
  const { tooltip, size } = props;
  return <IconSvg icon={WarningSvg} isStatic tooltip={tooltip} width={size} />;
}

WarningSign.propTypes = {
  tooltip: PropTypes.string,
  size: PropTypes.number,
};

WarningSign.defaultProps = {
  tooltip: null,
  size: 14,
};