import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { setNestedProperty } from '../../../../common/utils';
import { TriggerByTargetingAppMode } from '../MobileTriggering/TriggerByTargeting';

export const isRuleExists = (triggerState, ruleId) => {
  return getNestedProperty(triggerState, ruleId);
};

export const formatInputNumberNullValue = (number) => {
  return number ? number : '';
};

export const formatInputTextValue = (triggerState , rule) => {
  const value = getNestedProperty(triggerState , rule);
  return value ? value : '';
};

export const updateLightTheme = (formTargetingData = {}) => {

  setNestedProperty(formTargetingData, 'theme.themeData.light.palette.core.canvas',
    getNestedProperty(formTargetingData, 'trigger.triggerCustomBannerContract.backgroundColor'));

  setNestedProperty(formTargetingData, 'theme.themeData.light.palette.action.primary',
    getNestedProperty(formTargetingData, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonBackgroundColor'));

  setNestedProperty(formTargetingData, 'theme.themeData.light.palette.action.auxiliary',
    getNestedProperty(formTargetingData, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.closeButtonColor'));

  setNestedProperty(formTargetingData, 'theme.themeData.light.palette.typography.headline',
    getNestedProperty(formTargetingData, 'trigger.triggerCustomBannerContract.textColor'));

  setNestedProperty(formTargetingData, 'theme.themeData.light.palette.typography.action.primary',
    getNestedProperty(formTargetingData, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonTextColor'));
};

export const prepareRulesList = (formTargetingData = {}) => {
  const { triggerScope } = formTargetingData.trigger || TriggerByTargetingAppMode.FOREGROUND;
  const rules =  getNestedProperty(formTargetingData, 'trigger.rules');
  const activeRules = Object.keys(rules)
    .filter((ruleName) => rules[ruleName].isActive)
    .reduce((rulesList, currentRule) => ({ ...rulesList, [currentRule]: { ...rules[currentRule], type: currentRule } }), {});
  removeRulesByTriggerScope(activeRules, triggerScope);
  setNestedProperty(formTargetingData, 'trigger.rules',activeRules);
};

const removeRulesByTriggerScope = (activeRules, triggerScope) => {
  const foregroundRulesToRemove = ['MobileTimeInForeground'];
  const backgroundRulesToRemove = ['MobileAppxDelay', 'MobileDidntVisitApp', 'MobileLastTimeInForeground'];
  const isForegroundAppMode = triggerScope === TriggerByTargetingAppMode.FOREGROUND;
  const rulesToRemoveArray = isForegroundAppMode ? backgroundRulesToRemove : foregroundRulesToRemove;
  rulesToRemoveArray.forEach((type) => {
    Object.hasOwn(activeRules, type) && delete activeRules[type];
  });
};