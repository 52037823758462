import React, { useContext, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { setNestedProperty } from '../../../../../common/utils';
import { TriggerContext } from '../..';
import { PROVISIONS } from '../../../../../common/enums';
import { fetchAvailableFormRules, fetchOCQRules } from '../../../../../redux/actions/rule-engine.actions';
import { getThemeByName } from '../../../../../redux/actions/theme.action';
import { checkProvision } from '../../../../../redux/store/store-helper';
import Background from '../../../../assets/TriggeringModal/TriggerTypes/Background.svg';
import Foreground from '../../../../assets/TriggeringModal/TriggerTypes/Foreground.svg';
import withProvider from '../../../../hoc/ProviderHoc/ProviderHoc';
import { StyledLeftPanel, StyledRightContainer, StyledTriggerByCodeContainer } from '../Shared/SharedMobileTriggeringStyling';
import { TargetingActions } from './Actions';
import TargetingLeftPanel from './TargetingPanel/TargetingLeftPanel';
import TargetingRightPanel from './TargetingPanel/TargetingRightPanel';
import { TARGETING_TYPES } from './TargetingTypes';


export const TargetingNavigationStep = {
  INVITE_DISPLAY : 0,
  TARGETING: 1,
  QUARANTINE: 2,
  SAVE: 3,
};

export const TriggerByTargetingAppMode = {
  FOREGROUND: 'Foreground',
  BACKGROUND: 'Background',
};
export const TextPathPrefix = 'app.pages.forms.triggeringModal.triggerByTargeting';

export const TargetingAppMode = [
  {
    label: TriggerByTargetingAppMode.FOREGROUND,
    Icon: Foreground,
    value: TriggerByTargetingAppMode.FOREGROUND,
  }, 
  {
    label: TriggerByTargetingAppMode.BACKGROUND,
    Icon: Background,
    value: TriggerByTargetingAppMode.BACKGROUND,
  }
];

const targetingContract = '.MobileInvitationContract';

const TriggerByTargeting = () => {
  const { formID, triggerState, triggerDispatch,  setIsDarkMode } = useContext(TriggerContext);
  const triggeringData = useSelector((state) => state.forms.triggeringData);
  const { theme } = useSelector((state) => state.theme.forms);
  const isDarkMode = useSelector((state) =>  state.forms.undeletedForms.find((form) => form.id === formID).isDarkModeEnabled);
  const dispatch = useDispatch();
  const isDarkModeProvisionEnabled =  checkProvision(PROVISIONS.MOBILE_SDKV2_NATIVE_DARK_MODE);
  const isOCQEnabled = checkProvision(PROVISIONS.MOBILE_SDKV2_OMNI_CHANNEL_QUARANTINE);
  const isLocalNotificationsEnabled = checkProvision(DigProvisions.MOBILE_SDKV2_LOCAL_Notifications);

  useEffect(()=> {
    const themeName = 'banner';
    dispatch(fetchAvailableFormRules(formID));
    isOCQEnabled && dispatch(fetchOCQRules());
    isDarkModeProvisionEnabled && dispatch(getThemeByName(formID , themeName));
    setIsDarkMode(isDarkMode);
  },[formID]);

  const resetLocalNotificationToBanner = () => {
    const trigger = { ...triggeringData };
    setNestedProperty(trigger, 'triggerCustomBannerContract.invitationTitle', getNestedProperty(triggeringData, 'triggerCustomLocalNotificationContract.title'));
    setNestedProperty(trigger, 'triggerCustomBannerContract.invitationBody', getNestedProperty(triggeringData, 'triggerCustomLocalNotificationContract.body'));
    trigger.invitationType = TARGETING_TYPES.BANNER;
    triggerDispatch({ type: TargetingActions.INIT, payload: trigger });
  };

  useEffect(() => {
    if (triggeringData && triggeringData.triggerType === targetingContract) {
      if (!isLocalNotificationsEnabled && triggeringData.invitationType === TARGETING_TYPES.LOCAL_NOTIFICATION) {
        return resetLocalNotificationToBanner();
      }
      triggerDispatch({ type : TargetingActions.INIT , payload : triggeringData });
    }
  },[triggeringData]);

  useEffect(() => {
    theme && Object.keys(theme).length != 0 && triggerDispatch({ type : TargetingActions.INIT_THEME , payload : theme });
  },[theme]);

  return (
    <StyledTriggerByCodeContainer>
      {triggerState && triggerState.trigger && triggerState.trigger.triggerType === targetingContract &&
        <React.Fragment>
          <StyledLeftPanel>
            <TargetingLeftPanel/>
          </StyledLeftPanel>
          <StyledRightContainer>
            <TargetingRightPanel/>
          </StyledRightContainer>
        </React.Fragment>
      }
    </StyledTriggerByCodeContainer>
  );
};

export default withProvider()(
  connect()(TriggerByTargeting)
);