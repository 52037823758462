import { createGlobalStyle } from 'styled-components';
import ComingSoon from '../ComingSoon.ttf';
import RobotoLight from '../Roboto-Light.ttf';
import RobotoMedium from '../Roboto-Medium.ttf';
import RobotoThin from '../Roboto-Thin.ttf';
import RobotoBlack from '../Roboto-Black.ttf';
import RobotoCondensedLight from '../RobotoCondensed-Light.ttf';
import RobotoCondensedRegular from '../RobotoCondensed-Regular.ttf';
import CarroisGothicSCRegular from '../CarroisGothicSC-Regular.ttf';
import CutiveMonoRegular from '../CutiveMono-Regular.ttf';
import NotoSerifRegular from '../NotoSerif-Regular.ttf';
import DancingScriptRegular from '../DancingScript-Regular.ttf';
import DroidSansMono from '../DroidSansMono.ttf';
import RobotoRegular from '../Roboto-Regular.ttf';
const AlchemyAndroidFonts = createGlobalStyle`

@font-face {
    font-family: 'Roboto-Light'; 
    src: url(${RobotoLight}) format('truetype');
    font-display: block;
}
@font-face {
    font-family: 'Roboto-Medium'; 
	src: url(${RobotoMedium}) format('truetype');
}
@font-face {
    font-family: 'Roboto-Thin'; 
	src: url(${RobotoThin}) format('truetype');
}
@font-face {
    font-family: 'Roboto-Black'; 
	src: url(${RobotoBlack}) format('truetype');
}
@font-face {
    font-family: 'RobotoCondensed-Light'; 
	src: url(${RobotoCondensedLight}) format('truetype');
}
@font-face {
    font-family: 'RobotoCondensed-Regular'; 
	src: url(${RobotoCondensedRegular}) format('truetype');
}
@font-face {
    font-family: 'CarroisGothicSC-Regular'; 
	src: url(${CarroisGothicSCRegular}) format('truetype');
}
@font-face {
    font-family: 'CutiveMono'; 
	src: url(${CutiveMonoRegular}) format('truetype');
}
@font-face {
    font-family: 'NotoSerif-Regular'; 
	src: url(${NotoSerifRegular}) format('truetype');
}
@font-face {
    font-family: 'ComingSoon'; 
	src: url(${ComingSoon}) format('truetype');
}

@font-face {
    font-family: 'DancingScript-Regular'; 
	src: url(${DancingScriptRegular}) format('truetype');
}

@font-face {
    font-family: 'DroidSansMono'; 
	src: url(${DroidSansMono}) format('truetype');
}

@font-face {
    font-family: 'Roboto-Regular'; 
    src: url(${RobotoRegular}) format('truetype');
}

`

export default AlchemyAndroidFonts;