import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AutoSuggest from '@mdigital/components/dist/components/AutoSuggest';
import { getSingleText } from '../../../common/utils';
import { ANIMATED_DIRECTIONS, FORM_TRIGGER_TYPES } from '../../../common/enums';
import centerRight from '../../assets/formDisplay/centerRight.gif';
import centerLeft from '../../assets/formDisplay/centerLeft.gif';
import bottomRight from '../../assets/formDisplay/bottomRight.gif';
import bottomLeft from '../../assets/formDisplay/bottomLeft.gif';
import Wizard from '@mdigital/components/dist/components/Wizard';

AnimatedDisplay.propTypes = {
  triggerType: PropTypes.string,
  displayDirection: PropTypes.string,
  onDirectionChange: PropTypes.func,
};

export function AnimatedDisplay(props) {
  const autoSuggestProps = {
    suggestions: animatedDirections,
    digDisableSuggest: true,
    onSuggestionSelected: props.onDirectionChange,
    value: getDisplayDirectionLabel(props.displayDirection),
  };

  const isShowSuggestions = props.triggerType === FORM_TRIGGER_TYPES.CODE || props.triggerType === FORM_TRIGGER_TYPES.INVITE;
  const currentStepIndex = animatedDirections.findIndex((direction) => direction.label === props.displayDirection);
  return (
    <Fragment>
      <div className="display-type">
        <p className="display-type-description">
          {getSingleText('app.pages.forms.invitation.formDisplayTab.animatedDisplay.description')}
        </p>
        <div className="contextual-options" data-aut="animated-display-contextual-options">
          <p className="contextual-options-description">
            {getContextualDescription(props.triggerType)}
          </p>
          {isShowSuggestions && <AutoSuggest {...autoSuggestProps}/>}
        </div>
      </div>

      <div className="display-preview">
        {
          isShowSuggestions ? renderPreviewWizard(currentStepIndex)
            : <img src={centerRight} className="display-preview-image"/>
        }
      </div>
    </Fragment>
  );
}

function renderPreviewWizard(index) {
  return (
    <Wizard currentStepIndex={index}
      hideStatusBar={true}>

      <Wizard.Step>
        <img src={centerRight} className="display-preview-image"/>
      </Wizard.Step>

      <Wizard.Step>
        <img src={centerLeft} className="display-preview-image"/>
      </Wizard.Step>

      <Wizard.Step>
        <img src={bottomRight} className="display-preview-image"/>
      </Wizard.Step>

      <Wizard.Step>
        <img src={bottomLeft} className="display-preview-image"/>
      </Wizard.Step>

    </Wizard>
  );
}

const animatedDirections = [
  { label: ANIMATED_DIRECTIONS.CENTER_RIGHT, id: getDisplayDirectionLabel(ANIMATED_DIRECTIONS.CENTER_RIGHT) },
  { label: ANIMATED_DIRECTIONS.CENTER_LEFT, id: getDisplayDirectionLabel(ANIMATED_DIRECTIONS.CENTER_LEFT) },
  { label: ANIMATED_DIRECTIONS.BOTTOM_RIGHT, id: getDisplayDirectionLabel(ANIMATED_DIRECTIONS.BOTTOM_RIGHT) },
  { label: ANIMATED_DIRECTIONS.BOTTOM_LEFT, id: getDisplayDirectionLabel(ANIMATED_DIRECTIONS.BOTTOM_LEFT) }
];

function getDisplayDirectionLabel(direction) {
  const pathToString = `app.pages.forms.invitation.formDisplayTab.animatedDisplay.contextualOptions.${direction}`;
  return getSingleText(pathToString);
}

function getContextualDescription(triggerType) {
  const pathToString = 'app.pages.forms.invitation.formDisplayTab.animatedDisplay.contextualOptions.description';
  const fallbackText = getSingleText(`${pathToString}.common`);
  return getSingleText(`${pathToString}${triggerType}`, fallbackText);
}