import styled from 'styled-components';
import REACTCOLORS from '../../../../../common/colors';

export const StyledThankYouImageResourceSelectionWrapper = styled.div`
  display : flex;
  justify-content: space-between;
  width: 411px;
  align-items: center;
  ${(props) => props.disabled ? 'pointer-events: none; opacity: 0.4;' : ''};
  .dig-container{
      font-size : 14px;
  }

  .checkbox-info {
    margin-top: 2px;
    margin-bottom: 0;
  }
`;

export const StyledThankYouImageEnablementCheckbox = styled.div`
  .dig-container {
    margin: 0;
  }
`;

export const StyledThankYouImageResourceSelection = styled.div`
  width: 300px;
  .react-select__control {
    border-radius: 0;
    color: ${REACTCOLORS.TITLE_TEXT};

    &:hover {
      border-color: ${REACTCOLORS.REUSABLE_RESOURCE_DROPDOWN_BORDER_COLOR};
    }

    .react-select__value-container {
      padding-left: 0;
    }

    .react-select__placeholder {
      padding-left: 4px;
      padding-top: 2px;
    }

    .react-select__indicator {
      padding: 0;
      svg {
        height: 14px;
        margin-top: 9px;
        margin-right: 3px;
      }
    }
  }
  .react-select__control--is-disabled {
    border-color: ${REACTCOLORS.DISABLED_GRAY};
    .react-select__indicator {
      color: ${REACTCOLORS.DISABLED_GRAY};
    }
  }
`;
