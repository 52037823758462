import React from 'react';
import PropTypes from 'prop-types';
import { ContainerWrapper } from './ReusableCustomParametersContainerStyled';
import FieldMappingView from '../ReportFieldMappingModal/FieldMappingView/FieldMappingView';
import NewQuestionTypeForm from '../ReportFieldMappingModal/NewQuestionTypeForm/NewQuestionTypeForm';
import MakeFieldReusableForm from '../ReportFieldMappingModal/MakeFieldReusableForm/MakeFieldReusableForm';

const customStyle = {
  radioButtonWrapper: { marginTop: 0 },
  radioButtonLabel: { color: '#858585' },
  fieldNameTitle: { color: '#858585' },
  fieldNameSubtitle: { color: '#97979C' },
  reuseUsageContainer: { display: 'none' },
  noteContainer: { display: 'none' },
};
function ReusableCustomParametersContainer(props) {
  const { ecKey, ecFriendlyName, savedEcKey, savedEcFriendlyName, ecFields = [], handleCustomParamObjChanged, handleCustomParamValueChanged } = props;
  const BASE_TEXT_PATH =  'app.pages.administration.customParams.reusableFields.';

  const fieldState = {
    ecKey: ecKey || '',
    ecFriendlyName: ecFriendlyName || '',
    isMarkedReusable: !!ecFriendlyName,
  };

  const componentProps = {
    customStyle,
    baseTextPath: BASE_TEXT_PATH,
    fieldState,
    handleSetFieldState: handleCustomParamValueChanged,
    handleSetFieldObj : handleCustomParamObjChanged,
  };

  function getReusableContent() {
    if (savedEcKey && savedEcFriendlyName) {
      return <FieldMappingView fieldState={fieldState} baseTextPath={BASE_TEXT_PATH} customStyle={customStyle}/>;
    } else if (!savedEcKey && !savedEcFriendlyName) {
      return  <NewQuestionTypeForm ecFields={ecFields || []}  {...componentProps} />;
    } 
    return <MakeFieldReusableForm {...componentProps} />;
  }
     

  return (
    <ContainerWrapper>
      {getReusableContent()}
    </ContainerWrapper>
  );

}

ReusableCustomParametersContainer.propTypes = {
  ecKey: PropTypes.string,
  ecFriendlyName: PropTypes.string,
  savedEcKey: PropTypes.string,
  savedEcFriendlyName: PropTypes.string,
  ecFields: PropTypes.array,
  handleCustomParamObjChanged: PropTypes.func,
  handleCustomParamValueChanged: PropTypes.func,
};

export default ReusableCustomParametersContainer;
