import styled from 'styled-components';

export const StyledColumnElements = styled.div`
	width: ${({ width = 500 }) => `${width}px`};
`;

export const StyledRowElements = styled(StyledColumnElements)`
	display: flex;
	align-items: center;
	width: 500px;
	justify-content: space-between;
	height: 34px;
`;


export const CheckboxWrapper = styled.div`
	div {
		display: flex;
		align-items: center;
	}
	label {
		display: flex;
		align-items: center;
		padding-top: 0;
		margin-bottom: 0px; 
	}
`;