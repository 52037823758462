const code = {
  mobileIOS: 
    `MedalliaDigital.showForm(YOUR_FORM_ID, success: {
      //Handle successfully showing a form to your user
   }) { (error) in
      //Handle failure to show a form to your user
   }`, 
  mobileAndroid: 
    `MedalliaDigital.showForm(YOUR_FORM_ID, new MDResultCallback() {
      @Override
      public void onSuccess() {
          //Handle successful showing a form
      }
  
      @Override
      public void onError(MDExternalError error) {
          //Handle failure to show a form
      }
    });`,
};
const pushNotification = {
  mobileIOS:
    `MedalliaDigital.handleNotification(YOUR_FORM_ID, success: {
      //Handle successfully handling a notification to your user
      }) { (error) in
      //Handle failure to handling a notification to your user
      }`,
  mobileAndroid:
    `MedalliaDigital.handleNotification(YOUR_FORM_ID, new MDResultCallback() {
      @Override
      public void onSuccess() {
          //Handle successful showing a form
      }
  
      @Override
      public void onError(MDExternalError error) {
          //Handle failure to show a form
      }
  });`,
};

export default { code, pushNotification };

export const CodeSnippet =  { 
  code, 
  pushNotification, 
};