import styled from 'styled-components';
import { units, color } from '@m/alchemy-ui';

export const StyledTargetingLeftPanel = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
     .targeting-vertical-tabs{
          width: 100%;
     }
`;

export const StyledContentContainer = styled.div`
     margin: ${units(3)} 0px ${units(3)} 0px;
`;

export const Separator = styled.div`
     border-top: 1px solid ${color('line')};
     margin: ${units(3)} 0px ${units(3)} 0px;
     max-width: 370px;
`;

export const StyledTargetingSettingsContainer = styled.div`
     .custom-field-margin{
          margin-top: 0px;
     }
    .app-name {
      margin-top: 0;
      input {
        padding-right: ${units(9.5)}
      }
    }
     .display-invite-content{
          margin: 0px 0px ${units(2)} 0px;
     }
     .display-invite-content:empty{
          margin: 0px;
     }
  
`;

export const StyledSubTitle = styled.div`
     label {
          margin-bottom: ${units(.5)}
     }
`;

export const StyledInviteTypeText = styled.div`
     margin-top: ${units(1)};
`;