import React, { useContext } from 'react';
import InviteDisplay from './InviteDisplay';
import Targeting from './Targeting';
import Quarantine from './Quarantine';
import { TriggerContext } from '../../../..';

export const TargetingLeftPanelType = {
  INVITE_DISPLAY : 'inviteDisplay',
  TARGETING: 'targeting',
  QUARANTINE: 'quarantine',
};

export const TargetingLeftPanelViewMap = {
  [TargetingLeftPanelType.INVITE_DISPLAY] : InviteDisplay,
  [TargetingLeftPanelType.TARGETING]: Targeting,
  [TargetingLeftPanelType.QUARANTINE] : Quarantine,
};

const TargetingRightPanel = () => {
  const { targetingViewType } = useContext(TriggerContext);

  const renderLeftPanelViewType = () => {
    const TargetingLeftPanel = TargetingLeftPanelViewMap[targetingViewType];
    return <TargetingLeftPanel/>;
  };

  return renderLeftPanelViewType();
};
export default TargetingRightPanel;