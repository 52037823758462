import React from 'react';
import { getSingleText } from '../../../../common/utils';
import { StyledApiUrlPrefixContent } from './StyledSdkIntergartionV2AnywhereUrlPrefix';

const SdkIntergartionV2AnywhereUrlPrefix = ({ urlsPrefix }) => (
  <React.Fragment>
    <p>
      {getSingleText('app.topMenu.sdkV2.anywhere.urlPrefix')}
      <StyledApiUrlPrefixContent spellCheck='false' readOnly>
        { urlsPrefix } 
      </StyledApiUrlPrefixContent>
    </p>
        
    <p>{getSingleText('app.topMenu.sdkV2.apiToken')}</p>
  </React.Fragment>
);

export default SdkIntergartionV2AnywhereUrlPrefix;