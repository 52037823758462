
export const isRequired = (value) => value ? undefined : 'Required';

export const isNumber = (value) => isNaN(value) ? 'Must be a number' : undefined;

export const isPositiveNumber = (value) => isNumber(value) || value < 1 ? 'Please provide a positive number' : undefined;

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const parseToPositiveNumber = (value) => Number(value) > 1 ? Number(value) : 1;

export default {
  composeValidators,
  parseToPositiveNumber,
};
