import styled from 'styled-components';
import { COLORS } from '../../../common/colors';
import reactColors from '../../common/colors';

export const StyledLabelsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;
  
export const StyledTitleSpan = styled.span`
  color: ${COLORS.TEXT_HOVER};
  flex: 1;
`;

export const selectStyle = {
  control: (style) => ({
    ...style,
    color: COLORS.TEXT_HOVER,
    '&:hover': { borderColor: reactColors.TITLE_TEXT_COLOR },
    borderRadius: '3px',
    boxShadow: reactColors.SELECT_BOX_SHADOW,
    border: `1px solid ${reactColors.SELECT_BORDER_COLOR}`,
    background: COLORS.WHITE,
    minHeight: 34,
    height: 34,
    cursor: 'pointer',
  }),
};
