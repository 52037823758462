import React from 'react';
import { StyledDarkModeSelect , StyledDarkModeSelectIcon } from './StyledDarkModeSelect';
import LightIcon from './LightIcon';
import DarkIcon from './DarkIcon'; 
import Tooltip from '@mdigital/components/dist/components/Tooltip';

const TOOLTIP_STYLE = { display: 'block' };

const DarkModeSelect = ({ isDark, setIsDark }) => {
  const lightIcon = () => <LightIcon isActive={!isDark}/>;
  const darkIcon = () => <DarkIcon isActive={isDark}/>;
  return (
    <StyledDarkModeSelect>
      <StyledDarkModeSelectIcon className='dark-mode-select' isActive={!isDark} onClick={() => setIsDark(false)}>
        <Tooltip digTooltip='Preview Light Mode' digSize='small' position='top' digElement={lightIcon} digStyle={TOOLTIP_STYLE}/>
      </StyledDarkModeSelectIcon>
      <StyledDarkModeSelectIcon className='dark-mode-select' isActive={isDark} onClick={() => setIsDark(true)}>
        <Tooltip digTooltip='Preview Dark mode' digSize='small' position='top' digElement={darkIcon} digStyle={TOOLTIP_STYLE}/>
      </StyledDarkModeSelectIcon>
    </StyledDarkModeSelect>
  );
};

export default DarkModeSelect;