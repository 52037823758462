import React from 'react';
import { StyledMediaCaptureAccessibility , StyledMediaCaptureAccessibilityTitle } from './StyledMediaCaptureAccessibility';
import { getSingleText } from '../../../../common/utils';
import { SUPPORTED_MEDIA_TYPES } from '../MediaCaptureFormatsPreview';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import MediaCaptureAccessibilityFiled from './MediaCaptureAccessibilityFiled';
import { PROPERTY_TYPES } from '../../../../common/constants';

const MediaCaptureAccessibility = ({ mediaCaptureDynamicFieldContract , setAudioAltText , setVideoAltText , setUploadMediaAltText, propertyType }) => {
  const supportedMediaTypes = getNestedProperty(mediaCaptureDynamicFieldContract , 'supportedMediaTypes') || [];
  const audioAltText = getNestedProperty(mediaCaptureDynamicFieldContract , 'audioAltText');
  const videoAltText = getNestedProperty(mediaCaptureDynamicFieldContract , 'videoAltText');
  const uploadMediaAltText = getNestedProperty(mediaCaptureDynamicFieldContract , 'uploadAltText');
  const isAndroidProperty = propertyType === PROPERTY_TYPES.ANDROID;
  return (
    <StyledMediaCaptureAccessibility>
      <StyledMediaCaptureAccessibilityTitle>
        {supportedMediaTypes.length > 0 && getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.accessibility')}
      </StyledMediaCaptureAccessibilityTitle>
      {supportedMediaTypes.includes(SUPPORTED_MEDIA_TYPES.VIDEO) &&
                <MediaCaptureAccessibilityFiled 
                  title={getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.videoAltTitle')}
                  value={videoAltText}
                  onChange={setVideoAltText}
                />
      }
      {supportedMediaTypes.includes(SUPPORTED_MEDIA_TYPES.AUDIO) &&
                <MediaCaptureAccessibilityFiled 
                  title={getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.audioAltTitle')}
                  value={audioAltText}
                  onChange={setAudioAltText}
                />
      }
      {supportedMediaTypes.includes(SUPPORTED_MEDIA_TYPES.UPLOAD) && isAndroidProperty &&
                <MediaCaptureAccessibilityFiled 
                  title={getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.uploadMediaAltTitle')}
                  value={uploadMediaAltText}
                  onChange={setUploadMediaAltText}
                />
      }
    </StyledMediaCaptureAccessibility>
  );
};

export default MediaCaptureAccessibility;