import React from 'react';
import PropTypes from 'prop-types';
import StyledMobileEngagementDesign, { StyledEngagementPageLeftSide } from './StyledMobileEngagementDesign';
import AlertSettings from './Alert';

const engagementType = 'ALERT';
const flavor = 'invitation';

const AnywhereEngagementDesign = ({ isReadOnlyMode }) => (
  <StyledMobileEngagementDesign>
    <StyledEngagementPageLeftSide>
      <AlertSettings isAnywhereProperty type={engagementType} flavor={flavor} isReadOnlyMode={isReadOnlyMode} />
    </StyledEngagementPageLeftSide>
  </StyledMobileEngagementDesign>
);

AnywhereEngagementDesign.propTypes = {
  isReadOnlyMode: PropTypes.bool,
};
  
AnywhereEngagementDesign.defaultProps = {
  isReadOnlyMode: false,
};

export default AnywhereEngagementDesign;