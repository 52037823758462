import styled from 'styled-components';
import { typeStyle, units } from '@m/alchemy-ui';

export const StyledCardContainer = styled.div`
    margin: ${units(0)} ${units(3)} ${units(0)} ${units(3)};
    .card-wrapper{
        cursor: pointer;
    }
    .card-header{
       padding-bottom: ${units(2)};
       i{
        height : ${units(6)};
       }
    }
    .card-body{
        ${typeStyle('subHeaderM')};
        text-align: center;
        padding-bottom: ${units(0)};
    }
    .card-footer{
        ${typeStyle('captionM')};
        padding: ${units(0.5)} ${units(3)} ${units(3)} ${units(3)};
    }
`;