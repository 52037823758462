export default {
  TOP:{
    liveImg:{
      bottom : '-19px',
      height: '466px',
    },
    liveDeviceBackground : {
      bottom : '425px',
      right : '-96px',
      width: '377px',
      height: '444px',
    },
    background : {
      height: '485px',
    },
  },
  BOTTOM:{
    liveImg:{
      bottom : '0px',
    },
    liveDeviceBackground : {
      bottom : '466px',
      right : '-96px',
      width: '377px',
      height: '451px',
    },
    background : {
      height: '485px',
    },
  }, 
};