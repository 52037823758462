import React from 'react';
import Modal from '@mdigital/components/dist/components/Modal';
import StyledInUsedModalHeader from './StyledInUsedModalHeader';
import StyledInUsedModalBody from './StyledInUsedModalBody';

export default function UsedInModal(props) {
  return (
    <Modal show={props.isShow} height="305px" width="522px">
      <UsedInModalHeader onClose={props.onClose} title="Used in forms"/>
      <UsedInModalBody usedIn={props.usedIn}/>
    </Modal>
  );
}

function UsedInModalHeader(props) {
  const { title, onClose } = props;
  return (
    <StyledInUsedModalHeader>
      <i onClick={onClose}/>
      <h3>{title}</h3>
    </StyledInUsedModalHeader>
  );
}

function UsedInModalBody(props) {
  const { usedIn } = props;
  return (
    <StyledInUsedModalBody>
      <table>
        <tbody>
          <tr>
            <th>ID</th>
            <th>Name</th>
          </tr>
          {
            (usedIn || []).map(({ formId, formName }, index) => (
              <tr key={index}>
                <td>{formId}</td>
                <td className="with-ellipsis">{formName}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </StyledInUsedModalBody>
  );
}