import React,{ Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SideNavigation from '@mdigital/components/dist/components/SideNavigation';
import medalliaMinLogo from '../../../assets/images/MedalliaMinLogo.svg';
import medalliaLogo from '../../../assets/images/MedalliaLogo.svg';
import '../UPSideBar.scss';

import { getLeftMenuComponents } from '../../utils/menuService';
import AppAndProfileInfo from '../../components/AppAndProfileInfo';

class DigLeftMenuContainer extends Component {

  isActiveMenuItem(item) {    
    return item.stateUrls && item.stateUrls.find((stateUrl) => this.props.currentPage === stateUrl);       
  }
  
  goToPage(dataItem) {    
    if (!!dataItem.stateUrls && this.props.stateService) {
      this.props.stateService.go(dataItem.stateUrls[0]);
    }   
  }
  composeData() {
    let defaultKey = null;
    const items = getLeftMenuComponents(this.props.provisions, this.props.propertyType, this.props.role, this.props.appTreeService);
      
    items.forEach((item) => {      
      if (item.stateUrls) {
        item.routeCallBack = (dataItem) => this.goToPage(dataItem);
      }

      if (this.isActiveMenuItem(item)) {        
        defaultKey = item.key;
      }
      
      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => {          
          child.routeCallBack = (dataItem)=>{this.goToPage(dataItem);};
          if (this.isActiveMenuItem(child)) {            
            defaultKey = child.key;
          }
        });
      }
    });
    return { items, defaultKey };
  }
  
  
  render() {    
    let { items, defaultKey }  = this.composeData();

    const props = {
      header : { icon:medalliaMinLogo , logo: medalliaLogo },
      data: items,
      initialSelectionKey: defaultKey,
      footer: <AppAndProfileInfo />,
    };
    return <SideNavigation {...props} />;         
  }
}

DigLeftMenuContainer.propTypes = {  
  stateService: PropTypes.object,
  appTreeService: PropTypes.object,
  currentPage: PropTypes.string,
  provisions: PropTypes.array,  
  propertyType: PropTypes.string,  
  role: PropTypes.string,  
};

function mapStateToProps(state) {  
  return {   
    provisions: state.app.provisions,
    propertyType: state.app.propertyType,
    role: state.profile.userRole,
    currentPage: state.app.currentPage,
  };
}

export default connect(mapStateToProps,{})(DigLeftMenuContainer);
