import styled from 'styled-components';
import { units, color } from '@m/alchemy-ui';

export const StyledSubtitleContainer = styled.div `
  display : flex;
  align-items: center;
  padding-left: ${units(3)};
  svg{
    margin: ${units(0)} ${units(0.5)} ${units(0)} ${units(1)};
    display : flex;
  }
`;

export const StyledHorizontalSeparator = styled.hr`
    border-top: 1px solid ${color('line')};
    margin-bottom: ${units(0)};
`;

export const StyledDraftToggle = styled.div`
    position: absolute;
    right: ${units(7.5)};
    top: ${units(4)};
`;