import React from 'react';
import { getSingleText } from '../../../../../common/utils';

export const DetachFieldLink = ({ explicitFieldDetach, baseTextPath }) => {    

  return (
    <p className="text-dark">{getSingleText(`${baseTextPath}detachFieldLink`)}
      <button 
        type="link" 
        className="btn-link" 
        onClick={() => explicitFieldDetach()}
      >
        {getSingleText(`${baseTextPath}clickHere`) }
      </button>
    </p>
  );
};