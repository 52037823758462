import styled from 'styled-components';
import { BLACK, WHITE, GRAY, LIGHT_SEPARATOR, DARK_SEPARATOR , IOS_PRIMARY, ANDROID_PRIMARY , HEADER_TEXT , GRAY_BACKGROUND , DARK_ANDROID_PRIMARY , DARK_BACKGROUND } from '../../../../common/colors';

const mobileIosFont = '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Oxygen, Ubuntu, Cantarell, \'Open Sans\', \'Helvetica Neue\', sans-serif';

export const StyledAlertPreview = styled.div`
	position: relative;
    font-family: ${(props) => props.propertyType === 'mobileIOS' ? mobileIosFont : 'sans-serif'};
`;

export const StyledIOSAlertPreviewBodyWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 165px;
    left: 149px;
    z-index: 3;
    min-height: 230px;
    width: 270px;
    border-radius: 14px;
    background-color: ${(props) => props.isDark ? DARK_BACKGROUND : GRAY_BACKGROUND};
`;

export const StyledIOSInvitationHeadline = styled.span`
    font-size: 17px;
    font-weight: 600;
    z-index: 4;
    width: 100%;
    text-align: center;
    color: ${(props) => props.isDark ? WHITE : BLACK};
    padding: 19px 17px 0px 17px;
`;

export const StyledIOSInvitationText = styled.span`
    z-index: 4;
    width: 100%;
    text-align: center;
    color: ${(props) => props.isDark ? WHITE : BLACK};
    padding: 2px 17px 23px 17px;
`;

export const StyledIOSProvideButtonText = styled.span`
    font-size: 17px;
    z-index: 4;
    width: 100%;
    text-align: center;
    color: ${IOS_PRIMARY};
    padding: 12px;
`;

export const AlertLivePreviewSpeartor = styled.div `
  border-top : ${(props) => props.isDark ? `1px solid ${DARK_SEPARATOR}` : `1px solid ${LIGHT_SEPARATOR}`};
`;

export const StyledIOSAlertButton = styled.span`
    width: 100%;
    text-align: center;
    color: ${IOS_PRIMARY};
    font-size: 15px;
    padding: 12px;
    font-weight: ${(props) => props.isBold ? '600' : '400' };
`;


export const StyledAndroidAlertPreviewBodyWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 165px;
    left: 146px;
    z-index: 3;
    box-shadow: 0 5px 13px rgb(0 0 0 / 30%);
    min-height: 256px;
    width: 280px;
    background-color: ${(props) => props.isDark ? HEADER_TEXT : WHITE};
    padding-bottom: 9px;
    letter-spacing: 0.5px;
`;

export const StyledAndroidInvitationHeadline = styled.span`
    font-size: 16px;
    z-index: 4;
    width: 100%;
    color: ${(props) => props.isDark ? WHITE : BLACK};
    padding: 22px 24px 0px 24px;
`;

export const StyledAndroidInvitationText = styled.span`
    font-size: 14px;
    z-index: 4;
    width: 100%;
    color: ${(props) => props.isDark ? GRAY : BLACK};
    padding: 13px 24px 41px 24px;
`;

export const StyledAndroidProvideButtonText = styled.span`
    font-size: 14px;
    z-index: 4;
    width: 100%;
    color: ${(props) => props.isDark ? DARK_ANDROID_PRIMARY : ANDROID_PRIMARY};
    padding-right: 20px;
    text-align: right;
`;
export const StyledAndroidAlertButton = styled.span`
    font-size: 14px;
    width: 100%;
    color: ${(props) => props.isDark ? DARK_ANDROID_PRIMARY : ANDROID_PRIMARY};
    padding: 20px 20px 0px 0px;
    text-align: right;
`;