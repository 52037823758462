import styled from 'styled-components';

export const StyledModalContent = styled.div`
    display:flex;
`;
export const StyledText = styled.span`
    font-size:14px;
    color:#595959;
`;
export const StyledWarningText = styled(StyledText)`
    color:#D43F32;
`;

export const StyledBodyText = styled(StyledText)`
    padding-top:8px;
`;
export const StyledTextContainer = styled.div`
    display:flex;
    flex-direction:column;
`;
export const StyledSign = styled.div`
    margin-right:5.9px;
`;