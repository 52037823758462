import React, { Component } from 'react';
import withModal from '@mdigital/components/dist/hoc/ModalHoc';
import WarningIcon from '../../assets/Packages/warning.svg';
import IconSvg from '@mdigital/components/dist/components/IconSvg';
import { getSingleText } from '../../../common/utils';
import { StyledModalHeader, StyleHeaderText, StyledText } from './StyledConfirmationModal';

export default class ConfirmationModal extends Component{
  renderConfirmationModalContent = () => {
    const { title, description } = this.props;
    return (
      <React.Fragment>
        <StyledModalHeader> 
          <IconSvg color={'#747474'} icon={WarningIcon} width={16} height={14} isStatic={true}/> 
          <StyleHeaderText>
            {title}
          </StyleHeaderText>
        </StyledModalHeader>
        <br/>
        <StyledText>
          {description}
        </StyledText>
      </React.Fragment>
    );
  }
  
  render() {
    const { modalProps } = this.props;
    const basicModalProps = {
      cancelButtonText: getSingleText('app.pages.packages.confirmModal.cancel'),
      okButtonClass: 'btn-success',
      modalClassName: 'modal-design',
      ...modalProps,
    };
    const Modal = withModal(basicModalProps)(this.renderConfirmationModalContent);

    return <Modal {...this.props} />;
  }
}
