import fontList from '../../../assets/font-list';

const DEFAULT_TIMEOUT = 5;
const MILLISECONDS_IN_SECONDS = 1000;

const getDefaultFontByPropertyType = (propertyType = PROPERTY_TYPES.IOS) => 
  (fontList[propertyType].find((font) => font.isDefault) || {}).label || 'Helvetica';

export const getInitialFormModalData = (propertyType) => ({
  name: '',
  appRatingUrl: '',
  trigger: {
    triggerType: '.MobileInvitationContract',
    invitationHeadline: 'Are you enjoying our app?',
    invitationText: "We'd love you to rate our app and let us know what you think",
    provideButtonText: 'Rate App',
    declineButtonText: 'No Thanks',
    laterButtonText: 'Maybe Later',
    skipInvitation: false,
    invitationType: 'BANNER',
    triggerCustomBannerContract: {
      invitationTitle: 'Are you enjoying our app?',
      invitationBody: "We'd love you to rate our app and let us know what you think",
      textColor: '#000000',
      backgroundColor: '#ececec',
      position: 'TOP',
      isPartial: true,
      font: getDefaultFontByPropertyType(propertyType),
      invitationTimeout: 5,
      isSticky: false,
      customBannerButtonsSettingsContract: {
        buttonsDisplay: false,
        acceptButtonText: 'Rate Now',
        acceptButtonTextColor: '#FFFFFF',
        acceptButtonBackgroundColor: '#121826',
        closeButtonColor: '#7B7A7A',
      },
    },
    rules: {
      MobileFrequency: {
        params: {
          type: 'INVITE_PERCENTAGE_OF_VISITORS',
          frequency: 30,
        },
      },
      MobileNumberOfAppSession: {
        params: {
          compareString: 'greaterThan',
        },
      },
      MobileTimeInForeground: {
        params: {
          compareString: 'greaterThan',
          seconds: 120,
        },
      },
    },
  },
});

function prepareRulesList(rules = {}) {
  return Object.keys(rules)
    .filter((ruleName) => rules[ruleName].isActive)
    .reduce((aggrRulesList, currentRule) => ({ ...aggrRulesList, [currentRule]: { ...rules[currentRule], type: currentRule } }), {});
}

function setIsActive(rulesObject, rule) {
  return { ...rulesObject, [rule]: { ...rulesObject[rule], isActive: true } };
}

function prepareTriggerCustomBannerContract(triggerCustomBannerContract) {
  const { invitationTimeout, ...restOfTriggerCustomBannerContract } = triggerCustomBannerContract;
  return {
    invitationTimeout: (isNaN(invitationTimeout) ? DEFAULT_TIMEOUT : invitationTimeout) * MILLISECONDS_IN_SECONDS,
    ...restOfTriggerCustomBannerContract,
  };
}

function triggerCustomBannerContractToModel(triggerCustomBannerContract) {
  const { invitationTimeout } = triggerCustomBannerContract;
  return {
    ...triggerCustomBannerContract,
    invitationTimeout: invitationTimeout / MILLISECONDS_IN_SECONDS,
  };
}

export function convertFormDataIntoContract(data) {
  const { trigger, ...restOfData } = data;
  const { rules, triggerCustomBannerContract, ...restOfTrigger } = trigger;
  return { triggers: [{
    rules: prepareRulesList(rules),
    triggerCustomBannerContract: prepareTriggerCustomBannerContract(triggerCustomBannerContract),
    ...restOfTrigger,
  }], ...restOfData };
}

export function convertAppRatingDataForCreate(data) {
  const { trigger, mobileThemes, ...restOfData } = data;
  const { rules, triggerCustomBannerContract, ...restOfTrigger } = trigger;
  return { 
    appRatingCreateThemesDataContract : {
      bannerThemeData : mobileThemes.themeData,
    },
    appRating :{
      triggers: [{
        rules: prepareRulesList(rules),
        triggerCustomBannerContract: prepareTriggerCustomBannerContract(triggerCustomBannerContract),
        ...restOfTrigger,
      }], 
      ...restOfData,
    },
  };
}

export function convertContractIntoFormData(contract) {
  const { triggers = [], ...restOfContract } = contract;
  const [contractTriggers] = triggers;

  const rules = contractTriggers && contractTriggers.rules && Object
    .keys(contractTriggers.rules)
    .reduce(setIsActive, contractTriggers.rules);
  
  const { triggerCustomBannerContract } = contractTriggers;
    
  return { ...restOfContract, trigger: {
    ...contractTriggers, triggerCustomBannerContract: triggerCustomBannerContractToModel(triggerCustomBannerContract), rules },
  };
}
