import React from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import Toggle from '@mdigital/components/dist/components/Toggle';
import Input from '@mdigital/components/dist/components/Input';
import ColorPicker from '@mdigital/components/dist/components/ColorPicker';
import StyledButtonSettings from '../../../MobileEngagementDesign/Banner/SettingsExpandableList/ButtonSettings/StyledButtonSettings';
import FieldRow from '../../../MobileEngagementDesign/Banner/SettingsExpandableList/shared/FieldRow';

const ButtonFields = ({ disabled }) => (
  <React.Fragment>
    <FieldRow disabled={disabled} required title="Text" name="trigger.mobileThankYouPromptButtonSectionContract.buttonText"
      errorMessage="Please provide button text" render={({ input }) => (
        <Input maxLength={25} enableCounter digValue={input.value} digOnChange={input.onChange} />
      )} />
    <FieldRow disabled={disabled} title="Text Color" name="trigger.mobileThankYouPromptButtonSectionContract.buttonTextColor" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop  {...input} onChange={({ hex }) => input.onChange(hex)}  />
    )} />
    <FieldRow disabled={disabled} title="Button Color" name="trigger.mobileThankYouPromptButtonSectionContract.buttonColor" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop  {...input} onChange={({ hex }) => input.onChange(hex)}  />
    )} />
  </React.Fragment>
);

const onButtonToggle = (input , mutators , submitButtonColors) =>{
  input.onChange(!input.value);
  mutators.setButtonTextColor(submitButtonColors.textColor);
  mutators.setButtonColor(submitButtonColors.buttonColor);
};

const ThankYouPromptButtonSettings = ({ mutators , submitButtonColors }) => (
  <StyledButtonSettings>
    <FormSpy subscription={{ values: true }} render={() => (
      <FieldRow title="Button Display" name="trigger.mobileThankYouPromptButtonSectionContract.buttonDisplayed" render={({ input }) => (
        <Toggle onToggle={() => onButtonToggle(input , mutators , submitButtonColors)} switchState={input.value} />
      )} />
    )} />

    <FormSpy subscription={{ values: true }} render={({ values }) => (
      <ButtonFields disabled={!values.trigger.mobileThankYouPromptButtonSectionContract.buttonDisplayed}/>
    )} />
  </StyledButtonSettings>
);

ThankYouPromptButtonSettings.propTypes = {
  mutators: PropTypes.object,
  submitButtonColors : PropTypes.object,
};

ThankYouPromptButtonSettings.defaultProps = {
  mutators: {},
  submitButtonTextColor : {},
};

export default ThankYouPromptButtonSettings;
