import { ModalsService } from '@mdigital/components/dist/utils/modals';
import { getSingleText } from '../../../../common/utils';

const TextPathPrefix = 'app.pages.forms.targetingWarn';

export const EditingLiveForm = ({ onContinue }) => {
  return ModalsService.showConfirmation({
    title: getSingleText(`${TextPathPrefix}.publishWarnTitle`),
    type: 'danger',
    description:getSingleText(`${TextPathPrefix}.publishWarnMessage`),
    close: {
      title: 'Cancel',
    },
    confirm: {
      title: 'Edit',
      callback: () => {
        ModalsService.closeModals();
        onContinue();
      },
    },
  });
};
