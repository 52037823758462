export const COLORS = {
  WHITE: '#fff',
  LIGHT_BLACK : '#272727',
  BLUE: '#547FFB',
  DARK_BLUE: '#006BFF',
  SECONDARY_TEXT: '#aaaaaa',
  PRIMARY_TEXT: '#595959',
  ON_HOVER_PRIMARY_BUTTON : '#3395FF',
  ACTIVE_BLUE: '#007aff',
  SEPARATOR: '#d9d9d9',
  GRAY_SEPARATOR : '#e2e2e4',
  BANNER_PREVIEW_BACKGROUND : '#d8d8d8',
  BANNER_PREVIEW_DEFULAT_BACKGROUND : '#f7f7f7',
  PUBLISHED_BORDER: '#e0f2e0',
  PUBLISHED_BACKGROUND: '#eff8ef',
  PUBLISHED_COLOR: '#5bb95b',
  DRAFT_BORDER: '#ecae5e33',
  DRAFT_BACKGROUND: '#fcf2e4e5',
  DRAFT_COLOR: '#d68b2b',
  DRAFT_POINT_COLOR: '#f89248',
  GREEN: '#00c59e',
  YELLOW : '#F3D124',
  TEXT_HOVER: '#40404a',
  BUTTON_LABEL: '#F7AC4C',
  INVITE_LABEL: '#007CFF',
  EMBEDDED_LABEL: '#5F8295',
  CODE_LABEL: '#47C1DF',
  BADGE_LABEL: '#aaa',
  TEXT_HIGHLIGHT: 'rgba(0, 124, 255, .2)',
  BORDER: '#c8c7cc',
  TEXT_TITLE: '#858585',
  TEXT_SUBTITLE: '#5B5B60',
  INPUT_BORDER: '#c8c7cc',
  GRAY_BACKGROUND : '#fafafa',
  UPLOAD_ERR: '#a94442',
  BIN_COLOR: '#9F9FA4',
  DARK_GREY: '#707070',
  LIGHT_GREY: '#F0F0F0',
  ON_HOVER_BIN: '#808080',
  LIST_BORDER: '#C8C7CB',
  REQUIRE_MARK: '#E97E66',
  
  TOOLTIP: '#666',
  DARK_MODE_BLACK_BACKGROUND: '#121826',
  DARK_MODE_LIGHT_BACKGROUND: '#E6E8E8',
  ICON: '#034CCB',
  SECONDARY_TOOLBAR: {
    BORDER_COLOR: 'rgb(200, 199, 204)',
    TITLE_TEXT_COLOR: 'rgb(142, 142, 147)',
    ACTION_BUTTON: {
      BORDER_COLOR: 'rgb(209, 209, 209)',
      DEFAULT_TEXT_COLOR: '#00c59e',
    },
  },
  ERROR: '#d43f3a',
  TABLE: {
    ROW: {
      BORDER: '#E4E3E6'
    }
  },
  ACTION: {
    DEFAULT: '#D9D9D9',
    HOVER: 'rgba(0, 0, 0, 0.5)'
  },
  LIVE_PREVIEW:{
    LIGHT_GRAY: '#F2F2F2',
    SHADOW : '#00000029',
    HEADER_BORDER: '#E7E8EA',
    HEADER_BORDER_DARK_MODE: 'rgb(231,232,234,0.3)',
  },
  CLOSE_BUTTON: '#8e8e93'
};