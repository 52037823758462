export default {
  TOP:{
    liveImg:{
      bottom : '-16px',
      height: '617px',
    },
    liveDeviceBackground : {
      bottom : '587px',
      right : '-93px',
      width: '384px',
      height: '603px',
    },
    background : {
      height: '631px',
    },
  },
};