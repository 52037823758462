import styled from 'styled-components';
import { COLORS } from '../../../../../common/colors';

export const StyledThankYouPromptLivePreviewHeader = styled.div`
    position: relative;
    height: 47px;
    width: 344px;
    top: -10px;
    right: 10px;
    border-bottom: ${(props) => props.isDarkEnabled ? 
    `1px solid ${COLORS.LIVE_PREVIEW.HEADER_BORDER_DARK_MODE}` :
    `1px solid ${COLORS.LIVE_PREVIEW.HEADER_BORDER}`};
    text-align: center;
    border-radius: ${(props) => props.borderRadius} ${(props) => props.borderRadius} 0px 0px;
    background-color: ${(props) => props.titleBackgroundColor};
`; 

export const StyledThankYouPromptLivePreviewHeaderButton = styled.i`
    font-size: 12px;
    position: absolute;
    right: ${(props) => props.direction};
    bottom: 14px;
    color: ${(props) => props.isDarkEnabled ? COLORS.WHITE : COLORS.CLOSE_BUTTON}
`; 

export const StyledThankYouPromptLivePreviewHeaderText = styled.label`
    text-align: center;
    width: 100%;
    margin-top: 14px;
    font-size: 17px;
    font-weight: 500;
    color: ${(props) => props.titleTextColor};
`;