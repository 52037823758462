import React , { Component } from 'react';
import DigLeftMenuContainer from './DigLeftMenuContainer';
import PropTypes from 'prop-types';
import withProvider from '../../hoc/ProviderHoc';

@withProvider()
export default class DigLeftMenuContainerWrapper extends Component{
  render(){
    return(
      <DigLeftMenuContainer stateService={this.props.stateService}
        appTreeService={this.props.appTreeService}/>
    );
  }
}

DigLeftMenuContainerWrapper.propTypes = {
  stateService: PropTypes.object,
  appTreeService: PropTypes.object,
};