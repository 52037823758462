import styled from 'styled-components';
import { SEPARATOR , HEADER_TEXT } from '../../common/colors';

export const StyledFormNameContainer = styled.div`
    display: flex;
`;

export const StyledFormID = styled.label`
    margin: auto;
    flex: 1;
    margin-left: 76px;
`;

export const StyledFormSettingsTitle = styled.div`
    color: ${HEADER_TEXT};
    font-weight: 600;
    padding: 28px 0 16px 0;
`;

export const StyledDarkModeContainer = styled.div`
    display: flex;
`;

export const FormSettingsHeaderSpeartor = styled.div `
  border-top : 1px solid ${SEPARATOR};
`;