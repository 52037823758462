import React, { useContext } from 'react';
import { ExcludeActiveIcon, VerticalTabs } from '@m/alchemy-ui';
import { TargetingNavigationStep, TextPathPrefix, TargetingAppMode, TriggerByTargetingAppMode } from '../..';
import { TriggerContext, TRIGGER_TYPE } from '../../../..';
import { getSingleText } from '../../../../../../../common/utils';
import SharedLeftPanel from '../../../Shared/SharedLeftPanel';
import { StyledTargetingLeftPanel } from '../../StyledTriggerByTargeting';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { TargetingActions } from '../../Actions';
import { checkProvision } from '../../../../../../../redux/store/store-helper';
import SharedLeftPanelDescription from '../../../Shared/SharedLeftPanelDescription';
import { TARGETING_TYPES } from '../../TargetingTypes';

const TargetingLeftPanel = () => {
  const { triggerState, triggerDispatch, currentTab, targetingViewType, setTargetingViewType, isReadOnly } = useContext(TriggerContext);
  const isLocalNotificationsEnabled = checkProvision(DigProvisions.MOBILE_SDKV2_LOCAL_Notifications);
  const targetingTypeSelection = getNestedProperty(triggerState, 'trigger.triggerScope');
  const invitationType = getNestedProperty(triggerState, 'trigger.invitationType');

  const isDisable = (id) => {
    return isReadOnly ? false : currentTab < id;
  };

  const getTabIcon = (step) => {
    return isDisable(step) && <ExcludeActiveIcon size="16" />;
  };

  const setTargetingTypeSelection = (value) => {
    if (value === TriggerByTargetingAppMode.BACKGROUND){
      triggerDispatch({ type: TargetingActions.SET_INVITATION_TYPE , payload : TARGETING_TYPES.LOCAL_NOTIFICATION });
    }
    triggerDispatch({ type: TargetingActions.SET_TRIGGER_SCOPE , payload : value });
  };
  
  return (
    <StyledTargetingLeftPanel>
      {isLocalNotificationsEnabled ?
        <SharedLeftPanel 
          items={TargetingAppMode}
          selection={targetingTypeSelection}
          setSelection={setTargetingTypeSelection}
          triggerType={TRIGGER_TYPE.TRIGGER_BY_TARGETING}
          disabled={isReadOnly}/> :
        <SharedLeftPanelDescription triggerType={TRIGGER_TYPE.TRIGGER_BY_TARGETING}/>
      }
      {
        invitationType !== TARGETING_TYPES.PUSH_NOTIFICATION &&
        <VerticalTabs 
          className="targeting-vertical-tabs"
          activeTabValue={targetingViewType}
          tabs={[
            { id: TargetingNavigationStep.INVITE_DISPLAY, 
              label: getSingleText(`${TextPathPrefix}.triggerSteps.inviteDisplay`), 
              value: 'inviteDisplay', 
            },
            { id: TargetingNavigationStep.TARGETING, 
              label: getSingleText(`${TextPathPrefix}.triggerSteps.targeting`), 
              value: 'targeting' ,  
              icon: getTabIcon(TargetingNavigationStep.TARGETING) , 
              disabled: isDisable(1), 
            },
            { id: TargetingNavigationStep.QUARANTINE, 
              label: getSingleText(`${TextPathPrefix}.triggerSteps.quarantine`), 
              value: 'quarantine' ,  
              icon: getTabIcon(TargetingNavigationStep.QUARANTINE) , 
              disabled: isDisable(2), 
            }
          ]}
          onTabClick={ (event, tabData)=> setTargetingViewType(tabData)}
        />
      }
    </StyledTargetingLeftPanel>
  );
};

export default TargetingLeftPanel;