import styled from 'styled-components';

export const CustomCssWrapper = styled.div`
	.resources-select-container, .css-url-input .input-wrapper {
		width: 75%;
	}
	[data-aut="validationError"] {
		color: #a94442;
    font-size: 12px !important;
	}
`;