import React from 'react';
import PropTypes from 'prop-types';
import withModal from '@mdigital/components/dist/hoc/ModalHoc';

export default function DeleteConfirmationModal(props) {
  const {
    title = 'Delete Confirmation',
    okButtonText = 'Yes, Delete it',
    cancelButtonText = 'I\'ll keep it',
    okButtonClass = 'btn-danger',
    modalClassName = 'modal-design',
    getContent,
    ...rest } = props;

  const modalStaticProps = {
    title,
    okButtonText,
    cancelButtonText,
    okButtonClass,
    modalClassName,
  };

  const ModalHOC = withModal(modalStaticProps)(getContent);
  return <ModalHOC {...rest}/>;
}

DeleteConfirmationModal.defaultProps = {
  title : 'Delete Confirmation',
  okButtonText : 'Yes, Delete it',
  cancelButtonText : 'I\'ll keep it',
  okButtonClass : 'btn-danger',
  modalClassName : 'modal-design',
};
DeleteConfirmationModal.propTypes = {
  title : PropTypes.string.isRequired,
  okButtonText : PropTypes.string,
  cancelButtonText : PropTypes.string,
  okButtonClass : PropTypes.string,
  modalClassName : PropTypes.string,
  getContent: PropTypes.func.isRequired,

  isShown:PropTypes.bool.isRequired,
  onCancelClicked:PropTypes.func,
  onOkClicked:PropTypes.func,
  isOkButtonDisabled:PropTypes.bool,
  additionalInfo: PropTypes.object,
};