import React , { Component } from 'react';
import DigLanuageDropdown from './DigLanguageDropdown';
import PropTypes from 'prop-types';
import withProvider from '../../hoc/ProviderHoc';

@withProvider()
export default class DigLanguageDropdownWrapper extends Component{
  render(){
    return(
      <DigLanuageDropdown defaultLanguage={this.props.defaultValue}
        onSelected={this.props.onLanguageSelect}
        onChange={this.props.onChange} />
    );
  }
}

DigLanguageDropdownWrapper.propTypes = {
  onLanguageSelect:PropTypes.func,
  onChange:PropTypes.func,
  defaultValue: PropTypes.string,
};