import mobileAndroid from './mobileAndroid'
import mobileIOS from './mobileIOS'
import backgrounds from './backgrounds'
import introductionImages from './introduction'
import migrationIntroductionImages from './migrationIntoduction'

export const Banner = {
  mobileIOS,
  mobileAndroid,
}

export const DestinationPageBackground = backgrounds.destinationPage
export const CardBoardEmptyStateBackground = backgrounds.cardboardEmptyState;

export const IntroductionImages = introductionImages
export const getMigrationIntroductionImages = migrationIntroductionImages