import React, { useEffect, useContext } from 'react';
import { TriggerContext } from '../../../../../../..';
import TargetingBlockRow from '../../../TargetingBlockRow';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { NumberInput } from '@m/alchemy-ui';
import { TargetingActions } from '../../../../../Actions';
import { Subtitle } from '../../../../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../../../../common/utils';
import { TextPathPrefix } from '../../../../..';
import { isRuleExists } from '../../../../../../../helpers';

const paramType = 'INVITE_PERCENTAGE_OF_VISITORS';

const TargetByPercentile = () => {
  const { triggerState, triggerDispatch, isReadOnly } = useContext(TriggerContext);
  const defaultValue = getNestedProperty(triggerState, 'trigger.rules.MobileFrequency.params.frequency');
  const [value, setValue] = React.useState(defaultValue || 30);

  useEffect(() => {
    isRuleExists(triggerState, 'trigger.rules.MobileFrequency.id') && 
    triggerDispatch({ type: TargetingActions.SET_MOBILE_FREQUENCY_IS_ACTIVE , payload : true } );
  },[]);


  const handleCheckboxChange = (e) => {
    triggerDispatch({ type: TargetingActions.SET_MOBILE_FREQUENCY_IS_ACTIVE , payload : e.target.checked } );
    onNumberChanged(30);
  };
  const onNumberChanged = (val) => {
    setValue(val);
    triggerDispatch({ type: TargetingActions.SET_MOBILE_FREQUENCY_RULE , payload : val });
    triggerDispatch({ type: TargetingActions.SET_MOBILE_FREQUENCY_TYPE , payload : paramType });
  };
  
  return (
    <TargetingBlockRow
      checkboxLabel="Trigger"
      checked={getNestedProperty(triggerState , 'trigger.rules.MobileFrequency.isActive')}
      disabled={isReadOnly}
      onCheckboxChange={ handleCheckboxChange }>
      <React.Fragment>
        <NumberInput 
          min="1"
          max="100"
          value={value}
          onNumberChange={ onNumberChanged } />
        <Subtitle marginLeft={1.5}>{getSingleText(`${TextPathPrefix}.rulesTexts.percentile`)}</Subtitle>
      </React.Fragment>
    </TargetingBlockRow>
  );
};

export default TargetByPercentile;
