import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import StyledMobileEngagementDesign, {
  StyledEngagementPageLeftSide,
  StyledEngagementPageRightSide,
  StyledEngagementTypeSelectionSection
} from './StyledMobileEngagementDesign';
import { BannerSettings , BannerLivePreview } from './Banner';
import AlertSettings from './Alert';
import NotificationSettings from './Notification';
import SelectionGroup from '@mdigital/components/dist/components/SelectionGroupV2';
import CustomIntercept from './Custom';
import { getEngagementTextByFlavorAndType } from './utils';
import AlertLivePreview from './Alert/AlertLivePreview';

export const ENGAGEMENT_TYPES = {
  BANNER: 'BANNER',
  ALERT: 'ALERT',
  NOTIFICATION: 'PUSH_NOTIFICATION',
  CUSTOM_INTERCEPT: 'CUSTOM',
};


const AlertRenderer = ( { isNativeAlertRevamp , ...props } ) => isNativeAlertRevamp ? <AlertLivePreview {...props}/> : <AlertSettings {...props}/>;

const EngagementMap = {
  [ENGAGEMENT_TYPES.BANNER]: BannerLivePreview,
  [ENGAGEMENT_TYPES.ALERT]: AlertRenderer,
  [ENGAGEMENT_TYPES.NOTIFICATION]: NotificationSettings,
  [ENGAGEMENT_TYPES.CUSTOM_INTERCEPT]: CustomIntercept,
};

const GlobalDataContext = React.createContext({});

export const GlobalDataProvider = GlobalDataContext.Provider;
export const GlobalDataConsumer = GlobalDataContext.Consumer;


const renderEngagementPageByType = ({ type, ...props }) => {
  const Component = EngagementMap[type] || BannerLivePreview;
  return <Component {...props} />;
};

const HelpLink = ({ engagementType , helpCenterLinks }) => {
  const leanMore = 'Learn more';
  if(helpCenterLinks) {
    switch(engagementType) {
      case ENGAGEMENT_TYPES.BANNER:
        return <span>. <a href={helpCenterLinks.bannerInvite}>{leanMore}</a></span>;
      case ENGAGEMENT_TYPES.NOTIFICATION:
        return <span>. <a href={helpCenterLinks.pushNotification}>{leanMore}</a></span>;
      case ENGAGEMENT_TYPES.CUSTOM_INTERCEPT:
        return <span>. <a href={helpCenterLinks.customIntercept}>{leanMore}</a></span>;
      default:
        return null;
    }
  }
};

export const FLAVORS = {
  APP_RATING: 'appRating',
  INVITATION: 'invitation',
};

const MobileEngagementDesign = ({ propertyType, flavor, engagementType, customInterceptSupported, helpCenterLinks, isDarkModeEnabled, isNativeAlertRevamp, isPushNotificationsSupported, ...props }) => (
  <GlobalDataProvider value={{ propertyType, flavor, engagementType , isDarkModeEnabled }}>
    <StyledMobileEngagementDesign className="mobile-engagement">
      <StyledEngagementPageLeftSide>
        <StyledEngagementTypeSelectionSection>
          <Field type="radio" name="trigger.invitationType" value={engagementType} render={({ input }) => (
            <SelectionGroup {...input}>
              <SelectionGroup.Option value={ENGAGEMENT_TYPES.BANNER}>Banner</SelectionGroup.Option>
              <SelectionGroup.Option value={ENGAGEMENT_TYPES.ALERT}>Native Alert</SelectionGroup.Option>
              {customInterceptSupported && <SelectionGroup.Option value={ENGAGEMENT_TYPES.CUSTOM_INTERCEPT}>Custom</SelectionGroup.Option>}
              {isPushNotificationsSupported && <SelectionGroup.Option value={ENGAGEMENT_TYPES.NOTIFICATION}>Notification</SelectionGroup.Option>}
            </SelectionGroup>
          )}/>
          <p>{getEngagementTextByFlavorAndType('description', flavor, engagementType)}<HelpLink engagementType={engagementType} helpCenterLinks={helpCenterLinks}/></p>
        </StyledEngagementTypeSelectionSection>
        {engagementType === ENGAGEMENT_TYPES.BANNER && <BannerSettings {...props} />}
        {isNativeAlertRevamp && engagementType === ENGAGEMENT_TYPES.ALERT && <AlertSettings isNativeAlertRevamp={isNativeAlertRevamp} flavor={flavor} {...props} />}
      </StyledEngagementPageLeftSide>
      <StyledEngagementPageRightSide>
        {renderEngagementPageByType({ type: engagementType, flavor, propertyType, helpCenterLinks, isDarkModeEnabled, isNativeAlertRevamp, ...props })}
      </StyledEngagementPageRightSide>
    </StyledMobileEngagementDesign>
  </GlobalDataProvider>
);

MobileEngagementDesign.propTypes = {
  flavor: PropTypes.string,
  engagementType: PropTypes.string,
  customInterceptSupported: PropTypes.bool,
  propertyType: PropTypes.oneOf(['mobileIOS', 'anywhere' , 'mobileAndroid']),
  mutators: PropTypes.object,
  helpCenterLinks: PropTypes.object,
  isDarkModeEnabled: PropTypes.bool,
  isNativeAlertRevamp: PropTypes.bool,
  isPushNotificationsSupported: PropTypes.bool,
};

MobileEngagementDesign.defaultProps = {
  flavor: FLAVORS.INVITATION,
  engagementType: ENGAGEMENT_TYPES.BANNER,
  customInterceptSupported : false,
  propertyType: 'mobileIOS',
  mutators: {},
  helpCenterLinks: {},
  isDarkModeEnabled: false,
  isNativeAlertRevamp: false,
  isPushNotificationsSupported: false,
};


export default MobileEngagementDesign;