import styled from 'styled-components';
import REACTCOLORS from '../../../../../common/colors';
import { COLORS } from '../../../../../../common/colors';

export const StyledThankYouPromptImageContainer = styled.div`
    ${(props) => props.disabled ? 'pointer-events: none; opacity: 0.4;' : ''};
`;

export const StyledThankYouPromptImageUpload = styled.div`
    display : flex;
    justify-content: space-between;
    width: 411px;
    align-items: center;

    .dig-container{
        font-size : 14px;
    }
    .checkbox-info{
        margin-top: 2px;
    }
   
`;

export const StyledThankYouPromptImageInputUpload = styled.div`
    display : flex;
    .image-uploader-input{
        width: 208px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        padding-right: 26px;
        text-overflow: ellipsis;
    }
    .image-uploader-input::placeholder{
        color : ${(props) => props.textColor ? COLORS.TEXT_SUBTITLE : REACTCOLORS.TITLE_TEXT_COLOR};
    }
    .image-uploader-button{
        font-size: 14px;
        border-color: ${REACTCOLORS.BLACK};
        background-color: ${REACTCOLORS.BLACK} !important;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        width: 91px;
        font-weight: 500;
    }
    .image-uploader-button:hover{
        border-color: ${COLORS.LIGHT_BLACK};
        background-color: ${COLORS.LIGHT_BLACK} !important;
    }
    .image-uploader-close-button{
        position: relative;
        top: 10px;
        left: 195px;
        font-size: 12px;
        cursor : pointer;
        color : ${COLORS.SECONDARY_TEXT};
        z-index: 2;
    }
    .image-uploader-close-button:hover{
        color : ${COLORS.PRIMARY_TEXT}
    }
`;

export const StyledThankYouPromptImageSpan = styled.div`
    direction: rtl;
    color : ${(props) => props.hasError ? REACTCOLORS.REQUIRED_RED : REACTCOLORS.TITLE_TEXT_COLOR};
    font-size: 12px;
    padding-bottom: 20px;
`;

export const StyledThankYouPromptImageInfo = styled.span`
    margin-right: 10px;
    margin-bottom: 8px;
`;