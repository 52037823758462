import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../redux/store/configure-store';

export default function withProvider() {
  return function(WrappedComponent) {
    return class ProviderHoc extends React.Component {
      render() {
        return (
          <Provider store={store}>
            <WrappedComponent {...this.props}/>
          </Provider>
        );
      }
    };
  };
}