import React from 'react';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { Preview } from '../../../../../assets/BannerPreviewAssets/base64';
import { StyledLivePreviewDeviceImg , StyledLivePreviewBackground , StyledLivePreviewDeviceBackgroundImg } from './StyledLivePreviewLayout';
import BannerLivePreviewLayoutPosition from './LivePreviewLayoutPosition/BannerLivePreviewLayoutPosition';
import ThankYouPromptLivePreviewLayoutPosition from './LivePreviewLayoutPosition/ThankYouPromptLivePreviewLayoutPosition';
import { PROPERTY_TYPES } from '../../../../../../common/constants';
import { BannerPositionType } from '../BannerLivePreviewHelper';

export const LIVE_PREVIEW_TYPE = {
  BANNER: 'banner',
  THANK_YOU_PROMPT: 'thankYouPrompt',
  ALERT: 'alert',
};

const LivePreviewLayout = ({ propertyType, position , previewType, isDarkEnabled }) => {
  const previewImgPath = 'livePreview';
  const isBannerOrAlertLayout = previewType === LIVE_PREVIEW_TYPE.BANNER || previewType === LIVE_PREVIEW_TYPE.ALERT;
  const darkModeImage = propertyType === PROPERTY_TYPES.IOS && position === BannerPositionType.TOP && isDarkEnabled ? 'DARK' : '';
  const pathToImage = `${isBannerOrAlertLayout ? LIVE_PREVIEW_TYPE.BANNER : LIVE_PREVIEW_TYPE.THANK_YOU_PROMPT}.${propertyType}.${position}${darkModeImage}`;
  const layoutPositionSettings = isBannerOrAlertLayout ? BannerLivePreviewLayoutPosition[position] :
    ThankYouPromptLivePreviewLayoutPosition[position];
  return (
    <StyledLivePreviewBackground layoutPositionSettings={layoutPositionSettings} >
      <StyledLivePreviewDeviceImg src={getNestedProperty(Preview[previewImgPath], pathToImage)} layoutPositionSettings={layoutPositionSettings}/>
      <StyledLivePreviewDeviceBackgroundImg layoutPositionSettings={layoutPositionSettings} isDarkEnabled={isDarkEnabled} position={position} previewType={previewType}/>
    </StyledLivePreviewBackground>
  );
};

export default LivePreviewLayout;