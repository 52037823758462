import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import Checkbox from '@mdigital/components/dist/components/Checkbox';
import RowBlocker from './RowBlocker';
import QuestionMarkTooltip from '../../../../../../../common/QuestionMarkTooltip';

const StyledComponent = styled.div`
  display: flex;
  padding: 2px 0;
  & > div:nth-child(1) {
    padding: 5px 0;
    flex: ${({ labelWeight }) => labelWeight};
    display: flex;
    & > span {
      padding-left: 7px;
    }
  }

  & > div:nth-child(2) {
    position: relative;
    flex: ${({ inputWeight }) => inputWeight};

    .targeting-settings-fields-section {
      display: flex;
    }
  }
`;

const checkboxOnChange = (input , setIsActive)=>{
  input.onChange(!input.value);
  setTimeout(() => {
    setIsActive && setIsActive(!input.value);
  });
};

const BlockableRow = ({ children, title, isActiveFieldName, labelWeight = 1, inputWeight = 5, tooltipText ,setIsActive }) => (
  <StyledComponent labelWeight={labelWeight} inputWeight={inputWeight}>
    <div>
      <Field name={isActiveFieldName} render={({ input }) => (
        <Checkbox
          label={title}
          handleCheckboxChange={() => checkboxOnChange(input , setIsActive)}
          digIsChecked={input.value} />
      )} />
      {tooltipText && <QuestionMarkTooltip text={tooltipText} />}
    </div>
    <div>
      <RowBlocker valueToWatch={isActiveFieldName} />
      {children}
    </div>
  </StyledComponent>
);

export default BlockableRow;
