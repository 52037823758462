import React, { useState }  from 'react';
import DigCheckbox from '@mdigital/components/dist/components/Checkbox';
import { StyledMediaCaptureResponseFormatsContainer , StyledMediaCaptureResponseFormatsTitle , StyledMediaCaptureResponseFormats 
  , StyledMediaCaptureFileUploaderContainer , StyledMediaCaptureResponseFormatsErrorMessage } from './StyledMediaCaptureResponseFormats';
import { getSingleText } from '../../../../common/utils';
import MediaCaptureVideoLimit from './MediaCaptureVideoLimit';
import MediaCaptureAudioPlaceholder from './MediaCaptureAudioPlaceholder';
import { SUPPORTED_MEDIA_TYPES } from '../MediaCaptureFormatsPreview';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { PROPERTY_TYPES } from '../../../../common/constants';

const MediaCaptureResponseFormats = ({ mediaCaptureDynamicFieldContract , removeMediaCaptureFormats , addMediaCaptureFormats , setMaxVideoLength , setAudioPlaceholderText, propertyType }) =>{
  const supportedMediaTypes = getNestedProperty(mediaCaptureDynamicFieldContract , 'supportedMediaTypes') || [];
  const [isVideoCheck, setisVideoCheck] = useState(supportedMediaTypes.includes(SUPPORTED_MEDIA_TYPES.VIDEO));
  const [isAudioCheck, setisAudioCheck] = useState(supportedMediaTypes.includes(SUPPORTED_MEDIA_TYPES.AUDIO));
  const [isUploadMediaCheck, setUploadMediaCheck] = useState(supportedMediaTypes.includes(SUPPORTED_MEDIA_TYPES.UPLOAD));
  const isErrorMessage = supportedMediaTypes.length === 0 ;
  const audioPlaceholderText = getNestedProperty(mediaCaptureDynamicFieldContract , 'audioPlaceholderText');
  const isAndroidProperty = propertyType === PROPERTY_TYPES.ANDROID;

  const checkboxChange = (isChecked , onChange ,format) => {
    isChecked ? removeMediaCaptureFormats(format) : addMediaCaptureFormats(format);
    onChange(!isChecked);
  };

  const isVideoLimitComponent = () => {
    return isVideoCheck || (isAndroidProperty && isUploadMediaCheck);
  };

  return (
    <StyledMediaCaptureResponseFormatsContainer>
      <StyledMediaCaptureResponseFormatsTitle>
        {getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.title')}
      </StyledMediaCaptureResponseFormatsTitle>
      <StyledMediaCaptureResponseFormats>
        <DigCheckbox 
          label={getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.Audio')}
          digIsChecked={isAudioCheck}
          handleCheckboxChange={() => checkboxChange(isAudioCheck , setisAudioCheck , SUPPORTED_MEDIA_TYPES.AUDIO)}
        />
        {isAudioCheck && <MediaCaptureAudioPlaceholder audioPlaceholderText={audioPlaceholderText} setAudioPlaceholderText={setAudioPlaceholderText}/>}
        <DigCheckbox 
          label={isAndroidProperty ? getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.video') :
            getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.IOSVideo')}
          digIsChecked={isVideoCheck}
          handleCheckboxChange={() => checkboxChange(isVideoCheck , setisVideoCheck , SUPPORTED_MEDIA_TYPES.VIDEO)}
        />
        {isAndroidProperty && 
        <StyledMediaCaptureFileUploaderContainer>
          <DigCheckbox 
            label={getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.uploadMedia')}
            digIsChecked={isUploadMediaCheck}
            handleCheckboxChange={() => checkboxChange(isUploadMediaCheck, setUploadMediaCheck , SUPPORTED_MEDIA_TYPES.UPLOAD)}
          />
        </StyledMediaCaptureFileUploaderContainer>
        }
        {isVideoLimitComponent() &&
          <MediaCaptureVideoLimit mediaCaptureDynamicFieldContract={mediaCaptureDynamicFieldContract} setMaxVideoLength={setMaxVideoLength}/> }
        {isErrorMessage &&  
                <StyledMediaCaptureResponseFormatsErrorMessage className="neb-has-error">  
                  {getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.responseFormatErrorMessage')} 
                </StyledMediaCaptureResponseFormatsErrorMessage> }
      </StyledMediaCaptureResponseFormats>
    </StyledMediaCaptureResponseFormatsContainer>
  );
};

export default MediaCaptureResponseFormats;