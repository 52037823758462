import React from 'react';
import AppRatingIntroduction from './AppRatingIntroduction';
import AppRatingIntroductionMigrationUsers from './AppRatingIntroductionMigrationUsers';
import AppRatingNewIntroduction from './AppRatingNewIntroduction';

const AppRatingIntoductionRenderer = ({
  showIntroduction,
  showMigrationIntroduction,
  mobileSDKV2AppRatingsiOSDirect,
  mobileSDKV2AppRatingAndroidDirect, 
  handleIntroductionClose,
  handleMigrationIntroductionClose,
  handleNewIntroductionClose,
}) => {

  if (!showMigrationIntroduction) {
    return null;
  }

  const isDirectEnalbed = mobileSDKV2AppRatingAndroidDirect || mobileSDKV2AppRatingsiOSDirect;
  
  if(showIntroduction && !isDirectEnalbed){
    return <AppRatingIntroduction
      onClose={handleIntroductionClose}
      isShown={showIntroduction} />;
  } else if (showIntroduction && isDirectEnalbed){
    return <AppRatingNewIntroduction
      isAndroidDirect={mobileSDKV2AppRatingAndroidDirect}
      onClose={handleNewIntroductionClose}
      isShown={showIntroduction} />;
  } else if (!showIntroduction && isDirectEnalbed) {
    return <AppRatingIntroductionMigrationUsers
      isAndroidDirect={mobileSDKV2AppRatingAndroidDirect}
      onClose={handleMigrationIntroductionClose}
      isShown={showMigrationIntroduction} />;
  } else {
    return null;
  }
};
    
export default AppRatingIntoductionRenderer;
