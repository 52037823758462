import styled from 'styled-components';

export const StyledFormDisplay = styled.div`
font-family: 'Open Sans';
.wizard-header {
  margin-top: 40px;
  -webkit-font-smoothing: auto !important;
}
.wizard-step {
  display: flex;
  .display-type {
    flex: 1;
    .display-type-description {
      text-align: left;
      height: 55px;
      margin-top: 23px;
    }
  }
  .display-preview {
    flex: 1.8;
    .display-preview-image {
      margin: -35px auto 0 auto;
      display: block;
    }
  }  
}
`;