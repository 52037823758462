import React from 'react';
import {  Header, PanelRow, PanelContent, PanelColumn, PanelRowContent, PanelRowPreview } from '../../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../../common/utils';
import { TextPathPrefix } from '../../..';
import TargetingFieldBasicSettings from './TargetingFieldBasicSettings';
import { TargetingPreview } from '../../../TargetingPreview';

const InviteDisplay = () => {
  return (
    <PanelRow>
      <Header>{getSingleText(`${TextPathPrefix}.inviteDisplayHeader`)}</Header>
      <PanelContent>
        <PanelColumn>
          <PanelRowContent>
            <TargetingFieldBasicSettings/>
          </PanelRowContent>
          <PanelRowPreview>
            <TargetingPreview/>
          </PanelRowPreview>
        </PanelColumn>
      </PanelContent>
    </PanelRow>
  );
};

export default InviteDisplay;