import { createSelector } from 'reselect';

export const getAllFoldersSelector = (state) => {
  const { folders: { allFolders } } = state;
  return allFolders;
};

export const getSortedFolders = createSelector(getAllFoldersSelector, (allFolders = []) => {
  const foldersWithoutUniqueFolder = allFolders.filter( (folder) => !folder.root );
  let sortedFolderList = _.sortBy(foldersWithoutUniqueFolder, function(folder) {
    return folder.name.toLowerCase();
  });

  const rootFolder = allFolders.find( (folder) => folder.root);

  // sort the persist folder to front
  return rootFolder ? [rootFolder ,... sortedFolderList] : sortedFolderList;
}
);

export const nameValidationObject = createSelector(getAllFoldersSelector, (allFolders = []) => allFolders.reduce((acc, folder) => Object.assign(acc,{ [folder.name.toLowerCase().trim()]: true }), {})
);


