import React, { Component } from 'react';
import withModal from '@mdigital/components/dist/hoc/ModalHoc';
import styled from 'styled-components';
import { COLORS } from '../../../common/colors';
import { getSingleText } from '../../../common/utils';

const StyledDeleteFolderModal = styled.span`
color: ${COLORS.TEXT_SUBTITLE};
font-size: 14px;
.bold {
  color: ${COLORS.ERROR};
}
 `;
const basicModalProps = {
  title: getSingleText('app.pages.forms.folders.modals.delete.title'),
  okButtonText: getSingleText('app.pages.forms.folders.modals.delete.okButton'),
  cancelButtonText: getSingleText('app.pages.forms.folders.modals.delete.cancel'),
  okButtonClass: 'btn-danger',
  modalClassName: 'modal-design',
};

@withModal(basicModalProps)
export default class DeleteFolderModal extends Component {

  getMessage() {
    const { isShowDeleteWarningMessage } = this.props;
    return (isShowDeleteWarningMessage ? getSingleText('app.pages.forms.folders.modals.delete.warningMessage') : '')
      .concat(getSingleText('app.pages.forms.folders.modals.delete.confirmationMessage'));
  }

  render() {
    const message = { __html: this.getMessage() };
    return <StyledDeleteFolderModal dangerouslySetInnerHTML={message} />;
  }
}