import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';


export const StyledSdkIntegrationV2ErrorBody = styled.div `
    padding: 20px 20px 0px 22px;
    font-size: 14px;

    .sdk-integration-v2-error-body-header{
        font-weight: 600;
        display: flex;

        .sdk-integration-v2-error-body-icon{
            font-size: 18px;
            margin-right: 7px;
            color: ${COLORS.YELLOW};
        }
    }

    .sdk-integration-v2-error-body-content{
        margin-bottom : 15px;
        margin-top: 16px;
    }
`;