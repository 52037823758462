import styled from 'styled-components';

export const ReusableFieldFormWrapper = styled.div`
  display: flex; 
  flex-direction: column;
  .input-wrapper {
    width: 100%;
  }

  svg {
    margin-top: 3px;
  }

  label {
    text-align: left;
    font: normal normal normal 14px/16px Open Sans;
    letter-spacing: 0px;
    color: #40440A;
    opacity: 1;
  }

  .dig-container label {
    margin-top: 2px;
  }
`;

export const InputTitleWrapper = styled.div`
  display: flex;
`;

export const ReuseTitleWrapper = styled.div`

`;

export const CheckBoxWrapper = styled.div`
  label {
    text-align: left;
    font: normal normal normal 14px/16px Open Sans;
    letter-spacing: 0px;
    color: #40440A;
    opacity: 1;
    margin-top:2px;
  }
`;

export const RequireText = styled.span`
  text-align: left;
  font: normal normal normal 14px/16px Open Sans;
  letter-spacing: 0px;
  color: #EF6262;
  margin-right: 3px;
`;