import React from 'react';
import DigInput from '@mdigital/components/dist/components/Input';
import { StyledVideoLimitContianer , StyledVideoLimitText , StyledVideoLimitErrorMessage } from './StyledMediaCaptureVideoLimit';
import { getSingleText } from '../../../../../common/utils';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import QuestionMarkTooltip from '../../../../common/QuestionMarkTooltip';

const TOOLTIP_STYLE = { margin: '0px 15px 7px 7px' , color: '#666' };
const TOOLTIP_HTML_STYLE = { width: '250px' }; 

const MediaCaptureVideoLimit = ({ mediaCaptureDynamicFieldContract , setMaxVideoLength }) =>{
  const maxLength = getNestedProperty(mediaCaptureDynamicFieldContract , 'maxLength') || 0;
  const isErrorMessage = maxLength < 1 || maxLength > 300;

  return(
    <React.Fragment>
      <StyledVideoLimitContianer isError={isErrorMessage}>
        <label> {getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.timeLimit')}</label>
        <QuestionMarkTooltip  
          text={getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.videoTimeLimitTooltip')}
          style={TOOLTIP_STYLE}
          htmlStyle={TOOLTIP_HTML_STYLE}
        />
        <DigInput 
          digValue={ maxLength.toString() }
          digOnChange={ (e) => setMaxVideoLength(parseInt(e) || 0 ) }
        />
        <StyledVideoLimitText> {getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.seconds')}</StyledVideoLimitText>
      </StyledVideoLimitContianer>
      {isErrorMessage && 
            <StyledVideoLimitErrorMessage className="neb-has-error">
              {getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.videoLimitErrorMessage')}
            </StyledVideoLimitErrorMessage>}
    </React.Fragment>
  );
};

export default MediaCaptureVideoLimit;