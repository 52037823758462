import React from 'react';
import DigButton from '@mdigital/components/dist/components/Button';
import { LABEL_TYPES } from '@mdigital/components/dist/components/Label';
import CardBoard from '../../../components/CardBoard';
import StyledAppRatingCardCollection, { StyledEmptyState } from './StyledAppRatingCardCollection';
import { CARDS_ACTION_TYPES } from '../../../../common/constants';
import { COLORS } from '../../../../common/colors';
import { CardBoardEmptyStateBackground } from '../../../assets/AppRating/base64';
import darkModeLabelIcon from '../../../../assets/images/darkModeLabel.svg';
import { getSingleText } from '../../../../common/utils';

const lockIconLabel = {
  type: LABEL_TYPES.ICON,
  text: 'lock',
  background: '#AAA',
};

const darkModeIconLabel = {
  type: LABEL_TYPES.SVG,
  svg: darkModeLabelIcon,
  digClassName: 'svg-dark-mode',
  tooltipText: getSingleText('app.pages.forms.tooltips.appRatingDarkModeLabel'),
};

const ROLE_ADMIN = 'ROLE_ADMIN';

function getActionByUserRoleAndLockedStatus(userRole, isLocked, onAction , isDarkModeProvisionEnabled, isDarkModeEnabled, isDirectApiEnabled) {
  const panelActions = [
    {
      tooltip: 'Edit',
      callback: onAction(CARDS_ACTION_TYPES.EDIT),
      icon: 'pencil',
      autLabel: 'pencil',
    },
    {
      tooltip: isLocked ? 'Unlock' : 'Lock',
      callback: onAction(CARDS_ACTION_TYPES.LOCK),
      icon: 'lock',
      autLabel: 'lock',
    },
    {
      tooltip: 'Delete',
      callback: onAction(CARDS_ACTION_TYPES.DELETE),
      icon: 'trash',
      autLabel: 'trash',
    },
    {
      tooltip: 'Translate',
      callback: onAction(CARDS_ACTION_TYPES.TRANSLATE),
      icon: 'globe',
      autLabel: 'globe',
    }
  ];

  if(isDarkModeProvisionEnabled && !isDirectApiEnabled){
    panelActions.splice(panelActions.findIndex((panel) => panel.icon.includes('fa-trash')), 0,
      {
        tooltip: `${isDarkModeEnabled ? 'Disable Dark Mode' : 'Enable Dark Mode'}`,
        callback: onAction(CARDS_ACTION_TYPES.DARK_MODE),
        icon: 'moon-o',
        autLabel: 'darkMode',
      }
    );
  }

  if (isLocked && userRole !== ROLE_ADMIN) {
    return [];
  }

  return panelActions
    .filter((action) => userRole === ROLE_ADMIN || action.icon !== 'lock')
    .filter((action) => !isDirectApiEnabled || action.icon !== 'globe');

}

function convertAppRatingResponseToCardContracts(appRatingResponse = [], userRole, onAction , isDarkModeProvisionEnabled, isDirectApiEnabled) {
  return appRatingResponse.map(({ id, name, isLocked, published , isDarkModeEnabled }) => ({
    id, name, published, isLocked, isDarkModeEnabled,
    maxActionsInRow: 3,
    cardId: id,
    labels: [].concat(isLocked ? lockIconLabel : []).concat(isDarkModeEnabled && !isDirectApiEnabled ? darkModeIconLabel : []),
    actionPanelCollection: getActionByUserRoleAndLockedStatus(userRole, isLocked, onAction , isDarkModeProvisionEnabled, isDarkModeEnabled, isDirectApiEnabled),
  }));
}

const EmptyState = ({ onAction }) => (
  <StyledEmptyState>
    <div>
      <span>
        <img src={CardBoardEmptyStateBackground} />
      </span>
      <span>
        <p className="empty-state-title-text">Prompt users to rate your app</p>
      </span>
      <span>
        <p className="empty-state-body-text">Once you start adding ratings, you’ll see them here</p>
      </span>
      <span>
        <DigButton digClassName='action-button'
          digAutLabel='actionButton'
          digOnClick={onAction(CARDS_ACTION_TYPES.CREATE)}
          digType='link'
          digIconClassName='plus-circle'
          digIconColor={null}
          digTextColor={COLORS.GREEN}
          digTextColorHover={COLORS.TEXT_HOVER}>&nbsp;&nbsp;New App Rating</DigButton>
      </span>
    </div>
  </StyledEmptyState>
);

const AppRatingCardCollection = ({ appRatings = [], userRole, onAction , isDarkModeProvisionEnabled, isDirectApiEnabled }) => (
  <StyledAppRatingCardCollection>
    {
      appRatings.length ? 
        <CardBoard cards={convertAppRatingResponseToCardContracts(appRatings, userRole, onAction , isDarkModeProvisionEnabled, isDirectApiEnabled)} onSwitchToggle={onAction(CARDS_ACTION_TYPES.PUBLISH)} /> :
        <EmptyState onAction={onAction} />
    }
  </StyledAppRatingCardCollection>
);

export default AppRatingCardCollection;