import React from 'react';
import { MediaCaptureAudioPlaceholderContainer , StyledAudioPlaceholderText } from './StyledMediaCaptureAudioPlaceholder';
import DigInput from '@mdigital/components/dist/components/Input';
import { getSingleText } from '../../../../../common/utils';
import QuestionMarkTooltip from '../../../../common/QuestionMarkTooltip';

const maxLength = 40;
const TOOLTIP_STYLE = { margin: '0px 15px 7px 7px' , color: '#666' };
const TOOLTIP_HTML_STYLE = { width: '250px' };

const MediaCaptureAudioPlaceholder = ({ audioPlaceholderText , setAudioPlaceholderText }) =>{
  return(
    <MediaCaptureAudioPlaceholderContainer>
      <StyledAudioPlaceholderText>
        {getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.placeholder')}
      </StyledAudioPlaceholderText>
      <QuestionMarkTooltip
        text={getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.audioPlaceholderTooltip')}
        style={TOOLTIP_STYLE}
        htmlStyle={TOOLTIP_HTML_STYLE}
      />
      <DigInput
        digValue={audioPlaceholderText}
        digOnChange={setAudioPlaceholderText}
        enableCounter
        maxLength={ maxLength }
      />
    </MediaCaptureAudioPlaceholderContainer>
  );
};

export default MediaCaptureAudioPlaceholder;
