import styled from 'styled-components';
import { units, typeStyle, borderRadius, color } from '@m/alchemy-ui';

export const CodeSnippedBlock = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${color('contrast')};
    border-radius: ${borderRadius('extraExtraLarge')};
    padding: ${units(3)};
    margin: ${units(1)} 0;
    position: relative;
    color: ${color('container')};
    ${typeStyle('bodyS')};
    & > pre {
        background: transparent;
        color: ${color('inverse')};
        white-space: break-spaces;
    }
`;
export const CodeSnippedButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const WarningAlert = styled.div`
    ${typeStyle('bodyL')};
    display: flex;
    padding: ${units(2)} ${units(3)};
    background: ${color('hint', { palette : 'expressiveWarning' })};
    border-radius: ${borderRadius('extraExtraLarge')};
`;
export const InstructionBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${units(1)};
    ${typeStyle('bodyS')};
    color: ${color('typeMeta')};
`;

export const InstructionLink = styled.a`
    ${typeStyle('bodyS')};
    color: ${color('typeLink')};
`;