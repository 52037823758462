import React, { useEffect, useContext } from 'react';
import { TriggerContext } from '../../../../../../..';
import TargetingBlockRow from '../../../TargetingBlockRow';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { NumberInput } from '@m/alchemy-ui';
import { TargetingActions } from '../../../../../Actions';
import { Subtitle } from '../../../../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../../../../common/utils';
import { TextPathPrefix } from '../../../../..';
import { isRuleExists, formatInputNumberNullValue } from '../../../../../../../helpers';

const TargetByApproximateDelay = () => {
  const { triggerState, triggerDispatch, isReadOnly } = useContext(TriggerContext);
  useEffect(() => {
    isRuleExists(triggerState, 'trigger.rules.MobileAppxDelay.id') ?
      triggerDispatch({ type: TargetingActions.SET_MOBILE_APPROXIMATE_IS_ACTIVE , payload : true } ) :
      triggerDispatch({ type: TargetingActions.INIT_MOBILE_APPROXIMATE_DELAY , payload : { seconds : 10 } } );
  },[]);

  return (
    <TargetingBlockRow
      checkboxLabel="Approximate delay"
      checked={getNestedProperty(triggerState, 'trigger.rules.MobileAppxDelay.isActive')}
      disabled={isReadOnly}
      onCheckboxChange={ (e) => triggerDispatch({ type: TargetingActions.SET_MOBILE_APPROXIMATE_IS_ACTIVE , payload : e.target.checked } ) }
      errorMessage={getSingleText(`${TextPathPrefix}.errors.required`)}
    >
      <React.Fragment>
        <NumberInput
          min="0" 
          formatValue={formatInputNumberNullValue}
          value={getNestedProperty(triggerState, 'trigger.rules.MobileAppxDelay.params.seconds') || 30}
          onNumberChange={ (value) => triggerDispatch({ type: TargetingActions.SET_MOBILE_APPROXIMATE_SECONDS , payload : value })} />
        <Subtitle marginLeft={1.5}>{getSingleText(`${TextPathPrefix}.rulesTexts.duration`)}</Subtitle>
      </React.Fragment>
    </TargetingBlockRow>
  );
};

export default TargetByApproximateDelay;