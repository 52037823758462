import React, { useState } from 'react';
import { FormSpy } from 'react-final-form';
import { StyledBannerPreview , StyledBannerPreviewBodyWrapper , StyledBannerPreveiwButtonWrapper } from './StyledBannerPreview';
import { MobileAndroidCustomFonts } from '../../../../../resources/assets/neb-icons/fonts/BannerPreviewFonts/mobileAndroid';
import { renderJsonStyleFile , DRAWER_TYPES } from './BannerLivePreviewHelper.js';
import LivePreviewLayout from './LivePreviewLayout';
import { LIVE_PREVIEW_TYPE } from './LivePreviewLayout';
import DarkModeSelect from '../../../DarkModeSelect';
const renderPreviewImage = (values, propertyType , flavor , isDarkEnabled) => {
  const {
    trigger: {
      triggerCustomBannerContract: {
        position, 
        isPartial, 
        invitationTitle,
        invitationBody,
        textColor,
        backgroundColor,
        font,
        customBannerButtonsSettingsContract: { 
          buttonsDisplay,
          acceptButtonText,
          acceptButtonTextColor,
          acceptButtonBackgroundColor,
          closeButtonColor,
        },
      },
    },
  } = values;
  const bannerPositionSettings = renderJsonStyleFile(flavor , propertyType)[position][isPartial ? DRAWER_TYPES.PARITAL : DRAWER_TYPES.FULL];
  const theme = {
    backgroundColor: `${isDarkEnabled ? values.mobileThemes.themeData.dark.palette.core.canvas : backgroundColor}`,
    acceptButtonBackgroundColor: `${isDarkEnabled ? values.mobileThemes.themeData.dark.palette.action.primary : acceptButtonBackgroundColor}`,
    closeButtonColor: `${isDarkEnabled ? values.mobileThemes.themeData.dark.palette.action.auxiliary : closeButtonColor}`,
    textColor: `${isDarkEnabled ? values.mobileThemes.themeData.dark.palette.typography.headline : textColor}`,
    acceptButtonTextColor: `${isDarkEnabled ? values.mobileThemes.themeData.dark.palette.typography.action.primary : acceptButtonTextColor}`,
  };
  return (
    <StyledBannerPreview bannerPositionSettings={bannerPositionSettings} font={font}>
      <MobileAndroidCustomFonts> 
        <LivePreviewLayout propertyType={propertyType} position={position} previewType={LIVE_PREVIEW_TYPE.BANNER} isDarkEnabled={isDarkEnabled}/>
        <StyledBannerPreviewBodyWrapper bannerPositionSettings={bannerPositionSettings} closeButtonColor={theme.closeButtonColor} fontColor={theme.textColor} 
          backgroundColor={theme.backgroundColor} font={font} position={position}>
          {buttonsDisplay &&  <i className="neb-icon-Close banner-preview-icon-close"/>}
          <span className="banner-preview-title"> {invitationTitle} </span>
          <span className="banner-preview-body"> {invitationBody} </span>
          {buttonsDisplay &&
          <StyledBannerPreveiwButtonWrapper bannerPositionSettings={bannerPositionSettings} 
            acceptButtonTextColor={theme.acceptButtonTextColor} acceptButtonBackgroundColor={theme.acceptButtonBackgroundColor} >
            <span className="banner-preview-button-title">{acceptButtonText}</span>        
          </StyledBannerPreveiwButtonWrapper>
          }
        </StyledBannerPreviewBodyWrapper>
      </MobileAndroidCustomFonts>
    </StyledBannerPreview>
  );

};

const BannerLivePreview = ({ propertyType, flavor , isDarkModeEnabled }) => {
  const [isDark , setIsDark] = useState(false);
  const isDarkEnabled = isDark && isDarkModeEnabled;
  return (
    <React.Fragment>
      {isDarkModeEnabled && <DarkModeSelect isDark={isDark} setIsDark={setIsDark}/>}
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) =>
          renderPreviewImage(values, propertyType, flavor , isDarkEnabled)} />
    </React.Fragment>
  );
};

export default BannerLivePreview;