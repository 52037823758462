import React from 'react';
import NotificationStyle  from './NotificationStyle.js';
const codeExampleSuccess = '//success code';
const codeExampleError = '}) { (error: MDExternalError) in';
const failureCode = '//failure code';

const Notification = () =>(
  <NotificationStyle>
    <div className="notification-container">
      <div className="notification-header">
        <p className="title">Launch your in app form from Push Notification</p>
        <p>Paste this code in the Handle Notification API</p>
      </div>
      <div className="code-example-container">
        <div className="code-example-text">
          <p>MedalliaDigital.handleNotification(“9527”, success:</p>
          <p>{codeExampleSuccess}</p>
          <p>{codeExampleError}</p>
          <p>{failureCode}</p>
          <p>{'}'}</p>
        </div>
      </div>
      <br/>
      <p>*Code snippet will only work once form is saved and published</p>
      <br/>
      <br/>
    </div>
  </NotificationStyle>
);

export default Notification;
