import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withProvider from '../../hoc/ProviderHoc';

function mapStateToProps({ app }) {
  const { isShowSurveyUpgradeModal } = app;
  return {
    isShowSurveyUpgradeModal,
  };
}

/* eslint-disable no-mixed-spaces-and-tabs */
class SurveyUpgrade extends React.PureComponent {

	static propTypes = {
	  isShowSurveyUpgradeModal: PropTypes.bool,
	};

	getSurveyUpgradeModal() {
	  <h1>SurveyUpgrade</h1>;
	}

	render() {
	  return this.props.isShowSurveyUpgradeModal && ReactDOM.createPortal(this.getSurveyUpgradeModal(), document.body);
	}
}
/* eslint-enable no-mixed-spaces-and-tabs */

export default withProvider()(connect(mapStateToProps, null)(SurveyUpgrade));
