export const autoSuggestLabelByIdFinder = (suggestions = []) => (label) => (suggestions.find((sugg) => sugg.label === label) || {}).id || label;

export function copyToClipboard(textToCopy, parent) {
  const el = document.createElement('textarea');
  const wrapper = parent || document.body;
  el.value = textToCopy;
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  wrapper.appendChild(el);
  el.select();
  document.execCommand('copy');
  wrapper.removeChild(el);
}