import React from 'react';
import StyledDeactivatedButtonSettings from './StyledDeactivatedButtonSettings';
import { DeactiatedButtonImage } from '../../../../../../assets/BannerButtonSettings/base64';

const DeactivatedButtonSettings = () => (
  <StyledDeactivatedButtonSettings>
    <img src={DeactiatedButtonImage} />
    <p>When buttons option is Off,<br />Tap gesture on the banner will take to the form Swipe gesture on banner will decline</p>
  </StyledDeactivatedButtonSettings>
);

export default DeactivatedButtonSettings;
