import React from 'react';
import PropTypes from 'prop-types';

export default function DigToolbarButton(props) {
  return (
    <div>
      {!props.isEnabled && <span className="disable-btn"></span>}
      <span className="clickable  center-table-wrap" onClick={props.onClick}>
        <i className={'fa ' + props.icon}></i>
        <span> {props.label}</span>
      </span>
    </div>
  );
}

DigToolbarButton.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
};
