import styled from 'styled-components';

export const StyledInputRow = styled.div`
  display: flex;
  padding: 10px 0 15px;

  & > h5 {
    flex: 1;
    font-size: 12px;
    color: #7B7A7A;
    margin: auto;
  }
`;

export default styled.div`
  padding-bottom: 20px;
`;
