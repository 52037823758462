import styled from 'styled-components';

export const StyledThankYouPromptLivePreview = styled.div`
        position: relative;
       font-family: ${(props) => props.defaultFontFamily};
`;

export const StyledThankYouPromptLivePreviewContainer = styled.div`
        max-height: 452px;
        width: 344px;
        background-color: ${(props) => props.backgroundColor};
        z-index: 9999;
        position: absolute;
        top: 160px;
        left: 113px;
        border-radius: ${(props) => props.borderRadius};
        padding: 10px 10px 20px 10px;
        box-shadow: 0 5px 13px rgba(0,0,0,0.1);
`;