import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import BlockableRow from '../BlockableRow';

const IS_ACTIVE_KEY = 'trigger.rules.MobileAppRatingSystemProvided.isActive';

const StyledElement = styled.div`
  input {
    width: 65px;
  }
`;
const StyledBlockableRow = styled.div`
  label{
    width: 525px;
  }
`;

const TargetBySystemProvided = () => (
  <StyledBlockableRow>
    <BlockableRow
      title="Don’t invite a visitor if the system-provided prompt was triggered for in the past"
      isActiveFieldName={IS_ACTIVE_KEY}
      labelWeight={3}
      inputWeight={4}>
      <StyledElement>
        <Field name="trigger.rules.MobileAppRatingSystemProvided.params.days" render={({ input }) => (
          <React.Fragment>
            <input {...input} type="number" /><span>&nbsp;&nbsp;Days</span>
          </React.Fragment>
        )} />
      </StyledElement>
    </BlockableRow>
  </StyledBlockableRow>
);

export default TargetBySystemProvided;
