import styled from 'styled-components';
import { units } from '@m/alchemy-ui';
import { BannerPosition } from '.';
import { PROPERTY_TYPES } from '../../../../../../../../common/constants';

export const StyledBannerPreviewContainer = styled.div `
  position: absolute;
  min-height: ${({ bannerPositions }) => bannerPositions.minHeight};
  width: ${({ bannerPositions }) => bannerPositions.width};
  left: ${({ bannerPositions }) => bannerPositions.left};
  top: ${({ position, bannerPositions }) => position === BannerPosition.TOP && bannerPositions.top};
  bottom: ${({ position, bannerPositions }) => position === BannerPosition.BOTTOM && bannerPositions.bottom};
  border-radius: ${({ isPartial, propertyType }) => isPartial && propertyType === PROPERTY_TYPES.IOS ? units(1.25) : 0 };
  text-align: center;
  font-size: ${units(2)};
  padding ${units(2)} ${units(4)} ${units(2)} ${units(4)};
  background-color :  ${({ theme }) => theme.backgroundColor };
  box-shadow: 0 5px 13px rgb(0 0 0 / 30%);

  & svg{
    position: absolute;
    top: ${({ position, isPartial }) => !isPartial && position === BannerPosition.TOP ? '50px' : '16px' };
    right: 16px;
    color: ${({ theme }) => theme.closeButtonColor };
  }
`;

export const StyledBannerPreviewTitle = styled.span`
  display: block;
  margin-top: ${({ isPartial, position }) => !isPartial && position === BannerPosition.TOP ? units(4) : '0px'}};
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ font }) => font};
  font-size: 17px;
  font-weight: 600;
`;

export const StyledBannerPreviewBody = styled.span`
  display: block;
  margin-top: 4px;
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ font }) => font};
  font-size: 15px;
`;

export const StyledBannerPreviewButtonWrapper = styled.div `
  position: relative;
  min-height: ${({ position, buttonPositions }) => position === BannerPosition.BOTTOM && buttonPositions.minHeight };
  width: ${({ buttonPositions }) => buttonPositions.width};
  right: ${({ buttonPositions }) => buttonPositions.right};
  top: ${({ buttonPositions }) => buttonPositions.top};
  border-radius: ${({ isPartial, propertyType }) => isPartial && propertyType === PROPERTY_TYPES.IOS ? `0 0 ${units(1.25)} ${units(1.25)}` : 0 };
  text-align: center;
  font-size: ${units(2)};
  padding ${units(2)};
  background-color :  ${({ theme }) => theme.acceptButtonBackgroundColor };
`;

export const StyledBannerPreviewButtonText = styled.span`
  display: block;
  color: ${({ theme }) => theme.acceptButtonTextColor};
  font-family: ${({ font }) => font};
  font-size: 17px;
  font-weight: 500;
`;