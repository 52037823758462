"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainsConfiguration = void 0;
const alchemy_ui_1 = require("@m/alchemy-ui");
const react_1 = __importStar(require("react"));
const utils_1 = require("../../../common/utils");
const store_helper_1 = require("../../../redux/store/store-helper");
const DomainCard_1 = require("./DomainCard");
const styled_1 = require("./styled");
const TextPathPrefix = 'app.pages.administration.domainsList';
const Title = `${TextPathPrefix}.title`;
const Subtitle = `${TextPathPrefix}.subtitle`;
const AllDomains = `${TextPathPrefix}.allDomains`;
const PredefinedDomains = `${TextPathPrefix}.predefinedDomains`;
const SubtitleDHH = `${TextPathPrefix}.subtitleDHH`;
const approvedDomainsVariants = [{
        key: 'all',
        label: (0, utils_1.getSingleText)(AllDomains),
    }, {
        key: 'selected',
        label: (0, utils_1.getSingleText)(PredefinedDomains),
    }];
const DomainsConfiguration = ({ domainsSetup, updateDomainsSetup }) => {
    const [filteredDomainList, setFilteredDomainList] = (0, react_1.useState)([]);
    const [searchValue, setSearchValue] = (0, react_1.useState)('');
    const isDHH = (0, store_helper_1.checkProvision)(DigProvisions.DYNAMIC_HYBRID_HOSTING);
    const CardSubtitle = react_1.default.createElement("div", null,
        (0, utils_1.getSingleText)(Subtitle),
        " ",
        react_1.default.createElement("br", null),
        " ",
        isDHH && (0, utils_1.getSingleText)(SubtitleDHH));
    (0, react_1.useEffect)(() => {
        let domains = domainsSetup.allowedDomains;
        if (searchValue != null) {
            domains = domains.filter((domain) => domain.domainName.toLowerCase().indexOf(searchValue.toLowerCase().trim()) !== -1);
        }
        setFilteredDomainList(domains);
    }, [domainsSetup, searchValue]);
    const filterDomainList = (e) => {
        const value = e.target.value;
        setSearchValue(value);
    };
    const addDomain = (item) => {
        const newItem = Object.assign(Object.assign({}, item), { domainName: item.domainName.toLowerCase() });
        updateDomainsSetup(Object.assign(Object.assign({}, domainsSetup), { allowedDomains: [newItem, ...domainsSetup.allowedDomains] }));
        setSearchValue('');
    };
    const removeDomain = (item) => {
        const filteredDomains = domainsSetup.allowedDomains.filter((domain) => domain.lastModifiedTimestamp !== item);
        updateDomainsSetup(Object.assign(Object.assign({}, domainsSetup), { allowedDomains: filteredDomains }));
    };
    const updateDomain = (item, newValue) => {
        const editedDomain = {
            domainName: newValue.toLowerCase(),
            lastModifiedTimestamp: Date.now(),
        };
        const filteredDomains = domainsSetup.allowedDomains.filter((domain) => domain.lastModifiedTimestamp !== item.lastModifiedTimestamp);
        updateDomainsSetup(Object.assign(Object.assign({}, domainsSetup), { allowedDomains: [editedDomain, ...filteredDomains] }));
    };
    const domainsSelectionChange = (selection) => {
        updateDomainsSetup(Object.assign(Object.assign({}, domainsSetup), { allDomainsAllowed: selection === 'all' }));
    };
    return (react_1.default.createElement(styled_1.StyledDomainsConfigurationContent, null,
        react_1.default.createElement(styled_1.FullHeightVerticalStack, { gap: (0, alchemy_ui_1.units)(2) },
            react_1.default.createElement(styled_1.StaticHeightCard, { height: 150 },
                react_1.default.createElement(alchemy_ui_1.CardHeader, { title: (0, utils_1.getSingleText)(Title), subtitle: CardSubtitle }),
                react_1.default.createElement(alchemy_ui_1.CardBody, null,
                    react_1.default.createElement(alchemy_ui_1.RadioGroup, { layout: "row", selection: domainsSetup.allDomainsAllowed ? 'all' : 'selected', onChange: (selection) => domainsSelectionChange(selection) }, ({ isSelected, handleChange }) => approvedDomainsVariants.map((item) => (react_1.default.createElement(alchemy_ui_1.Radio, { key: item.key, label: item.label, name: "disabled-group", checked: isSelected(item.key), onChange: () => handleChange(item.key) })))))),
            !domainsSetup.allDomainsAllowed && react_1.default.createElement(DomainCard_1.DomainCard, { domains: filteredDomainList, totalDomainsCount: domainsSetup.allowedDomains.length, filterDomain: filterDomainList, addDomain: addDomain, removeItem: removeDomain, updateDomain: updateDomain, searchedValue: searchValue }))));
};
exports.DomainsConfiguration = DomainsConfiguration;
