import React from 'react';
import { getSingleText } from '../../../../common/utils';
import { StyledSdkIntegrationV2Footer , StyledSdkIntegrationV2FooterButtons } from './StyledSdkIntegrationV2ModalFooter';
import Button from '@mdigital/components/dist/components/Button';

const SdkIntegrationV2ModalFooter = ({ isCopied , onClose , sdkDocsLink , apiToken , copyApiTokenToClipboard }) => (
  <StyledSdkIntegrationV2Footer>
    <a className="sdk-integration-v2-help" target="_blank" href={sdkDocsLink}>
      <i className="neb-icon-help sdk-integration-v2-footer-modal-icon" />
      <span className="sdk-integration-v2-footer-help">
        {getSingleText('app.topMenu.sdkV2.help')}
      </span>
    </a>

    <StyledSdkIntegrationV2FooterButtons>
      {apiToken ? 
        <Button digType="primary" digClassName="sdk-integration-v2-footer-copy-button" digOnClick={ copyApiTokenToClipboard }>
          { isCopied ? getSingleText('app.topMenu.sdkV2.copied') : getSingleText('app.topMenu.sdkV2.copyToken') }
        </Button>
        :  <Button digType="primary" digClassName="sdk-integration-v2-footer-copy-button" digOnClick={onClose}>
          {getSingleText('app.topMenu.sdkV2.ok')}
        </Button>}
  
    </StyledSdkIntegrationV2FooterButtons>
  </StyledSdkIntegrationV2Footer>
);
  
export default SdkIntegrationV2ModalFooter;