import React from 'react';
import PropTypes from 'prop-types';
import FormSettingsField from './FormSettingsField';
import Input from '@mdigital/components/dist/components/Input';
import ColorPicker from '@mdigital/components/dist/components/ColorPicker';
import { StyledFormSettingsTitle , FormSettingsHeaderSpeartor } from './StyledFormBasicSettings';
import { getSingleText } from '../../../common/utils';

const SubmitButtonSettings = ( { isPublished } ) => {
  return (
    <React.Fragment>
      <FormSettingsHeaderSpeartor/>
      <StyledFormSettingsTitle>{getSingleText('app.pages.forms.formBasicSettings.submitButton')}</StyledFormSettingsTitle>
      <FormSettingsField
        name="formSettings.settings.formBasicSettings.submitButtonLabel"
        title="Submit Label"
        isDisabled={isPublished}
        render={({ input }) => (
          <Input digValue={input.value} digOnChange={input.onChange} />
        )}/>

      <FormSettingsField
        name="formSettings.settings.formBasicSettings.submitButtonTextColor"
        title="Text Color"
        isDisabled={isPublished}
        render={({ input }) => (
          <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
        )}/>

      <FormSettingsField
        name="formSettings.settings.formBasicSettings.submitButtoncolor"
        title="Button Color"
        isDisabled={isPublished}
        className="submit-button-section"
        render={({ input }) => (
          <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
        )}/>
    </React.Fragment>
  );
};

export default SubmitButtonSettings;

SubmitButtonSettings.propTypes = {
  isPublished: PropTypes.bool,
};

SubmitButtonSettings.defaultProps = {
  isPublished: false,
};