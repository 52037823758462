import React, { Component } from 'react';
import withModal from '@mdigital/components/dist/hoc/ModalHoc';
import { getSingleText } from '../../../common/utils';
import WarningSign from '../../components/WarningSign/WarningSign';
import DigButton from '@mdigital/components/dist/components/Button';
import { TextWrapper,IconAndTextWrapper,ContentWrapper,SystemUpdateWrapper } from  './FreezModeStyles';
import { LOGOUT_BUTTON } from '../../../common/constants';

const customButton  = [{
  button: <DigButton digType="primary" data-aut="ok" digClassName="primary">{getSingleText('app.freezmode.systemUpdateModal.textButton')}</DigButton>,
  id:LOGOUT_BUTTON,
}];

const basicModalProps = {
  title: getSingleText('app.freezmode.systemUpdateModal.title'),
  footerButtons:customButton,
  persistentLock:true,
  modalClassName:'system-warning-modal',
};

@withModal(basicModalProps)
export default class SystemUpdateModal extends Component {

  render(){
    return (
      <SystemUpdateWrapper>
        <IconAndTextWrapper>
          <div className="icon-container">
            <WarningSign size={18}/>
          </div>
          <ContentWrapper>
            <TextWrapper first>{getSingleText('app.freezmode.systemUpdateModal.header')}</TextWrapper>
            <TextWrapper bold>{getSingleText('app.freezmode.systemUpdateModal.subHeader')}</TextWrapper>
            <TextWrapper>{getSingleText('app.freezmode.systemUpdateModal.body')}</TextWrapper>
          </ContentWrapper>
        </IconAndTextWrapper>
      </SystemUpdateWrapper>
    );
  }
}


