import React from 'react';
import PropTypes from 'prop-types';
import IconSvg from '@mdigital/components/dist/components/IconSvg';
import { Form, Field } from 'react-final-form';
import { LOGIN_VIEWS } from '../Login';
import { getSingleText } from '../../../../common/utils';
import * as authActions from '../../../../redux/actions/auth.actions';
import { WHITE } from '../../../common/colors';
import BackArrow from '../../../assets/Login/BackArrow.svg';

class ForgotPassword extends React.Component{
  state = { isEmailSent : false };

  handleForgotPassword = ({ email }) => {
    if(email){
      this.setState({ isEmailSent : true });
    }
    this.props.onSubmit({ email },
      getSingleText('app.pages.mdLogin.requiredMessage'),
      authActions.forgotPassword);
  };

  render() {
    return (
      <Form
        onSubmit={this.handleForgotPassword}
        render={({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="md-sub-title md-sans-semibold">
              { getSingleText('app.pages.mdLogin.forgotPassword.description') }
            </div>
            <Field
              className="md-login-form-field"
              name="email"
              component="input"
              type="text"
              placeholder="Email" />
            { this.state.isEmailSent ?
              <div className="md-sub-title md-sans-semibold md-forgot-confirmation">
                {getSingleText('app.pages.mdLogin.forgotPassword.forgotMessage')}
              </div> :
              <button className='md-login-btn' type="submit" disabled={submitting}>
                {getSingleText('app.pages.mdLogin.forgotPassword.continue')}
              </button>
            }
            <button className='md-login-step-btn md-forgot-step md-sans-semibold'
              onClick={() => this.props.onBackClick(LOGIN_VIEWS.LOGIN)}>
              <IconSvg className='md-back-arrow' icon={BackArrow} width={14} height={10} isStatic={true} color={WHITE}/>
              { getSingleText('app.pages.mdLogin.forgotPassword.navigateToLogin') }
            </button>
          </form>
        )}
      />);
  }
}

export default ForgotPassword;

ForgotPassword.propTypes = {
  onBackClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

