import React, { Fragment } from 'react';
import WarningSign from '../../components/WarningSign';
import withProvider from '../../hoc/ProviderHoc';
import { StyledNotificationWrapper } from './StyledAppNotification';
import { connect } from 'react-redux';
import { getAppNotification } from './selectors';
import { BANNER_TYPES } from '../../../common/constants';

const COMMAND_CENTER_CSS_LINK = './react/components/AppNotification/appNotification.css';

function AppNotification(props) {
  const appNotification = props.appNotification;

  if (!(appNotification && appNotification.component && appNotification.type)) {
    return null;
  }

  const Content = appNotification.component;

  function renderRelevantBanner() {
    switch (appNotification.type) {
      case BANNER_TYPES.WARNING:
        return <Fragment>
          <WarningSign/>
          <Content {...props}/>
        </Fragment>;
      case BANNER_TYPES.ANNOUNCEMENT:
        return <Content {...props}/>;
      default:
        return null;
    }
  }

  return (
    <React.Fragment>
      <link rel={'stylesheet'} type={'text/css'} href={COMMAND_CENTER_CSS_LINK}/>
      <StyledNotificationWrapper type={appNotification.type} id="app-notification">
        {renderRelevantBanner()}
      </StyledNotificationWrapper>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    appNotification: getAppNotification(state),
  };
}

export default withProvider()(connect(mapStateToProps, {})(AppNotification));
