import React, { useContext } from 'react';
import { HelpIcon, NumberInput, Radio, RadioGroup, Select, Tooltip } from '@m/alchemy-ui';
import { TextPathPrefixLocalNotification } from '..';
import { PanelSubHeader, PanelTooltip } from '../../../../../StyledTriggeringModal';
import { StyleExpirationOption, StyleExpirationRadio } from './StyleExpiration';
import { getSingleText } from '../../../../../../../../common/utils';
import { TargetingActions } from '../../../Actions';
import { TriggerContext } from '../../../../../index';
import { Separator } from '../../../StyledTriggerByTargeting';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { AlchemyFiledRequiredDot } from '../LogoImage/StyledLogoImage';

const RadioItem = {
  NO_EXPIRATION: 'No expiration',
  EXPIRES_IN: 'Expires in',
};

export const expirationTimeOptions = [
  { value: 'SECONDS', label: 'Seconds' },
  { value: 'MINUTES', label: 'Minutes' },
  { value: 'HOURS', label: 'Hours' }
];

export const Expiration = () => {
  const { triggerState, triggerDispatch, isReadOnly } = useContext(TriggerContext);
  const expirationItems = [RadioItem.NO_EXPIRATION, RadioItem.EXPIRES_IN];
  const isExpired = getNestedProperty(triggerState, 'trigger.triggerCustomLocalNotificationContract.expiration.isExpired');
  const selectionRadio = isExpired ? expirationItems[1] : expirationItems[0];

  return (
    <React.Fragment>
      <PanelTooltip>
        <PanelSubHeader><AlchemyFiledRequiredDot/>{getSingleText(`${TextPathPrefixLocalNotification}.expiration.expirationTitle`)}</PanelSubHeader>
        <Tooltip
          trigger={
            <HelpIcon size="12" palette="structural" />
          }
          placement="right"
          contents={getSingleText(`${TextPathPrefixLocalNotification}.expiration.expirationTooltip`)}
        />
      </PanelTooltip>

      <RadioGroup selection={selectionRadio} onChange={ ()=> triggerDispatch({ type : TargetingActions.SET_IS_EXPIRED , payload : !isExpired })}>
        {({ isSelected, handleChange }) =>
          expirationItems.map((item, index) => (
            <StyleExpirationRadio key={index}>
              <Radio
                key={item}
                label={item}
                checked={isSelected(item)}
                onChange={() => handleChange(item)}
                disabled={isReadOnly}
              />
              {item === RadioItem.EXPIRES_IN &&
                <StyleExpirationOption>
                  <React.Fragment>
                    <NumberInput
                      type="number" 
                      min="1" 
                      max="60"
                      disabled={ !getNestedProperty(triggerState, 'trigger.triggerCustomLocalNotificationContract.expiration.isExpired') || isReadOnly}
                      value={ getNestedProperty(triggerState, 'trigger.triggerCustomLocalNotificationContract.expiration.value')}
                      onNumberChange={(value) => triggerDispatch({ type: TargetingActions.SET_EXPIRATION_VALUE, payload:  value })}/>
                    <Select
                      options={expirationTimeOptions}
                      disabled={ !getNestedProperty(triggerState, 'trigger.triggerCustomLocalNotificationContract.expiration.isExpired') || isReadOnly}
                      value={ getNestedProperty(triggerState, 'trigger.triggerCustomLocalNotificationContract.expiration.unit')}
                      onChange={(selected) => triggerDispatch({ type: TargetingActions.SET_EXPIRATION_UNIT, payload: selected.value })}
                    />
                  </React.Fragment>
                </StyleExpirationOption>
              }
            </StyleExpirationRadio>
          ))
        }
      </RadioGroup>
      <Separator />
    </React.Fragment>
  );
};