import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormViewContainer from './FormViewContainer';
import FoldersContainer from '../FormFolders/FoldersContainer';
import { checkProvision } from '../../../redux/store/store-helper';
import { COLORS } from '../../../common/colors';
import withProvider from '../../hoc/ProviderHoc';

const StyledContainer = styled.div `
	display: flex;
	flex-direction: row-reverse;
	user-select: none;
	.forms-container {
		min-width: 0;
		width: 100%;
	}
  .left-section {
		width: auto;
        min-width: ${() => checkProvision(DigProvisions.ENABLE_SURVEY_DRAFTS) ? 240 : 327}px;
		.folders-container {
			position: fixed;
			width: auto;
			min-width: inherit;
			max-width: 200px;
			height: 94%;
    	border-right: 1px solid ${COLORS.LIST_BORDER};
		}
	}
`;

@withProvider()
export default class FormView extends React.Component {
  render() {
    const isFormFoldersEnabled = checkProvision(DigProvisions.FORM_FOLDERS);
    const formViewProps = Object.assign({ isFormFoldersEnabled: isFormFoldersEnabled }, this.props);
    return (
      <StyledContainer>
        <div className='forms-container'>
          <FormViewContainer {...formViewProps} checkProvision={checkProvision} spinner={this.props.spinner}/>
        </div>
        {isFormFoldersEnabled &&
          <div className='left-section'>
            <div className='folders-container'>
              <FoldersContainer spinner={this.props.spinner}/>
            </div>
          </div>
        }
      </StyledContainer>
    );
  }
}

FormView.propTypes = {
  actions: PropTypes.func,
  createForm: PropTypes.func,
  publishUnpublish: PropTypes.func,
  labels: PropTypes.func,
  spinner:PropTypes.object,
};
