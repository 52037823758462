import styled from 'styled-components';
import { BannerPositionType } from './BannerLivePreviewHelper';

export const StyledBannerPreview = styled.div`
	position: relative;
	font-family : ${(props) => props.font};
		.banner-preview-img{
			border: 1px solid;
		}
`;

export const StyledBannerPreviewBodyWrapper = styled.div `
	display: flex;
	flex-direction : column;
	background-color :  ${(props) => (props.backgroundColor)};
	width : 100%;
	z-index : 3;
	min-height: ${(props) => props.bannerPositionSettings.BodyWrapper.height};
	height : auto;
	position: absolute;
    width: ${(props) => props.bannerPositionSettings.BodyWrapper.width};
	right: ${(props) => props.bannerPositionSettings.BodyWrapper.right};
	${(props) => props.position === BannerPositionType.TOP ? 
    `top: ${props.bannerPositionSettings.BodyWrapper.top}` : `bottom: ${props.bannerPositionSettings.BodyWrapper.bottom}` };
    border-radius: ${(props) => props.bannerPositionSettings.BodyWrapper.borderRadius};
    box-shadow: 0 5px 13px rgba(0, 0, 0, 0.3);
	
	.banner-preview-title{
		margin-left: ${(props) => props.bannerPositionSettings.previewTitle.left};
		margin-top: ${(props) => props.bannerPositionSettings.previewTitle.top};
		width: 293px;
		border: none;
		font-weight: 600;
		font-size: 17px;
		color: ${(props) => (props.fontColor)};
		z-index: 4;
		text-align: center;
		background-color :  ${(props) => (props.backgroundColor)};
	}
	.banner-preview-body{
		font-size: 15px;
		border: none;
		margin-bottom: 16px;
		margin-top: 4px;
		margin-left: ${(props) => props.bannerPositionSettings.previewBody.left};
		width: 301px;
		text-align: center;
		color: ${(props) => (props.fontColor)};
		z-index: 4;
        background-color :  ${(props) => (props.backgroundColor)};
        line-height : 19px;
	}

	.banner-preview-icon-close{
		position: absolute;
		top : ${(props) => props.bannerPositionSettings.iconClose.top};
		right: ${(props) => props.bannerPositionSettings.iconClose.right};
		z-index: 4;
		font-size: 12px;
		color: ${(props)=>props.closeButtonColor};
	}
`;

export const StyledBannerPreveiwButtonWrapper = styled.div `
	background-color : ${(props) => (props.acceptButtonBackgroundColor)};
    width: ${(props) => props.bannerPositionSettings.BodyWrapper.width};
	z-index: 5;
    height: ${(props) => props.bannerPositionSettings.buttonWrapper.height};
    border-bottom-left-radius: ${(props) => props.bannerPositionSettings.BodyWrapper.borderRadius};;
    border-bottom-right-radius: ${(props) => props.bannerPositionSettings.BodyWrapper.borderRadius};;
    display: flex;
    align-items: center;
	justify-content: center;
	.banner-preview-button-title{
		color: ${(props) => (props.acceptButtonTextColor)};
		margin-bottom : ${(props) => props.bannerPositionSettings.buttonWrapper.bottom};
		border: none;
		background-color : ${(props) => (props.acceptButtonBackgroundColor)};
		text-align : center;
        font-size: 17px;
        font-weight: 500;
		width: 250px;
	}
	.banner-preview-button-title:focus{
		background-color : ${(props) => (props.acceptButtonBackgroundColor)} !important;
    }
`;