import React, { useContext, useEffect, useRef, useState } from 'react';
import { StyledBackgroundImage, StyledDeviceImage, StyledPreviewContainer, StyledAlertBackground, bannerBackgroundPosition } from '../StyledTargetingPreview';
import { Preview } from '../../../../../../assets/TriggeringModal/Preview';
import { TriggerContext } from '../../../../index';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import BannerPreview from './BannerPreview';
import AlertPreview from './AlertPreview';
import DarkModeSwitcher from '../../TargetingTypes/DarkModeSwitcher';
import { getPropertyType } from '../../../../../../../redux/store/store-helper';
import { TARGETING_TYPES } from '../../TargetingTypes';
import { LocalNotificationPreview } from './LocalNotificationPreview';
import { TriggerByTargetingAppMode } from '../..';

const LivePreviewTypes = {
  [TARGETING_TYPES.BANNER]: BannerPreview,
  [TARGETING_TYPES.ALERT]: AlertPreview,
  [TARGETING_TYPES.LOCAL_NOTIFICATION]: LocalNotificationPreview,
};

const LivePreview = () => {
  const { triggerState, isDarkModeEnabled } = useContext(TriggerContext);
  const previewTopContainerRef = useRef(null);
  const previewBottomContainerRef = useRef(null);


  const propertyType = getPropertyType();
  const [isDark, setIsDark] = useState(false);
  const {
    trigger: {
      invitationType,
      triggerScope,
      triggerCustomBannerContract: {
        position,
      },
    } } = triggerState;

  useEffect(() => {
    if (previewBottomContainerRef.current && previewTopContainerRef.current) {
      if (position === 'BOTTOM') {
        previewBottomContainerRef.current.scrollIntoView({ behavior: 'smooth' });
      } else  {
        previewTopContainerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [position]);

  const pathToImageBackground = `Background.${position}`;
  const pathToImageDevice = `Device.${propertyType}.${position}`;
  const backgroundPosition = bannerBackgroundPosition[propertyType];
  const pathToImageBackgroundLN = `${triggerScope}.${propertyType}`;

  const renderBackgroundImageByInviteType = () => {
    switch (invitationType) {
      case TARGETING_TYPES.BANNER:
        return <StyledBackgroundImage 
          backgroundPosition={backgroundPosition} 
          position={position} 
          src={getNestedProperty(Preview.Banner, pathToImageBackground)}/>;
      case TARGETING_TYPES.ALERT:
        return <StyledAlertBackground propertyType={propertyType}/>;
      case TARGETING_TYPES.LOCAL_NOTIFICATION:
        return triggerScope === TriggerByTargetingAppMode.FOREGROUND ?  
          <StyledBackgroundImage 
            backgroundPosition={backgroundPosition} 
            position={position} 
            src={getNestedProperty(Preview.Banner, 'Background.TOP')}/> :
          <React.Fragment/> ;
    }
  };

  const renderDeviceImageByInviteType = () => {
    switch (invitationType) {
      case TARGETING_TYPES.BANNER:
        return <StyledDeviceImage src={getNestedProperty(Preview.Banner, pathToImageDevice)}/>;
      case TARGETING_TYPES.ALERT:
        return <StyledDeviceImage src={getNestedProperty(Preview.Banner, pathToImageDevice)}/>;
      case TARGETING_TYPES.LOCAL_NOTIFICATION:
        return triggerScope === TriggerByTargetingAppMode.FOREGROUND ? 
          <StyledDeviceImage src={getNestedProperty(Preview.Banner, pathToImageDevice)}/> :
          <StyledDeviceImage src={getNestedProperty(Preview.LocalNotifications, pathToImageBackgroundLN)}/>;
    }
  };

  const renderPreviewByType = () => {
    const LivePreviewComponent = LivePreviewTypes[invitationType];
    return <LivePreviewComponent isDarkEnabled={isDark && isDarkModeEnabled}/>;
  };

  return (
    <React.Fragment>
      {isDarkModeEnabled && <DarkModeSwitcher isDark={isDark} setIsDark={setIsDark}/>}
      <StyledPreviewContainer  position={position}>
        <div ref={previewTopContainerRef}/>
        {renderBackgroundImageByInviteType()}
        {renderDeviceImageByInviteType()}
        {renderPreviewByType()}
        <div ref={previewBottomContainerRef}/>
      </StyledPreviewContainer>
    </React.Fragment>
  );
};

export default LivePreview;