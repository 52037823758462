import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import Checkbox from '@mdigital/components/dist/components/Checkbox';
import {
  StyledThankYouImageResourceSelection,
  StyledThankYouImageEnablementCheckbox,
  StyledThankYouImageResourceSelectionWrapper
} from './StyledThankYouPromptGeneralSettingsImageReusableResourceSelect';
import ResourcesSelect from '../../../../ResourcesSelect';
import { getSingleText } from '../../../../../../common/utils';
import { setThankYouPromptImage } from '../../utils';

const SUPPORTED_SELECTION_TYPES = ['png', 'jpg', 'jpeg', 'gif'];

const ImageReusableReourcesSelect = (props) => {
  const { value, mutators , isDarkModeImage } = props;
  return (
    <FormSpy subscription={{ values: true }} render={({ values }) => (
      <StyledThankYouImageResourceSelectionWrapper disabled={values.trigger.sameDarkModeImageAsLight && isDarkModeImage}>
        <StyledThankYouImageEnablementCheckbox>
          <Field name="trigger.isImageDisplay" render={({ input }) => (
            <Checkbox 
              label="Image"
              digIsChecked={input.value}
              handleCheckboxChange={input.onChange}
              info={getSingleText('app.pages.forms.builder.formSettingsModal.thankYouPrompt.imageInfo')}
              isDisabled={values.trigger.sameDarkModeImageAsLight && isDarkModeImage} />
          )} />
        </StyledThankYouImageEnablementCheckbox>
        <StyledThankYouImageResourceSelection>
          <ResourcesSelect 
            resourceId={value} 
            placeholder="Select image from library" 
            isDisabled={!values.trigger.isImageDisplay} 
            types={SUPPORTED_SELECTION_TYPES} 
            onSelect={(e) => setThankYouPromptImage(isDarkModeImage, values.trigger.sameDarkModeImageAsLight, mutators.setThankYouPromptImageResourceData, mutators.setThankYouPromptDarkImageResourceData, e)}/>
        </StyledThankYouImageResourceSelection>
      </StyledThankYouImageResourceSelectionWrapper>
    )} />
  );
};

ImageReusableReourcesSelect.propTypes = {
  mutators: PropTypes.object,
  value: PropTypes.any,
  isDarkModeImage: PropTypes.bool,
};

ImageReusableReourcesSelect.defaultProps = {
  mutators: {},
  value: '',
  isDarkModeImage: false,
};

export default ImageReusableReourcesSelect;
