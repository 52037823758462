import React, { useContext } from 'react';
import { StyledTargetingSettings, StyledTargetingSettingsBody, Separator } from './StyledTargeting';
import { Header, SubHeader, Subtitle, PanelSubHeader } from '../../../../../StyledTriggeringModal';
import { TextPathPrefix, TriggerByTargetingAppMode } from '../../..';
import { getSingleText } from '../../../../../../../../common/utils';
import TargetByPercentile from './TargetingRules/TargetByPercentageOfVisitors';
import TargetByNumberOfSessions from './TargetingRules/TargetByNumberOfSessions';
import TargetByTimeInForeground from './TargetingRules/TargetByTimeInForeground';
import TargetByAppVersion from './TargetingRules/TargetByAppVersion';
import TargetByOSVersion from './TargetingRules/TargetByOSVersion';
import TargetByCustomRule from './TargetingRules/TargetByCustomRule';
import TargetByLastTimeInForeground from './TargetingRules/TargetByLastTimeInForeground';
import TargetByDidntVisitApp from './TargetingRules/TargetByDidntVisitApp';
import TargetByApproximateDelay from './TargetingRules/TargetByApproximateDelay';
import { checkProvision } from '../../../../../../../../redux/store/store-helper';
import { TriggerContext } from '../../../../..';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';

const Targeting = () => {
  const { triggerState } = useContext(TriggerContext);

  const isBackgroundAppMode = () => {
    const triggerScope = getNestedProperty(triggerState, 'trigger.triggerScope');
    const isBackgroundAppState = triggerScope === TriggerByTargetingAppMode.BACKGROUND;
    const isLocalNotificationsEnabled = checkProvision(DigProvisions.MOBILE_SDKV2_LOCAL_Notifications);
    return isBackgroundAppState && isLocalNotificationsEnabled;
  };
  
  return (
    <StyledTargetingSettings>
      <Header>{getSingleText(`${TextPathPrefix}.targetingSettingsHeader`)}</Header>
      <StyledTargetingSettingsBody>
        <SubHeader marginBottom={0.5}>{getSingleText(`${TextPathPrefix}.targetingSettingsSubHeader`)}</SubHeader>
        <Subtitle marginBottom={3}>{getSingleText(`${TextPathPrefix}.targetingSettingsBody`)}</Subtitle>
        <PanelSubHeader>{getSingleText(`${TextPathPrefix}.whoToTarget`)}</PanelSubHeader>
        <TargetByPercentile/>
        <TargetByNumberOfSessions/>
        <Separator/>
        <PanelSubHeader>{getSingleText(`${TextPathPrefix}.whenToTrigger`)}</PanelSubHeader>
        {isBackgroundAppMode() ?
          <React.Fragment>
            <TargetByLastTimeInForeground/>
            <TargetByDidntVisitApp/>
            <TargetByApproximateDelay/>
          </React.Fragment>
          :
          <TargetByTimeInForeground/>
        }
        <Separator/>
        <PanelSubHeader>{getSingleText(`${TextPathPrefix}.whereToTrigger`)}</PanelSubHeader>
        <TargetByAppVersion/>
        <TargetByOSVersion/>
        <Separator/>
        <PanelSubHeader>{getSingleText(`${TextPathPrefix}.custom`)}</PanelSubHeader>
        <TargetByCustomRule/>
      </StyledTargetingSettingsBody>
    </StyledTargetingSettings>
  );
};
export default Targeting;