import React from 'react';
import { Field } from 'react-final-form';
import { isRequired } from '../../../common/utils/fieldValidators';
import { StyledAlertInput, StyledAlertPromptField, StyledAlertPromptLabel } from './StyledAlert';

const AlertInputClassnames = (isNativeAlertRevamp , required)=>{
  const isRequired = required ? 'required-field ' :  '';
  const isAlertRevamp = isNativeAlertRevamp ? 'alert-revamp' : '';
  return isRequired + isAlertRevamp;
};

const AlertInputComponent = ({ name, title, errorMessage, required, isNativeAlertRevamp, render, disabled }) => (
  <React.Fragment>
    <Field name={name} parse={(value) => (value)} validate={required ? isRequired : null} render={({ input, meta: { error } }) => (
      <StyledAlertInput className={AlertInputClassnames(isNativeAlertRevamp , required)}>
        <StyledAlertPromptLabel>
          <label>{title}</label>
        </StyledAlertPromptLabel>
        <StyledAlertPromptField className={error && 'has-error'} disabled={disabled}>
          {render({ input , error })}
          <span className="error-message">{errorMessage}</span>
        </StyledAlertPromptField>
      </StyledAlertInput>
    )} />
    <br/>
  </React.Fragment>
);

export default AlertInputComponent;