import styled from 'styled-components';

export default styled.div`
  display: flex;
  fflex-direction: column;
  background: #E2E5E5;
  border-top: 1px solid #d6d7d7;
  padding: 10px 20px;
  
  .wizard-modal-footer-content {
    flex: 1;
  }
  
  .wizard-modal-footer-buttons {
    button {
      margin-left: 8px;
    }
  }
  .wizard-modal-footer-help-link {
    position: absolute;
    bottom: 18px;
  }
`;