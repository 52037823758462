import React from 'react';
import { FormSpy } from 'react-final-form';
import { StyledThankYouPromptLivePreviewBody , StyledThankYouPromptLivePreviewHeaderContent 
  ,StyledThankYouPromptLivePreviewBodyContent , StyledThankYouPromptLivePreviewBodyImage } from './StyledThankYouPromptLivePreviewBody';
import Button from '@mdigital/components/dist/components/Button';
import imagePlaceHolderLightMode from '../../../../assets/ThankYouPromptAssets/base64/livePreview/ThankYouPromptImagePlaceHolder.base64';
import imagePlaceHolderDarkMode from '../../../../assets/ThankYouPromptAssets/base64/livePreview/ThankYouPromptImagePlaceHolderDarkMode.base64';

const parseHyperLinkValue = (value)=> value && value.replace(/&lta/gi,'<a').replace(/&lt\/a/gi,'</a').replace(/&gt/gi,'>').replace(/href/g, "target='_blank' href");

const renderThankYouPromptLivePreviewBody = ( values , propertyType, isDarkEnabled ) => {
  const {
    trigger: {
      isImageDisplay,
      mobileThankYouPromptGeneralSettingsSectionContract: {
        bodyContent,
        promptContentFontColor,
        promptContentFontType,
        textContent,
      },
      mobileThankYouPromptButtonSectionContract: { 
        buttonColor,
        buttonDisplayed,
        buttonText,
        buttonTextColor,
      },
      mobileThankYouPromptImageDataContract,
      mobileThankYouPromptDarkImageDataContract,
    },
  } = values;
  const theme = {
    buttonColor: `${isDarkEnabled ? values.mobileThemes.themeData.dark.palette.action.primary : buttonColor}`,
    promptContentFontColor: `${isDarkEnabled ? values.mobileThemes.themeData.dark.palette.typography.headline : promptContentFontColor}`,
    buttonTextColor: `${isDarkEnabled ? values.mobileThemes.themeData.dark.palette.typography.action.primary : buttonTextColor}`,
  };
  const imagePlaceHolder = isDarkEnabled ? imagePlaceHolderDarkMode : imagePlaceHolderLightMode;
  const imageUrl = isDarkEnabled ? mobileThankYouPromptDarkImageDataContract.url : mobileThankYouPromptImageDataContract.url;
  return (
    <StyledThankYouPromptLivePreviewBody promptContentFontType={promptContentFontType} buttonColor={theme.buttonColor} buttonTextColor={theme.buttonTextColor}
      buttonWidth={propertyType === 'mobileIOS' ? '300px' : '90px'} buttonHeight={propertyType === 'mobileIOS' ? '40px' : '36px'}>
      {isImageDisplay && <StyledThankYouPromptLivePreviewBodyImage src={imageUrl ? imageUrl : imagePlaceHolder} />}
        
      <StyledThankYouPromptLivePreviewHeaderContent dangerouslySetInnerHTML={{ __html: parseHyperLinkValue(textContent) }} 
        promptContentFontColor={theme.promptContentFontColor}/>
      <StyledThankYouPromptLivePreviewBodyContent dangerouslySetInnerHTML={{ __html: parseHyperLinkValue(bodyContent) }} 
        promptContentFontColor={theme.promptContentFontColor}/>
      {buttonDisplayed && <Button digType={'default'} text={buttonText} digClassName={'thank-you-prompt-body-button'} /> }
    </StyledThankYouPromptLivePreviewBody>
  );
};

const ThankYouPromptLivePreviewBody = ({ propertyType, isDarkEnabled }) => (
  <React.Fragment>
    <FormSpy subscription={{ values: true }} render={({ values }) =>
      renderThankYouPromptLivePreviewBody(values, propertyType, isDarkEnabled )} />
  </React.Fragment>
);

export default ThankYouPromptLivePreviewBody;