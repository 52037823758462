import styled from 'styled-components';
import { typeStyle, units } from '@m/alchemy-ui';

export const StyledSelectionPageContainer = styled.div`
    .card-group{
        justify-content: center;
        margin-top: ${units(6)};
    }
`;

export const StyledSelectionPageText = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${units(6)};
    text-align: center;
`;

export const StyledSelectionPageHeader = styled.span`
    ${typeStyle('headerM')};
`;

export const StyledSelectionPageSubHeader = styled.span`
    ${typeStyle('bodyS')};
`;