import { ModalsService } from '@mdigital/components/dist/utils/modals';
import { CustomDialog } from '../StyledTriggeringModal';

export const SwitchAppModeModal = ( appMode, setSelection) => {

  ModalsService.showConfirmation({
    title: 'Switch app mode?',
    description: `Are you sure you want to switch to ${appMode.toLowerCase()} app mode?
    Notice that your current targeting settings will be discarded.`,
    type: 'danger',
    CustomDialog: CustomDialog,
    close: {
      title: 'Cancel',
    },
    confirm: {
      title: 'Switch app mode?',
      callback: () => setSelection(appMode),
    },
  });
};