import styled from 'styled-components';

export const StyledRowElements = styled.div`
	height: 34px;
	display: flex;
	align-items: center;
	input {
		border-radius: 0px;
	}
`;

export const StyledCheckBoxWrapper = styled.div`
	min-width: 420px;
	margin-bottom: -20px;
	.dig-container {
		padding-top: 1px;
	}
`;

export const StyledDaysTitleWrapper = styled.div`
	margin-bottom: -5px;
`;

export const StyledSelectWrapper = styled.div`
	position: relative;
	cursor:${({ disabled }) => disabled ? 'no-drop' : 'pointer' }; 
	opacity: ${({ disabled }) => disabled ? '0.5' : '1' };
	border-radius: 0px !important;
	width: 165px;
	font-size: 14px !important;
	.react-select__input {
		width: 124px;
    height: 28px;
		border-radius: 0px;
		margin-bottom: 10px !important;
		
		input{
			background-color: transparent !important;
			position: absolute;
			top:0;
			border-radius: 0px;
		}
	}
	.react-select__control {
		border-radius: 0px !important;
		opacity: 0.9;
		:hover{
			border-color: #c8c7cc !important;
			opacity: 1;
			cursor:${({ disabled }) => disabled ? 'no-drop' : 'pointer' }; 
		}
	}
	p{
		margin-top: 4px;
		font-size: 14px !important;
	}
	svg {
		width: 13px;
		height: 13px;
		margin-top: 5px;
	}
`;