export function flex(size, position) {
  return `
    flex:${size};
    display: flex;
    justify-content:${position};
    align-items: center;
  `;
}

export function ellipsis() {
  return `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}