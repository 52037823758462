import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import LivePreviewLayout from '../../MobileEngagementDesign/Banner/BannerLivePreview/LivePreviewLayout';
import { StyledThankYouPromptLivePreview , StyledThankYouPromptLivePreviewContainer } from './StyledThankYouPromptLivePreview';
import { LIVE_PREVIEW_TYPE } from '../../MobileEngagementDesign/Banner/BannerLivePreview/LivePreviewLayout';
import { MobileAndroidCustomFonts } from '../../../../resources/assets/neb-icons/fonts/BannerPreviewFonts/mobileAndroid';
import fontList from '../../../assets/font-list';
import ThankYouPromptLivePreviewHeader from './ThankYouPromptLivePreviewHeader';
import ThankYouPromptLivePreviewBody from './ThankYouPromptLivePreviewBody';
import PoweredByFooter from '../../PoweredByFooter';
import DarkModeSelect from '../../DarkModeSelect';

const renderThankYouLivePreview = (values, propertyType , headerContract , isPoweredByEnable, isFormV2Enabled , isDarkEnabled, darkHeaderContract) => {
  const propertyDefaultFont = fontList[propertyType].find((font) => font.isDefault) || 'sans-serif';
  const {
    trigger: {
      mobileThankYouPromptGeneralSettingsSectionContract: {
        backgroundColor,
      },
    },
  } = values;

  const poweredByLogoWidth = isFormV2Enabled ? 64 : 51;
  const poweredByLogoHeight = isFormV2Enabled ? 16 : 14;
  const themeBackgroundColor = isDarkEnabled ? values.mobileThemes.themeData.dark.palette.core.canvas : backgroundColor;
  return (
    <StyledThankYouPromptLivePreview defaultFontFamily={propertyDefaultFont}>
      <LivePreviewLayout propertyType={propertyType} position={'TOP'} previewType={LIVE_PREVIEW_TYPE.THANK_YOU_PROMPT} isDarkEnabled={isDarkEnabled}/>
      <MobileAndroidCustomFonts>
        <StyledThankYouPromptLivePreviewContainer borderRadius={propertyType === 'mobileIOS' ? '20px' : '0px'} backgroundColor={themeBackgroundColor}>
          <ThankYouPromptLivePreviewHeader propertyType={propertyType} headerContract={isDarkEnabled ? darkHeaderContract : headerContract} isDarkEnabled={isDarkEnabled}/>
          <ThankYouPromptLivePreviewBody propertyType={propertyType} isDarkEnabled={isDarkEnabled}/>
          {isPoweredByEnable && <PoweredByFooter logoWidth={poweredByLogoWidth} logoHeight={poweredByLogoHeight} isDarkEnabled={isDarkEnabled} /> }
        </StyledThankYouPromptLivePreviewContainer>
      </MobileAndroidCustomFonts>
    </StyledThankYouPromptLivePreview>
  );
};


const ThankYouPromptLivePreview = ({ propertyType , headerContract, isPoweredByEnable, isFormV2Enabled , isDarkModeEnabled , darkHeaderContract, isPublished }) => {
  const [isDark , setIsDark] = useState(false);
  const isDarkEnabled = isDark && isDarkModeEnabled;
  return (
    <React.Fragment>
      {isDarkModeEnabled && isPublished &&  (<DarkModeSelect isDark={isDark} setIsDark={setIsDark}/>)}
      <FormSpy subscription={{ values: true }} render={({ values }) =>
        renderThankYouLivePreview(values, propertyType, headerContract , isPoweredByEnable, isFormV2Enabled, isDarkEnabled, darkHeaderContract )} />
    </React.Fragment>
  );
};

ThankYouPromptLivePreview.propTypes = {
  propertyType: PropTypes.string,
  headerContract: PropTypes.object,
  isPoweredByEnable: PropTypes.bool,
  isFormV2Enabled: PropTypes.bool,
  isDarkModeEnabled : PropTypes.bool,
  isPublished : PropTypes.bool,
};

ThankYouPromptLivePreview.defaultProps = {
  propertyType:  'mobileIOS',
  headerContract: {},
  isPoweredByEnable: false,
  isFormV2Enabled: false,
  isDarkModeEnabled : false,
  isPublished : PropTypes.false,
};

export default ThankYouPromptLivePreview;