import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    margin: auto;
    margin-top: 12px;
  }

  p {
    width: 265px;
    margin: 20px auto;
    text-align: center;
  }
`;