import React from 'react';
import DigLabel, { LABEL_TYPES } from '@mdigital/components/dist/components/Label';
import DigTooltip from '@mdigital/components/dist/components/Tooltip';
import { COLORS } from '../../../common/colors';

const PublishStateLabel = (props) => {
  const { isPublished, digTooltip } = props;
  const label = {
    content: isPublished ? FORM_STATUS.PUBLISHED : FORM_STATUS.DRAFT,
    color: isPublished ? COLORS.PUBLISHED_BACKGROUND : COLORS.DRAFT_BACKGROUND,
    textColor: isPublished ? COLORS.PUBLISHED_COLOR : COLORS.DRAFT_COLOR,
  };

  const digLabelProps = {
    content: label.content,
    type: LABEL_TYPES.TEXT,
    digClassName: 'dig-badge status-' + (isPublished ? 'published' : 'draft'),
    textColor: label.textColor,
    color: label.color,
  };

  if(isPublished === undefined) {
    return '';
  }

  if(digTooltip) {
    return <DigTooltip name="published" digTooltip={digTooltip} digElement={() => <DigLabel {...digLabelProps} />} />;
  }

  return (
    <DigLabel {...digLabelProps} />
  );
};

export default PublishStateLabel;
