import React, { useContext } from 'react';
import { TriggerContext } from '../../../../../..';
import { ExpandableContent, ColorField } from '@m/alchemy-ui';
import { StyledBannerDarkMode } from './StyledDarkMode';
import TargetingField from '../../../../../Shared/TargetingField';
import { TargetingActions } from '../../../../Actions';
import { BANNER_INITIAL_COLOR_VALUES } from '../../../../../../../../assets/TriggeringModal/InitialFormTriggeringData';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { convertAlchemyColorFieldToHex } from '../../../../../../../../utils/colorHexaUtils';

const DarkMode = () => {
  const { triggerState, triggerDispatch, isReadOnly } = useContext(TriggerContext);
  return (
    <StyledBannerDarkMode>
      <ExpandableContent label="Dark mode appearance" labelTypeStyle="subHeaderM">
        <TargetingField
          label="Background"
          layoutVariant="inline"
          className="custom-field-margin"
          disabled={isReadOnly}
          render={
            <ColorField
              hasVariantLabel={false}
              defaultColor={BANNER_INITIAL_COLOR_VALUES.darkMode.background}
              value={getNestedProperty(triggerState, 'theme.themeData.dark.palette.core.canvas') || BANNER_INITIAL_COLOR_VALUES.darkMode.background}
              onColorChange={({ color }) => { triggerDispatch({ type: TargetingActions.SET_DARK_MODE_BACKGROUND, payload: convertAlchemyColorFieldToHex(color) }); }}
              stringMap={{}}
            />}
        />

        <TargetingField
          label="Text"
          layoutVariant="inline"
          disabled={isReadOnly}
          render={
            <ColorField
              hasVariantLabel={false}
              defaultColor={BANNER_INITIAL_COLOR_VALUES.darkMode.text}
              value={getNestedProperty(triggerState, 'theme.themeData.dark.palette.typography.headline') || BANNER_INITIAL_COLOR_VALUES.darkMode.text}
              onColorChange={({ color }) => { triggerDispatch({ type: TargetingActions.SET_DARK_MODE_TEXT, payload: convertAlchemyColorFieldToHex(color) }); }}
              stringMap={{}} />}
        />

        <TargetingField
          label="Button"
          layoutVariant="inline"
          disabled={isReadOnly}
          render={
            <ColorField
              hasVariantLabel={false}
              defaultColor={BANNER_INITIAL_COLOR_VALUES.darkMode.buttonText}
              value={getNestedProperty(triggerState, 'theme.themeData.dark.palette.action.primary') || BANNER_INITIAL_COLOR_VALUES.darkMode.buttonText}
              onColorChange={({ color }) => { triggerDispatch({ type: TargetingActions.SET_DARK_MODE_BUTTON_BACKGROUND, payload: convertAlchemyColorFieldToHex(color) }); }}
              stringMap={{}} />}
        />

        <TargetingField
          label="Button Text"
          layoutVariant="inline"
          disabled={isReadOnly}
          render={
            <ColorField
              hasVariantLabel={false}
              defaultColor={BANNER_INITIAL_COLOR_VALUES.darkMode.buttonText}
              value={getNestedProperty(triggerState, 'theme.themeData.dark.palette.typography.action.primary') || BANNER_INITIAL_COLOR_VALUES.darkMode.buttonText}
              onColorChange={({ color }) => { triggerDispatch({ type: TargetingActions.SET_DARK_MODE_BUTTON_TEXT, payload: convertAlchemyColorFieldToHex(color) }); }}
              stringMap={{}} />}
        />

        <TargetingField
          label="Close Button"
          layoutVariant="inline"
          disabled={isReadOnly}
          render={
            <ColorField
              variant="hex"
              hasVariantLabel={false}
              defaultColor={BANNER_INITIAL_COLOR_VALUES.darkMode.closeButton}
              value={getNestedProperty(triggerState, 'theme.themeData.dark.palette.action.auxiliary') || BANNER_INITIAL_COLOR_VALUES.darkMode.closeButton}
              onColorChange={({ color }) => { triggerDispatch({ type: TargetingActions.SET_DARK_MODE_CLOSE_BUTTON, payload: convertAlchemyColorFieldToHex(color) }); }}
              stringMap={{}} />}
        />

      </ExpandableContent>
    </StyledBannerDarkMode>
  );
};

export default DarkMode;