import React from 'react';
import withProvider from '../../hoc/ProviderHoc';
import Packages from './Packages';

@withProvider()
class PackagesWrapper extends React.Component{
  render(){
    return <Packages />;
  }
}
export default PackagesWrapper;