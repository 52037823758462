import styled from 'styled-components';
import { REQUIRED_RED } from '../../../../../common/colors';

export default styled.div`
  padding: 20px 30px;
  
  img.destination-image {
    position: absolute;
    bottom: 54px;
    right: 20px;
    height: 550px;
  }

  label {
    position:relative;
    font-size: 14px;
    display: block;
    &::after {
      content: '*';
      position: absolute;
      top: -2px;
      margin-left: 4px;
      color: ${REQUIRED_RED};
      font-size: 14px;
    }
  }

  input {
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    height: 34px;
    width: 350px;
    padding-left: 5px;
  }

  span.error-message {
    color: ${REQUIRED_RED};
    position: absolute;
    left: 0;
    bottom: -20px;
    display: none;
  }

  span.has-error {
    input {
      border-color: ${REQUIRED_RED}
    }
    span.error-message {
      display: block;
    }
  }

  div.app-id-input-container {
    position: relative;
    display: flex;
    flex-flow: column;
  }

  
`;