export const METADATA = 'METADATA';
export const SESSION = 'SESSION';
export const DESKTOP = 'desktop';


export const TEXT_PATH = 'app.pages.forms.invitation.targetingTab';

export const RULES_NAME = {
  TARGETING_URL: 'TargetingUrl',
  FREQUENCY: 'Frequency',
  NUMBER_OF_VISITS: 'NumberOfVisits',
  NUMBER_OF_PAGES_VIEWED: 'NumberOfPagesViewed',
  DONT_INVITE_ON_SUBMITTED: 'DontInviteOnSubmitted',
  DONT_INVITE_ON_DECLINED: 'DontInviteOnDeclined',
  GENERIC_RULE: 'GenericRule',
};

export const PARAMS_URLS = {
  FREQUENCY: 'Frequency.params.frequency',
  NUMBER_OF_VISITS: 'NumberOfVisits.params.compareString',
  NUMBER_OF_VISITS_VALUE: 'NumberOfVisits.params.numberOfRepeats',
  NUMBER_OF_PAGES_VIEWED: 'NumberOfPagesViewed.params.compareString',
  NUMBER_OF_PAGES_VIEWED_VALUE: 'NumberOfPagesViewed.params.numberOfRepeats',
  DONT_INVITE_ON_SUBMITTED: 'DontInviteOnSubmitted.params.days',
  DONT_INVITE_ON_DECLINED: 'DontInviteOnDeclined.params.days',
  INCLUDED_TARGETING_URL: 'TargetingUrl.params.included',
  EXCLUDED_TARGETING_URL: 'TargetingUrl.params.excluded',
  GENERIC_RULE: 'GenericRule.params.name',
};


export const INITIAL_GENERIC_RULE = {
  formId: null,
  formName: null,
  id: null,
  params: { name: '', ruleId: null },
  type: 'GenericRule',
};

export const INITIAL_TARGETING_URL = {
  type: 'TargetingUrl',
  id: null,
  params: {
    excluded: '',
    included: '',
  },
  formId: null,
  formName: null,
};

export const INITIAL_FREQUENCY = {
  type: 'Frequency',
  id: null,
  params: {
    type: 'INVITE_PERCENTAGE_OF_VISITORS',
    frequency: 0,
  },
  formId: null,
  formName: null,
};

export const INITIAL_NUMBER_OF_VISITS = {
  type: 'NumberOfVisits',
  id: null,
  params: {
    compareString: '',
    numberOfRepeats: '',
  },
  formId: null,
  formName: null,
};

export const INITIAL_NUMBER_OF_PAGES_VIEWED = {
  type: 'NumberOfPagesViewed',
  id: null,
  params: {
    compareString: '',
    numberOfRepeats: 0,
  },
  formId: null,
  formName: null,
};

export const INITIAL_INVITE_ON_SUBMITTED = {
  type: 'DontInviteOnSubmitted',
  id: null,
  params: {
    days: 0,
  },
  formId: null,
  formName: null,
  days: null,
};

export const INITIAL_INVITE_ON_DECLINE = {
  type: 'DontInviteOnDeclined',
  id: null,
  params: {
    days: 0,
  },
  formId: null,
  formName: null,
};
