import React from 'react';
import Input from '@mdigital/components/dist/components/Input';
import Textarea from '@mdigital/components/dist/components/Textarea';
import AutoSuggest from '@mdigital/components/dist/components/AutoSuggest';
import ColorPicker from '@mdigital/components/dist/components/ColorPicker';
import StyledGeneralSettings from './StyledGeneralSettings';
import FieldRow from '../shared/FieldRow';
import { autoSuggestLabelByIdFinder } from '../../../../../common/utils';
import fontList from '../../../../../assets/font-list';
import COLORS from '../../../../../common/colors';
// this should be replaced once will be provided with the final JSON file

const GeneralSettings = ({ propertyType }) => (
  <StyledGeneralSettings>
    <FieldRow required title="Title" name="trigger.triggerCustomBannerContract.invitationTitle" errorMessage="Title is required" render={({ input }) => (
      <Input maxLength={30} enableCounter digValue={input.value} digOnChange={input.onChange} />
    )} />
    <FieldRow required title="Body" name="trigger.triggerCustomBannerContract.invitationBody" errorMessage="Body text is required" render={({ input }) => (
      <Textarea maxLength={70} enableCounter {...input} />
    )}/>
    <FieldRow title="Font" name="trigger.triggerCustomBannerContract.font" render={({ input }) => (
      <span className="font-selection-container">
        <AutoSuggest
          digDisableSuggest
          suggestions={fontList[propertyType]}
          onSuggestionSelected={({ label }) => input.onChange(label)}
          value={autoSuggestLabelByIdFinder(fontList[propertyType])(input.value)}
        />
      </span>
    )} />
    <FieldRow title="Text Color" name="trigger.triggerCustomBannerContract.textColor" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
    <FieldRow title="Background" name="trigger.triggerCustomBannerContract.backgroundColor" render={({ input }) => (
      <ColorPicker value={COLORS.BANNER_PREVIEW_DEFULAT_BACKGROUND} pickerOnLeft pickerOnTop  {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
  </StyledGeneralSettings>
);

export default GeneralSettings;
