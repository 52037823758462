import styled from 'styled-components';
import { COLORS } from '../../../common/colors';
import { dynamicStyling } from './FormViewDynamicStyles';
import { SORT_ORDER } from '../../../common/enums';
import sortIcon from '../../../resources/assets/neb-icons/svg/sort.svg';
import { units } from '@m/alchemy-ui';

const SORT_ICON_HEIGHT = 17, SORT_ICON_WIDTH = 27;
const SELECTED_SORT_COLOR = '#5B5B60', SORT_TOP_SELECTOR = '#sort-icon-top', SORT_BOTTOM_SELECTOR = '#sort-icon-bottom';
const SORT_ICON_PROPERTIES = { icon:sortIcon, width:SORT_ICON_WIDTH, height:SORT_ICON_HEIGHT, className: 'sort-wrapper' };
export const FORM_LIST_VIEW_COLUMNS = [
  { label: '' },
  { autLabel:'Type', label: 'Type',sortType:'triggers.0.triggerType', icon: SORT_ICON_PROPERTIES, id: 'sort-column-type' },
  { autLabel:'Name', label: 'Name', sortType:'name', icon: SORT_ICON_PROPERTIES, id: 'sort-column-name' },
  { autLabel:'Modified', label: 'Modified', sortType:'formChangeTime', icon: SORT_ICON_PROPERTIES, id: 'sort-column-modified' },
  { autLabel:'Form ID', label: 'Form ID', sortType:'id' , icon: SORT_ICON_PROPERTIES, id: 'sort-column-formId' },
  { autLabel:'Published', label: 'Published', sortType:'published' , icon: SORT_ICON_PROPERTIES, id: 'sort-column-published' }
];

export const FORM_DRAFT_LIST_VIEW_COLUMNS = [
  { autLabel:'Name', label: 'Name', sortType:'name', icon: SORT_ICON_PROPERTIES, id: 'sort-column-name' },
  { autLabel:'Type', label: 'Type',sortType:'triggers.0.triggerType', icon: SORT_ICON_PROPERTIES, id: 'sort-column-type' },
  { autLabel:'Form ID', label: 'Form ID', sortType:'id' , icon: SORT_ICON_PROPERTIES, id: 'sort-column-formId' },
  { autLabel:'Modified', label: 'Modified', sortType:'formChangeTime', icon: SORT_ICON_PROPERTIES, id: 'sort-column-modified' },
  { autLabel:'Status', label: 'Status', sortType:'statusSurveyDraft' , icon: SORT_ICON_PROPERTIES, id: 'sort-column-published' },
  { label: '' },
  { label: '' }
];

const getSortStyleByProps = (props) => `
    #${getSortColumnId(props.sortBy).id}{
      ${props.sortOrder === SORT_ORDER.DESC ? SORT_TOP_SELECTOR : SORT_BOTTOM_SELECTOR}{
        fill: ${SELECTED_SORT_COLOR};
      }
    }
  `;

const getSortColumnId = (sortBy) => FORM_LIST_VIEW_COLUMNS.find((col) => col.sortType === sortBy) || {};

export const StyledFormViewContainerComponent = styled.div`

    .view-form-wrapper{
        height: calc(100vh - 108px);
    		overflow-y: auto;
    }
    .empty-folder-container,.delete-folder-container{
      margin-top: 150px; // Due to problem with forms-container layout
    }
    .delete-folder-container .view-container {
      .content {
        margin-bottom: 110px;
      }
      .sub-title {
        margin-top: 17px;
      }
      .title {
        color: ${COLORS.ERROR}
      }
    }
    .no-forms-message {
        margin: 30px -15px 0 20px;
    }
    .highlight {
      	background-color: ${COLORS.TEXT_HIGHLIGHT};
     }
    .dig-columns-container {
        margin: ${(props) => props.isSurveyDraftEnabled ? `${units(4.375)} ${units(5)} 0 ${units(0.625)};` : '35px 35px 0 0;' };
        
        ${(props) => getSortStyleByProps(props)}
        .dig-column {
          ${(props) =>
    props.isSurveyDraftEnabled ? dynamicStyling.DRAFT_FORM_VIEW_DYNAMIC_STYLES.DIG_COLUMN_STYLES :
      props.isFormFoldersEnabled ? dynamicStyling.FORM_VIEW_DYNAMIC_STYLES.DIG_COLUMN_STYLES : dynamicStyling.DEFAULT_FORM_VIEW_DYNAMIC_STYLES.DEFAULT_DIG_COLUMN_STYLES
}
          display:flex;
        }
    } 

    .dig-rows-container {
      svg{
          margin-top:1.2px;
      }
      ${(props) =>
    props.isSurveyDraftEnabled ? dynamicStyling.DRAFT_FORM_VIEW_DYNAMIC_STYLES.DIG_ROW_STYLES :
      props.isFormFoldersEnabled ? dynamicStyling.FORM_VIEW_DYNAMIC_STYLES.DIG_ROW_STYLES : dynamicStyling.DEFAULT_FORM_VIEW_DYNAMIC_STYLES.DEFAULT_DIG_ROW_STYLES
}
    }
}

.svg-dark-mode{
      svg{
        height: 23px;
        width: 23px;
        top: -4px;
        left: -4px;
      }
    }
`;