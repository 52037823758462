import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';

export const StyledSdkIntegrationV2Header = styled.header `
    height: 64px;
    padding: 19px 22px 15px 22px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid ${COLORS.SEPARATOR};
    
    .sdk-integration-v2-modal-header{
        font-family : open sans;
        font-weight : 400;
    }
`;

export const StyledsdkIntegrationV2HeaderButton = styled.button `
    border: none;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    font-size: 10px;
    color: ${COLORS.SEPARATOR};

    &:hover{
        color: ${COLORS.SECONDARY_TEXT};
    }
`;