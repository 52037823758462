"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderSyntax = void 0;
const state_1 = require("../ConditionBuilder/state");
const renderSyntax = (props) => {
    if (props.state == null) {
        return '';
    }
    else {
        const state = props.state;
        return state.conditions
            .map(item => (0, state_1.isGroup)(item) ? '(' + (0, exports.renderSyntax)({ state: item, renderCondition: props.renderCondition }) + ')' : props.renderCondition(item))
            .join(` ${state.logicalOperator} `);
    }
};
exports.renderSyntax = renderSyntax;
