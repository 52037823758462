import ConfirmExtensionUpdate from './ConfirmExtensionUpdate';
import DigButton from '@mdigital/components/dist/components/Button';
import PropTypes from 'prop-types';
import React from 'react';
import httpManager from '../../../services/HttpManager';
import moment from 'moment';
import { ROLE_TYPES } from '../../../common/constants.js';
import { connect } from 'react-redux';
import { getProfile } from '../../../redux/actions/profile.actions';
import { StyledFreezeModeNotification } from './DhhFreezeModeNotification';


const NOTIFICATION_SETTINGS = {
  info: 'Hey there. The system is currently in “Freeze Mode” due to a software update.',
  timeInfo: (timestamp) => {
    if (!timestamp) {
      return '';
    }
    const date = moment(timestamp);
    return `A new extension version was released on ${date.utc().format('DD/MM/YYYY')} ${date.utc().format('h:mm A')} GMT.`;
  },
};

class ExtensionFreezeModeNotification extends React.Component {
  state = {
    showExtensionUpdateModal: false,
    extensionTimestamp: null,
  }

  static propTypes = {
    addAlert: PropTypes.string,
    userRole: PropTypes.string,
    propertyId: PropTypes.number,
  };

  componentDidMount() {
    if (!this.state.extensionTimestamp) {
      const url = `kma/api/extension/property/${this.props.propertyId}/last-version-info`;

      httpManager.get(url)
        .then((res) => {
          if (res && res.timestamp) {
            this.setState({
              extensionTimestamp: res.timestamp,
            });
          }
        });
    }
  }

  handleUpdateExtension = (ev) => {
    ev.currentTarget.blur();
    this.setState({
      showExtensionUpdateModal: true,
    });
  }

  handleCancelUpdateModal = () => {
    this.setState({
      showExtensionUpdateModal: false,
    });
  }

  handleApproveUpdateModal = () => {
    const { propertyId, getProfile, addAlert } = this.props;
    const url = `kma/api/extension/property/${propertyId}/unfreeze`;

    httpManager.post(url)
      .then(getProfile)
      .then(() => {
        this.setState({
          showExtensionUpdateModal: false,
        });
        addAlert('success', 'Good job, you are out of "Freeze mode"! Extension was successfully updated');
      });
  }

  render() {
    const { userRole } = this.props;
    const isAdmin = userRole === ROLE_TYPES.ROLE_ADMIN;

    return (
      <StyledFreezeModeNotification>
        <div>
          <p>{NOTIFICATION_SETTINGS.info}</p>
          <p>{NOTIFICATION_SETTINGS.timeInfo(this.state.extensionTimestamp)}</p>
        </div>
        {isAdmin &&
          <React.Fragment>
            <DigButton
              id={'confirm-extension-update'}
              digType="action"
              digOnClick={this.handleUpdateExtension}
              digClassName={'confirmExtension'}
            >
              Confirm Update
            </DigButton>
            <ConfirmExtensionUpdate
              isShown={this.state.showExtensionUpdateModal}
              onOkClicked={this.handleApproveUpdateModal}
              onCancelClicked={this.handleCancelUpdateModal}
            />
          </React.Fragment>
        }
      </StyledFreezeModeNotification>
    );
  }
}

function mapStateToProps({ profile }) {
  return {
    userRole: profile.userRole,
    propertyId: profile.propertyId,
  };
}

export default connect(mapStateToProps, { getProfile })(ExtensionFreezeModeNotification);
