"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validation = void 0;
const utils_1 = require("../../../common/utils");
const findDomainDuplication = (value, list) => list.find((item) => {
    if (item.charAt(0) === '*') {
        return value.includes(item.substring(1));
    }
    else {
        return value === item;
    }
});
const validation = (value, domains, errorText, duplicationErrorText) => {
    const correctDomainReg = /^((\*)|([a-zA-Z0-9]))[a-zA-Z0-9\-_]{0,243}(\.[a-zA-Z0-9][a-zA-Z0-9\-_]{0,243}){0,10}\.[a-zA-Z]{2,243}$/i;
    if (!value) {
        return (0, utils_1.getSingleText)(errorText);
    }
    else if (!correctDomainReg.test(value)) {
        return (0, utils_1.getSingleText)(errorText);
    }
    else if (findDomainDuplication(value, domains)) {
        return (0, utils_1.getSingleText)(duplicationErrorText);
    }
    else {
        return undefined;
    }
};
exports.validation = validation;
