import React from 'react';
import { Header, PanelRow, PanelContent, PanelRowPreview, PanelColumn } from '../../StyledTriggeringModal';
import SharedRightPanel from '../Shared/SharedRightPanel';
import { getPropertyType } from '../../../../../redux/store/store-helper';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { getSingleText } from '../../../../../common/utils';
import TriggerByCodeStaticImages from '../../../../assets/TriggeringModal/TriggerByCodeStaticImages';
import CodeSnippet from './CodeSnippet';
import { Links } from '../../enumns/links.enumn';

const TextPathPrefix = 'app.pages.forms.triggeringModal.triggerByCode.rightPanel.code';

const CodeRightPanel = () => {
  const propertyType = getPropertyType();
  const img = getNestedProperty(TriggerByCodeStaticImages, propertyType);
  const code = getNestedProperty(CodeSnippet['code'], propertyType);
  const header = getSingleText(`${TextPathPrefix}.header`);
  const title = getSingleText(`${TextPathPrefix}.title`);
  const subtitle = getSingleText(`${TextPathPrefix}.subtitle`);
  const description = getSingleText(`${TextPathPrefix}.description`);
  const instruction = getSingleText(`${TextPathPrefix}.instruction`);

  return (
    <PanelRow>
      <Header>{ header }</Header>
      <PanelContent>
        <PanelColumn>
          <SharedRightPanel title={title}
            subtitle={subtitle}
            description={description}
            code={code}
            instruction={instruction}
            instructionLink={Links.CODE_TRIGGER}/>
          <PanelRowPreview>
            <img src={ img } alt="Preview" />
          </PanelRowPreview>
        </PanelColumn>
      </PanelContent>
    </PanelRow>
  );
};

export default CodeRightPanel;