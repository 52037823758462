import React, { useEffect, useContext, useState } from 'react';
import { TriggerContext } from '../../../../../../..';
import TargetingBlockRow from '../../../TargetingBlockRow';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { NumberInput } from '@m/alchemy-ui';
import { TargetingActions } from '../../../../../Actions';
import { isRuleExists, formatInputNumberNullValue } from '../../../../../../../helpers';
import { Subtitle } from '../../../../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../../../../common/utils';
import { TextPathPrefix } from '../../../../..';

const TargetBySubmitted = () => {
  const { triggerState, triggerDispatch, isReadOnly } = useContext(TriggerContext);
  const defaultValue = getNestedProperty(triggerState, 'trigger.rules.MobileDontInviteOnSubmitted.params.days');
  const [value, setValue] = useState(defaultValue || 1);

  useEffect(() => {
    isRuleExists(triggerState, 'trigger.rules.MobileDontInviteOnSubmitted.id') ?
      triggerDispatch({ type: TargetingActions.SET_MOBILE_DONT_INVITE_BY_SUBMITTED_IS_ACTIVE , payload : true }) :
      triggerDispatch({ type: TargetingActions.INIT_MOBILE_DONT_INVITE_BY_SUBMITTED , payload : {} } );
  },[]);

  const onNumberChanged = (val) => {
    setValue(val);
    triggerDispatch({ type: TargetingActions.SET_MOBILE_DONT_INVITE_BY_SUBMITTED_DAYS , payload : val });
  };

  const handleCheckboxChange = (e) => {
    triggerDispatch({ type: TargetingActions.SET_MOBILE_DONT_INVITE_BY_SUBMITTED_IS_ACTIVE , payload : e.target.checked } );
    onNumberChanged(1);
  };

  return (
    <TargetingBlockRow
      checkboxLabel="Exclude users who have submitted feedback in the past"
      isFullWidth
      isQuarantineRule
      checked={getNestedProperty(triggerState , 'trigger.rules.MobileDontInviteOnSubmitted.isActive')}
      disabled={isReadOnly}
      onCheckboxChange={handleCheckboxChange} >
      <NumberInput
        min="1"
        formatValue={formatInputNumberNullValue}
        value={value}
        onNumberChange={onNumberChanged} />
      <Subtitle marginLeft={1.5}>{getSingleText(`${TextPathPrefix}.rulesTexts.days`)}</Subtitle>
    </TargetingBlockRow>
  );
};

export default TargetBySubmitted;
