import styled from 'styled-components';

export const StyledBannerContainer = styled.div`
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    letter-spacing: 0px;
    background: #EAF3FF 0% 0% no-repeat padding-box;
    position: relative;
    i {
        width: 18px;
        margin-top: 3px;
    }
`;

export const StyledUpgradeButton = styled.span`
    top: 12px;
    right: 24px;
    height: 32px;
    position: absolute;
    .action-upgrade-form {
      width: 119px;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      font-family: Open Sans;
      letter-spacing: 0px;
      color: #FFFFFF;
    }
`;


export const StyledBannerText = styled.h1`
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    font-family: Open Sans;
    letter-spacing: 0px;
    color: #2B3447;
    margin: 0 7px;
`;

export const StyledLearnMoreText = styled(StyledBannerText)`
    text-decoration: underline; 
    cursor:pointer;
    margin: 0;
`;
