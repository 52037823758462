import React from 'react';
import { Svg } from '@m/alchemy-ui';

export const WindowsIcon = ({ title, description, ...otherProps }) => (
  <Svg title={title} description={description} {...otherProps} viewBox="0 0 96 87" >
    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M14 11C9.58172 11 6 14.5817 6 19V23H91V19C91 14.5817 87.4183 11 83 11H14ZM15 16C12.7909 16 11 17.7909 11 20V22H86V20C86 17.7909 84.2091 16 82 16H15Z"/>
    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M19 0C14.5817 0 11 3.58172 11 8V12H85V8C85 3.58172 81.4183 0 77 0H19ZM20 5C17.7909 5 16 6.79086 16 9V11H80V9C80 6.79086 78.2091 5 76 5H20Z"/>
    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M8 22C3.58172 22 0 25.5817 0 30V79C0 83.4183 3.58172 87 8 87H88C92.4183 87 96 83.4183 96 79V30C96 25.5817 92.4183 22 88 22H8ZM9 27C6.79086 27 5 28.7909 5 31V38H91V31C91 28.7909 89.2091 27 87 27H9ZM5 78V43H91V78C91 80.2091 89.2091 82 87 82H9C6.79086 82 5 80.2091 5 78ZM15 30H10V35H15V30ZM18 30H23V35H18V30ZM31 30H26V35H31V30Z"/>
  </Svg>
);
