import React from 'react';
import { getSingleText } from '../../../../../common/utils';
import { StyledLeftPanelTextContainer } from './SharedMobileTriggeringStyling';
import { Subtitle, TextLink } from '../../StyledTriggeringModal';
import { Links } from '../../enumns/links.enumn';

const SharedLeftPanelDescription = ({ triggerType }) => {
  const TextPathPrefix = 'app.pages.forms.triggeringModal';
  const LeftPanelText = `${TextPathPrefix}.${triggerType}.leftPanelText`;
  const LearnMore = `${TextPathPrefix}.${triggerType}.learnMore`;
  return (
    <StyledLeftPanelTextContainer>
      <Subtitle>{getSingleText(LeftPanelText)}</Subtitle>
      <TextLink target="_blank" href={Links.TRIGGER_TYPES}>{getSingleText(LearnMore)}</TextLink>
    </StyledLeftPanelTextContainer>
  );
};

export default SharedLeftPanelDescription; 