import styled from 'styled-components';

export const StyledAccessDeniedContainer = styled.div`
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   div.title {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1B2437;
   } 
   div.sub-title {
    font-family: 'Nunito', sans-serif;
    line-height: 22px;
    color: #2C3548;
    margin-top: 6px;
   }  
`;