import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { InstructionBlock, CodeSnippedBlock, CodeSnippedButtonContainer, WarningAlert, InstructionLink } from '../TriggerByCode/StyledTriggerByCode';
import { PanelRowContent, Title, Subtitle } from '../../StyledTriggeringModal';
import { getSingleText } from '../../../../../common/utils';
import { Button } from '@m/alchemy-ui';
import { copyToClipboard } from '../../../../common/utils';
import { TRIGGER_TYPE, TriggerContext } from '../../';

const TextPathPrefix = 'app.pages.forms.triggeringModal.triggerByCode.rightPanel';
const CopyText = getSingleText(`${TextPathPrefix}.copyCode`);
const CopiedText = getSingleText(`${TextPathPrefix}.copied`);
const Warning = getSingleText(`${TextPathPrefix}.warning`);
const copyTimer = 5000;
const instructionLinkText = getSingleText(`${TextPathPrefix}.instructionLinkText`);

const SharedRightPanel = ({ title, subtitle, description, code, instruction, instructionLink }) =>{
  const [buttonText, setButtonText] = useState(CopyText);
  const codeSnippedBlockRef = useRef(null);
  const { triggerType } = useContext(TriggerContext);

  const isTargetingView = triggerType === TRIGGER_TYPE.TRIGGER_BY_TARGETING;
  const copyCode = () => {
    copyToClipboard(code, codeSnippedBlockRef.current);
    setButtonText(CopiedText);
    setTimeout(() => {
      setButtonText(CopyText, codeSnippedBlockRef);
    }, copyTimer);
  };


  return (
    <PanelRowContent>
      <Title addMargin={isTargetingView}>{ title }</Title>
      <Subtitle>{ subtitle }</Subtitle>
      <Subtitle marginTop={3}>{ description }</Subtitle>
      <CodeSnippedBlock ref={codeSnippedBlockRef}>
        <pre>{ code }</pre>
        <CodeSnippedButtonContainer>
          <Button size="small" priority="primary" onClick={copyCode}>{ buttonText }</Button>
        </CodeSnippedButtonContainer>
      </CodeSnippedBlock>
      <WarningAlert>{ Warning }</WarningAlert>
      { instruction && 
      <InstructionBlock>
        <p>{ instruction }</p>
        <InstructionLink target="_blank" href={instructionLink}>{ instructionLinkText }</InstructionLink>
      </InstructionBlock>
      }
    </PanelRowContent>
  );
};

export default SharedRightPanel;

SharedRightPanel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  code:  PropTypes.string,
  instruction:  PropTypes.string,
  instructionLink:  PropTypes.string,
};

SharedRightPanel.defaultProps = {
  title: '',
  subtitle: '',
  description: '',
  code: '',
  instruction: '',
  instructionLink: '',
};