import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PackageItemList from '@mdigital/components/dist/components/PackageItemList';
import moment from 'moment';
import { COLORS } from '../../../common/colors';

const DigPackageHistoryComponent = styled.div`
  .last-package-info {
    margin-bottom: 5px;
    margin-left: 12px;
    color: ${COLORS.PRIMARY_TEXT};
    .value {
      color: ${COLORS.SECONDARY_TEXT};
      .at {
        color: ${COLORS.PRIMARY_TEXT};
      }
    }
    .latest-package {
      line-height: 22px;
    }
  }
  .separator {
    width: 382px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: ${COLORS.SEPARATOR};
  }
  .view-all {
    margin-left: 41px;
  }
`;

// TODO: can we delete this unused code? it is written in 2017
// To be returned when the backend code is ready
// {/*{createdByUser &&*/}
// {/*<p className="last-package-info">*/}
// {/*Downloaded by <span className="value">{createdByUser}</span>*/}
// {/*</p>*/}
// {/*}*/}

export default class DigPackageHistory extends React.Component {

  constructor(props) {
    super(props);
    const { packageItems = [], numberOfPackagesToDisplay } = props;
    this.state = {
      isShowAllPackages: packageItems.length <= numberOfPackagesToDisplay,
    };
    this.onViewAllDownloads = this.onViewAllDownloads.bind(this);
  }

  onViewAllDownloads() {
    this.setState({
      isShowAllPackages: true,
    });
  }

  getFormattedTimestamp(timestamp) {
    return (
      <span className="value">
        {moment(timestamp).format('MMM DD, YYYY ')}
        <span className="at">at</span>
        {moment(timestamp).format(' HH:mm')}
      </span>
    );
  }

  getLatestPackage(packageItems) {
    return packageItems.reduce((currentLatest, packageItem) => currentLatest.timestamp > packageItem.timestamp ? currentLatest : packageItem, packageItems[0]);
  }

  render() {
    const { packageItems = [], numberOfPackagesToDisplay, lastSubmittedFrom } = this.props;
    const { isShowAllPackages } = this.state;
    const latestPackageItem = packageItems.length >= 1 ? this.getLatestPackage(packageItems) : {};
    const { version, timestamp } = latestPackageItem;
    return (
      <DigPackageHistoryComponent>
        {version && timestamp ?
          <div className="last-package-info">
            <p>
              Latest Package Downloaded:
            </p>
            <span><b>{version}</b></span>
          </div>
          :
          <p className="last-package-info">
            <span className="value">No package has been downloaded for this property.</span>
          </p>
        }

        {version && timestamp &&
          <p className="last-package-info">
            Last downloaded on {this.getFormattedTimestamp(timestamp)}
          </p>
        }

        {lastSubmittedFrom && lastSubmittedFrom.packageVersion ?
          <p className="last-package-info">
            Last feedback submitted from <span className="value">{lastSubmittedFrom.packageVersion}</span>
          </p> :
          <p className="last-package-info">
            <span className="value">No feedback has been submitted from an on-premise package.</span>
          </p>
        }

        {timestamp &&
          <div>
            <br />
            <p className="last-package-info"><b>Download history</b></p>
            <hr className="separator"/>
            <PackageItemList packageItems={isShowAllPackages ? packageItems : packageItems.slice(0, numberOfPackagesToDisplay)}/>
            {!isShowAllPackages && <a onClick={this.onViewAllDownloads} className="view-all">View all downloads</a>}
          </div>
        }
      </DigPackageHistoryComponent>
    );
  }
}

DigPackageHistory.propTypes = {
  packageItems: PropTypes.array.isRequired,
  numberOfPackagesToDisplay: PropTypes.number.isRequired,
  lastSubmittedFrom: PropTypes.any,
};
