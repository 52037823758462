import PropTypes from 'prop-types';
const nonSpacingValidator = ({
  value,
  onError,
}) => {

  if (typeof value === 'string') {
    if (value.trim() === '') {
      onError();
    }
  }

};

export { nonSpacingValidator };
nonSpacingValidator.propTypes = {
  value: PropTypes.string,
  onError: PropTypes.func,
};