import React from 'react';
import PropTypes from 'prop-types';
import withModal from '@mdigital/components/dist/hoc/ModalHoc';
import _, { filter } from 'lodash';
import { bind } from '@mdigital/components/dist/decorators';
import DigMessage from '@mdigital/components/dist/components/Message';
import { connect } from 'react-redux';

import DigAddLanguageModal from '../DigAddLanguageModal';
import DigDeleteLanguageModal from '../DigDeleteLanguageModal';
import FormPrimaryLanguageSection from './FormPrimaryLanguageSection';
import LanguageDeterminationSection from './LanguageDeterminationSection';
import AvailableLanguagesSection from './AvailableLanguagesSection';
import './DigLocalizationModalContainer.scss';
import { getSingleText } from '../../../common/utils';

import * as localizationActions from '../../../redux/actions/localization.actions';
import { PROPERTY_TYPES } from '../../../common/constants';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import VerticalStack from '@mdigital/components/dist/components/VerticalStack';
import FormPublishSwitcher from '../FormPublishSwitcher';
import { ThemeProvider, theme } from '@m/alchemy-ui';

const DELAY = 250;
const basicModalProps = {
  title: getSingleText('app.pages.forms.localization.mainModalTitle'),
  okButtonText: getSingleText('app.pages.forms.localization.mainModalOkButtonText'),
  cancelButtonText: getSingleText('app.pages.forms.localization.mainModalCancelButtonText'),
  modalClassName: 'dig-localization-modal',
};

let URLS = [
  '/kma/api/property/{{propertyId}}/engagements/{{formId}}/localization',
  '/kma/api/property/{{propertyId}}/customParameters?formId={{formId}}'
];

const CONFIG = {
  credentials: 'same-origin',
};

const versionToBackupNameMap = {
  published: 'publishedBackup',
  draft: 'draftBackup',
};

function prepareUrls(propertyId, formId) {
  return URLS.map((url) => url.replace('{{propertyId}}', propertyId).replace('{{formId}}', formId));
}

function fetchData(url) {
  return fetch(url, CONFIG)
    .then((promise) => promise.json())
    .then((res) => res);
}

function getDigErrorMessageHeader(numberOfErrors) {
  return numberOfErrors === 0 ?
    getSingleText('app.pages.forms.localization.importFailedHeader') :
    (`${getSingleText('app.pages.forms.localization.importFailedHeader')} - ${numberOfErrors} ${getSingleText('app.pages.forms.localization.importFailedHeaderBody')}`);
}

@connect(mapStateToProps, mapDispatchToProps)
@withModal(basicModalProps)
export default class DigLocalizationModal extends React.Component {
  static propTypes = {
    // mapStateToProps
    formId: PropTypes.number,
    propertyId: PropTypes.number,
    formLocalizationSettings: PropTypes.object,
    availableLanguages: PropTypes.array,
    propertyType: PropTypes.string,
    version: PropTypes.string,
    showFormPublishSwitcher: PropTypes.bool,
    publishedId: PropTypes.number,
    draftId: PropTypes.number,

    // mapDispatchToProps
    setImportFileName: PropTypes.func,
    setAvailableLanguages: PropTypes.func,
    appendToAvailableLangauges: PropTypes.func,
    setFormLocalizationSettings: PropTypes.func,
    setUseCustomParam: PropTypes.func,
    setDefaultLanguage: PropTypes.func,
    setCustomParamToUse: PropTypes.func,
    deleteFromAvailableLanguages: PropTypes.func,
    toggleFormMode: PropTypes.func,

    modalStatus: PropTypes.object,
    resetModalStatus: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisableOkButtonInAddLanguageModal: true,
      formLocalization: {},
      isLangModalOpened: false,
      isDeleteLangModalOpened: false,
      languageLabelSelectedForDelete: '',
      selectedLanguages: [],
      langSelectedData: {
        label: null,
        id: null,
        duplicated: '',
      },
      customParamsDropDownSuggestions: [],
      messageData: null,
      draftBackup: null,
      publishedBackup: null,
    };
    this.fetchData();
  }

  @bind
  componentDidUpdate(prevProps) {
    const gotToggled = prevProps.version === ShownVersion.PUBLISHED && this.props.version === ShownVersion.DRAFT ||
      prevProps.version === ShownVersion.DRAFT && this.props.version === ShownVersion.PUBLISHED;
    if (gotToggled) {
      this.toggleModeBackupHandler();
      this.setState({
        messageData: null,
      });
    }
  }

  @bind
  toggleModeBackupHandler() {
    if (this.props.version === ShownVersion.PUBLISHED) {
      this.saveBackupByMode(ShownVersion.DRAFT);
    }
    if (this.state.publishedBackup && this.state.draftBackup) {
      this.loadBackupByMode(this.props.version);
    } else {
      this.fetchData();
    }
  }

  @bind
  onDuplicateLangSelect({ label }) {
    const langSelectedData = Object.assign({}, this.state.langSelectedData, {
      duplicated: label,
    });
    this.setState({ langSelectedData });
  }

  @bind
  setCustomParameters(data = []) {
    let suggestions = data.filter((suggestion) => suggestion.type === 'Text')
      .map((suggestion) => ({ id: suggestion.name, label: suggestion.id }));
    this.setState({ customParamsDropDownSuggestions: suggestions });
  }

  @bind
  setNewLang(lang) {
    const tempSelectedLang = Object.assign({}, this.state.langSelectedData, lang);
    this.setState({
      langSelectedData: tempSelectedLang,
    });

  }

  @bind
  handleResponse(responses) {
    const formLocalization = responses[0];
    const customParameters = responses[1];
    this.setCustomParameters(customParameters);
    this.initFormLocalization(formLocalization);
    this.initFormAvailableLanguages(formLocalization.languageContracts);
    this.saveBackupByMode(this.props.version);
  }
  
  @bind
  saveBackupByMode(versionMode) {
    const backupModeName = versionToBackupNameMap[versionMode];
    
    this.setState({
      [backupModeName]: {
        formLocalizationSettings: this.props.formLocalizationSettings,
        availableLanguages: this.props.availableLanguages,
        selectedLanguages: this.state.selectedLanguages,
      },
    });
  }

  @bind
  loadBackupByMode(versionMode) {
    const backupModeName = versionToBackupNameMap[versionMode];
    const formLocalizationBackup = this.state[backupModeName];
    
    this.props.setFormLocalizationSettings(formLocalizationBackup.formLocalizationSettings);
    this.props.setAvailableLanguages(formLocalizationBackup.availableLanguages);
    
    this.setState({
      selectedLanguages: formLocalizationBackup.selectedLanguages,
    });
  }

  @bind
  fetchData() {
    const URLS = prepareUrls(this.props.propertyId, this.props.formId);
    Promise
      .all(URLS.map(fetchData))
      .then(this.handleResponse);
  }

  @bind
  handleFileUpload(data) {
    if (data.resultCode !== 'FAILURE') {
      this.props.setImportFileName(data.fileName);
      this.setState({
        messageData: {
          digType: 'success',
          digMessageHeader: getSingleText('app.pages.forms.localization.importSuccessHeader'),
        },
      });
    } else {
      this.handleFileUploadFailed(data);
    }
  }

  @bind
  handleFileUploadFailed(data) {
    this.setState({
      messageData: {
        digType: 'danger',
        digMessageHeader: getDigErrorMessageHeader(data.kampyleAdditionalInfo.length),
        digMessageBody: getNestedProperty(data,'kampyleAdditionalInfo'),
      },
    });
  }

  @bind
  initFormAvailableLanguages(languages = []) {
    const selectedLanguages = languages.map((lang) => ({
      label: lang.languageCode,
      id: lang.languageDisplay,
      isDefault: lang.isDefault,
    }));
    this.props.setAvailableLanguages(selectedLanguages);
  }

  @bind
  closeAddLangModal() {
    this.setState({ isLangModalOpened: false });
  }

  @bind
  closeDeleteLangModal() {
    this.setState({
      isDeleteLangModalOpened: false,
      languageLabelSelectedForDelete: '',
    });
  }

  @bind
  deleteSelectedLang(deleteLang) {
    this.setState({
      selectedLanguages: filter(this.state.selectedLanguages, (lang) => lang.id !== deleteLang.id),
    });
    this.props.deleteFromAvailableLanguages(deleteLang.label);
  }

  @bind
  openDeleteLangModal(label) {
    this.setState({ 
      isDeleteLangModalOpened: true,
      languageLabelSelectedForDelete: label,
    });
  }
  
  @bind
  onConfirmDeleteLanguage() {
    this.props.deleteFromAvailableLanguages(this.state.languageLabelSelectedForDelete);
    this.closeDeleteLangModal();
  }

  @bind
  openAddLangModal() {
    this.setState({ isLangModalOpened: true });
  }

  @bind
  addNewLanguage() {
    const tempLangArr = [].concat(this.state.selectedLanguages);
    tempLangArr.push(this.state.langSelectedData);
    this.setState({
      selectedLanguages: tempLangArr,
      isLangModalOpened: false,
    });
    this.props.appendToAvailableLangauges(this.state.langSelectedData);
    this.resetSelectedLanguage();
  }

  @bind
  resetSelectedLanguage() {
    return this.setState({
      langSelectedData: {
        label: null,
        id: null,
        duplicated: '',
      },
    });
  }

  @bind
  handleToggleFormMode(formMode) {
    this.props.toggleFormMode(formMode);
  }

  @bind
  initFormLocalization(data) {
    this.props.setFormLocalizationSettings(data.formLocalizationSettingsContract);
  }

  getLanguagesAsUrlParams(languages){
    return `${languages.map((language) => `languages=${language}`).join('&')}`;
  }

  @bind
  exportAPI(fileType) {
    let url = `/kma/api/property/${this.props.propertyId}/engagements/${this.props.formId}/localization/export`;
    const languages = this.props.availableLanguages.map((language) => language.label) || [];
    const languagesUrlParams = this.getLanguagesAsUrlParams(languages);
    url = `${url}?${languagesUrlParams}&fileType=${fileType}`;
    window.location.href = url;
  }

  @bind
  onAddNewLanguageChange(propertyName, value) {
    let langSelectedData = Object.assign({},this.state.langSelectedData, {
      [propertyName]: value,
    });
    this.setState({ langSelectedData });
  }

  render() {
    const { duplicated, label } = this.state.langSelectedData;
    const { modalStatus, showFormPublishSwitcher, version } = this.props;
    const isReadOnly = version === ShownVersion.PUBLISHED;
    const settings = this.props.formLocalizationSettings;

    const isOkButtonDisabled = !label || duplicated === null || isReadOnly;
    return (
      <React.Fragment>
        {showFormPublishSwitcher && (
          <ThemeProvider theme={theme}>
            <div className="title-button">
              <FormPublishSwitcher displayedVersion={version} changeDisplayedVersion={(formMode) => this.handleToggleFormMode(formMode)} />
            </div>
          </ThemeProvider>
        )}
        <VerticalStack gap="medium">
          <div>
            <FormPrimaryLanguageSection
              selectedLanugage={settings.defaultLanguage}
              onSelected={this.props.setDefaultLanguage}
              availableLanguages={this.props.availableLanguages}
              isReadOnly={isReadOnly}/>
            <hr/>

            {this.props.propertyType === PROPERTY_TYPES.WEBSITE &&
            <React.Fragment>
              <LanguageDeterminationSection
                useCustomParam={settings.useCustomParam}
                onChange={this.props.setUseCustomParam}
                customParameters={this.state.customParamsDropDownSuggestions}
                onSelected={this.props.setCustomParamToUse}
                customParam={settings.customParam}
                isReadOnly={isReadOnly}/>
              <hr/>
            </React.Fragment>
            }
            <AvailableLanguagesSection
              availableLanguages={this.props.availableLanguages}
              defaultLanguage={settings.defaultLanguage}
              onDelete={this.openDeleteLangModal}
              onExport={_.debounce(this.exportAPI, DELAY)}
              onAddLanguage={this.openAddLangModal}
              message={this.state.messageData}
              propertyId={this.props.propertyId}
              formId={this.props.formId}
              onImportSuccess={this.handleFileUpload}
              onImportError={this.handleFileUploadFailed} 
              isReadOnly={isReadOnly}/>

            <DigAddLanguageModal
              onOkClicked={this.addNewLanguage}
              onDuplicateLangSelect={this.onDuplicateLangSelect}
              onLangSelect={this.setNewLang}
              newDefaultLanguage={this.state.langSelectedData.id}
              selectedLanguages={this.props.availableLanguages}
              onCancelClicked={this.closeAddLangModal}
              isShown={this.state.isLangModalOpened}
              onChange={this.onAddNewLanguageChange}
              isOkButtonDisabled={isOkButtonDisabled}
              filterer={this.filterer}
            />
            <DigDeleteLanguageModal
              onOkClicked={this.onConfirmDeleteLanguage}
              onCancelClicked={this.closeDeleteLangModal}
              isShown={this.state.isDeleteLangModalOpened}
            />
          </div>
          {modalStatus.hasError ? <DigMessage {...modalStatus.errorMessage} digId="message-localization-modal-submit" /> : null}
        </VerticalStack>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { localization, property } = state;
  let propsAssigning = {
    propertyId: property.id,
    propertyType: property.propertyType,
    formId: localization.formId,
    formLocalizationSettings: localization.formLocalizationSettings,
    availableLanguages: localization.availableLanguages,
  };
  if (property && property.id && localization.version) {
    Object.assign(propsAssigning, {
      version: localization.version,
      showFormPublishSwitcher: !!localization.isWithToggle,
      publishedId: localization.publishedId,
      draftId: localization.draftId,
    });
  }
  return propsAssigning;
}

function mapDispatchToProps(dispatch) {
  return {
    setImportFileName(fileName) {
      dispatch(localizationActions.setImportFileName(fileName));
    },

    setAvailableLanguages(languages) {
      dispatch(localizationActions.setAvailableLanguages(languages));
    },

    appendToAvailableLangauges(languageToAppned) {
      dispatch(localizationActions.appendToAvailableLangauges(languageToAppned));
    },

    deleteFromAvailableLanguages(langCode) {
      dispatch(localizationActions.deleteFromAvailableLanguages(langCode));
    },

    setFormLocalizationSettings(formLocalizationSettings) {
      dispatch(localizationActions.setFormLocalizationSettings(formLocalizationSettings));
    },

    toggleFormMode(formMode) {
      dispatch(localizationActions.toggleFormMode(formMode));
    },

    setUseCustomParam(useCustomParam) {
      dispatch(localizationActions.setUseCustomParam(useCustomParam));
    },

    setDefaultLanguage(defaultLanguage) {
      dispatch(localizationActions.setDefaultLanguage(defaultLanguage));
    },

    setCustomParamToUse(customParam) {
      dispatch(localizationActions.setCustomParamToUse(customParam));
    },
    
  };
}
