import styled from 'styled-components';
import { COLORS } from '../../../common/colors';

const DigLanguageDropDownComponent = styled.div`
    .language-suggestion-container {
        user-select: none;
    }
    .language-label {
        color: ${COLORS.PRIMARY_TEXT};
        margin-left: 5px;    
    }
    .language-dialect {
        float: right;
        color: ${COLORS.SECONDARY_TEXT};
    }
    .react-autosuggest__suggestions-container--open{
        // each suggestion is 42px height, setting the container to show 5 suggestions max
        max-height: 210px;
    }
`;

export default DigLanguageDropDownComponent;