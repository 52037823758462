import React from 'react';

const LightIcon = ({ isActive }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="light_mode" transform="translate(-3504 -1167)" clipPath="url(#clip-path)">
        <path id="Path_86" data-name="Path 86" d="M10,5.909A4.091,4.091,0,1,0,14.091,10,4.092,4.092,0,0,0,10,5.909ZM1.818,10.818H3.455a.818.818,0,0,0,0-1.636H1.818a.818.818,0,0,0,0,1.636Zm14.727,0h1.636a.818.818,0,0,0,0-1.636H16.545a.818.818,0,0,0,0,1.636Zm-7.364-9V3.455a.818.818,0,0,0,1.636,0V1.818a.818.818,0,0,0-1.636,0Zm0,14.727v1.636a.818.818,0,0,0,1.636,0V16.545a.818.818,0,0,0-1.636,0ZM5.083,3.929A.816.816,0,0,0,3.929,5.083L4.8,5.95A.816.816,0,0,0,5.95,4.8ZM15.2,14.05A.816.816,0,0,0,14.05,15.2l.867.867a.816.816,0,0,0,1.154-1.154Zm.867-8.967a.816.816,0,1,0-1.154-1.154L14.05,4.8A.816.816,0,0,0,15.2,5.95ZM5.95,15.2A.816.816,0,0,0,4.8,14.05l-.867.867a.816.816,0,0,0,1.154,1.154Z" 
          transform="translate(3504 1167)" 
          fill={`${isActive ? '#fff' : '#a8aaaa'}`}/>
      </g>
    </svg>
  );
};

export default LightIcon;