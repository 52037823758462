/**
 * Created by tomeryechiel on 08/06/2017.
 */
import React, { Fragment } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import DigInput from '@mdigital/components/dist/components/Input';
import MultiOptionIcon from './MultiOptionEditor/MultiOptionEditorIcon';

import { getSingleText } from '../../common/utils';
import { checkProvision } from '../../redux/store/store-helper';
import { MultiOptionIconEnum } from './MultiOptionEditor/multi-option-editor.enum';
import { LabelOptionStyled, TooltipStyled, TooltipTriangleStyled ,TooltipWrapperStyled } from './DigMultiOptionEditorStyled';
import DigMessage from '@mdigital/components/dist/components/Message';

const ERROR_UNIQUE = 'Option ID must be unique';
const ERROR_EMPTY = 'Option fields can\'t be empty';
const ERROR_CLASSNAME = 'has-error neb-has-error';
const ERROR_EMPTY_OPTIONS = 'Please add at least one option';

const OPTION_LABEL_STYLE = {
  textAlign: 'left',
  font: 'normal normal normal 12px/16px Open Sans',
  letterSpacing: 0,
  color: '#979797',
  width: '100%',
};

const BASE_TEXT = 'app.pages.forms.builder.tabs.fieldSettings.hideOptions.';

export default class DigMultiOptionEditor extends React.Component {

  constructor(props) {
    super(props);

    const isAnMECProperty = (
      checkProvision(DigProvisions.SELF_SERVICE_FIELDS)
      && checkProvision(DigProvisions.MDEC_FORM_INTEGRATION)
      && checkProvision(DigProvisions.INTEGRATION_SET_EC_FIELDS)
    );
    const isDetachableField = checkProvision(DigProvisions.DETACH_FROM_EC_FIELD);
    const ignoreEcOptionsChanged = (
      isAnMECProperty 
      && isDetachableField 
      && props.value.length === 0
      && props.ecFieldIntegrationBroken
    );

    this.state = { 
      showIDs: false, 
      iconToDisplay: null,
      options: props.value, 
      optionHoverIndex: null,
      isHideOptionEnable: false,
      currentId: props.currentId, 
      ecOptionsChanged: ignoreEcOptionsChanged ? false : props.ecOptionsChanged,
      disableEditId: props.disableEditId || false, 
      disableAddRemove: props.disableAddRemove || false, 
      limitOptionsNumber: props.component === 'checkbox' ? 1 : 2,
      ecFieldIntegrationBroken: props.ecFieldIntegrationBroken,
      isDetachableField,
    };

    this.editorRef = React.createRef();
    this.hideOptionProvisionEnable = checkProvision(DigProvisions.HIDE_OPTIONS);
  }

  componentDidMount() {
    const isHideOptionEnable = this.hideOptionProvisionEnable && this.state.disableEditId;
    const trashOrWarningIcon = this.state.ecOptionsChanged ? MultiOptionIconEnum.Warning : MultiOptionIconEnum.Trash;
    
    this.setState({
      iconToDisplay: isHideOptionEnable ? MultiOptionIconEnum.Eye : trashOrWarningIcon,
      isHideOptionEnable: isHideOptionEnable,
    });
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.disableEditId !== this.state.disableEditId) {
      const isHideOptionEnable = this.hideOptionProvisionEnable && this.state.disableEditId;
      const trashOrWarningIcon = this.state.ecOptionsChanged && this.state.disableEditId
        ? MultiOptionIconEnum.Warning
        : MultiOptionIconEnum.Trash;
      this.setState({ 
        iconToDisplay: isHideOptionEnable ? MultiOptionIconEnum.Eye : trashOrWarningIcon,
        isHideOptionEnable: isHideOptionEnable,
      });
    }
  }

    deleteOption = (i) => {
      this.state.options.splice(i, 1);
      this.updateState({ options: this.state.options });
    };

    toggleHiddenOption = (index) => {
      if (!this.hiddenOptionsNumberValidation() || this.state.options[index].hidden) {
        const newOption = [...this.state.options];
        newOption[index].hidden = !newOption[index].hidden;
        this.updateState({ options: newOption });
      }
    }

    hiddenOptionsNumberValidation = () => {
      let count = 0;
      this.state.options.forEach( (option) => !option.hidden && count++);
      return count === this.state.limitOptionsNumber;
    }

    setHoverOptionIndex = (optionHoverIndex) => {
      this.setState({ optionHoverIndex });
    }

    addNewOption = () => {
      const currentId = this.state.currentId + 1;
      let options = this.state.options.concat({ id: 'ID-' + ('00' + currentId).slice(-3), label: '' });
      this.updateState({ options, currentId });
    };

    updateState = (stateToUpdate) => {
      this.setState(stateToUpdate, () => {
        let scope = window.$(this.editorRef.current).scope();
        scope.$apply(() => {
          scope.options = [...this.state.options];
          scope.currentId = this.state.currentId;
        });
        if(this.isValid()){
          scope.$emit('componentStateUpdated',{
            id : scope.nebUuid,
          });
        }
      });
    };

    isValid = ()=>!this.state.options.some((option,index)=>_.isEmpty(option.label) || _.isEmpty(option.id) || !this.isUniqueId(index));

    isUniqueId = (index) => {
      const value = this.state.options[index].id;
      return !this.state.options.find((option, i) => option.id === value && index !== i);
    };

    onIdChange = (i, val) => {
      const tmp = this.state.options[i];
      tmp.id = val;
      this.updateState({ options: this.state.options });
    };

    onLabelChange = (i, val) => {
      const tmp = this.state.options[i];
      tmp.label = val;
      this.updateState({ options: this.state.options });
    };

    toggleButton = () => {
      this.updateState({ showIDs: !this.state.showIDs });
    };

    getOptionsDisplayText = () => {
      let limitText =  this.state.isHideOptionEnable && !this.state.showIDs ? getSingleText(`${BASE_TEXT}optionLimit`) : '';
      const plural = this.state.limitOptionsNumber > 1 ? 's' : '';
      limitText = limitText.replace('{{limit_options}}', this.state.limitOptionsNumber + ' option' + plural);
      return `Options Display ${limitText}`;
    }

    getOptionsControls = () => {
      if (!_.isEmpty(this.state.options)) {
        return (
          <div>
            <div className="show-id-button ">
              <i onClick={this.toggleButton}>{(this.state.showIDs ? 'Hide IDs' : 'Show IDs')}</i>
            </div>
            <div className="row multi-titles">
              {this.state.showIDs && <div className="col-md-4 no-padding option-id">Option ID</div>}
              <div className="col-md-6 no-padding option-display" style={ this.state.isHideOptionEnable ? { ...OPTION_LABEL_STYLE, width: this.state.showIDs ? 125 : '100%' } : {} }>{this.getOptionsDisplayText()}</div>
            </div>
          </div>
        );
      }
      return null;
    };

    idDynamicClasses = (index, value) => {
      let className = '';
      if (!this.isUniqueId(index) || _.isEmpty(value)) {
        className += ' ' + ERROR_CLASSNAME;
      }
      if (this.state.showIDs) {
        className += ' id-input-shown';
      }
      return className;
    };

    labelDynamicClasses = (value) => {
      let className = '';
      if (_.isEmpty(value)) {
        className += ' ' + ERROR_CLASSNAME;
      }
      return className;
    };

    digInputDynamicClasses = () => {
      let className = ' with-single-icon';

      if (this.state.ecOptionsChanged && this.hideOptionProvisionEnable) {
        className = ' with-double-icons';
      }
      if (!this.state.showIDs) {
        className += ' display-input-extra';
      }
      
      return className;
    };

    render() {
      if (this.state.ecFieldIntegrationBroken && !this.state.isDetachableField) {
        return <DigMessage
          digMessageBody={getSingleText('app.pages.forms.builder.tabs.fieldSettings.tooltips.ecFieldDeleted')}
          digId={'id'}
          digType='warning'
        />;
      }
      return (
        <div className={'container well multi-option-container' + (_.isEmpty(this.state.options) ? ' neb-has-error no-options' : '')} ref={this.editorRef}>
          {this.getOptionsControls()}
          {this.state.options.map((entry, i) => (
            <div className="row multi-inputs" data-aut={`option-${i}`} key={i}>
              {
                this.state.isHideOptionEnable
                  ?
                  <Fragment>
                    <LabelOptionStyled disabled={entry.hidden} onMouseOver={()=> entry.hidden && this.setHoverOptionIndex(i)} onMouseOut={()=>entry.hidden && this.setHoverOptionIndex(null)}>
                      {entry.hidden && this.state.optionHoverIndex === i &&
                      <TooltipWrapperStyled className={`animated animatedFadeInUp ${entry.hidden && this.state.optionHoverIndex === i ? 'fadeInUp' : 'fadeOutDown'}`}>
                        <TooltipStyled>{getSingleText(`${BASE_TEXT}hiddenLabel`)}</TooltipStyled>
                        <TooltipTriangleStyled />
                      </TooltipWrapperStyled>}
                      <span className={'input-wrapper id-input' + this.idDynamicClasses(i, entry.id)}>
                        <DigInput digIsDisabled={this.state.disableEditId}  digValue={entry.id} />
                      </span>
                      <span className={'input-wrapper display-input' + this.labelDynamicClasses(entry.label) + this.digInputDynamicClasses()} data-aut="optionInput">
                        <DigInput digIsDisabled={this.props.disableEditOptions} digValue={entry.label} digOnChange={this.onLabelChange.bind(this, i)}/>
                      </span>
                    </LabelOptionStyled>
                  </Fragment>
                  :
                  <Fragment>
                    <span className={'input-wrapper id-input' + this.idDynamicClasses(i, entry.id)}>
                      <DigInput digIsDisabled={this.state.disableEditId} digValue={entry.id} digOnChange={this.onIdChange.bind(this, i)} />
                    </span>
                    <span className={'input-wrapper display-input' + this.labelDynamicClasses(entry.label) + this.digInputDynamicClasses()} data-aut="optionInput">
                      <DigInput digIsDisabled={this.props.disableEditOptions} digValue={entry.label} digOnChange={this.onLabelChange.bind(this, i)} />
                    </span>
                  </Fragment>
              }

              {
                this.state.iconToDisplay 
                && 
                <MultiOptionIcon 
                  isEntryHidden={entry.hidden} 
                  option={this.state.options[i]}
                  iconToDisplay={this.state.iconToDisplay}
                  isAddRemoveDisabled={this.state.disableAddRemove} 
                  deleteOption={() => this.deleteOption.bind(this, i)} 
                  toggleHiddenOption={() => this.toggleHiddenOption(i)}
                  isTooltipDisabled={ this.hiddenOptionsNumberValidation() && !entry.hidden }/>
              }

              {!this.isUniqueId(i) && <div className="md-error">{ERROR_UNIQUE}</div>}
              { ( _.isEmpty(entry.id) || _.isEmpty(entry.label)) && <div className="md-error">{ERROR_EMPTY}</div>}
            </div>))}
          <div className="row add-button" data-aut="addOption">
            {
              this.state.isHideOptionEnable ?
                <div className="col-md-6 no-padding option-display" style={{ ...OPTION_LABEL_STYLE, marginBottom: 7 }}>
                  {getSingleText(`${BASE_TEXT}optionFooter`)}
                </div>
                :
                <i className={'fa fa-plus-circle ' + (_.isEmpty(this.state.options) ? 'no-options' : '' ) + (this.state.disableAddRemove ? ' dig-disable' : '')}
                  onClick={!this.state.disableAddRemove ? this.addNewOption : undefined}><span>Add new option</span></i>
            }
          </div>
          {
            _.isEmpty(this.state.options) ? <div className="md error no-options-error">{ERROR_EMPTY_OPTIONS}</div> : null
          }
        </div>
      );
    }

    UNSAFE_componentWillReceiveProps({ disableEditId = false, disableAddRemove = false, value }){
      this.setState({ options: value, disableEditId: disableEditId, disableAddRemove: disableAddRemove });
    }
}


DigMultiOptionEditor.propTypes = {
  value: PropTypes.array,
  component: PropTypes.string,
  currentId: PropTypes.number,
  disableEditId : PropTypes.bool,
  ecOptionsChanged: PropTypes.bool,
  disableAddRemove : PropTypes.bool,
  disableEditOptions : PropTypes.bool,
  ecFieldIntegrationBroken: PropTypes.bool,
};
