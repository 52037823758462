import styled from 'styled-components';

export default styled.div`

  span.font-selection-container {
    input {
      width: 300px;
    }
  }

`;