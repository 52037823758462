import iosBannerLivePreviewPosition from './BannerLivePreviewPosition/IOSBannerLivePreviewPosition';
import androidBannerLivePreviewPosition from './BannerLivePreviewPosition/AndroidBannerLivePreviewPosition';
import iosAppRatingBannerLivePreviewPosition from './AppRatingBannerLivePreviewPosition/IOSAppRatingBannerLivePreviewPosition';
import androidAppRatingBannerLivePreviewPosition from './AppRatingBannerLivePreviewPosition/AndroidAppRatingBannerLivePreviewPosition';
import { FLAVORS } from '../../../MobileEngagementDesign';
import { PROPERTY_TYPES } from '../../../../../common/constants';

export const renderJsonStyleFile = (flavor , propertyType) => {
  if(flavor === FLAVORS.APP_RATING && propertyType === PROPERTY_TYPES.IOS) {return iosAppRatingBannerLivePreviewPosition;} else if((flavor === FLAVORS.APP_RATING && propertyType === PROPERTY_TYPES.ANDROID)) {return androidAppRatingBannerLivePreviewPosition;} else if(flavor === FLAVORS.INVITATION && propertyType === PROPERTY_TYPES.IOS) {return iosBannerLivePreviewPosition;} else if(flavor === FLAVORS.INVITATION && propertyType === PROPERTY_TYPES.ANDROID) {return androidBannerLivePreviewPosition;} 
};

export const DRAWER_TYPES = {
  PARITAL: 'ParitalDrawer',
  FULL: 'Drawer',
};

export const BannerPositionType = {
  TOP : 'TOP',
  BOTTOM : 'BOTTOM',
};