import React from 'react';
import withModal from '@mdigital/components/dist/hoc/ModalHoc';
import { getSingleText } from '../../../common/utils';
import './digDeleteLang.css';

const basicModalProps = {
  title: getSingleText('app.pages.forms.localization.warning'),
  okButtonText: getSingleText('app.pages.forms.localization.remove'),
  cancelButtonText: getSingleText('app.cancel'),
  modalClassName: 'remove-translation-modal',
  okButtonClass: 'btn-danger',
};

@withModal(basicModalProps)
export default class DigDeleteLanguageModal extends React.Component {
  render() {
    return (
      <div className="remove-translation-wrapper">
        <div className="fa fa-warning font-awesome-icon"></div>
        <div className="remove-translation-text">
          { getSingleText('app.pages.forms.localization.removingLanguage') }
        </div>
      </div>
    );
  }
}
