import React, { useState, useEffect, useRef } from 'react';
import {
  CheckboxWrapper,
  StyledColumnElements,
  StyledRowElements
} from './EmbeddedTargetingMetadataStyled';
import DigTitle from '@mdigital/components/dist/components/Title';
import Textarea from '@mdigital/components/dist/components/Textarea';
import DigCheckbox from '@mdigital/components/dist/components/Checkbox';
import Input from '@mdigital/components/dist/components/Input';
import { PARAMS_URLS, RULES_NAME, TEXT_PATH } from '../../utils/constants';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { getSingleText } from '../../../../../common/utils';
import { StyledTargetingContainer } from '../../EmbeddedTargetingContainer/EmbeddedTargetingContainerStyled';
import Select from '@mdigital/components/dist/components/Select';
import { StyledSelectWrapper } from '../Session/EmbeddedTargetingSessionStyled';

const options = [
  { id: 'desktop', label: 'Desktop', dataAut: 'desktopCheckbox' },
  { id: 'mobile', label: 'Mobile', dataAut: 'mobileCheckbox' },
  { id: 'tablet', label: 'Tablet', dataAut: 'tabletCheckbox' }
];

const TEXTAREA_STYLE = {
  width: 680,
  height: 52,
  padding: 7,
  borderRadius: 4,
  outline: 'none',
};

const ADDITION_INPUT_STYLE = {
  borderRadius: 0,
  width: 200,
  height: 34,
  marginBottom: 40,
};

export default function EmbedTargetingMetadata({
  rules,
  onTargetDevices,
  handleTargetUrls,
  onGenericRuleChange,
  targetRules,
  handleCheckBoxToggle,
  pageElementID,
  setPageElementID,
  shouldShowIncludedError,
  shouldShowExcludedError,
  isReadOnlyMode,
}) {
  const [isIncludeVisitersCheck, setIsIncludeVisitersCheck] = useState(
    getNestedProperty(rules, PARAMS_URLS.INCLUDED_TARGETING_URL, '') !== ''
  );
  const [isExcludedVisitersCheck, setIsExcludedVisitersCheck] = useState(
    getNestedProperty(rules, PARAMS_URLS.EXCLUDED_TARGETING_URL, '') !== ''
  );

  const [isGenericRuleCheck, setIsGenericRuleCheck] = useState(
    getNestedProperty(rules, PARAMS_URLS.GENERIC_RULE, '') !== ''
  );

  const previousRef = useRef({});

  useEffect(() => {
    handleTargetUrlToggle(isIncludeVisitersCheck, 'included');
  }, [isIncludeVisitersCheck]);

  useEffect(() => {
    handleTargetUrlToggle(isExcludedVisitersCheck, 'excluded');
  }, [isExcludedVisitersCheck]);

  useEffect(() => {
    handleCheckBoxToggle(isGenericRuleCheck, RULES_NAME.GENERIC_RULE);
  }, [isGenericRuleCheck]);

  //save previous props to re-value when checkbox is true
  function handleTargetUrlToggle(state, param, optionalParam) {
    if (!state) {
      previousRef.current = {
        ...previousRef.current,
        [param]: optionalParam ? optionalParam : getNestedProperty(rules, `TargetingUrl.params.${[param]}`, ''),
      };
      handleTargetUrls({ value: '', param });
    } else if (state && previousRef.current[param]) {
      handleTargetUrls({ value: previousRef.current[param], param });
      delete previousRef.current[param];
    }
  }

  function handleGenericRuleChange(name) {
    const ruleId = targetRules.find((rule) => rule.name === name).id;
    onGenericRuleChange(name, ruleId);
  }

  function mapTargetRules() {
    return targetRules.map((item) => ({ value: item.name, label: item.name }));
  }

  function renderCheckboxList() {
    return options.map(({ id, label, dataAut }, index) => (
      <div style={{ margin: '0 10px' }} key={`${index}${id}`}>
        <DigCheckbox
          label={label}
          digIsChecked={
            rules && rules.DeviceTypes.params.devices.includes(id)
          }
          handleCheckboxChange={() => onTargetDevices(id)}
          dataAut={dataAut}
          isDisabled={isReadOnlyMode}
        />
      </div>
    ));
  }
  
  function renderUrlError() {
    return <div className="error text-small block"> { getSingleText(`${TEXT_PATH}.spaceNotAllowedError`) } </div>;
  }

  function onTargetUrlChange({ value, param }) {
    if(value === '') {
      if(param === 'included') {
        setIsIncludeVisitersCheck(false);
        handleTargetUrlToggle(false, 'included' , '');
      } else {
        setIsExcludedVisitersCheck(false);
        handleTargetUrlToggle(false, 'excluded' , '');
      }
      return;
    }
    if(value.includes('\u2028')){
      value = value.replace(/\u2028/gm, ' ');
    }
    if(param === 'included') {
      setIsIncludeVisitersCheck(true);
    } else {
      setIsExcludedVisitersCheck(true);
    }
    handleTargetUrls({ value, param });
  }

  function getSelectRuleValue() {
    const value = getNestedProperty(rules, PARAMS_URLS.GENERIC_RULE,null);
    if(value) {
      return { value, label: value };
    }
    return '';
  }

  return (
    <StyledTargetingContainer data-aut={'metadataBasedTargeting'}>
      <StyledColumnElements>
        <DigTitle
          digLabel={getSingleText(`${TEXT_PATH}.pageId`)}
          digTooltip={getSingleText(`${TEXT_PATH}.pageElementIdTooltip`)}
        />
        <Input
          digLabel={false}
          digValue={pageElementID}
          digOnChange={(value) => setPageElementID(value)}
          inputStyle={ADDITION_INPUT_STYLE}
          autoFocus={false}
          digAutLabel={'pageElementIDInput'}
          digIsDisabled={isReadOnlyMode}
        />
      </StyledColumnElements>
      <StyledRowElements style={{ marginBottom: 32 }}>
        <DigTitle digLabel={getSingleText(`${TEXT_PATH}.device`)} />
        <div style={{ display: 'flex' }}>{renderCheckboxList()}</div>
      </StyledRowElements>
      <StyledRowElements style={{ width: 395,marginBottom: 10 }}>
        <CheckboxWrapper>
          <DigCheckbox
            label={getSingleText(`${TEXT_PATH}.targetByRule`)}
            info={getSingleText(`${TEXT_PATH}.targetingByRule.tooltip`)}
            digIsChecked={isGenericRuleCheck}
            handleCheckboxChange={() => setIsGenericRuleCheck(!isGenericRuleCheck)}
            dataAut={'targetByRuleCheckbox'}
            isDisabled={isReadOnlyMode}
          />
        </CheckboxWrapper>
        <StyledSelectWrapper disabled={!isGenericRuleCheck || isReadOnlyMode} data-aut={'targetByRuleDropdown'}>
          <Select
            isDisabled={!isGenericRuleCheck || isReadOnlyMode}
            isSearchable={true}
            placeholderText={getSingleText(`${TEXT_PATH}.rulePlaceholder`)}
            options={mapTargetRules()}
            onSelect={(e)=>handleGenericRuleChange(e.value)}
            selected={getSelectRuleValue()}
          />
        </StyledSelectWrapper>
      </StyledRowElements>
      <StyledColumnElements style={{ marginBottom: 30 }}>
        <CheckboxWrapper>
          <DigCheckbox
            label={getSingleText(`${TEXT_PATH}.IncludeUrlText`)}
            digIsChecked={isIncludeVisitersCheck}
            info={getSingleText(`${TEXT_PATH}.IncludeUrlTooltip`)}
            handleCheckboxChange={() => setIsIncludeVisitersCheck(!isIncludeVisitersCheck)}
            dataAut={'includeVisitersCheckbox'}
            isDisabled={isReadOnlyMode}
          />
        </CheckboxWrapper>
        <div className={shouldShowIncludedError ? 'has-error' : ''}>
          <DigTitle digLabel={getSingleText(`${TEXT_PATH}.IncludeUrlExampleText`)}/>
          <DigTitle digLabel={getSingleText(`${TEXT_PATH}.urlExampleSecondaryText`)}/>
          <Textarea
            value={getNestedProperty(rules,PARAMS_URLS.INCLUDED_TARGETING_URL,'')}
            onChange={(e) => onTargetUrlChange({ value: e.target.value, param: 'included' })}
            placeholder={getSingleText(`${TEXT_PATH}.IncludeUrlPlaceholder`)}
            style={TEXTAREA_STYLE}
            disableLimit={true}
            autLabel={'includeVisitorsTextarea'}
            disabled = {isReadOnlyMode}
          />
          { shouldShowIncludedError && renderUrlError() }
        </div>
        
      </StyledColumnElements>
      <StyledColumnElements>
        <CheckboxWrapper>
          <DigCheckbox
            digIsChecked={isExcludedVisitersCheck}
            label={getSingleText(`${TEXT_PATH}.excludeUrlText`)}
            info={getSingleText(`${TEXT_PATH}.excludeUrlTooltip`)}
            handleCheckboxChange={() => setIsExcludedVisitersCheck(!isExcludedVisitersCheck)}
            dataAut={'excludeVisitersCheckbox'}
            isDisabled={isReadOnlyMode}
          />
        </CheckboxWrapper>        
        <div className={shouldShowExcludedError ? 'has-error' : ''}>
          <DigTitle digLabel={getSingleText(`${TEXT_PATH}.excludeUrlExampleText`)} />
          <DigTitle digLabel={getSingleText(`${TEXT_PATH}.urlExampleSecondaryText`)}/>
          <Textarea
            value={getNestedProperty( rules, PARAMS_URLS.EXCLUDED_TARGETING_URL,'')}
            onChange={(e) => onTargetUrlChange({ value: e.target.value, param: 'excluded' })}
            placeholder={getSingleText(`${TEXT_PATH}.excludeUrlPlaceholder`)}
            style={TEXTAREA_STYLE}
            disableLimit={true}
            autLabel={'excludeVisitorsTextarea'}
            disabled = {isReadOnlyMode}
          />
          { shouldShowExcludedError && renderUrlError() }
        </div>
      </StyledColumnElements>
    </StyledTargetingContainer>
  );
}
