import React from 'react';
import PropTypes from 'prop-types';
import DigTitle from '@mdigital/components/dist/components/Title';
import DigLangsList from '../DigLangList';
import DigButton from '@mdigital/components/dist/components/Button';
import DigMessage from '@mdigital/components/dist/components/Message';
import ContextMenu from '@mdigital/components/dist/components/ContextMenu';
import { FileUploader } from '@mdigital/components/dist/components/FileUploader';
import { bind } from '@mdigital/components/dist/decorators';
import { getSingleText } from '../../../common/utils';
import DropDown from '../../assets/General/DropDown.svg';


function generateImportUrl(propertyId, formId) {
  return `/kma/api/property/${propertyId}/engagements/${formId}/localization/import`;
}

function renderImportButton(propertyId, formId, availableLanguages = [], onResponse, onError, disabled = false) {

  const languages = availableLanguages.map((language) => language.label).join(',');
  const uploaderSettings = {
    url: generateImportUrl(propertyId, formId),
    accept: ['.csv', '.xlsx'],
    body: { languages },
    onResponse,
    onError,
  };
  return (<FileUploader {...uploaderSettings}><DigButton digType="link" digIsDisabled={disabled}>Import</DigButton></FileUploader>);
}

renderImportButton.propTypes = {
  propertyId: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  languages: PropTypes.arrayOf(PropTypes.any).isRequired,
  onResponse: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default class AvailableLanguagesSection extends React.Component {

    static propTypes = {
      availableLanguages: PropTypes.array,
      onDelete: PropTypes.func,
      defaultLanguage: PropTypes.string,
      onExport: PropTypes.func,
      onAddLanguage: PropTypes.func,
      message: PropTypes.object,
      propertyId: PropTypes.number,
      formId: PropTypes.number,
      onImportSuccess: PropTypes.func,
      onImportError: PropTypes.func,
      isReadOnly: PropTypes.bool,
    };

    constructor(props){
      super(props);
      this.defaultLanguage = null;
    }

    @bind
    onDelete({ label }) {
      this.props.onDelete(label);
    }

    renderMessage() {
      return this.props.message ? <DigMessage {...this.props.message} digId="message-import"/> : null;
    }

    renderExportOption() {
      const EXPORT_FILE_TYPE = {
        CSV: 'csv',
        XLSX: 'xlsx',
      };
      const contextMenuOptions = [
        {
          label: getSingleText('app.pages.forms.localization.exportContextMenu.csvFile'),
          callback: () => {
            this.props.onExport(EXPORT_FILE_TYPE.CSV);
          },
        },
        {
          label: getSingleText('app.pages.forms.localization.exportContextMenu.xlsxFile'),
          callback: () => {
            this.props.onExport(EXPORT_FILE_TYPE.XLSX);
          },
        }];

      return (<ContextMenu icon={DropDown} label={getSingleText('app.pages.forms.localization.exportContextMenu.label')} trigger={'click'} options={contextMenuOptions} />);
    }

    render() {

      const languages = this.props.availableLanguages || [];
      if (this.props.defaultLanguage && this.props.defaultLanguage !== this.defaultLanguage) {
        this.defaultLanguage = this.props.defaultLanguage;
      }

      return (
        <div>
          <DigTitle
            digLabel={getSingleText('app.pages.forms.localization.availableLanguagesTitle')}
            digClass='bold-title' />
          <br/>
          <DigTitle
            digLabel={getSingleText('app.pages.forms.localization.availableLanguagesDescription')}
            digClass='default-title language-sub-title' />

          {this.renderMessage()}

          <span className='language-interactions'>
            <div className='language-action-buttons'>
              { this.renderExportOption() }
              { renderImportButton(this.props.propertyId, this.props.formId, languages, this.props.onImportSuccess, this.props.onImportError, this.props.isReadOnly)}
              <DigButton digType='link' digOnClick={this.props.onAddLanguage} digIsDisabled={this.props.isReadOnly}>{getSingleText('app.pages.forms.localization.addLanguageButtonText')}</DigButton>
            </div>
          </span>

          <DigLangsList onDelete={this.onDelete} languages={languages} selectedLanguage={this.defaultLanguage} isReadOnly={this.props.isReadOnly}/>

        </div>
      );
    }
}
