import styled from 'styled-components';
import { WHITE, DARK_LIGHT, LIGHT_GRAY } from '../../../common/colors';
const NotificationStyle = styled.div`
.notification-header{
  p{

  color:${LIGHT_GRAY};
  font-size:14px;
  }
  .title{
    font-size:1rem;
  }
}
.code-example-container{
  background-color:${DARK_LIGHT};
  width:560px;
  height:160px;
  border-radius:6px;
  color:${WHITE};
  .code-example-text{
    padding:17px;
  }
}
`;
export default NotificationStyle;
