import styled from 'styled-components';
import { REQUIRED_RED } from '../../../../../../common/colors';

export default styled.div`
  display: flex;
  padding: 10px 0 15px;
  ${(props) => props.disabled ? 'pointer-events: none; opacity: 0.4;' : ''};

  & > div.field-row-label-container {
    flex: 1;
    margin: auto;
    position: relative;
    & > label {
      font-size: 14px;
      color: #7B7A7A;
      position: relative;
      ${({ required }) => required ? `
      &::after {
        content: '*';
        color: #D92D2D;
        position: absolute;
        right: -8px;
      }
      ` : ''}
    }
  }

  input.dig-input[type="text"], textarea {
    width: 300px;
    border-radius: 4px;
  }
  
  .button-settings-input-text{
    width: 275px !important;
  }
`;

const errorStyle = `
  input,textarea,select {
    border-color: ${REQUIRED_RED};
  }
`;

export const StyledField = styled.div`

  ${({ hasError }) => hasError ? errorStyle : ''}

  span.error-message {
    color: ${REQUIRED_RED};
    position: absolute;
  }

`;