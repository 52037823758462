import React from 'react';
import FieldRow from '../shared/FieldRow';
import ColorPicker from '@mdigital/components/dist/components/ColorPicker';

const DarkMode = () => (
  <React.Fragment>
    <FieldRow title="Text Color" name="mobileThemes.themeData.dark.palette.typography.headline" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
    <FieldRow title="Background" name="mobileThemes.themeData.dark.palette.core.canvas" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
    <FieldRow title="Button" name="mobileThemes.themeData.dark.palette.action.primary" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
    <FieldRow title="Button Text" name="mobileThemes.themeData.dark.palette.typography.action.primary" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
    <FieldRow title="Close Button" name="mobileThemes.themeData.dark.palette.action.auxiliary" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
  </React.Fragment>
);
export default  DarkMode;