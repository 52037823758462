import React from 'react';
import { StyledPackages } from './StyledPackages';
import ActionBar from '../../components/ActionBar';
import DigTable from '@mdigital/components/dist/components/Table';
import DigButton from '@mdigital/components/dist/components/Button';
import ViewTemplate from '@mdigital/components/dist/components/ViewTemplate';
import FileDownloader from '@mdigital/components/dist/components/FileDownloader';
import IconSvg from '@mdigital/components/dist/components/IconSvg';
import { getSingleText } from '../../../common/utils';
import { DATE_FORMAT } from '../../../common/constants';
import * as packagesActions from '../../../redux/actions/packages.actions';
import ZipIcon from '../../assets/Packages/zipIcon.svg';
import DownloadIcon from '../../assets/Packages/downloadIcon.svg';
import { connect } from 'react-redux';
import moment from 'moment';
import { API, PLACEHOLDER } from '../../../common/api';
import { getPropertyId } from '../../../redux/store/store-helper';
import ConfirmPackageModal from './confirmPackage';
import { PACKAGE_STATUS } from '../../../common/enums';

export class Packages extends React.Component{

  constructor(props) {
    super(props);
    this.state = { currentPackageConfirmationId: null };
  }

  componentDidMount() {
    this.props.fetchPackages();
  }

  PACKAGES_TABLE_COLUMNS = [
    { label: '' },
    { label: 'Name' },
    { label: 'CC Version' },
    { label: 'Date Created' },
    { label: 'Date Confirmed' },
    { label: '' }
  ];

  handleCreatePackage = () => this.props.generatePackage();

  handleConfirmPackage = (ev,packageId) => {
    ev.currentTarget.blur();
    this.setState({ currentPackageConfirmationId: packageId });
  }

  formatPackage = (currPackage, index) => {
    const { id, filename, version, creationDate, deploymentDate, status } = currPackage;
    const shouldHaveActions = index == 0;
    const shouldShowConfirmationBtn = shouldHaveActions && !deploymentDate && status === PACKAGE_STATUS.DOWNLOADED;
    return {
      id,
      values: [
        { value: <IconSvg icon={ZipIcon} width={40} height={40} isStatic={true}/> },
        { value: filename },
        { value: version },
        { value: moment(creationDate).format(DATE_FORMAT) },
        { value: deploymentDate ? moment(deploymentDate).format(DATE_FORMAT) : 'Not confirmed' },
        { value: shouldShowConfirmationBtn ? <DigButton id={'confirm-package'} digType="action" digOnClick={(ev) => this.handleConfirmPackage(ev,id) }>Confirm Deployment</DigButton> : null }
      ],
      actions: shouldHaveActions ? [{
        tooltip: 'Download Package',
        iconSvg: DownloadIcon,
        callback: this.handleDownloadPackage,
        autLabel: 'download-package',
      }] : [],
      rowObject: currPackage,
      cellMaxCharacters: 35,
      autLabel: id,
    };
  };

  handleCancelConfirmModal = () => {
    this.setState({ currentPackageConfirmationId: null });
  }

  handleApproveConfirmModal = () => {
    this.props.setPackageConfirmationDate(this.state.currentPackageConfirmationId);
    this.setState({ currentPackageConfirmationId: null });
  }

  renderPackages = () => {
    const packagesData = this.props.allPackages || [];

    if (packagesData.length) {
      return <div className={'table-container'}><DigTable digRows={packagesData.map(this.formatPackage)} digColumns={this.PACKAGES_TABLE_COLUMNS}/></div>;
    }else {
      return this.renderNoPackages();
    }
  };

  renderNoPackages = () => {
    const { title, subTitle } = getSingleText('app.pages.packages');
    const props = {
      imageSettings: { src: '../../../assets/images/zip.svg', style: { width: 135, height: 105 } },
      title,
      subTitle,
      actions: [],
    };

    return <div className={'no-packages-container'}><ViewTemplate viewWidth="500px" viewHeight="350px" {...props}/>
    </div>;
  }

  handleDownloadPackage = (currPackage) => this.props.triggerDownloadPackage(currPackage.id);

  handleDownloadEnded = (response) => this.props.endPackageDownload(response);

  renderFileDownloader = () => {
    const shouldDownloadPackage = !!this.props.packageToDownload;

    if(shouldDownloadPackage){
      const downloadUrlWithProperty = API.PACKAGES.DOWNLOAD.replace(PLACEHOLDER.PROPERTY_ID, getPropertyId());
      const fullDownloadUrl = downloadUrlWithProperty.replace(PLACEHOLDER.PACKAGE_ID, this.props.packageToDownload);
      const packagesData = this.props.allPackages || [];
      const downloadFilename = packagesData[0] && packagesData[0].filename;
      return <FileDownloader url={fullDownloadUrl} downloadFilename={downloadFilename} onSuccess={(response) => {
        this.handleDownloadEnded(response);
        this.props.fetchPackages();
      }} onError={this.handleDownloadEnded}/>;
    } else{
      return null;
    }
  };

  render() {
    return (
      <StyledPackages>
        <ConfirmPackageModal
          isShown={!!this.state.currentPackageConfirmationId}
          onOkClicked={this.handleApproveConfirmModal}
          onCancelClicked={this.handleCancelConfirmModal}
        />
        <div className={'packages-container'}>
          <div className={'action-bar'}>
            <ActionBar
              title="Packages"
              actionButtonText={this.props.isPolling ? 'Creating package' : 'Create Package'}
              shouldHideSearch={true}
              onAction={this.handleCreatePackage}
              isButtonLoading={this.props.isPolling} />
          </div>
          {this.renderPackages()}
          {this.renderFileDownloader()}
        </div>
      </StyledPackages>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    generatePackage: () => dispatch(packagesActions.generatePackage()),
    fetchPackages: () => dispatch(packagesActions.fetchPackages()),
    triggerDownloadPackage: (id) => dispatch(packagesActions.triggerDownloadPackage(id)),
    endPackageDownload: (downloadResponse) => dispatch(packagesActions.endPackageDownload(downloadResponse)),
    setPackageConfirmationDate: (id) => dispatch(packagesActions.setPackageConfirmationDate(id)),
  };
}

function mapStateToProps(state) {
  const { packages } = state;
  return {
    isPolling: packages.isPolling,
    allPackages: packages.allPackages,
    packageToDownload: packages.packageToDownload,
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(Packages);

