import React from 'react';
import PropTypes from 'prop-types';
import MobileEngagementDesign from './index';
import AnywhereEngagementDesign from './AnywhereEngagementDesign';

const propertyTypeAnywhere = 'anywhere';

const EngagementDesignRenderer = ({ propertyType, engagementType,
  customIntercept, mutators, helpCenterLinks , isDarkModeEnabled, isNativeAlertRevamp, isReadOnlyMode }) =>(
  propertyType === propertyTypeAnywhere ? 
    <AnywhereEngagementDesign isReadOnlyMode={isReadOnlyMode}/>
    :
    <MobileEngagementDesign
      customInterceptSupported={customIntercept}
      propertyType={propertyType}
      mutators={mutators}
      engagementType={engagementType}
      helpCenterLinks = {helpCenterLinks}
      isDarkModeEnabled={isDarkModeEnabled}
      isNativeAlertRevamp={isNativeAlertRevamp}
      isPushNotificationsSupported={true}/>
);

EngagementDesignRenderer.propTypes = {
  engagementType: PropTypes.string,
  propertyType: PropTypes.oneOf(['mobileIOS', 'anywhere' , 'mobileAndroid']),
  mutators: PropTypes.object , 
  helpCenterLinks: PropTypes.object,
  isDarkModeEnabled: PropTypes.bool,
  isNativeAlertRevamp: PropTypes.bool,
  isReadOnlyMode: PropTypes.bool,
};
  
EngagementDesignRenderer.defaultProps = {
  engagementType: null,
  propertyType: null,
  mutators: {},
  helpCenterLinks : {},
  isDarkModeEnabled: false,
  isNativeAlertRevamp: false,
  isReadOnlyMode: false,
};

export default EngagementDesignRenderer;