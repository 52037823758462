import styled from 'styled-components';
import { DISABLED_GRAY } from '../../../../../common/colors';

export default styled.div`
  padding-top: 12px;
  display: flex;

  div.duration-input {
    flex: 1;
    display: flex;
    justify-content: center;
    label {
      padding: 0 5px;
      font-size: 11px;
      padding-top: 8px;
    }
    input {
      width: 55px;
      height: 32px;

      &:disabled {
        color: ${DISABLED_GRAY};

        & + label {
          color: ${DISABLED_GRAY};
        }
      }
    }
  }

  div.is-sticky-cb {
    display: flex;
    padding-top: 6px;

    p {
      padding-top: 2px;
      margin-right: 20px;
    }
  }
`;