import { getNestedProperty } from '@mdigital/common/dist/utils/object';


export function mapAutoSuggestOptionToValue(option) {
  switch (option) {
    case 'Greater Than':
      return 'greaterThan';
    case 'Equals':
      return 'equals';
    case 'Smaller Than':
      return 'smallerThan';
    default:
      return option;
  }
}

export function mapAutoSuggestValueToLabel(url, rules) {
  const value = getNestedProperty(rules, url, '')
    ? getNestedProperty(rules, url, '')
    : '';
  switch (value) {
    case 'greaterThan':
      return { value: 'Greater Than', label: 'Greater Than' };
    case 'equals':
      return { value: 'Equals', label: 'Equals' };
    case 'smallerThan':
      return { value: 'Smaller Than', label: 'Smaller Than' };
    default:
      return value;
  }
}



// input validation

export function isFrequencyValueValid(frequency) {
  const value = parseInt(frequency);
  if (isNaN(value)) {return false;}
  return value <= 100 && value > 0;
}

export function isNumberPositive(number) {
  return !isNaN(number) && number > 0;
}