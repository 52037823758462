"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubjectDefinition = void 0;
const operators_1 = require("../operators");
const types_1 = require("../types");
const optionsFromValues = (values) => {
    return values.map((item) => ({
        label: `${item}`,
        value: `${item}`,
    }));
};
const getSubjectDefinition = (type, customParamType, nebUuid, componentType, components) => {
    var _a, _b, _c, _d;
    if (type == null) {
        return undefined;
    }
    else if (type === 'custom-param') {
        switch (customParamType) {
            case 'Boolean':
                return {
                    operators: operators_1.booleanOperators,
                    valueType: 'select',
                    values: [
                        {
                            label: 'True',
                            value: 'true',
                        },
                        {
                            label: 'False',
                            value: 'false',
                        }
                    ],
                };
            case 'Number':
                return {
                    operators: operators_1.gradingOperators,
                    valueType: 'number',
                };
            case 'Text':
                return {
                    operators: operators_1.stringOperators,
                    valueType: 'text',
                };
            case 'Datetime':
                return {
                    operators: operators_1.dateOperators,
                    valueType: 'date',
                };
            default:
                return undefined;
        }
    }
    else if (type === 'component') {
        const component = components.filter((item) => item.nebUuid === nebUuid)[0];
        switch (componentType) {
            case types_1.FormComponents.CHECKBOX:
                return {
                    operators: operators_1.checkboxOperators,
                    valueType: 'multichoice',
                    values: (_b = (_a = component === null || component === void 0 ? void 0 : component.options) === null || _a === void 0 ? void 0 : _a.filter((item) => item.hidden !== true).map((item) => ({
                        label: item.label,
                        value: item.id,
                    }))) !== null && _b !== void 0 ? _b : [],
                };
            case types_1.FormComponents.TEXT_INPUT:
            case types_1.FormComponents.TEXT_AREA:
            case types_1.FormComponents.EMAIL_INPUT:
                return {
                    operators: operators_1.stringOperators,
                    valueType: 'text',
                };
            case types_1.FormComponents.DATE:
                return {
                    operators: operators_1.dateOperators,
                    valueType: 'date',
                };
            case types_1.FormComponents.RADIO:
            case types_1.FormComponents.SELECT:
                return {
                    operators: operators_1.selectOperators,
                    valueType: 'select',
                    values: (_d = (_c = component === null || component === void 0 ? void 0 : component.options) === null || _c === void 0 ? void 0 : _c.filter((item) => item.hidden !== true).map((item) => ({
                        label: item.label,
                        value: item.id,
                    }))) !== null && _d !== void 0 ? _d : [],
                };
            case types_1.FormComponents.GRADING0TO10:
                return {
                    operators: operators_1.gradingOperators,
                    valueType: 'select',
                    values: optionsFromValues([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
                };
            case types_1.FormComponents.GRADING1TO5:
                return {
                    operators: operators_1.gradingOperators,
                    valueType: 'select',
                    values: optionsFromValues([1, 2, 3, 4, 5]),
                };
            case types_1.FormComponents.GRADING1TO7:
                return {
                    operators: operators_1.gradingOperators,
                    valueType: 'select',
                    values: optionsFromValues([1, 2, 3, 4, 5, 6, 7]),
                };
            case types_1.FormComponents.GRADING1TO10:
                return {
                    operators: operators_1.gradingOperators,
                    valueType: 'select',
                    values: optionsFromValues([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
                };
            case types_1.FormComponents.NPS:
                return {
                    operators: operators_1.gradingOperators,
                    valueType: 'select',
                    values: optionsFromValues([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
                };
            case types_1.FormComponents.SCREEN_CAPTURE:
                return {
                    operators: operators_1.screenCaptureOperators,
                    valueType: undefined,
                };
            case types_1.FormComponents.MEDIA_CAPTURE:
                return {
                    operators: operators_1.mediaCaptureOperators,
                    valueType: undefined,
                };
        }
    }
    else {
        return undefined;
    }
};
exports.getSubjectDefinition = getSubjectDefinition;
