import styled from 'styled-components';

export default styled.div`
  display: flex;
  & > div {
    display: flex;

    input {
      width: 58px;
    }

    & > span {
      
      &:nth-child(1) {
        width: 190px;
        padding-right: ${({ isFeedbackSubmittedSelected }) => `${isFeedbackSubmittedSelected ? 0 : 25}px`};
      }

      &:nth-child(2) {
        width: 150px;
        padding-right: 8px;
      }
    }

    &.target-by-rating-time-frame {
      & > span {
        padding: 0;
        &.target-by-rating-time-frame-value {
          width: 58px;
        }
        &.target-by-rating-time-frame-units {
          width: 100px;
          margin-left: -1px;
        }
      }
    }
  }

  label {
    padding: 8px 8px 0 25px;
  }
`;