import { EditingLockedForm } from './EditingLockedForm';
import { EditingLiveForm } from './EditingLiveForm';
import { ModalsService } from '@mdigital/components/dist/utils/modals';
import { checkProvision } from '../../../redux/store/store-helper';

const CARD_FORM_TYPE = {
  LOCKED_FORM: 'lockedForm',
  EDIT_LIVE_FORM: 'editLiveForm',
  CLOSE_MODAL: 'closeModal',
};

const getModalType = ({ isLocked, published }) => {
  const isSurveyDraftEnable = checkProvision(DigProvisions.ENABLE_SURVEY_DRAFTS);
  if (isSurveyDraftEnable){
    return CARD_FORM_TYPE.CLOSE_MODAL;
  }
  if (isLocked) {
    return CARD_FORM_TYPE.LOCKED_FORM;
  }
  if (published) {
    return CARD_FORM_TYPE.EDIT_LIVE_FORM;
  }
  return '';
};

const FormsCardModals = (props) => {
  const modalType = getModalType(props.form);
  switch (modalType) {
    case CARD_FORM_TYPE.LOCKED_FORM:
      return EditingLockedForm(props);
    case CARD_FORM_TYPE.EDIT_LIVE_FORM:
      return EditingLiveForm(props);
    case CARD_FORM_TYPE.CLOSE_MODAL:
      ModalsService.closeModals();
      props.onContinue();
      break;
    default:
      props.onContinue();     
  }
};

export const configureFormsCardModals = (props) => {
  return FormsCardModals(props);
};
