import React from 'react';
import PublishErrorModal from './PublishErrorModal';

const CLOSE_PUBLISH_ERROR_BTN = 'close_errorModal_btn';

export default  function CustomNoticeModalContainer({ isShown,handleCloseModal }){
  const dynamichErrorButtonProps = {
    [CLOSE_PUBLISH_ERROR_BTN]: {
      digOnClick:handleCloseModal,
    },
  };

  return(<PublishErrorModal onCancelClicked={handleCloseModal} isShown={isShown} dynamicButtonsProps={dynamichErrorButtonProps}/>);
}

