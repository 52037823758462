import styled from 'styled-components';

export default styled.div`
  margin-top: 40px;
`;

export const StyledGeneralSettings = styled.div``;

export const StyledPositionDropdown = styled.div`
  position: absolute;
  right: 0;
  top: 11px;
  width: 200px;
`;

export const StyledButtonsSettings = styled.div``;

export const StyledCornerRadiusSlider = styled.div``;

export const StyledVibrationToggle = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 16px;
`;