import React from 'react';
import { FormSpy } from 'react-final-form';
import Toggle from '@mdigital/components/dist/components/Toggle';
import Input from '@mdigital/components/dist/components/Input';
import ColorPicker from '@mdigital/components/dist/components/ColorPicker';
import StyledButtonSettings from './StyledButtonSettings';
import DeactivatedButtonSettings from './DeactivatedButtonSettings';
import FieldRow from '../shared/FieldRow';

const ButtonFields = () => (
  <React.Fragment>
    <FieldRow required title="Accept Button" name="trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonText" errorMessage="Please provide button text" render={({ input }) => (
      <Input maxLength={25} enableCounter digValue={input.value} digOnChange={input.onChange} digClass="button-settings-input-text" />
    )} />
    <FieldRow title="Accept Button Text Color" name="trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonTextColor" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop  {...input} onChange={({ hex }) => input.onChange(hex)}  />
    )} />
    <FieldRow title="Accept Button Button Color" name="trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonBackgroundColor" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop  {...input} onChange={({ hex }) => input.onChange(hex)}  />
    )} />
    <FieldRow title="Close Button Color" name="trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.closeButtonColor" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop  {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
  </React.Fragment>
);

const ButtonSetting = () => (
  <StyledButtonSettings>
    <FieldRow title="Button Display" name="trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.buttonsDisplay" render={({ input }) => (
      <Toggle onToggle={() => input.onChange(!input.value)} switchState={input.value} />
    )} />

    <FormSpy subscription={{ values: true }} render={({ values }) => (
      values.trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.buttonsDisplay ? <ButtonFields /> : <DeactivatedButtonSettings />
    )} />
  </StyledButtonSettings>
);

export default ButtonSetting;
