import styled from 'styled-components';

export const StyledThankYouPrompt = styled.div`
    display: flex;
    padding: 25px 25px 0;
    height: 644px;
    input, textarea, select {
        &:focus {
            outline: none;
        }
    }
`;