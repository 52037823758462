import styled from 'styled-components';

export const StyeldMediaCaptureFormatsContainer = styled.div`
     display: flex;
     justify-content: space-evenly;

     .media-capture-formats{
        background-color: #EDEDED;
        width: 99%;
        text-align: center;
        height: 39px;

        svg{
        margin-top: 7px;
        }
     }

     .media-capture-formats:first-child{
          border-radius: 3px 0px 0px 3px;
     } 
     .media-capture-formats:last-child{
          border-radius: 0px 3px 3px 0px;
          margin-right: 2px;
     }
     .media-capture-formats:only-child{
          border-radius: 3px;
     }  
     .media-capture-formats:not(:last-child){
          margin-right: 4px;
     }        
`;

export const StyledMediaCaptureVideoLimit = styled.div`
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 3px;
    text-align: center;
`;

export const StyledMediaCaptureVideoLimitText = styled.span`
     margin-right: 3px;
`;

