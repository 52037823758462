import React, { useContext } from 'react';
import { Input, TextArea } from '@m/alchemy-ui';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { getSingleText } from '../../../../../../../../../common/utils';
import { TriggerContext } from '../../../../../../index';
import { PanelSubHeader } from '../../../../../../StyledTriggeringModal';
import TargetingField from '../../../../../Shared/TargetingField';
import { TargetingActions } from '../../../../Actions';
import { TextPathPrefix } from '../../../../index';
import {
  StyledContentContainer, StyledSubTitle,
  StyledTargetingSettingsContainer
} from '../../../../StyledTriggerByTargeting';

export const BannerBasicFields = () => {
  const { triggerState , triggerDispatch, isReadOnly } = useContext(TriggerContext);
  return (
    <StyledTargetingSettingsContainer>
      <StyledContentContainer>
        <StyledSubTitle>
          <PanelSubHeader>{getSingleText(`${TextPathPrefix}.content`)}</PanelSubHeader>
        </StyledSubTitle>
        <TargetingField
          required={true}
          className="custom-field-margin"
          label="Title Text"
          errorMessage="Title is required"
          inputMaxLength={'30'}
          inputCounter={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.invitationTitle').length}
          disabled={isReadOnly}
          render={<Input
            maxLength={30}
            value={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.invitationTitle') || ''}
            placeholder={getSingleText(`${TextPathPrefix}.titlePlaceholder`)}
            onChange={ (e) => triggerDispatch({ type: TargetingActions.SET_TITLE_TEXT , payload : e.target.value })} />}
        />
        <TargetingField
          required={true}
          label="Description Text"
          errorMessage="Description is required"
          disabled={isReadOnly}
          render={<TextArea
            maxLength={70}
            value={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.invitationBody') || ''}
            placeholder={getSingleText(`${TextPathPrefix}.descriptionPlaceholder`)}
            onChange={ (e) => triggerDispatch({ type: TargetingActions.SET_DESCRIPTION_TEXT , payload : e.target.value })} />}
        />
      </StyledContentContainer>

    </StyledTargetingSettingsContainer>
  );
};