import React from 'react';
import Tooltip from '@mdigital/components/dist/components/Tooltip';

const QuestionMarkIcon = () => <i className="fa fa-question-circle"/>;

const QuestionMarkTooltip = ({ text = '', style = {}, htmlStyle = {} }) => (
  <Tooltip digTooltip={text} digStyle={style} digHtmlStyle={htmlStyle} digElement={QuestionMarkIcon}/>
);

export default QuestionMarkTooltip;
