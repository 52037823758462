import React from 'react';
import { getSingleText } from '../../../common/utils';
import { ModalsService } from '@mdigital/components/dist/utils/modals';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import DigButton from '@mdigital/components/dist/components/Button';
import { COLORS } from '../../../common/colors';
import { connect } from 'react-redux';
import FormV2EnablementModal from './FormV2EnablementModal';
import { checkProvision } from '../../../redux/store/store-helper';
import { FormV2EnablementLoadingModal } from './FormV2EnablementLoadingModal';
import HttpManager from '../../../services/HttpManager';
import { dispatch } from '../../../redux/store/store-helper';
import { getProfile } from '../../../redux/actions/profile.actions';

//#region filters
const isCss = ({ fileType }) => fileType === 'css';
const isDeployed = ({ isDeployed }) => isDeployed;
//#endregion

const TEXTS = {
  DONE: {
    TITLE: 'Success!',
    DESCRIPTION: 'Update is successfully completed',
  },
  FAILURE: {
    TITLE: 'Something went wrong',
    DESCRIPTION: 'We were unable to upgrade your property, please reach out to Medallia support',
    ERROR_CODES: {
      cantUpgradePropertyBeforeGeneratingNewPackage: 'Package deployment is required',
    },
  },
};

const UPGRADE_STATUS = {
  SUCCESS: 'DONE',
  FAILURE: 'FAILURE',
};

function FormV2Enablement(props) {
  const { propertyId, resources } = props;

  function openModal() {
    const isDhhProvisioned = checkProvision('dynamicHybridHosting');
    let reusableCssResources = resources.filter(isCss);

    if (isDhhProvisioned) {
      reusableCssResources = reusableCssResources.filter(isDeployed);
    }

    ModalsService.showCustomModal(FormV2EnablementModal, {
      reusableCssResources,
      submit: handleSubmit,
    });
  }

  function handleSubmit(data) {
    ModalsService.closeModals();

    const url = `/kma/api/property/${propertyId}/upgradeToV2Form`;
    HttpManager.post(url, data)
      .then(showUpgradeInProgress)
      .catch(onUpgradeFailed);
  }

  function showUpgradeInProgress() {
    ModalsService.showCustomModal(FormV2EnablementLoadingModal, {
      propertyId,
      submit: showUpgradeConfirmation,
    });
  }

  function onUpgradeFailed(response) {
    const errorMessage = getNestedProperty(TEXTS, `${UPGRADE_STATUS.FAILURE}.ERROR_CODES.${response.kampyleId}`);
    showUpgradeConfirmation(UPGRADE_STATUS.FAILURE, errorMessage);
  }

  function showUpgradeConfirmation(status = UPGRADE_STATUS.SUCCESS, customMessage) {
    ModalsService.closeModals();
    const isUpgradeSuccessful = status === UPGRADE_STATUS.SUCCESS;

    ModalsService.showConfirmation({
      type: isUpgradeSuccessful ? 'success' : 'danger',
      title: TEXTS[status].TITLE,
      description: customMessage || TEXTS[status].DESCRIPTION,
    	close: {
    		callback: () => {
          if (isUpgradeSuccessful) {
            // update profile with new provisions,
            // after successful upgrade, provision: 21dcr2_MPC2743_enable_newLiveForm (formV2) should be true
            dispatch(getProfile());
          }
        },
      },
    });
  }

  return (
    <DigButton digType='primary'
							 digClassName='action-upgrade-form'
							 digAutLabel='upgrade-form-btn'
							 digOnClick={openModal}
							 digTextColor={COLORS.WHITE}>
      {getSingleText('app.topMenu.sdkV2.announcementBanner.buttonText')}
    </DigButton>
  );
}

function mapStateToProps({ profile, resources }) {
  return {
    propertyId: profile.propertyId,
    resources: resources.allResources || [],
  };
}

export default connect(mapStateToProps)(FormV2Enablement);
