import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import StyledFieldRow, { StyledField } from './StyledFieldRow';
import { isRequired } from '../../../../../../common/utils/fieldValidators';

const FieldRow = ({ title, name, required, render, errorMessage, children , disabled, className }) => (
  disabled ||
  <StyledFieldRow required={required} disabled={disabled} className={className}>
    <div className="field-row-label-container">
      <label>{title}</label>
    </div>
    <Field parse={(value) => (value === '' ? '' : value)} validate={required && isRequired} name={name} render={({ input, meta: { error } }) => (
      <StyledField hasError={error}>
        {render ? render({ input, error }) : React.cloneElement(children, { ...input, error })}
        {error && <span className="error-message">{errorMessage}</span>}
      </StyledField>
    )}/>
  </StyledFieldRow>
);

FieldRow.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  render: PropTypes.func,
  errorMessage: PropTypes.string,
  disabled : PropTypes.bool,
};

FieldRow.defaultProps = {
  render: null,
  required: false,
  errorMessage: 'Field is required',
  disabled:false,
};

export default FieldRow;