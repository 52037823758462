import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';
export const StyledMediaCaptureAccessibility = styled.div`
    margin-top: 14px;
`;

export const StyledMediaCaptureAccessibilityTitle = styled.label`
    color: ${COLORS.TEXT_HOVER};
    font-weight: 600;
    margin-bottom: 0px;
`;

export const StyledMediaCaptureAccessibilityTab = styled.div`
    margin-top: 10px;
    input{
        width: 340px;
        padding-right: 55px;
    }
`;

export const StyledMediaCaptureAccessibilityTabTitle = styled.label`
    color: ${COLORS.TEXT_HOVER};
`;

export const StyledRequiredIcon = styled.span`
    &:before{
        content: "*";
        display: inline;
        color: #E6674A;
        margin-right: 3px;
    }
`;

export const StyledHasErrorIcon = styled.span`
    &:before{
        content: "\f00d";
        display: inline-block;
        font-family: FontAwesome;
        color: #C82E29;
        margin-right: 3px;
    }
`;

export const StyledAccessibilityErrorMessage = styled.span`
    display: inline-block;
    margin-top: 3px;
    color : ${COLORS.UPLOAD_ERR}
`;