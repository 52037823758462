import React from 'react';
import PropTypes from 'prop-types';
import { StyledThankYouPromptImageContainer, StyledThankYouPromptImageUpload , StyledThankYouPromptImageInputUpload ,
  StyledThankYouPromptImageSpan } from './StyledThankYouPromptGeneralSettingsImageUpload';
import Checkbox from '@mdigital/components/dist/components/Checkbox';
import { FileUploader } from '@mdigital/components/dist/components/FileUploader';
import Button from '@mdigital/components/dist/components/Button';
import Input from '@mdigital/components/dist/components/Input';
import { getSingleText } from '../../../../../../common/utils';
import { Field, FormSpy } from 'react-final-form';
import { setThankYouPromptImage } from '../../utils';

const uploaderSettings = {
  url: '/kma/api/upload',
  accept: ['.jpeg', '.jpg' , '.png' , '.gif'],
  body: {},
  onError: (ev) => ev,
};

const maxFileSize = 2000000;

const NO_FILE_TEST = 'No File...';
class ThankYouPromptImageUpload extends React.Component {

    state = {
      errorMessage : null,
      isImageSelected: this.props.fileName !== NO_FILE_TEST,
    }

    setImageDataContract = (ev , sameDarkModeImageAsLight) =>{
      if (ev.resultCode === 'FAILURE') {
        this.onErrorFileUploader(ev);
        return;
      }
      const file = ev.file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const imageData = {
          name: ev.name,
          uuid: ev.uuid ,
          url : reader.result,
          resourceId : null,
        };
        setThankYouPromptImage(this.props.isDarkModeImage , sameDarkModeImageAsLight, this.props.mutators.setImageDataValues , this.props.mutators.setDarkImageDataValues , imageData);
        this.setState({ errorMessage : null, isImageSelected: true });
      };
    }

    removeImage = (removeBoth = false)=> {
     
      const imageData = {
        name : '',
        uuid : '',
        url : '',
        resourceId : null,
      };
      if (removeBoth) {
        this.props.mutators.setDarkImageDataValues(imageData);
        this.props.mutators.setImageDataValues(imageData);
      } else {
        this.props.isDarkModeImage ? this.props.mutators.setDarkImageDataValues(imageData) : this.props.mutators.setImageDataValues(imageData);
        if (!(this.state.isImageSelected && confirm('Are you sure you would like to delete this image?'))) {
          return;
        }
      }
      this.setState({ isImageSelected: false });
    }

    onErrorFileUploader = ()=>{
      const errorMessage = getSingleText('app.imageUpload.alerts.internalError');
      this.setState({ errorMessage });
      this.removeImage(true);
    }

    isContractExists = (values) => {
      const imageContract = this.props.isDarkModeImage ? values.trigger.mobileThankYouPromptDarkImageDataContract : values.trigger.mobileThankYouPromptImageDataContract;
      return imageContract && Object.keys(imageContract).length > 0;
    }

    render(){
      const handleUploadClicked = (ev) => {
        if (this.state.isImageSelected && !confirm('Are you sure you would like to override the existing image?')) {
          ev.preventDefault();
          ev.stopPropagation();
        }
      };

      return(
        <FormSpy subscription={{ values: true }} render={({  values }) => (
          <StyledThankYouPromptImageContainer disabled={values.trigger.sameDarkModeImageAsLight && this.props.isDarkModeImage}>
            <StyledThankYouPromptImageUpload>
              <Field name="trigger.isImageDisplay" render={({ input }) => (
                <Checkbox
                  label="Image"
                  digIsChecked={input.value}
                  handleCheckboxChange={input.onChange}
                  isDisabled={this.props.disabled || (values.trigger.sameDarkModeImageAsLight && this.props.isDarkModeImage)}
                  info={getSingleText('app.pages.forms.builder.formSettingsModal.thankYouPrompt.imageInfo')}/>
              )} />
              <Field name={`trigger.${this.props.isDarkModeImage ? 'mobileThankYouPromptDarkImageDataContract' : 'MobileThankYouPromptImageDataContract'}`} render={({ input }) => (
                <StyledThankYouPromptImageInputUpload textColor={this.isContractExists(values)}>
                  {this.isContractExists(values) && values.trigger.isImageDisplay &&
                    <i className="neb-icon-Close image-uploader-close-button" onClick={this.removeImage}/> }
                  <Input digPlaceholder={values.trigger.isImageDisplay ? this.props.fileName : ''} digClass="image-uploader-input"
                    digIsDisabled={!values.trigger.isImageDisplay} digOnChange={input.onChange}  />
                  <FileUploader {...uploaderSettings} onError={this.onErrorFileUploader} maxFileSize={maxFileSize}
                    onResponse={(ev) => this.setImageDataContract(ev , values.trigger.sameDarkModeImageAsLight)}>
                    <Button digOnClick={handleUploadClicked} digClassName="image-uploader-button" digType="primary" digIsDisabled={!values.trigger.isImageDisplay}>
                      {getSingleText('app.pages.forms.builder.formSettingsModal.thankYouPrompt.imageUploaderButton')}
                    </Button>
                  </FileUploader>
                </StyledThankYouPromptImageInputUpload>
              )} />
            </StyledThankYouPromptImageUpload>
            <StyledThankYouPromptImageSpan hasError={this.state.errorMessage && values.trigger.isImageDisplay}>
              { this.state.errorMessage && values.trigger.isImageDisplay ? this.state.errorMessage :
                getSingleText('app.pages.forms.builder.formSettingsModal.thankYouPrompt.imageUploaderText') }
            </StyledThankYouPromptImageSpan>
          </StyledThankYouPromptImageContainer>
        )} />
      );
    }
}

ThankYouPromptImageUpload.propTypes = {
  mutators: PropTypes.object,
  fileName: PropTypes.string,
  disabled: PropTypes.bool,
  isDarkModeImage: PropTypes.bool,
};

ThankYouPromptImageUpload.defaultProps = {
  mutators: {},
  fileName: '',
  disabled: false,
  isDarkModeImage: false,
};

export default ThankYouPromptImageUpload;
