import styled from 'styled-components';

export const StyledLoginHeader = styled.div`
  text-align: center;
  z-index: 1;
  position: relative;
  
  .login-title {
    font-size: 20px;
    margin-top: 30px;
    width: 100%;
    align-self: center;
    align-content: center;
    align-items: center;
    line-height:; 1;
  }
`;