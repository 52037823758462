import SelectionPage from './SelectionPage';
import TriggerByCode from './MobileTriggering/TriggerByCode';
import TriggerByTargeting from './MobileTriggering/TriggerByTargeting';
import { TRIGGER_TYPE } from '.';

const TriggerTypeRenderer = (triggerType) => {
  switch (triggerType) {
    case TRIGGER_TYPE.TRIGGER_BY_CODE:
      return TriggerByCode;

    case TRIGGER_TYPE.TRIGGER_BY_TARGETING:
      return TriggerByTargeting;

    default:
      return SelectionPage;
  }
};
export default TriggerTypeRenderer;