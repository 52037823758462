import React, { useContext } from 'react';
import { TriggerContext } from '../../../../../..';
import { Switch , Input, ColorField, Tooltip, HelpIcon } from '@m/alchemy-ui';
import { StyledButtonSwitchContainer, StyledButtonHeader } from './StyledButtonSettings';
import TargetingField from '../../../../../Shared/TargetingField';
import { TargetingActions } from '../../../../Actions';
import { BANNER_INITIAL_COLOR_VALUES } from '../../../../../../../../assets/TriggeringModal/InitialFormTriggeringData';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { TextPathPrefix } from '../../../..';
import { getSingleText } from '../../../../../../../../../common/utils';
import { convertAlchemyColorFieldToHex } from '../../../../../../../../utils/colorHexaUtils';

const ButtonSettings = () => {
  const { triggerState , triggerDispatch, isReadOnly } = useContext(TriggerContext);
  return (
    <StyledButtonSwitchContainer>
      <StyledButtonHeader>
        <TargetingField
          labelStyle="bodyM"
          className="custom-button-display"
          disabled={isReadOnly}
          render={
            <Switch
              checked={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.buttonsDisplay')}
              labelOff="Display Buttons"
              labelOn="Display Buttons"
              labelPosition="right"
              size="small"
              onChange={(e) => triggerDispatch({ type : TargetingActions.SET_BUTTON_DISPLAY , payload: e.target.checked })} /> 
          }
        />
        <Tooltip
          trigger={
            <HelpIcon aria-label="Information Icon" size="12" palette="structural" />
          }
          placement="right"
          contents={getSingleText(`${TextPathPrefix}.tooltip.buttonDisplay`)}
        />
      </StyledButtonHeader>
      <TargetingField
        required
        label="Button Text"
        errorMessage="Please provide button text"
        inputMaxLength={'25'}
        inputCounter={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonText').length}
        disabled={isReadOnly}
        render={<Input maxLength={25} value={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonText')} 
          onChange={ (e)=> triggerDispatch({ type : TargetingActions.SET_ACCEPT_BUTTON_TEXT , payload: e.target.value })} />}
      />

      <TargetingField
        label="Text"
        layoutVariant="inline"
        disabled={isReadOnly}
        render={<ColorField 
          hasVariantLabel={false}
          defaultColor={BANNER_INITIAL_COLOR_VALUES.customBannerButtonsSettingsContract.acceptButtonTextColor}
          value={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonTextColor') || BANNER_INITIAL_COLOR_VALUES.customBannerButtonsSettingsContract.acceptButtonTextColor}
          onColorChange={({ color }) => {
            triggerDispatch({ type: TargetingActions.SET_ACCEPT_BUTTON_TEXT_COLOR, payload : convertAlchemyColorFieldToHex(color) });
          }}
          stringMap={{}}/>} />

      <TargetingField
        label="Background"
        layoutVariant="inline"
        disabled={isReadOnly}
        render={<ColorField 
          hasVariantLabel={false}
          defaultColor={BANNER_INITIAL_COLOR_VALUES.customBannerButtonsSettingsContract.acceptButtonBackgroundColor}
          value={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonBackgroundColor') || BANNER_INITIAL_COLOR_VALUES.customBannerButtonsSettingsContract.acceptButtonBackgroundColor}
          onColorChange={({ color }) => {
            triggerDispatch({ type: TargetingActions.SET_ACCEPT_BUTTON_BACKGROUND_COLOR, payload: convertAlchemyColorFieldToHex(color) });
          }}
          stringMap={{}}/>} />

      <TargetingField
        label="Close Button"
        layoutVariant="inline"
        disabled={isReadOnly}
        render={<ColorField 
          hasVariantLabel={false}
          defaultColor={BANNER_INITIAL_COLOR_VALUES.customBannerButtonsSettingsContract.closeButtonColor}
          value={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.customBannerButtonsSettingsContract.closeButtonColor') || BANNER_INITIAL_COLOR_VALUES.customBannerButtonsSettingsContract.closeButtonColor}
          onColorChange={({ color }) => {
            triggerDispatch({ type : TargetingActions.SET_CLOSE_BUTTON_COLOR , payload : convertAlchemyColorFieldToHex(color) });
          }}
          stringMap={{}}/>} />

    </StyledButtonSwitchContainer>
  );
};

export default ButtonSettings;
