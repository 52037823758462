import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { LOGIN_VIEWS } from '../Login';
import { getSingleText } from '../../../../common/utils';
import * as authActions from '../../../../redux/actions/auth.actions';

class LoginScreen extends React.Component{
  state = { isLoginInProcess: false };

  handleSubmit = ({ email, password }) => {
    this.setState({ isLoginInProcess: true });
    this.props.onSubmit({ email,password },
      getSingleText('app.pages.mdLogin.requiredMessage'),
      authActions.login);
  };

  componentDidUpdate(prevProps) {
    if (this.props.loginError &&
        this.props.loginError !== prevProps.loginError) {
      this.setState({ isLoginInProcess: false });
    }
  }

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Field
              className="md-login-form-field"
              name="email"
              component="input"
              type="text"
              placeholder="Email"
              data-aut="login-username"/>
            <Field
              className="md-login-form-field"
              name="password"
              component="input"
              type="password"
              placeholder="Password"
              data-aut="login-password"/>
            <button className='md-login-btn'
              type="submit"
              data-aut="md-login-btn">
              { this.state.isLoginInProcess && !this.props.loginError ?
                <React.Fragment>
                  <img className='md-login-spinner' src="/react/assets/Login/Spinner.gif"/>
                  {getSingleText('app.pages.mdLogin.login.logging')}
                </React.Fragment> :
                getSingleText('app.pages.mdLogin.login.title') }
            </button>
            <button className='md-login-step-btn md-login-step md-sans-semibold'
              type="button"
              onClick={() => this.props.onForgotClick(LOGIN_VIEWS.FORGOT_PASSWORD)}>
              {getSingleText('app.pages.mdLogin.login.navigateToForgot')}
            </button>
          </form>
        )}
      />);
  }
}

function mapStateToProps(state){
  const { auth: { loginError } } = state;
  return {
    loginError,
  };
}

export default connect(mapStateToProps)(LoginScreen);

LoginScreen.propTypes = {
  onForgotClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

