import styled from 'styled-components';
import { COLORS } from '../../../common/colors';
import { NAVBAR_AND_BTNS_ROWS_HEIGHT } from '../../../common/constants';

export const StyledPackages = styled.div`
  .no-packages-container {
    margin-top: 150px;
  }
  
  .action-bar {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }
  
  .table-container {
    width: 100%;
    height: calc(100vh - ${NAVBAR_AND_BTNS_ROWS_HEIGHT}px);
    overflow-y: auto;
    
    > div {
      padding: 50px 0;
    }
  #confirm-package {
    font-weight: 400;
  }
    
  .dig-rows-container {
    overflow-y: hidden;
  } 
  
  .dig-rows-container > div {
    border: 1px solid ${COLORS.TABLE.ROW.BORDER};
    height: 52px;
    margin-top: 5px;
    align-items: center;
    
    [data-aut="download-package"]{
      display: inline-block;
    }
    
    .dig-actions{
      justify-content: flex-start;
    }

    > span {
      :nth-child(1) {
        padding: 10px 3px 6px 3px;
        flex: 0.4;
      }
      :nth-child(2),nth-child(7) {
        flex: 1.2;
      }
      :nth-child(6){
        div{
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
    
  .dig-column {
    border: none;
    :nth-child(1) {
      padding: 10px 3px 6px 3px;
      flex: 0.4;
    }
    :nth-child(2),nth-child(7) {
      flex: 1.2;
    }
  }
}`;