import styled from 'styled-components';
import { REQUIRED_RED } from '../../../../../common/colors';

export default styled.div`
  display: flex;
  div.wizard-modal-header-content {
    flex: 1;
    padding: 10px;

    input[type="text"] {
      border: none;
      outline: none;
      width: 30%;
      font-size: 24px;
      caret-color: #5180ff;
      &:focus, &:hover {
        background-color: white !important;
        box-shadow: 0 0px 0 #5180ff inset, 0 2px #5180ff !important;
      }
      &::placeholder {
        color: #E2E5E5;
      }

      &.has-error {
        caret-color: ${REQUIRED_RED};
        box-shadow: 0 0px 0 ${REQUIRED_RED} inset, 0 2px ${REQUIRED_RED} !important;

        &::placeholder {
          color: ${REQUIRED_RED};
          opacity: .4;
        }
      }
    }
  }
  button.close-button {
    color: black;
    opacity: .2;
    font-size: 28px;
    font-weight: 500;
    outline: none;
    border: none;
    height: 40px;
    padding: 0 10px;
    background-color: transparent;
    &:hover {
      opacity: .5;
    }
  }

`;