import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import RadioButtons from '@mdigital/components/dist/components/RadioButtons';
import { getSingleText } from '../../../common/utils';
import Input from '@mdigital/components/dist/components/Input';
import { POPUP_HOSTS, PROVISIONS } from '../../../common/enums';
import { checkProvision } from '../../../redux/store/store-helper';
import popupWindow from '../../assets/formDisplay/popupWindow.png';

PopupWindow.propTypes = {
  onHostedUrlChange: PropTypes.func,
  onHostChange: PropTypes.func,
  selfHostedUrl: PropTypes.string,
  sharedDomain: PropTypes.string,
  isSelfHosted: PropTypes.bool,
};

export function PopupWindow(props) {

  const { sharedDomain } = props;
  const isAddSelfHostedPopupEnabled = checkProvision(PROVISIONS.ADD_SELF_HOSTED_POPUP);
  const host = props.isSelfHosted ? POPUP_HOSTS.SELF : POPUP_HOSTS.MEDALLIA;
  const validationCallback = isUrlValid.bind({ sharedDomain });
  return (
    <Fragment>
      <div className="display-type">
        <p className="display-type-description">
          {getSingleText('app.pages.forms.invitation.formDisplayTab.popupWindow.description')}
        </p>
        {
          isAddSelfHostedPopupEnabled && (
            <div className="contextual-options">
              <p className="contextual-options-description">
                {getSingleText('app.pages.forms.invitation.formDisplayTab.popupWindow.contextualOptions.description')}
              </p>
              <RadioButtons digRadioButtons={radioButtonsOptions}
                digOnChange={props.onHostChange}
                digValue={host}/>

              {props.isSelfHosted && <Input digValue={props.selfHostedUrl}
                digOnChange={props.onHostedUrlChange}
                labelDirection={'TOP'}
                digType={'url'}
                digPlaceholder={getSingleText('app.pages.forms.invitation.formDisplayTab.popupWindow.contextualOptions.selfHostedUrlPlaceholder')}
                digLabel={getSingleText('app.pages.forms.invitation.formDisplayTab.popupWindow.contextualOptions.selfHostedUrlLabel')}
                autoFocus={false}
                validationCallback={validationCallback}
                digRequired={true}
                requiredMessage={getSingleText('app.pages.forms.invitation.formDisplayTab.popupWindow.contextualOptions.selfHostedUrlErrorMessage')}
                customErrorMsg={getSingleText('app.pages.forms.invitation.formDisplayTab.popupWindow.contextualOptions.selfHostedUrlErrorMessage')}/>
              }
            </div>
          )
        }
      </div>
      <div className="display-preview">
        <img src={popupWindow} alt="preview" className="display-preview-image"/>
      </div>
    </Fragment>
  );
}

const radioButtonsOptions = [
  {
    digLabel: getSingleText('app.pages.forms.invitation.formDisplayTab.popupWindow.contextualOptions.hosts.medallia'),
    digValue: POPUP_HOSTS.MEDALLIA,
  },
  {
    digLabel: getSingleText('app.pages.forms.invitation.formDisplayTab.popupWindow.contextualOptions.hosts.self'),
    digValue: POPUP_HOSTS.SELF,
  }
];

export function isUrlValid(url) {
  const host = new URL(url).hostname;
  return host === this.sharedDomain || host.endsWith(`.${this.sharedDomain}`);
}