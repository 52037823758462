import React from 'react';
import { PanelRowPreview } from '../../../../StyledTriggeringModal';
import { TriggerByPushNotificationAssets } from '../../../../../../assets/TriggeringModal/TriggerByPushNotificationAssets/PushNotification';
import { getPropertyType } from '../../../../../../../redux/store/store-helper';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';

const PushNotificationPreview = () => {
  const propertyType = getPropertyType();
  const image = getNestedProperty(TriggerByPushNotificationAssets, propertyType);
  return (
    <PanelRowPreview>
      <img src={ image } alt="Preview" />
    </PanelRowPreview>
  );
};

export default PushNotificationPreview;