import styled from 'styled-components';
import { COLORS } from '../../../../../common/colors';
export const StyledVideoLimitContianer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${(props) => props.isError ? '4px' : '14px'};
    margin-top: -8px;

    label{
        margin-bottom: 0px;
    }
    input{
        width: 73px;
        border-color : ${(props) => props.isError && COLORS.UPLOAD_ERR}
    }
`;

export const StyledVideoLimitText = styled.label`
    margin-left: 10px;
`;

export const StyledVideoLimitErrorMessage = styled.span`
    display: inline-block;
    margin-bottom : 14px;
    color : ${COLORS.UPLOAD_ERR}

`;