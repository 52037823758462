import styled from 'styled-components';
import { COLORS }  from '../../../../common/colors';

export const StyledThankYouPromptSettingsTitle = styled.span`
    size: 18px;
    font-size: 18px;
    color: ${COLORS.LIGHT_BLACK};
    font-weight: 600;
`;
export const StyledThankYouPromptSettingsHeader = styled.p`
    margin-bottom: 40px;
    margin-top: 5px;
`;

export const StyledThankYouPromptSettingsToggle = styled.div`
        ${({ disabled }) => disabled ? 'pointer-events: none; opacity: 0.4;' : ''};
        border-top: 0.1px ${COLORS.GRAY_SEPARATOR} solid;
        cursor: pointer;
        height: 55px;
        padding: 7px 0px 0px 0px;

        &:hover {
          background: ${COLORS.GRAY_BACKGROUND};
        }

        div.field-row-label-container > label {
        color : ${COLORS.LIGHT_BLACK};
        font-size: 14px;

        span.info-span {
          margin-left: 5px;
        }
      }

`;

export const StyledThankYouPromptSettingHelpLink = styled.a`
      margin-left: 7px;
      text-decoration: underline !important;
`;

export const StyledThankYouPromptAccessibility = styled.div`
    input {
      padding-right: 60px;
    }
`;