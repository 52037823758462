import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';

export const StyledMediaCaptureResponseFormatsContainer = styled.div`
    margin-top: 14px;
    max-height: 240px;
`;

export const StyledMediaCaptureResponseFormatsTitle = styled.label`
    color: ${COLORS.TEXT_HOVER};
    margin-bottom: 8px;
    font-weight: 600;
`;

export const StyledMediaCaptureResponseFormats = styled.div`
    border-bottom: 1px solid ${COLORS.BORDER};
    padding-top: 7x;

    div{
        font-size: 14px;
        margin-right: 1px;
    }
    label{
        color: ${COLORS.PRIMARY_TEXT}
    }
`;

export const StyledMediaCaptureFileUploaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledMediaCaptureResponseFormatsErrorMessage = styled.span`
    display: inline-block;
    color: ${COLORS.UPLOAD_ERR};
    margin-bottom: 10px;
`;