import { createSelector } from 'reselect';
import { chunk, buildPaginationObject, dynamicObjectArraySort, sortByDerivedStatus, sortByDates, sortByBoolean, sortByNumbers, sortByLatestChangeTime, sortByDraftIds } from '../../../common/utils';
import { FORM_VIEW_TYPES, SORT_ORDER } from '../../../common/enums';

const CHUNK_CAPACITY = 25;
const SortingType = {
  FormChangeTime : 'formChangeTime',
  Name : 'name',
};
const SORT_TYPE_MAP = {
  formChangeTime:'date',
  name: 'string',
  published: 'boolean',
  id:'number',
  'triggers.0.triggerType':'string',
  status: 'string',
  statusSurveyDraft: 'derivedStatus',
};
const FUNCTION_SORT_MAP = {
  date: sortByDates,
  boolean: sortByBoolean,
  string: dynamicObjectArraySort,
  number: sortByNumbers,
  derivedStatus: sortByDerivedStatus,
};
const CUSTOM_SORT_DRAFT_TYPES = ['id', 'formChangeTime'];
const CUSTOM_SORT_DRAFT_MAP = {
  id: sortByDraftIds,
  formChangeTime: sortByLatestChangeTime,
};

// Selectors
const getSelectedForms = (state) => {
  const { forms: { selectedForms } } = state;
  return selectedForms;
};

const getAllUndeletedFormsSelector = (state) => {
  const { forms: { undeletedForms } } = state;
  return undeletedForms || [];
};

const getQueryParamsSelector = (state) => {
  const { forms: { searchQuery } } = state;
  return searchQuery || '';
};

const getViewTypeSelector = (state) => {
  const { forms: { viewType } } = state;
  return viewType;
};
const getSortOrderSelector = (state) => {
  const { forms: { sortOrder } } = state;
  return sortOrder;
};
const getSortBySelector = (state) => {
  const { forms: { sortBy } } = state;
  return sortBy;
};
const getIsSurveyDraftEnabled = (state) => {
  const { profile } = state;
  return profile && profile.provisioning && profile.provisioning['23dcr3_MPC5608_enableDraftsSupport'];
};
const isFormPublished = (forms, formId) => {  
  const connectedForm = forms.find((form) => form.id === formId);
  return connectedForm && connectedForm.published;
};
//Memoized Selectors
const getSortFuncByType = (sortBy) => {
  const sortType = SORT_TYPE_MAP[sortBy];
  return FUNCTION_SORT_MAP[sortType];
};
const getRelevantForms = (forms) => forms.filter((form) => form.published || form.isDraft && !isFormPublished(forms, form.formId));

export const filterByQueryStringSelector = createSelector(getQueryParamsSelector ,getAllUndeletedFormsSelector,
  (queryParams, undeletedForms) => undeletedForms.filter(({ name = '' , id = 0 }) => isQueryExistsInProps(queryParams.toLowerCase(), [name, id])));

export const getFormIds = createSelector(filterByQueryStringSelector, getIsSurveyDraftEnabled, (forms, isSurveyDraftEnabled) => {
  const filteredForms = isSurveyDraftEnabled ? getRelevantForms(forms) : forms;
  return filteredForms.map((form) => {
    let isFormLocked;
    const isPublishedWithDraftForm = isSurveyDraftEnabled && !form.isDraft && form.formDraftId;
    if (isPublishedWithDraftForm) {
      const { isLocked } = forms.find((frm) => frm.id === form.formDraftId);
      isFormLocked = !!isLocked;
    } else {
      isFormLocked = form.isLocked;
    }
    return { formId: form.id, isLocked: isFormLocked };
  });
});

export const getPaginationSortedCatgories = createSelector(filterByQueryStringSelector, getViewTypeSelector, getSortBySelector, getSortOrderSelector, getIsSurveyDraftEnabled,
  (filteredForms, viewType, sortBy, sortOrder, isSurveyDraftEnabled) => {
    const relevantForms = isSurveyDraftEnabled ? getRelevantForms(filteredForms) : filteredForms;
    const sortedBy = viewType === FORM_VIEW_TYPES.LIST && sortBy ? sortBy : getSortByViewType(viewType);
    const keyToSortBy = sortedBy && sortedBy.param ? sortedBy.param : sortedBy;
    const sortFunc = isSurveyDraftEnabled && CUSTOM_SORT_DRAFT_TYPES.includes(keyToSortBy) ? CUSTOM_SORT_DRAFT_MAP[keyToSortBy] : getSortFuncByType(keyToSortBy);
    const formsList = isSurveyDraftEnabled && keyToSortBy === 'formChangeTime' ? relevantForms : undefined;
    const sortOrderBy = viewType === FORM_VIEW_TYPES.GRID ? SORT_ORDER.ASC : sortOrder;
    const sortedForms = relevantForms.length !== 0 ? relevantForms.sort(sortFunc(keyToSortBy, sortOrderBy, formsList)) : [];
    return buildPaginationObject(chunk(sortedForms, CHUNK_CAPACITY));
  });

export const getCount = createSelector(filterByQueryStringSelector,
  (filterByQueryStringSelector) => filterByQueryStringSelector.length);

export const selectedFormsSelector = createSelector(getSelectedForms,(selectedForms) => selectedForms);

// region Helper functions
export function isQueryExistsInProps(query, props = []) {
  return props.some(
    (property) => (String(property)
      .toLowerCase())
      .includes(query));
}

export function getSortByViewType(viewType) {
  switch (viewType) {
    case FORM_VIEW_TYPES.LIST:
      return { param: SortingType.FormChangeTime, order: SORT_ORDER.DESC };
    case FORM_VIEW_TYPES.GRID:
      return { param: SortingType.Name, order: SORT_ORDER.ASC };
    default:
      return { param: SortingType.FormChangeTime, order: SORT_ORDER.DESC };

  }
}
// endregion


