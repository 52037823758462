import styled from 'styled-components';
import { COLORS } from '../../../common/colors';

export const StyledUploaderView = styled.div `
  
  @-moz-document url-prefix(){
    // fix for firefox, automation couldn't access the input button because it had display: none
    .custom-upload input[type="file"] {
      display: block !important; // override inline style
      width: inherit; // setting display block caused the button to be bigger
      opacity: 0;
    }
  }
	display: flex;
	.custom-upload {
			display: flex;
			height: 34px;
			.dig-btn {
				width: 48.5%;
				}
			}
	.msg-info {
		font-size: 12px;
		height: 1em;
	}
	.msg-err {
			color: red;
		}		
	.upload-btn {
			width: 100%;
			font-weight: 400;
			font-size: 1em;
			color: white !important;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			&focus: {
				outline: none
			}
				}		
	.dig-input {
			width: 100% !important; // this is due invite modal global styling taking over.
			margin: 0 !important;
			height: 100%;
			border-left: 0;
			padding-left: 8px;
			background-color: ${COLORS.WHITE} !important;
			opacity: ${({ inputValue }) => inputValue.length > 0 ? '1' : '0.5'} ;
			&: focus {
			outline: none;
				}		
			}
	.delete-container {
			display: flex;
			align-items: center;
			margin-left: 5px;
			width: 2em;
			height: 34px;
			.icon-custom-style {
					font-size: 20px;
					color: ${COLORS.BIN_COLOR};
					&:hover {
						color: ${COLORS.ON_HOVER_BIN};
						}
					}
				}	
	.input-wrapper {
		width: 100%;
	}			
`;
