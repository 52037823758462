import React from 'react';
import { StyeldMediaCaptureFormatsContainer , StyledMediaCaptureVideoLimit , 
  StyledMediaCaptureVideoLimitText } from './StyledMediaCaptureFormats';
import IconSvg from '@mdigital/components/dist/components/IconSvg';
import { Icon_Preview } from '../../../assets/MediaCapture';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { COLORS } from '../../../../common/colors';
import { getSingleText } from '../../../../common/utils';
import { PROPERTY_TYPES } from '../../../../common/constants';

export const SUPPORTED_MEDIA_TYPES = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  UPLOAD: 'UPLOAD',
};

const MediaCaptureFormatsPreview = ({ mediaCaptureDynamicFieldContract , propertyType }) =>{
  const supportedMediaTypes = getNestedProperty(mediaCaptureDynamicFieldContract , 'supportedMediaTypes') || [];
  const maxLength = getNestedProperty(mediaCaptureDynamicFieldContract , 'maxLength') || 0;
  const isVideoCheck = supportedMediaTypes.includes(SUPPORTED_MEDIA_TYPES.VIDEO);
  const isAndroidProperty = propertyType === PROPERTY_TYPES.ANDROID;
  return (
    <React.Fragment>
      {isVideoCheck && 
            <StyledMediaCaptureVideoLimit>
              <StyledMediaCaptureVideoLimitText>{getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.videoMaxLength')}</StyledMediaCaptureVideoLimitText>
              <StyledMediaCaptureVideoLimitText>{maxLength}</StyledMediaCaptureVideoLimitText>
              <span>{getSingleText('app.pages.forms.builder.tabs.fieldSettings.mediaCapture.videoMaxLengthSec')}</span>
            </StyledMediaCaptureVideoLimit>
      }
      <StyeldMediaCaptureFormatsContainer>
        {supportedMediaTypes.includes(SUPPORTED_MEDIA_TYPES.UPLOAD) && isAndroidProperty &&
                    <IconSvg className='media-capture-formats' width={24} height={24} 
                      icon={getNestedProperty(Icon_Preview[propertyType], SUPPORTED_MEDIA_TYPES.UPLOAD)} 
                      color={COLORS.ICON} colorHover={COLORS.ICON}/>
        }
        {supportedMediaTypes.includes(SUPPORTED_MEDIA_TYPES.AUDIO) &&
                    <IconSvg className='media-capture-formats' width={24} height={24} 
                      icon={getNestedProperty(Icon_Preview[propertyType], SUPPORTED_MEDIA_TYPES.AUDIO)} 
                      color={COLORS.ICON} colorHover={COLORS.ICON}/>
        }
        {supportedMediaTypes.includes(SUPPORTED_MEDIA_TYPES.VIDEO) &&
                    <IconSvg className='media-capture-formats' width={24} height={24} 
                      icon={getNestedProperty(Icon_Preview[propertyType], SUPPORTED_MEDIA_TYPES.VIDEO)} 
                      color={COLORS.ICON} colorHover={COLORS.ICON}/>
        }
      </StyeldMediaCaptureFormatsContainer>
    </React.Fragment>
  );
};

export default MediaCaptureFormatsPreview;