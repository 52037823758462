import React , { Component } from 'react';
import DigLanguageDropDownStyledComponent from './DigLanguageDropDownStyledComponent';
import DigAutoSuggest from '@mdigital/components/dist/components/AutoSuggest';
import { connect } from 'react-redux';

function renderSuggestion(suggestion) {
  return (
    <a href="javascript:void(0)" className="ui-select-choices-row-inner language-suggestion-container">
      <i className="icon fa fa-globe" />
      <span className="kpl-ellipsis language-label">{suggestion.id}</span>
      <span className='language-dialect'>{suggestion.label}</span>
    </a>
  );
}

function languagesObjectToArray(languagesObj = {}) {
  return Object.keys(languagesObj).map((key) => Object.assign({},{
    id: languagesObj[key],
    label: key,
  }));
}

function isFunction(fn) {
  return fn && typeof(fn) === 'function';
}

class DigLanguageDropDown extends Component {

  render() {
    let languages;
    if(this.props && this.props.localization){
      languages = languagesObjectToArray(this.props.localization.allLanguages);
      languages = isFunction(this.props.filterer) ? languages.filter(this.props.filterer) : languages;
    } else {
      languages = [];
    }
    return ( 
      <DigLanguageDropDownStyledComponent className='dig-language-dropdown-container'>
        <DigAutoSuggest
          disabled={languages.length <= 0 || this.props.isReadOnly}
          value={this.props.defaultLanguage || ''}
          suggestions={languages}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={this.props.onSelected}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
        />
      </DigLanguageDropDownStyledComponent>
    );
  }
}

function mapStateToProps(state) {
  return {
    localization: state.localization,
  };
}

export default connect(mapStateToProps)(DigLanguageDropDown);