import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ROLE_TYPES } from '../../../common/constants.js';
import { connect } from 'react-redux';
import { WARNING_TEXT } from '../../common/colors.js';

const NOTIFICATION_SETTINGS = {
  adminWarningText: 'Hey there. The system is currently in “Freeze Mode” due to a software update, create & deploy a package to exit “Freeze Mode”.',
  nonAdminWarningText: 'You are in Freeze Mode while your Administrator is performing an update. Don\'t worry we are still collecting feedback',
  linkText: 'Learn More',
};

export const StyledLink =  styled.a`
  text-decoration: underline !important;
  color: ${WARNING_TEXT};
  margin-left: 10px;
`;

export const StyledFreezeModeNotification = styled.div`
display: flex;
justify-content: center;
align-items: center;

p {
  margin: 0;
  padding: 0;
}

.confirmExtension {
  padding: 4px 10px;
  margin-left: 20px;
  height: 28px;
  border-radius: 2px;
}
`;

class DhhFreezeModeNotification extends React.Component {
  static propTypes = {
    freezeModeHelpLink: PropTypes.string,
    userRole: PropTypes.string,
  };

  render() {
    const { userRole, freezeModeHelpLink } = this.props;
    const isAdmin = userRole === ROLE_TYPES.ROLE_ADMIN;
    
    return (
      <StyledFreezeModeNotification>
        <span>{isAdmin ? NOTIFICATION_SETTINGS.adminWarningText : NOTIFICATION_SETTINGS.nonAdminWarningText}</span>
        {isAdmin && <StyledLink href={freezeModeHelpLink} target={'_blank'}> {NOTIFICATION_SETTINGS.linkText} </StyledLink>}
      </StyledFreezeModeNotification>
    );
  }
}

function mapStateToProps({ profile, app }) {
  return {
    freezeModeHelpLink: app.helpCenterLinks && app.helpCenterLinks.freezeMode.link,
    userRole: profile.userRole,
  };
}

export default connect(mapStateToProps, {})(DhhFreezeModeNotification);
