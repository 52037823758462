import React from 'react';
import Introduction from '@mdigital/components/dist/components/Introduction';
import { getMigrationIntroductionImages } from '../../../../assets/AppRating/base64';

const handleClose = (onClose) => (ev) => onClose(ev);

const AppRatingIntroductionMigrationUsers = ({ isShown, onClose, isAndroidDirect }) => (
  <Introduction
    prerenderSteps
    show={isShown}
    finishButtonText="Get Started"
    onClose={handleClose(onClose)}>
    <Introduction.Step
      primaryText="Great News!"
      imageSource={getMigrationIntroductionImages(isAndroidDirect).Step1}
      secondayText="The ability to prompt an in-app store rating is now supported!"
    />
    <Introduction.Step
      primaryText="How it works?"
      imageSource={getMigrationIntroductionImages(isAndroidDirect).Step2}
      secondayText='For existing app ratings, simply click "Edit" and then "Save". <br /> New app ratings now only require targeting set-up'
    />
    <Introduction.Step
      primaryText="Good to go!"
      imageSource={getMigrationIntroductionImages(isAndroidDirect).Step3}
      secondayText={`The ${isAndroidDirect ? 'Android' : 'Apple'} prompt for app ratings will be automatically displayed <br /> based on ${isAndroidDirect ? 'Android' : 'Apple'} guidelines`}
    />
  </Introduction>
);

export default AppRatingIntroductionMigrationUsers;
