import React from 'react';
import { connect } from 'react-redux';
import { bind } from '@mdigital/components/dist/decorators';
import Table from '@mdigital/components/dist/components/Table';
import Tooltip from '@mdigital/components/dist/components/Tooltip';
import { API, PLACEHOLDER } from '../../../common/api';
import withProvider from '../../hoc/ProviderHoc';
import ActionBar from '../../components/ActionBar';
import { deleteResource, fetchResources } from '../../../redux/actions/resources.actions';
import { IMAGE_FILE_TYPES, RESULT_CODES, PROVISIONS, FILE_TYPES_EXTENSIONS } from '../../../common/enums';
import { DATE_FORMAT } from '../../../common/constants';
import { checkProvision, getPropertyId } from '../../../redux/store/store-helper';
import { pushNotification } from '../../../common/utils';
import { getNotificationByKampyleId } from '../../../common/notification-mapper';
import WarningSign from '../../components/WarningSign';
import StyledTable from './StyledTable';
import PreviewSvg from '../../assets/actions/preview.svg';
import DeleteSvg from '../../assets/actions/delete.svg';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import UsedInModal from '../../modals/ResourcesLibrary/UsedInModal';
import StyledResourcesLibrary from './StyledResourcesLibrary';

const SUCCESSFUL_UPLOAD = 'File uploaded successfully';
const DELETE_CONFIRMATION_MODEL_CONTENT = 'Are you sure you want to delete this file?';
const USED_IN_ACTION_TOOLTIP = 'This resource used in 1 or more forms. Remove it from all forms in order to delete it.';
const RESOURCES_TABLE_COLUMNS = [
  { label: '' },
  { label: 'File Name' },
  { label: 'Type' },
  { label: 'Upload Date' },
  { label: 'Size' },
  { label: 'Used In' }
];
const SUPPORTED_FILE_TYPES = [
  IMAGE_FILE_TYPES.GIF,
  IMAGE_FILE_TYPES.PNG,
  IMAGE_FILE_TYPES.JPG,
  IMAGE_FILE_TYPES.JPEG,
  ...FILE_TYPES_EXTENSIONS.STYLES,
  ...FILE_TYPES_EXTENSIONS.ARCHIVES
];
const ACTIONS = {
  PREVIEW: {
    iconSvg: PreviewSvg,
    tooltip: 'Preview',
    callback: openPreview,
  },
  DELETE: {
    iconSvg: DeleteSvg,
    tooltip: 'Delete',
    callback: null, // will be set inside buildRow method, needs access to 'this'
  },
};
const WARNING_SIGN_PROPS = {
  size: 14,
  tooltip: 'Deploy a digital package to enable reuse in all forms',
};
const ACTION_BAR_STYLE = {
  position: 'sticky',
  top: 0,
};

function openPreview({ url }) {
  window.open(url);
}

function isPreviewable(fileType) {
  const fileExtension = `.${(fileType || '')}`;
  return !FILE_TYPES_EXTENSIONS.ARCHIVES.includes(fileExtension);
}

function isShowWarningSign(isResourceDeployed) {
  return checkProvision(PROVISIONS.DYNAMIC_HYBRID_HOSTING) && !isResourceDeployed;
}

function getDeleteConfirmationModalContent() {
  return DELETE_CONFIRMATION_MODEL_CONTENT;
}

function mapStateToProps(state) {
  const { resources } = state;
  return { allResources: resources.allResources };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchResources: () => dispatch(fetchResources()),
    deleteResource: (id) => dispatch(deleteResource(id)),
  };
}

export class ResourcesLibrary extends React.Component {
  state = {
    isUploading: false,
    showDeleteConfirmationModal: false,
    showUsedInModal: false,
  };
  UPLOAD_SETTINGS = {
    url: API.RESOURCES.UPLOAD.replace(PLACEHOLDER.PROPERTY_ID, getPropertyId()),
    accept: SUPPORTED_FILE_TYPES,
    onResponse: this.uploadResponseHandler,
    onError: this.uploadErrorHandler,
    onUploadStarted: this.uploadStartedHandler,
  };
  resourceIdBeforeDeletion = null;
  usedIn = null;

  componentDidMount() {
    this.props.fetchResources();
  }

  renderTable(resources = []) {
    return (
      <StyledTable className={'table-container'}>
        <Table digColumns={RESOURCES_TABLE_COLUMNS} digRows={resources.map(this.buildRow, this)}/>
      </StyledTable>
    );
  }

  buildRow(resource) {
    const { id, fileName, uploadDate, size, usedIn = [], fileType = '', isDeployed } = resource;
    const numOfUsedIn = usedIn.length;
    const isInUse = numOfUsedIn > 0;
    const usedInText = `${numOfUsedIn} Forms`;
    return {
      id,
      values: [
        { value: isShowWarningSign(isDeployed) ? <WarningSign {...WARNING_SIGN_PROPS}/> : null },
        { value: fileName },
        { value: fileType.toUpperCase() },
        { value: moment(uploadDate).format(DATE_FORMAT) },
        { value: size },
        { value: isInUse ?  this.getUsedInActionLink(usedIn, usedInText) : usedInText }
      ],
      rowObject: resource,
      actions: [
        isPreviewable(fileType) ? ACTIONS.PREVIEW : null,
        !isInUse ? { ...ACTIONS.DELETE, callback: this.openDeleteConfirmationModal } : null
      ],
    };
  }

  getUsedInActionLink(usedIn, linkText) {
    const linkElement = () => <a onClick={this.openUsedInModal.bind(this, usedIn)}>{linkText}</a>;
    return (
      <Tooltip digTooltip={USED_IN_ACTION_TOOLTIP} digElement={linkElement} />
    );
  }

  @bind
  uploadResponseHandler({ resultCode, kampyleId }) {
    this.setState({ isUploading: false });
    if (resultCode === RESULT_CODES.FAILURE) {
      pushNotification({ type: NOTIFICATION_TYPES.FAILURE, message: getNotificationByKampyleId(kampyleId) });
    } else {
      pushNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: SUCCESSFUL_UPLOAD });
    }
    this.props.fetchResources();
  }

  uploadErrorHandler(error = {}) {
    const { kampyleId } = error;
    pushNotification({ type: NOTIFICATION_TYPES.FAILURE, message: getNotificationByKampyleId(kampyleId) });
  }

  @bind
  uploadStartedHandler() {
    this.setState({ isUploading: true });
  }

  @bind
  openDeleteConfirmationModal({ id }) {
    this.resourceIdBeforeDeletion = id;
    this.setState({ showDeleteConfirmationModal: true });
  }

  @bind
  onDeleteConfirmed() {
    this.props.deleteResource(this.resourceIdBeforeDeletion);
    this.closeDeleteConfirmationModal();
  }

  @bind
  closeDeleteConfirmationModal() {
    this.resourceIdBeforeDeletion = null;
    this.setState({ showDeleteConfirmationModal: false });
  }

  openUsedInModal(usedIn) {
    this.usedIn = usedIn;
    this.setState({ showUsedInModal:true });
    document.body.classList.add('modal-open');
  }

  @bind
  closeUsedInModal() {
    this.usedIn = null;
    this.setState({ showUsedInModal:false });
    document.body.classList.remove('modal-open');
  }

  render() {
    const isEmptyState = (!Array.isArray(this.props.allResources) || this.props.allResources.length === 0);
    return (
      <StyledResourcesLibrary>
        <UsedInModal isShow={this.state.showUsedInModal} usedIn={this.usedIn} onClose={this.closeUsedInModal}/>
        <DeleteConfirmationModal isShown={this.state.showDeleteConfirmationModal}
          title="Delete File"
          getContent={getDeleteConfirmationModalContent}
          onOkClicked={this.onDeleteConfirmed}
          onCancelClicked={this.closeDeleteConfirmationModal}/>
        <ActionBar title="Resources Library"
          actionButtonText={this.state.isUploading ? 'Uploading' : 'Upload File'}
          uploadSettings={this.UPLOAD_SETTINGS}
          isButtonLoading={this.state.isUploading}
          style={ACTION_BAR_STYLE}
          withUpload
          shouldHideSearch/>
        {
          isEmptyState ? null : this.renderTable(this.props.allResources)
        }
      </StyledResourcesLibrary>
    );
  }
}

export default withProvider()(connect(mapStateToProps, mapDispatchToProps)(ResourcesLibrary));
