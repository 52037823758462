import React  from 'react';
import { SegmentedControl, SunIcon, MoonIcon } from '@m/alchemy-ui';
import { StyledDarkModeSwitcherContainer } from './StyledDarkModeSwitcher';

const DarkModeSwitcher = ({ isDark, setIsDark }) => {
  return (
    <StyledDarkModeSwitcherContainer>
      <SegmentedControl
        showLabelsAtMinWidth={Infinity}
        controls={[
          { label: 'lightMode', value: false, icon: <SunIcon/> },
          { label: 'darkMode', value: true, icon: <MoonIcon/> }
        ]}
        activeControlValue={isDark}
        onControlChange={({ value }) => {
          setIsDark(value);
        }}
      />
    </StyledDarkModeSwitcherContainer>
  );
};

export default DarkModeSwitcher;