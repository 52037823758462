import styled from 'styled-components';

export const MediaCaptureAudioPlaceholderContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    margin-top: -8px;

    input{
        width: 228px;
        padding-right: 55px;
    }
`;

export const StyledAudioPlaceholderText = styled.label`
    margin: 0px;
`;