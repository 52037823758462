import styled from 'styled-components';
import { units } from '@m/alchemy-theme';

export const StyledFormVersionSelectDialogBody = styled.div` {
  font-family: "Nunito",-apple-system,system-ui,BlinkMacSystemFont,Roboto,Segoe UI,Oxygen Sans,Ubuntu,Helvetica Nue,sans-serif;
  margin-top: ${units(1)};
  font-size: ${units(1.75)};
  line-height: ${units(2.5)};
  
  p {
    margin-bottom: ${units(2)};
  }
  > div {
    margin-top: 0;
  }
  .radio-selection {
    cursor: pointer;
  }
}
`;
