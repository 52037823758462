import React, { useEffect, useContext } from 'react';
import { TriggerContext } from '../../../../../../..';
import TargetingBlockRow from '../../../TargetingBlockRow';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { NumberInput } from '@m/alchemy-ui';
import { TargetingActions } from '../../../../../Actions';
import { Subtitle } from '../../../../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../../../../common/utils';
import { TextPathPrefix } from '../../../../..';
import { isRuleExists, formatInputNumberNullValue } from '../../../../../../../helpers';

const TargetByDidntVisitApp = () => {
  const { triggerState, triggerDispatch, isReadOnly } = useContext(TriggerContext);
  useEffect(() => {
    isRuleExists(triggerState, 'trigger.rules.MobileDidntVisitApp.id') ?
      triggerDispatch({ type: TargetingActions.SET_MOBILE_DIDNT_VISIT_YOUR_APP_IS_ACTIVE , payload : true } ) :
      triggerDispatch({ type: TargetingActions.INIT_MOBILE_DIDNT_VISIT_YOUR_APP , payload : { days: 1 } } );
  },[]);

  return (
    <TargetingBlockRow
      checkboxLabel="When someone didn’t visit your app in the last"
      checked={getNestedProperty(triggerState, 'trigger.rules.MobileDidntVisitApp.isActive')}
      disabled={isReadOnly}
      onCheckboxChange={ (e) => triggerDispatch({ type: TargetingActions.SET_MOBILE_DIDNT_VISIT_YOUR_APP_IS_ACTIVE , payload : e.target.checked } ) }>
      <React.Fragment>
        <NumberInput
          min="1"
          formatValue={formatInputNumberNullValue}
          value={getNestedProperty(triggerState, 'trigger.rules.MobileDidntVisitApp.params.days') || 1}
          onNumberChange={ (value) => triggerDispatch({ type: TargetingActions.SET_MOBILE_DIDNT_VISIT_YOUR_APP_SECONDS , payload : value })} />
        <Subtitle marginLeft={1.5}>{getSingleText(`${TextPathPrefix}.rulesTexts.days`)}</Subtitle>
      </React.Fragment>
    </TargetingBlockRow>
  );
};

export default TargetByDidntVisitApp;