import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bind } from '@mdigital/components/dist/decorators';
import Select from '@mdigital/components/dist/components/Select';
import { Select as AlchemySelect } from '@m/alchemy-ui';
import withProvider from '../../hoc/ProviderHoc';
import { getResourceById, checkProvision } from '../../../redux/store/store-helper';
import { PROVISIONS } from '../../../common/enums';
import { getDeployedResources } from './resourcesSelectors';
import { dynamicObjectArraySort } from '../../../common/utils';
const NONE = 'None';
function mapStateToProps(state) {
  const { resources } = state;
  return {
    allResources: resources.allResources || [],
    deployedResources: getDeployedResources(state),
  };
}

function formatResourceToOption({ id, fileName }) {
  return { value: id, label: fileName };
}

function filterByType(resources, types = []) {
  return (resources || []).filter(({ fileType }) => types.includes(fileType));
}

const EMPTY_RESOURCE = {
  resourceId: null,
  url: null,
  fileName: NONE,
  name: NONE,
};

const EMPTY_OPTION = {
  value: NONE,
  label: NONE,
  resourceId: null,
};

export class ResourcesSelect extends React.Component {

  static propTypes = {
    placeholder: PropTypes.string,
    noOptionsHeader: PropTypes.string,
    onSelect: PropTypes.func,
    resourceId: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    isDisabled: PropTypes.bool,
    isShowAllResources: PropTypes.bool,
    isAlchemyDesign: PropTypes.bool,
  };

  @bind
  selectionHandler({ value }) {
    const resource = value === NONE ? EMPTY_RESOURCE : getResourceById(value);
    this.props.onSelect(resource);
  }

  render() {
    const isDHH = checkProvision(PROVISIONS.DYNAMIC_HYBRID_HOSTING);
    const resources = this.props.isShowAllResources || !isDHH ? this.props.allResources : this.props.deployedResources;

    const currentResource = getResourceById(this.props.resourceId);
    const filteredResources = filterByType(resources, this.props.types);
    let options = filteredResources.map(formatResourceToOption);
    const selectedOption = currentResource ? options.find(({ value }) => value === this.props.resourceId) : null;
    if (options.length) {
      const sortedOptions = options.sort(dynamicObjectArraySort('label', 'asc'));
      if(this.props.types.includes('zip')){
        options = [...sortedOptions];
      } else {
        options = [EMPTY_OPTION, ...sortedOptions];
      }
    }
    return (
      <React.Fragment>
        {this.props.isAlchemyDesign ? 
          <AlchemySelect 
            value={currentResource && selectedOption.value || NONE}
            options={options}
            placeholder={this.props.placeholder}
            disabled={this.props.isDisabled}
            onChange={this.selectionHandler}/> 
          :
          <Select 
            selected={selectedOption}
            options={options}
            placeholderText={this.props.placeholder}
            noOptionsHeader={this.props.noOptionsHeader}
            isDisabled={this.props.isDisabled}
            onSelect={this.selectionHandler}/>
        }
      </React.Fragment>
    );
  }
}

export default withProvider()(connect(mapStateToProps)(ResourcesSelect));
