import React from 'react';
import Introduction from '@mdigital/components/dist/components/Introduction';
import { IntroductionImages } from '../../../../assets/AppRating/base64';

const handleClose = (onClose) => (ev) => onClose(ev);

const AppRatingIntroduction = ({ isShown, onClose }) => (
  <Introduction
    prerenderSteps
    show={isShown}
    finishButtonText="Get Started"
    onClose={handleClose(onClose)}>
    <Introduction.Step
      primaryText="Welcome to App Rating!"
      imageSource={IntroductionImages.Step1}
      secondayText="Drive more Store Ratings and reviews! Customize your App Ratings Prompt with the perfect messaging for your customers"
    />
    <Introduction.Step
      primaryText="Target customers at the right mobile moment"
      imageSource={IntroductionImages.Step2}
      secondayText="Prompt the right customers at the right time and place within your app with advanced targeting capabilities."
    />
    <Introduction.Step
      primaryText="Redirection to store"
      imageSource={IntroductionImages.Step3}
      secondayText="Your customers will be automatically redirected to your app page in store"
    />
  </Introduction>
);

export default AppRatingIntroduction;