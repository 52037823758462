import React from 'react';
import PropTypes from 'prop-types';
import FormSettingsField from './FormSettingsField';
import Input from '@mdigital/components/dist/components/Input';
import ColorPicker from '@mdigital/components/dist/components/ColorPicker';
import { StyledDarkModeContainer , StyledFormSettingsTitle , FormSettingsHeaderSpeartor } from './StyledFormBasicSettings';
import { getSingleText } from '../../../common/utils';

const FormHeaderSettings = ({ isDarkModeEnabled , isPublished }) => {
  return (
    <React.Fragment>
      <FormSettingsHeaderSpeartor/>
      <StyledFormSettingsTitle>{getSingleText('app.pages.forms.formBasicSettings.formHeader')}</StyledFormSettingsTitle>
      <FormSettingsField
        name="formSettings.settings.formMobileSettingsContract.title"
        title="Survey Title"
        isDisabled={isPublished}
        render={({ input }) => (
          <Input digValue={input.value} digOnChange={input.onChange} />
        )}/>

      <StyledDarkModeContainer>
        <FormSettingsField
          name="formSettings.settings.formMobileSettingsContract.titleTextColor"
          title="Text Color"
          isDisabled={isPublished}
          render={({ input }) => (
            <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
          )}/>

        {isDarkModeEnabled &&  
        <FormSettingsField
          name="mobileTheme.themeData.dark.palette.typography.header"
          title="Dark Mode Text Color"
          isDisabled={isPublished}
          render={({ input }) => (
            <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
          )}/>
        }
      </StyledDarkModeContainer>
      
      <StyledDarkModeContainer>
        <FormSettingsField
          name="formSettings.settings.formMobileSettingsContract.titleBackgroundColor"
          title="Background Color"
          isDisabled={isPublished}
          render={({ input }) => (
            <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
          )}/>
        {isDarkModeEnabled &&  
          <FormSettingsField
            name="mobileTheme.themeData.dark.palette.core.header"
            title="Dark Mode Background"
            isDisabled={isPublished}
            render={({ input }) => (
              <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
            )}/>
        }
      </StyledDarkModeContainer>
    </React.Fragment>
  );
};

export default FormHeaderSettings;

FormHeaderSettings.propTypes = {
  isDarkModeEnabled: PropTypes.bool,
  isPublished: PropTypes.bool,
};

FormHeaderSettings.defaultProps = {
  isDarkModeEnabled: false,
  isPublished: false,
};