import BOTTOM_true from './partialBottom.base64'
import BOTTOM_false from './fullBottom.base64'
import TOP_true from './partialTop.base64'
import TOP_false from './fullTop.base64'

export default {
  BOTTOM_true,
  BOTTOM_false,
  TOP_true,
  TOP_false
}