import React, { useState, useEffect } from 'react';
import {
  StyledCheckBoxWrapper,
  StyledDaysTitleWrapper,
  StyledRowElements,
  StyledSelectWrapper
} from './EmbeddedTargetingSessionStyled';
import DigTitle from '@mdigital/components/dist/components/Title';
import DigCheckbox from '@mdigital/components/dist/components/Checkbox';
import Select from '@mdigital/components/dist/components/Select';
import Input from '@mdigital/components/dist/components/Input';
import { PARAMS_URLS, RULES_NAME,TEXT_PATH } from '../../utils/constants';
import { isNumberPositive, mapAutoSuggestValueToLabel } from '../../utils/helperFunctions';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { getSingleText } from '../../../../../common/utils';
import { StyledTargetingContainer } from '../../EmbeddedTargetingContainer/EmbeddedTargetingContainerStyled';

const optionsSelect = [
  { value: 'Greater Than', label: 'Greater Than' },
  { value: 'Equals', label: 'Equals' },
  { value: 'Smaller Than', label: 'Smaller Than' }
];

const additionInputStyle = {
  borderRadius: 0,
  width: 72,
  height: 34,
  marginRight: 8,
};

const additionalDigLabelStyle = {
  fontSize: 13,
  fontWeight: 400,
  color: '#929297',
  fontFamily: 'Open Sans',
};



export default function EmbedTargetingSession({
  rules,
  handleCheckBoxToggle,
  handleFrequencyChange,
  handleNumberOfPagesChange,
  handleNumberOfVisitsChange,
  handleInviteOnDeclinedChange,
  handleInviteOnSubmittedChange,
  isReadOnlyMode,
}) {
  const [isInviteCheck, setIsInviteCheck] = useState(
    getNestedProperty(rules, PARAMS_URLS.FREQUENCY, '') !== ''
  );
  const [isNumberOfVisitCheck, setIsNumberOfVisitsCheck] = useState(
    getNestedProperty(rules, PARAMS_URLS.NUMBER_OF_VISITS, '') !== ''
  );
  const [isNumberOfPageCheck, setIsNumberOfPageCheck] = useState(
    getNestedProperty(rules, PARAMS_URLS.NUMBER_OF_PAGES_VIEWED, '') !== ''
  );
  const [isInviteOnSubmittedCheck, setIsInviteOnSubmittedCheck] = useState(
    getNestedProperty(rules, PARAMS_URLS.DONT_INVITE_ON_SUBMITTED, '') !== ''
  );
  const [isInviteOnDeclinedCheck, setIsInviteOnDeclinedCheck] = useState(
    getNestedProperty(rules, PARAMS_URLS.DONT_INVITE_ON_DECLINED, '') !== ''
  );

  // each effect listen to state prop that handle checkbox toggle and fire handle function
  useEffect(() => {
    handleCheckBoxToggle(isInviteCheck, RULES_NAME.FREQUENCY);
  }, [isInviteCheck]);

  useEffect(() => {
    handleCheckBoxToggle(isNumberOfVisitCheck, RULES_NAME.NUMBER_OF_VISITS);
  }, [isNumberOfVisitCheck]);

  useEffect(() => {
    handleCheckBoxToggle(isNumberOfPageCheck,RULES_NAME.NUMBER_OF_PAGES_VIEWED);
  }, [isNumberOfPageCheck]);

  useEffect(() => {
    handleCheckBoxToggle(isInviteOnSubmittedCheck,RULES_NAME.DONT_INVITE_ON_SUBMITTED);
  }, [isInviteOnSubmittedCheck]);

  useEffect(() => {
    handleCheckBoxToggle(isInviteOnDeclinedCheck,RULES_NAME.DONT_INVITE_ON_DECLINED);
  }, [isInviteOnDeclinedCheck]);


  function onFrequencyChange({ frequency }) {
    if(frequency === ''){
      setIsInviteCheck(false);
      handleCheckBoxToggle(false, RULES_NAME.FREQUENCY, '');
      return;
    }
    setIsInviteCheck(true);
    handleFrequencyChange({ frequency });
  }

  function onSubmittedChange(value) {
    if(value === ''){
      setIsInviteOnSubmittedCheck(false);
      handleCheckBoxToggle(false, RULES_NAME.DONT_INVITE_ON_SUBMITTED, '');
      return;
    }
    setIsInviteOnSubmittedCheck(true);
    handleInviteOnSubmittedChange(value);
  }

  function onDeclinedChange(value) {
    if(value === ''){
      setIsInviteOnDeclinedCheck(false);
      handleCheckBoxToggle(false, RULES_NAME.DONT_INVITE_ON_DECLINED, '');
      return;
    }
    setIsInviteOnDeclinedCheck(true);
    handleInviteOnDeclinedChange(value);
  }

  function onNumberAndPagesChange(value, type) {
    let newValue = value === '' ? 0 : value;
    if(!isNumberPositive(parseInt(newValue))) {return;}
    switch (type) {
      case 'numberOfVisits':
        if(!isNumberOfVisitCheck) {
          setIsNumberOfVisitsCheck(true);
        }
        handleNumberOfVisitsChange(newValue, 'numberOfRepeats');
        break;
      case 'numberOfPages':
        if(!isNumberOfPageCheck) {
          setIsNumberOfPageCheck(true);
        }
        handleNumberOfPagesChange(newValue, 'numberOfRepeats');
        break;
      default:
        break;
    }

  }


  return (
    <StyledTargetingContainer data-aut={'sessionBasedTargeting'}>
      <StyledRowElements>
        <StyledCheckBoxWrapper>
          <DigCheckbox
            digIsChecked={isInviteCheck}
            label={getSingleText(`${TEXT_PATH}.displayTo`)}
            handleCheckboxChange={() => setIsInviteCheck(!isInviteCheck)}
            dataAut={'inviteCheckbox'}
            isDisabled={isReadOnlyMode}
          />
        </StyledCheckBoxWrapper>
        <Input
          customErrorMsg={getSingleText(`${TEXT_PATH}.percentageValidationMessage`)}
          digValue={getNestedProperty(rules, PARAMS_URLS.FREQUENCY, '').toString()}
          digOnChange={(frequency) => onFrequencyChange({ frequency })}
          digLabel={false}
          inputStyle={additionInputStyle}
          autoFocus={false}
          digAutLabel={'inviteInput'}
          digIsDisabled={isReadOnlyMode}
        />
        <StyledDaysTitleWrapper>
          <DigTitle additionalDigLabelStyle={additionalDigLabelStyle}  digLabel={getSingleText(`${TEXT_PATH}.users`)} />
        </StyledDaysTitleWrapper>
      </StyledRowElements>
      <StyledRowElements style={{ margin: '15px 0' }}>
        <StyledCheckBoxWrapper>
          <DigCheckbox
            label={getSingleText(`${TEXT_PATH}.numOfSessionsCBPH`)}
            digIsChecked={isNumberOfVisitCheck}
            handleCheckboxChange={() => setIsNumberOfVisitsCheck(!isNumberOfVisitCheck)}
            dataAut={'numOfSessionsCBPHCheckbox'}
            isDisabled={isReadOnlyMode}
          />
        </StyledCheckBoxWrapper>
        <StyledSelectWrapper disabled={!isNumberOfVisitCheck || isReadOnlyMode} data-aut={'numberOfVisitsDropdown'}>
          <Select
            isDisabled={!isNumberOfVisitCheck || isReadOnlyMode}
            isSearchable={true}
            placeholderText={getSingleText(`${TEXT_PATH}.selectFromList`)}
            options={optionsSelect}
            onSelect={(e)=>handleNumberOfVisitsChange(e.value)}
            selected={mapAutoSuggestValueToLabel(PARAMS_URLS.NUMBER_OF_VISITS,rules)}
          />
        </StyledSelectWrapper>
        <Input
          digValue={getNestedProperty(rules,PARAMS_URLS.NUMBER_OF_VISITS_VALUE,'').toString()}
          digType={'number'}
          digLabel={false}
          inputStyle={{ ...additionInputStyle, margin: '0 8px' }}
          digOnChange={(value) => onNumberAndPagesChange(value,'numberOfVisits')}
          autoFocus={false}
          digAutLabel={'numberOfVisitsInput'}
          digIsDisabled={isReadOnlyMode}
        />
        <DigTitle additionalDigLabelStyle={additionalDigLabelStyle}  digLabel={getSingleText(`${TEXT_PATH}.visitsPH`)}/>
      </StyledRowElements>
      <StyledRowElements>
        <StyledCheckBoxWrapper>
          <DigCheckbox
            label={getSingleText(`${TEXT_PATH}.numOfPagesCBPH`)}
            digIsChecked={isNumberOfPageCheck}
            handleCheckboxChange={() => setIsNumberOfPageCheck(!isNumberOfPageCheck)}
            dataAut={'numOfPagesCBPHCheckbox'}
            isDisabled={isReadOnlyMode}
          />
        </StyledCheckBoxWrapper>
        <StyledSelectWrapper disabled={!isNumberOfPageCheck || isReadOnlyMode} data-aut={'numberOfPagesViewedDropdown'}>
          <Select
            isDisabled={!isNumberOfPageCheck || isReadOnlyMode}
            isSearchable={true}
            placeholderText={getSingleText(`${TEXT_PATH}.selectFromList`)}
            options={optionsSelect}
            onSelect={(e)=>handleNumberOfPagesChange(e.value)}
            selected={mapAutoSuggestValueToLabel(PARAMS_URLS.NUMBER_OF_PAGES_VIEWED,rules)}
          />
        </StyledSelectWrapper>
        <Input
          digValue={getNestedProperty(rules,PARAMS_URLS.NUMBER_OF_PAGES_VIEWED_VALUE,'').toString()}
          digOnChange={(value) => onNumberAndPagesChange(value, 'numberOfPages')}
          digType={'number'}
          inputStyle={{ ...additionInputStyle, margin: '0 8px' }}
          autoFocus={false}
          digAutLabel={'numberOfPagesViewedInput'}
          digIsDisabled={isReadOnlyMode}
        />
        <DigTitle additionalDigLabelStyle={additionalDigLabelStyle}  digLabel={getSingleText(`${TEXT_PATH}.pagesPH`)} />
      </StyledRowElements>
      <StyledRowElements style={{ margin: '15px 0' }}>
        <StyledCheckBoxWrapper>
          <DigCheckbox
            label={getSingleText(`${TEXT_PATH}.MetadataSubmitted`)}
            digIsChecked={isInviteOnSubmittedCheck}
            handleCheckboxChange={() => setIsInviteOnSubmittedCheck(!isInviteOnSubmittedCheck)}
            dataAut={'MetadataSubmittedCheckbox'}
            isDisabled={isReadOnlyMode}
          />
        </StyledCheckBoxWrapper>
        <Input
          digValue={getNestedProperty(rules,PARAMS_URLS.DONT_INVITE_ON_SUBMITTED,'').toString()}
          digOnChange={(value) => onSubmittedChange(value)}
          digType={'number'}
          digLabel={false}
          inputStyle={additionInputStyle}
          autoFocus={false}
          digAutLabel={'visitorswWhoDeclinedInput'}
          digIsDisabled={isReadOnlyMode}
        />
        <StyledDaysTitleWrapper>
          <DigTitle additionalDigLabelStyle={additionalDigLabelStyle} digLabel={getSingleText(`${TEXT_PATH}.days`)} />
        </StyledDaysTitleWrapper>
      </StyledRowElements>
      <StyledRowElements>
        <StyledCheckBoxWrapper>
          <DigCheckbox
            label={getSingleText(`${TEXT_PATH}.MetadataDecline`)}
            digIsChecked={isInviteOnDeclinedCheck}
            handleCheckboxChange={() => setIsInviteOnDeclinedCheck(!isInviteOnDeclinedCheck)}
            dataAut={'MetadataDeclineCheckbox'}
            isDisabled={isReadOnlyMode}
          />
        </StyledCheckBoxWrapper>
        <Input
          digValue={getNestedProperty(rules,PARAMS_URLS.DONT_INVITE_ON_DECLINED,'').toString()}
          digOnChange={(value) => onDeclinedChange(value)}
          digType={'number'}
          digLabel={false}
          inputStyle={additionInputStyle}
          autoFocus={false}
          digAutLabel={'visitorsWhoSubmittedInput'}
          digIsDisabled={isReadOnlyMode}
        />
        <StyledDaysTitleWrapper>
          <DigTitle additionalDigLabelStyle={additionalDigLabelStyle}  digLabel={getSingleText(`${TEXT_PATH}.days`)} />
        </StyledDaysTitleWrapper>
      </StyledRowElements>
    </StyledTargetingContainer>
  );
}

