import styled from 'styled-components';

const fontWeightBold = 700;

const fontSizeLarge = 18;

const colors = {
  subHeader: '#6B7588',
};

export const StyledUpgradeModalTitle = styled.h1`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeLarge}px;
`;

export const StyledUpgradeModalSubTitle = styled.p`
	white-space: pre-line;
`;

export const UpgradeModalOptionsContainer = styled.div`

	legend { // overriding styles from assets/css/styles.css
		left: 0;
		padding: 0;
		top: 0;
	}

	fieldset {
		margin-bottom: 0; // override component's inner style
	}
	margin-left: 3px; // hover effect on radio buttons is cut unless adding margin
`;

export const StyledSubHeader = styled.p`
	color: ${colors.subHeader};
`;

export const SelectWrapper = styled.div`
  #field-5{
    width: 100%;
    margin-left: 20px;
  }
  #select-7{
  	border: none;
  }
`;

export const StyledFileUploaderContainer = styled.div`
  ol {
    margin-bottom: 0; // override component's inner style
  }
`;
