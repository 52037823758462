import React from 'react';
import FieldRow from '../../../MobileEngagementDesign/Banner/SettingsExpandableList/shared/FieldRow';
import ColorPicker from '@mdigital/components/dist/components/ColorPicker';
import ThankYouPromptImageReusableResourceSelect from '../../ThankYouPromptSettings/ThankYouPromptGeneralSettings/ThankYouPromptGeneralSettingsImageReusableResourceSelect';
import ThankYouPromptImageUpload from '../../ThankYouPromptSettings/ThankYouPromptGeneralSettings/ThankYouPromptGeneralSettingsImageUpload';
import { StyledTYPDarkModeContainer } from './StyledThankYouPromptDarkMode';
import Toggle from '@mdigital/components/dist/components/Toggle';
import { FormSpy } from 'react-final-form';

const onImageToggle = (input , mutators, values) =>{
  input.onChange(!input.value);
  !input.value && mutators.setDarkImageDataValues(values.trigger.mobileThankYouPromptImageDataContract);
};

const DarkMode = ({ reusableResourcesEnabled , mutators , disabled }) => (
  <StyledTYPDarkModeContainer>
    <FieldRow title="Text Color" name="mobileThemes.themeData.dark.palette.typography.headline" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
    <FieldRow title="Background" name="mobileThemes.themeData.dark.palette.core.canvas" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
    <FieldRow title="Button" name="mobileThemes.themeData.dark.palette.action.primary" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
    <FieldRow title="Button Text" name="mobileThemes.themeData.dark.palette.typography.action.primary" render={({ input }) => (
      <ColorPicker pickerOnLeft pickerOnTop {...input} onChange={({ hex }) => input.onChange(hex)} />
    )} />
    <FormSpy subscription={{ values: true }} render={({  values }) => (
      <FieldRow title="Use the Light Mode image" name="trigger.sameDarkModeImageAsLight" className="dark-image-toggle" render={({ input }) => (
        <Toggle onToggle={() => onImageToggle(input , mutators, values)} switchState={input.value} />
      )} />
    )} />
    {
      reusableResourcesEnabled ?
        <FieldRow name="trigger.mobileThankYouPromptDarkImageDataContract.resourceId" render= {({ input }) => (
          <ThankYouPromptImageReusableResourceSelect {...input} mutators={mutators} isDarkModeImage={true}/>
        )}/> :
        <FieldRow name="trigger.mobileThankYouPromptDarkImageDataContract.name" render= {( input ) => (
          <ThankYouPromptImageUpload mutators={mutators} fileName={input.input.value !== '' ? input.input.value : 'No File...'} disabled={disabled} isDarkModeImage={true}/>
        )} />
    }
  </StyledTYPDarkModeContainer>
);
export default  DarkMode;