import styled from 'styled-components';

export const StyledModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    svg{
        display: block;
    }
`;

export const StyledText = styled.span`
    font-size: 14px;
    color: #5B5B60;
    word-wrap: break-word;
    display: inline-block;
    width: 471px;
    padding-bottom: 15px;
`;

export const StyleHeaderText = styled.span`
    font-size: 14px;
    color: #5B5B60;
    margin-left: 12px;
`;