import React from 'react';
import PropTypes from 'prop-types';
import DigRadioButtons from '@mdigital/components/dist/components/RadioButtons';
import DigAutoSuggest from '@mdigital/components/dist/components/AutoSuggest';
import DigTitle from '@mdigital/components/dist/components/Title';
import { bind } from '@mdigital/components/dist/decorators';
import { getSingleText } from '../../../common/utils';

const languageDeterminationMethods = [
  { digLabel: getSingleText('app.pages.forms.localization.browserLocaleMethodLabel'), digValue: getSingleText('app.pages.forms.localization.browserLocaleMethodValue') },
  { digLabel: getSingleText('app.pages.forms.localization.customParameterMethodLabel'), digValue: getSingleText('app.pages.forms.localization.customParameterMethodValue') }
];

export default class LanguageDeterminationSection extends React.Component {

  constructor(props) {
    super(props);
    this.isCustomParameterValid = true;
  }

    static propTypes = {
      useCustomParam: PropTypes.bool,
      onChange: PropTypes.func,
      customParameters: PropTypes.array,
      onSelected: PropTypes.func,
      customParam: PropTypes.number,
      isReadOnly: PropTypes.bool,
    };

    @bind
    onMethodChange(event) {
      const isUseCustomParameter = (event.currentTarget.value === getSingleText('app.pages.forms.localization.customParameterMethodValue'));
      this.props.onChange(isUseCustomParameter);
      if(!isUseCustomParameter) {
        this.props.onSelected(null);
      }
    }

    @bind
    onSelected({ label }) {
      this.isCustomParameterValid = true;
      this.props.onSelected(label);
    }

    @bind
    onChange(customParameterLabel){
      this.customParameterLabel = customParameterLabel;
      if (this.isCustomParameterValid) {
        this.isCustomParameterValid = false;
        this.props.onSelected(null);
      }
    }

    getCustomParameterLabel() {
      const cp = this.props.customParameters.find( (customParameter) => customParameter.label === this.props.customParam) || {};
      return cp.id;
    }

    render() {
      let customParameterLabel;
      const textPath = this.props.useCustomParam ? 'app.pages.forms.localization.customParameterMethodValue' : 'app.pages.forms.localization.browserLocaleMethodValue';
      const languageDeterminationMethod = getSingleText(textPath);
      const isCustomParameterMethodDisabled = languageDeterminationMethod !== getSingleText('app.pages.forms.localization.customParameterMethodValue');
      if (!isCustomParameterMethodDisabled) {
        customParameterLabel = this.isCustomParameterValid ? this.getCustomParameterLabel() : this.customParameterLabel;
      }
      return (
        <div>
          <DigTitle
            digLabel={getSingleText('app.pages.forms.localization.languageDeterminationMethodTitle')}
            digTooltip={getSingleText('app.pages.forms.localization.languageDeterminationMethodTooltip')}
            digClass='bold-title' />

          <br/>

          <div className='radio-buttons-language-determination'>
            <DigRadioButtons
              digOnChange={this.onMethodChange}
              digRadioButtons={languageDeterminationMethods}
              digValue={languageDeterminationMethod}
              digDisabled={this.props.isReadOnly}/>
          </div>

          <div className='custom-param-dropdown'>
            <DigAutoSuggest
              disabled={isCustomParameterMethodDisabled || this.props.isReadOnly}
              onSuggestionSelected={this.onSelected}
              value={customParameterLabel}
              suggestions={this.props.customParameters}
              placeholder={getSingleText('app.pages.forms.localization.customParameterMethodPlaceholder')}
              onChange={this.onChange}
            />
          </div>
        </div>
      );
    }
}
