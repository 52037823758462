import { ModalsService } from '@mdigital/components/dist/utils/modals';
import { VIEW_TYPE } from '..';
import { CustomDialog } from '../StyledTriggeringModal';

export const SwitchTriggerTypeModal = ( setViewType, setHasErrors ) => {

  const switchTriggerTypeModalCallback = () => {
    setViewType(VIEW_TYPE.SELECTION_PAGE);
    setHasErrors({}); //initialize the validation object that contain errors
  };

  ModalsService.showConfirmation({
    title: 'Switch Trigger Type',
    description: 'Are you sure you want to switch Trigger type? Notice that your current settings will be discarded.',
    CustomDialog: CustomDialog,
    type: 'danger',
    close: {
      title: 'Cancel',
    },
    confirm: {
      title: 'Switch Trigger Type',
      callback: switchTriggerTypeModalCallback,
    },
  });
};