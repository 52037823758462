import React, { useEffect, useContext } from 'react';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { TriggerContext } from '../../../../../../..';
import TargetingBlockRow from '../../../TargetingBlockRow';
import { Input } from '@m/alchemy-ui';
import { TargetingActions } from '../../../../../Actions';
import { isRuleExists, formatInputTextValue } from '../../../../../../../helpers';
import { getSingleText } from '../../../../../../../../../../common/utils';
import { TextPathPrefix } from '../../../../../index';
import useVersionValidation from '../hooks/useVersionValidation';

const TargetByAppVersion = () => {
  const { triggerState, triggerDispatch, isReadOnly } = useContext(TriggerContext);
  const [hasError, setHasError] = useVersionValidation('trigger.rules.MobileAppVersion');

  useEffect(() => {
    isRuleExists(triggerState, 'trigger.rules.MobileAppVersion.id') ?
      triggerDispatch({ type: TargetingActions.SET_TARGET_BY_APP_VERSION_IS_ACTIVE, payload: true }) :
      triggerDispatch({ type: TargetingActions.INIT_TARGET_BY_APP_VERSION_PARAMS, payload: {} });
  }, []);

  const handleAppVersionChange = (e) => {
    triggerDispatch({ type: TargetingActions.SET_TARGET_BY_APP_VERSION, payload: e.target.value.split(',') });
    setHasError(e.target.value === '');
  };

  return (
    <TargetingBlockRow
      checkboxLabel="App version"
      isFullWidth
      checked={getNestedProperty(triggerState, 'trigger.rules.MobileAppVersion.isActive')}
      disabled={isReadOnly}
      hasError={hasError}
      errorMessage={getSingleText(`${TextPathPrefix}.errors.required`)}
      onCheckboxChange={(e) => triggerDispatch({ type: TargetingActions.SET_TARGET_BY_APP_VERSION_IS_ACTIVE, payload: e.target.checked })}>
      <Input
        className='targeting-large-field'
        value={formatInputTextValue(triggerState, 'trigger.rules.MobileAppVersion.params.versions')}
        onChange={handleAppVersionChange} />
    </TargetingBlockRow>
  );
};

export default TargetByAppVersion;
