import styled from 'styled-components';
import { PRIMARY_TEXT } from '../../common/colors';

export const SystemUpdateWrapper = styled.div``;

export const TextWrapper = styled.div`
  font-size:14px;
   color:${PRIMARY_TEXT};
   font-weight:${({ bold })=>bold ? 550 : 0};
   margin-bottom:${({ first }) => first ? '7px' : '2px' };
`;

export const IconAndTextWrapper = styled.div`
padding-bottom:23px;
display:flex;
`;

export const ContentWrapper = styled.div`
padding-left:9px;
`;