import { Input } from '@m/alchemy-ui';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import React, { useContext, useEffect, useState } from 'react';
import TargetingField from '../../../../Shared/TargetingField';
import { TargetingActions } from '../../../Actions';
import { StyleAppIconSettings } from './StyleAppIconSettings';
import { TriggerContext } from '../../../../../index';
import { getSingleText } from '../../../../../../../../common/utils';

export const AppName = () => {
  const { triggerDispatch, triggerState, isReadOnly } = useContext(TriggerContext);
  const defaultAppName =  getSingleText('app.pages.forms.triggeringModal.triggerByTargeting.localNotification.appName.yourAppName');
  const [appName, setAppName] = useState(defaultAppName);
  useEffect(() => {
    setAppName(getNestedProperty(triggerState, 'trigger.triggerCustomLocalNotificationContract.appName') || defaultAppName);
  },[]);

  const handleAppNameChange = (e) => {
    setAppName(e.target.value);
    triggerDispatch({ type: TargetingActions.SET_APP_NAME, payload: e.target.value });
  };

  const appNameMaxLength = 178;
  return (
    <React.Fragment>
      <StyleAppIconSettings>
        <TargetingField
          label="App Name"
          className="app-name"
          inputMaxLength={appNameMaxLength.toString()}
          inputCounter={appName && appName.length || 0}
          errorMessage={getSingleText('app.pages.forms.triggeringModal.triggerByTargeting.errors.required')}
          hasError={!appName}
          disabled={isReadOnly}
          required={true}
          render={<Input
            placeholder="Insert your app name here"
            maxLength={appNameMaxLength}
            value={appName}
            onChange={handleAppNameChange} />}
        />
      </StyleAppIconSettings>
    </React.Fragment>
  );
};
