import React from 'react';
import DigTitle from '@mdigital/components/dist/components/Title';
import Select from '@mdigital/components/dist/components/Select';
import DigRadioButtons from  '@mdigital/components/dist/components/RadioButtons/RadioButtons';
import { StyledSelectWrapper,LabelWrapper,RadioButtonContainer } from './RadioButtonStyled';
import { EXISTING_FIELD } from '../../NewQuestionTypeForm/NewQuestionTypeForm';


const radioButtonTitleStyle = {
  textAlign: 'left',
  font: 'normal normal normal 14px/18px Open Sans',
  letterSpacing: '-0.35px',
  color: '#40440A',
};

const radioButtonSubTitleStyle = {
  textAlign: 'left',
  font: 'normal normal normal 12px/18px Open Sans',
  letterSpacing: '-0.3px',
  color: '#747474',
};

const SELECT_PLACEHOLDER = 'Select an existing field';

export default function RadioButton({
  withSelect = false,
  title,
  subTitle,
  containerStyle,
  labelStyle,
  selectOptions,
  handleSetFieldObj,
  fieldState,
  setSelectedRadio,
  selectedRadio,
  onChange,
  digRadioButtons,
  customStyle = {},
}) {

  function mapDataToSelectOptions() {
    return selectOptions && selectOptions.map( ({ ecFriendlyName,ecKey }) => ({
      value: ecKey,
      label: ecFriendlyName,
    }));
  }

  function handleOnSelect({ value, label }) {
    setSelectedRadio(EXISTING_FIELD);
    const newFieldState = {
      ...fieldState,
      ecKey: value,
      ecFriendlyName: label,
      isExistingField: true,
    };
    handleSetFieldObj(newFieldState);
  }

  function getSelectedValue() {
    return selectedRadio === EXISTING_FIELD && fieldState && fieldState.ecFriendlyName ? { value: fieldState.ecFriendlyName, label: fieldState.ecFriendlyName } : null;
  }

  return <RadioButtonContainer style={{ ...containerStyle }}>
    <DigRadioButtons
      digOnChange={onChange}
      digRadioButtons={digRadioButtons}
      digValue={selectedRadio} />
    <LabelWrapper style={{ ...labelStyle }} isSubTitle={subTitle}>
      <DigTitle digLabel={title}  additionalDigLabelStyle={{ ...radioButtonTitleStyle, ...customStyle.radioButtonLabel }} />
      <DigTitle digLabel={subTitle}  additionalDigLabelStyle={{ ...radioButtonSubTitleStyle, ...customStyle.radioButtonLabel }} />
      { withSelect && <StyledSelectWrapper>
        <Select
          isSearchable={true}
          placeholderText={SELECT_PLACEHOLDER}
          options={mapDataToSelectOptions() || []}
          onSelect={ (data) => handleOnSelect(data)}
          selected={getSelectedValue()}
        />
      </StyledSelectWrapper>
      }
    </LabelWrapper>
  </RadioButtonContainer>;
}
