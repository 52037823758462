import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DigTitle from '@mdigital/components/dist/components/Title';
import DigLanguageDropdown from '../DigLanguageDropdown';
import { bind } from '@mdigital/components/dist/decorators';
import { getSingleText } from '../../../common/utils';

@connect( (store) => store)
export default class FormPrimaryLanguageSection extends React.Component {

    static propTypes = {
      availableLanguages : PropTypes.array,
    };

    constructor(props) {
      super(props);
      this.isValidLanguage = true;
      this.defaultLanguage = '';
    }

    @bind
    onChange(label) {
      this.defaultLanguage = label;
      if(this.isValidLanguage) {
        this.isValidLanguage = false;
        this.props.onSelected(null);
      }
    }

    @bind
    onSelected({ label }) {
      this.isValidLanguage = true;
      this.props.onSelected(label);
    }

    @bind
    filterer({ label }) {
      return !!(this.props.availableLanguages.find((language) => language.label === label));
    }

    render() {
      const filterer = this.props.availableLanguages ? this.filterer : null;
      let defaultLanguage;
      if (this.props.availableLanguages && this.isValidLanguage) {
        defaultLanguage = this.props.availableLanguages.find( (language) => language.label === this.props.selectedLanugage);
        defaultLanguage = defaultLanguage ? defaultLanguage.id : '';
      } else {
        defaultLanguage = this.defaultLanguage;
      }
      return (
        <div>
          <DigTitle digLabel={getSingleText('app.pages.forms.localization.formPrimaryLanguageTitle')} digTooltip={getSingleText('app.pages.forms.localization.formPrimaryLanguageTooltip')} digClass='default-title'/>
          <DigLanguageDropdown onChange={this.onChange} onSelected={this.onSelected} defaultLanguage={defaultLanguage} filterer={filterer} isReadOnly={this.props.isReadOnly}/>
        </div>
      );
    }
}

