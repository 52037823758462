import React from 'react';
import CodeSnippet from '../../../TriggerByCode/CodeSnippet';
import { getPropertyType } from '../../../../../../../redux/store/store-helper';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { getSingleText } from '../../../../../../../common/utils';
import SharedRightPanel from '../../../Shared/SharedRightPanel';
const TextPathPrefix = 'app.pages.forms.triggeringModal.triggerByTargeting.pushNotification';

const PushNotification = () =>{
  const propertyType = getPropertyType();
  const codeSnippet = getNestedProperty(CodeSnippet['pushNotification'], propertyType);
  const title = getSingleText(`${TextPathPrefix}.title`);
  const subtitle = getSingleText(`${TextPathPrefix}.subtitle`);
  const description = getSingleText(`${TextPathPrefix}.description`);
  const instruction = getSingleText(`${TextPathPrefix}.instruction`);
  const instructionLink = getSingleText(`${TextPathPrefix}.instructionLink`);

  return (
    <SharedRightPanel title={title}
      subtitle={subtitle}
      description={description}
      code={codeSnippet}
      instruction={instruction}
      instructionLink={instructionLink}/>
  );
};

export default PushNotification;