

import React from 'react';
import styled from 'styled-components';
import {
  TargetByRating,
  TargetByCustomRule,
  TargetByPercetageOfVisitors,
  TargetByNumberOfSessions,
  TargetBySessionDuration,
  TargetByAppVersion,
  TargetByOSVersion,
  MobileDirectRenderer
} from './Rows';
import { ModalGlobalDataConsumer } from '../../../index';
import { INPUT_BORDER_COLOR , APP_RATING_LABEL_COLOR } from '../../../../../common/colors';
const StyledComponent = styled.div`
  padding: 20px 30px;

  p {
    font-size: 13px;
    font-weight: bold;
    color: #59595D;
  }

  .target-by-rating-time-frame-units input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .target-by-rating-time-frame-value input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  input {
    height: 34px;
    border: 1px solid ${INPUT_BORDER_COLOR};
    border-radius: 4px;
  }

  .targeting-by-previous-visits {
    & > div {
      padding: 6px 0;
    }
  }

  .limitaions-info{
    color: ${APP_RATING_LABEL_COLOR};
    font-weight: 400;
  }
`;

const Target = ({ appRatingCustomRules , isMobileDirectEnabled , helpLink , mutators }) => (
  <StyledComponent>
    <p className="page-title">Set who to prompt to rate your app by the options below</p>
    <br />
    <ModalGlobalDataConsumer>{({ showScoreTargeting }) => showScoreTargeting && (
      <TargetByRating mutators={mutators} />
    )}</ModalGlobalDataConsumer>
    <TargetByCustomRule customRules={appRatingCustomRules} />
    <hr />
    <TargetByNumberOfSessions />
    <TargetByPercetageOfVisitors />
    <TargetBySessionDuration />
    <TargetByAppVersion />
    <TargetByOSVersion /> 
    <hr />
    <MobileDirectRenderer isMobileDirectEnabled={isMobileDirectEnabled} helpLink={helpLink}/>
  </StyledComponent>
);

export default Target;