export const addToArrayField = ([pathInState, newItem], state, { changeValue }) => 
  changeValue(state, pathInState, (pieceOfState = []) => pieceOfState.concat(newItem));

export const removeFromArrayField = ([pathInState, idx], state, { changeValue }) =>
  changeValue(state, pathInState, (pieceOfState = []) => [...pieceOfState.slice(0, idx), ...pieceOfState.slice(idx + 1)]);

export const mutateMobileBannerPosition = ([{ label = '' }], state, { changeValue }) => {
  const [position, isPartial] = label.split('-');
  changeValue(state, 'trigger.triggerCustomBannerContract', (bannerContract) => ({ ...bannerContract, position, isPartial: isPartial === 'true' }));
};

export const setMobileRatingScoresIsActive = ([value], state, { changeValue }) => {
  changeValue(state, 'trigger.rules.MobileRatingScores.IsActive', () => value);
};

export const setImageDataValues = ([value], state, { changeValue }) => {
  changeValue(state, 'trigger.mobileThankYouPromptImageDataContract', () => value);
};

export const setDarkImageDataValues = ([value], state, { changeValue }) => {
  changeValue(state, 'trigger.mobileThankYouPromptDarkImageDataContract', () => value);
};

export const setImagetextContent = ([value], state, { changeValue }) => {
  changeValue(state, 'trigger.mobileThankYouPromptGeneralSettingsSectionContract.textContent', () => value);
};

export const setImagebodyContent = ([value], state, { changeValue }) => {
  changeValue(state, 'trigger.mobileThankYouPromptGeneralSettingsSectionContract.bodyContent', () => value);
};

export const setButtonTextColor = ([value], state, { changeValue }) => {
  changeValue(state, 'trigger.mobileThankYouPromptButtonSectionContract.buttonTextColor', () => value);
};

export const setButtonColor = ([value], state, { changeValue }) => {
  changeValue(state, 'trigger.mobileThankYouPromptButtonSectionContract.buttonColor', () => value);
};

export const setThankYouPromptImageResourceData = ([{ id, fileName, url }], state, { changeValue }) =>
  changeValue(state, 'trigger.mobileThankYouPromptImageDataContract', (imageDataContract) => ({
    ...imageDataContract,
    resourceId: id || null,
    name: fileName || null,
    url,
  }));

export const setThankYouPromptDarkImageResourceData = ([{ id, fileName, url }], state, { changeValue }) =>
  changeValue(state, 'trigger.mobileThankYouPromptDarkImageDataContract', (imageDataContract) => ({
    ...imageDataContract,
    resourceId: id || null,
    name: fileName || null,
    url,
  }));
  
export const setFormViewType = ([value], state, { changeValue }) => {
  changeValue(state, 'formSettings.settings.formMobileSettingsContract.formViewType', () => value);
};