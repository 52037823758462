import styled from 'styled-components';

export const StyledSelectWrapper = styled.div`
	position: relative;
	cursor:${({ disabled }) => disabled ? 'no-drop' : 'pointer' }; 
	opacity: ${({ disabled }) => disabled ? '0.5' : '1' };
	border-radius: 0px !important;
	border-color: #fff !important;
	width: 100%;
	font-size: 13px !important;
	margin-top: 5px;
	.react-select__input {
		width: 124px;
    height: 28px;
		border-radius: 0px;
		margin-bottom: 10px !important;
		
		input{
			background-color: transparent !important;
			position: absolute;
			top:0;
			border-radius: 0px;
		}
	}
	.react-select__control {
		border-radius: 0px !important;
		opacity: 0.9;
		:hover{
			border-color: #c8c7cc !important;
			opacity: 1;
			cursor:${({ disabled }) => disabled ? 'no-drop' : 'pointer' }; 
		}
	}
	.react-select__value-container {
		align-items: flex-start;
	}
  .react-select__menu {
    padding: 6px;
		padding-right: 13px;
		div {
			border-color: #fff !important;
		}
		span {
			border-color: #fff !important;
		}
  }
  .react-select__menuList {
    border: solid 0px #fff !important;
  }
  .react-select__option {
    height: 28px;
    text-align: left;
    display: flex;
    align-items: center;
		:hover{
			border-radius: 0px !important;
      color: #fff;
      background-color: #007AFF;
			border-color: #000 !important;
			opacity: 1;
			cursor:${({ disabled }) => disabled ? 'no-drop' : 'pointer' }; 
		}
	}
	div {

	}
	p{
		margin-top: 4px;
		font-size: 13px !important;
	}
	svg {
		width: 13px;
		height: 13px;
		margin-top: 5px;
	}
`;


export const LabelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items:flex-start;
	width: 100%;
	 label {
		margin-bottom: 0 !important;
	}
`;

export const RadioButtonContainer = styled.div`
	display: flex;
`;