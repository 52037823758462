import { ModalsService } from '@mdigital/components/dist/utils/modals'; 
import { CustomDialog } from '../StyledTriggeringModal';

export const DiscardChangesModal = ( callbackFunc , value ) => {
  ModalsService.showConfirmation({
    title: 'Discard changes?',
    description: 'Any changes you made to this trigger won\'t be saved.',
    CustomDialog: CustomDialog,
    type: 'danger',
    close: {
      title: 'Cancel',
    },
    confirm: {
      title: 'Discard',
      callback: () => callbackFunc(value),
    },
  });
};
