import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';

export const StyledFormSettingsFieldContainer = styled.div`
    display: flex;
    padding-bottom: 12px;
    &:nth-child(1) {
       margin-right: 80px;
    }

    &.submit-button-section{
      margin-bottom: 24px;
    }
`;

export const StyledFormSettingsFieldLabel = styled.div`
    width: 200px;
    margin: auto 0 auto 0;
    position: relative;
    & > label {
      font-size: 14px;
      color: #7B7A7A;
      position: relative;
      ${({ required }) => required ? `
      &::after {
        content: '*';
        color: #D92D2D;
        float: left;
        margin-right: 3px;
      }
      ` : ''}
      ${({ required , hasError }) => required && hasError ? `
      &::after {
        content: "\f00d";
        font-family: FontAwesome;
        color: #C82E29;
        float: left;
        margin-right: 3px;
      }
      ` : ''}
    }
`;

export const StyledFormSettingsField = styled.div`
    ${({ disabled }) => disabled ? 'pointer-events: none; opacity: 0.4;' : ''};
    .dig-input[type="text"]{
        width: 306px;
        border-style: ${(props) => props.hasError ? 'dotted' : 'solid'};
        border-color: ${(props) => props.hasError ? COLORS.UPLOAD_ERR : ''};
    }
`;

export const FormSettingsErrorMessage = styled.span`
    color: ${COLORS.UPLOAD_ERR};
    position: absolute;
    font-size: 12px;
    top: 49px;
`;