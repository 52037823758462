import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import BlockableRow from '../BlockableRow';

const IS_ACTIVE_KEY = 'trigger.rules.MobileAppVersion.isActive';

const StyledElement = styled.div`
  input {
    width: 300px;
  }
`;

const TargetByAppVersion = () => (
  <BlockableRow title="App version" isActiveFieldName={IS_ACTIVE_KEY}>
    <StyledElement>
      <Field name="trigger.rules.MobileAppVersion.params.versions" component="input" />
    </StyledElement>
  </BlockableRow>
);

export default TargetByAppVersion;
