import React from 'react';
import { TargetByPreviousAccept ,TargetByPreviousDecline , TargetBySystemProvided } from '..';
const pText = 'Find out about prompts display limitaions';
const linkText = 'here';

const MobileDirectRenderer = ({ isMobileDirectEnabled , helpLink }) => (
  isMobileDirectEnabled ? 
    <React.Fragment>
      <TargetBySystemProvided/>
      {helpLink && <p className="limitaions-info"> {pText}  <a target="_blank" href={ helpLink }> { linkText }</a> </p>}
    </React.Fragment>
    :
    <React.Fragment>
      <TargetByPreviousDecline />
      <TargetByPreviousAccept />
    </React.Fragment>
);

export default MobileDirectRenderer;