import React from 'react';
import { StyledMediaCaptureAccessibilityTab , StyledMediaCaptureAccessibilityTabTitle , 
  StyledRequiredIcon , StyledHasErrorIcon , StyledAccessibilityErrorMessage } from './StyledMediaCaptureAccessibility';
import DigInput from '@mdigital/components/dist/components/Input';

const maxLength = 60;
const requiredMessage = 'This field is required.';

const isEmpty = (value)=>value.length === 0;

const showRequiredIcon = (value) =>value.length > 0 ? <StyledRequiredIcon/> : <StyledHasErrorIcon className="neb-has-error"/>;

const MediaCaptureAccessibilityFiled = ( { title , value , onChange  } ) => {
  const inputValue = value || '';
  return (
    <StyledMediaCaptureAccessibilityTab>
      {showRequiredIcon( inputValue )}
      <StyledMediaCaptureAccessibilityTabTitle>{title}</StyledMediaCaptureAccessibilityTabTitle>
      <DigInput 
        digValue={ value }
        digOnChange={ (e)=> onChange(e) }
        enableCounter
        maxLength={ maxLength }
        digRequired={true}
      />
      {isEmpty(inputValue) && 
              <StyledAccessibilityErrorMessage>{requiredMessage}</StyledAccessibilityErrorMessage>}
    </StyledMediaCaptureAccessibilityTab>
  );
};

export default MediaCaptureAccessibilityFiled;