export const ACTIVE_BLUE = '#007aff';
export const RADIO_CICLE_BACKGROUND = '#d0d0d0';
export const RADIO_CICLE_BORDER = '#a1a1a1';
export const WHITE = '#fff';
export const BLACK = '#000';
export const TITLE_TEXT = '#5B5B63';
export const REQUIRED_RED = '#D43E3A';
export const APP_RATING_LABEL_COLOR = '#858585';
export const APP_RATING_MODAL_INPUT_TEXT_COLOR = '#676265';
export const CHARACTER_COUNTER_GREY = '#8E8E93';
export const TITLE_TEXT_COLOR = '#8E8E93';
export const INPUT_BORDER_COLOR = '#C8C7CC';
export const DARK_LIGHT = '#434343';
export const LIGHT_GRAY = '#7B7A7A';
export const DISABLED_GRAY = '#cfcfd1';
export const PRIMARY_TEXT = '#595959';
export const WARNING_BACKGROUND = '#FDFAE9';
export const WARNING_BORDER = '#F3D124';
export const WARNING_TEXT = '#2B3447';
export const SEPARATOR = '#d9d9d9';
export const SELECT_BOX_SHADOW = '#00000029';
export const SELECT_BORDER_COLOR = '#CCC';
export const REUSABLE_RESOURCE_DROPDOWN_BORDER_COLOR = '#aeacb4';
export const HEADER_TEXT = '#2C2C2E';
export const IOS_PRIMARY = '#0A84FF';
export const ANDROID_PRIMARY = '#6200EE';
export const DARK_ANDROID_PRIMARY = '#BB86FC';
export const GRAY_BACKGROUND = '#ececec';
export const LIGHT_SEPARATOR = 'rgb(60,60,67,0.36)';
export const DARK_SEPARATOR = 'rgb(84,84,88,0.65)';
export const DARK_BACKGROUND = '#323232';
export const GRAY = '#FFFFFF99';
export const GRAY_SEPARATOR = '#DDE1E5';

export default {
  ACTIVE_BLUE,
  RADIO_CICLE_BACKGROUND,
  RADIO_CICLE_BORDER,
  WHITE,
  BLACK,
  TITLE_TEXT,
  REQUIRED_RED,
  APP_RATING_LABEL_COLOR,
  CHARACTER_COUNTER_GREY,
  DARK_LIGHT,
  LIGHT_GRAY,
  DISABLED_GRAY,
  SEPARATOR,
  PRIMARY_TEXT,
  REUSABLE_RESOURCE_DROPDOWN_BORDER_COLOR,
  HEADER_TEXT,
  IOS_PRIMARY,
  ANDROID_PRIMARY,
  DARK_ANDROID_PRIMARY,
  GRAY_BACKGROUND,
  LIGHT_SEPARATOR,
  DARK_SEPARATOR,
  DARK_BACKGROUND,
  GRAY,
  GRAY_SEPARATOR,
};
