import React, { useContext, useEffect, useState } from 'react';
import { HelpIcon , Field, FieldLabel, FieldContent, FieldStatus } from '@m/alchemy-ui';
import { Tooltip } from '@m/alchemy-ui/esm';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { TextPathPrefixLocalNotification } from '..';
import { TriggerContext } from '../../../../../index';
import { PanelLabel } from '../../../../../StyledTriggeringModal';
import { TargetingActions } from '../../../Actions';
import { getSingleText } from '../../../../../../../../common/utils';
import { StyledLogoImage, AlchemyFiledRequiredDot } from './StyledLogoImage';
import { PanelSubTooltip } from '../../../../../StyledTriggeringModal';
import ResourcesSelect from '../../../../../../ResourcesSelect/ResourcesSelect';
import { getPropertyType } from '../../../../../../../../redux/store/store-helper';
import { PROPERTY_TYPES } from '../../../../../../../../common/constants';

const SUPPORTED_SELECTION_TYPES = ['png', 'jpg', 'jpeg', 'gif'];

export const LogoImage = () => {
  const { triggerDispatch, triggerState, hasErrors, setHasErrors, isReadOnly } = useContext(TriggerContext);
  const [status, setStatus] = useState();
  const label = getSingleText(`${TextPathPrefixLocalNotification}.imageSelection.logoImage`);
  const errorMessage = getSingleText(`${TextPathPrefixLocalNotification}.imageSelection.errorMessage`);
  const propertyType = getPropertyType();
  const isAndroidProperty = propertyType === PROPERTY_TYPES.ANDROID;
  const resourceId = getNestedProperty(triggerState, 'trigger.triggerCustomLocalNotificationContract.appIcon.resourceId');
  const onSelectedLogoResource = (image) => {
    const payload = {
      name: image.fileName,
      resourceId: image.id,
      url: image.url,
      uuid: null,
    };
    triggerDispatch({ type: TargetingActions.SET_APP_LOGO, payload });
    if(payload.resourceId) {
      setStatus(null);
      hasErrors && hasErrors[label] && delete hasErrors[label];
    }else{
      setStatus({ level: 'error', message: errorMessage });
      isAndroidProperty && setHasErrors({ ...hasErrors , [label] : errorMessage });
    }
  };


  useEffect(() => {
    if (!resourceId) {
      setStatus({ level: 'error', message: errorMessage });
      isAndroidProperty && setHasErrors({ ...hasErrors , [label] : errorMessage });
    }
  }, []);

  return (
    <StyledLogoImage>
      <Field
        required={isAndroidProperty}>
        { isAndroidProperty && <AlchemyFiledRequiredDot/> }
        <FieldLabel>
          <PanelSubTooltip>
            <PanelLabel>{getSingleText(`${TextPathPrefixLocalNotification}.imageSelection.logoImage`)}</PanelLabel>
            <Tooltip
              trigger={
                <HelpIcon size="12" palette="structural" />
              }
              placement="right"
              contents={getSingleText(`${TextPathPrefixLocalNotification}.imageSelection.appIconTooltip`)}
            />
          </PanelSubTooltip>
        </FieldLabel>
        <FieldContent>
          <ResourcesSelect
            resourceId={getNestedProperty(triggerState, 'trigger.triggerCustomLocalNotificationContract.appIcon.resourceId')}
            placeholder={getSingleText(`${TextPathPrefixLocalNotification}.imageSelection.placeholder`)}
            types={SUPPORTED_SELECTION_TYPES}
            onSelect={onSelectedLogoResource}
            isAlchemyDesign={true}
            isDisabled={isReadOnly}/>
          {isAndroidProperty && <FieldStatus level={status && status.level} message={status && status.message} /> }
        </FieldContent>
      </Field>
    </StyledLogoImage>
  );
};