import React from 'react';
import { StyledIOSAlertPreviewBodyWrapper, StyledIOSInvitationHeadline, StyledIOSInvitationText,
  StyledIOSProvideButtonText, AlertLivePreviewSpeartor, StyledIOSAlertButton } from './StyledAlertLivePreview';

const IOSAlertPreview = ({ values, isDark }) => {
  const {
    trigger: {
      invitationHeadline,
      invitationText,
      provideButtonText,
      declineButtonText,
      laterButtonText,
    },
  } = values;
  return (
    <StyledIOSAlertPreviewBodyWrapper isDark={isDark}>
      <StyledIOSInvitationHeadline isDark={isDark}>{invitationHeadline}</StyledIOSInvitationHeadline>
      <StyledIOSInvitationText isDark={isDark}>{invitationText}</StyledIOSInvitationText>
      <AlertLivePreviewSpeartor isDark={isDark}/>
      <StyledIOSProvideButtonText>{provideButtonText}</StyledIOSProvideButtonText>
      <AlertLivePreviewSpeartor isDark={isDark}/>
      <StyledIOSAlertButton>{laterButtonText}</StyledIOSAlertButton>
      <AlertLivePreviewSpeartor isDark={isDark}/>
      <StyledIOSAlertButton isBold={true}>{declineButtonText}</StyledIOSAlertButton>
    </StyledIOSAlertPreviewBodyWrapper>
  );
};

export default IOSAlertPreview;