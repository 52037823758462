import React from 'react';
import { ModalsService } from '@mdigital/components/dist/utils/modals';
import { getSingleText } from '../../../common/utils';


const EditPublishedDialog = (props) => {
  const prefix = 'app.pages.forms.editPublishedDialog';

  return props.render({
    height: 244,
    size: 'small',
    title: getSingleText(`${prefix}.title.${props.dialogType}`),
    description: (
      <React.Fragment>
        <span dangerouslySetInnerHTML={
          { __html: getSingleText(
            `${prefix}.${props.isLocked ? 'publishedAndLocked' : 'published'}`
          ) }
        }></span>
        <span dangerouslySetInnerHTML={
          { __html: getSingleText(`${prefix}.description`) }
        }></span>
      </React.Fragment>
    ),
    close: {
      title: getSingleText(`${prefix}.cancel`),
      hideButton: true,
      hideCloseIcon: true,
      disableEscapeKey: false,
      disableOutsideClick: false,
    },
    additionalActions: [{
      title: getSingleText(`${prefix}.cancel`),
      shouldAutoClose: true,
      priority: 'tertiary',
      callback: () => {},
    },
    {
      title: getSingleText(`${prefix}.viewPublished`),
      shouldAutoClose: true,
      priority: 'secondary',
      callback: props.viewPublishedCallback,
    }, {
      title: getSingleText(`${prefix}.${props.isWithRelatedDraft ? 'editDraft' : 'createDraft'}`),
      shouldAutoClose: true,
      priority: 'primary',
      callback: props.editDraftCallback,
    }],
  });
};

export const openEditPublishedFormDialog = (
  dialogType, viewPublishedCallback, editDraftCallback, isWithRelatedDraft, isLocked) => {
  ModalsService.showCustomModal(EditPublishedDialog, {
    dialogType,
    viewPublishedCallback,
    editDraftCallback,
    isWithRelatedDraft,
    isLocked,
  });
};