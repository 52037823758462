import styled from 'styled-components';

export const LabelOptionStyled = styled.span`
  display:flex; 
  position:relative;
  input[type=text]:disabled {
  background:${({ disabled }) =>  `${disabled ? '#F5F5F5' : '#FFFFFF'} 0% 0% no-repeat padding-box !important`};
  opacity:${({ disabled }) =>  disabled ? 0.66 : 1};
}
`;


export const TooltipStyled = styled.div`
  background: #000 0% 0% no-repeat padding-box;
  border: 1px solid #00000000;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: rgba(255,255,255,1);
  padding: 9px 15px 15px 13px;
  border-radius: 7px;
  width: 165px;
  font-size: 12px;
`;

export const TooltipTriangleStyled = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #1E1E1E;
  position: absolute;
  left: 72px;
  bottom: -5px;
  opacity: 0.85;
`;

export const TooltipWrapperStyled = styled.div`
  position: absolute;
  z-index: 1;
  top: -70px;
  left: 115px;
  @keyframes fadeInUp {
    from {
        transform: translate3d(0,20px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
  }

  @keyframes fadeOutDown {
   0% {
      opacity: 1;
      transform: translateY(0);
   }
   100% {
      opacity: 0;
      transform: translateY(20px);
   } 
}

  @-webkit-keyframes fadeInUp {
      from {
          transform: translate3d(0,40px,0)
      }

      to {
          transform: translate3d(0,0,0);
          opacity: 1
      }
  }

  .animated {
      animation-duration: 1s;
      animation-fill-mode: both;
      -webkit-animation-duration: 1s;
      -webkit-animation-fill-mode: both
  }

  .animatedFadeInUp {
      opacity: 0
  }

  .fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }
`;