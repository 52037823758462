const mobileIOS = [
  {id: 'Default system font', label: '-apple-system, BlinkMacSystemFont', isDefaultIOSFont: true},
  {id: 'Al Nile', label: 'Al Nile'},
  {id: 'American Typewriter', label: 'American Typewriter'},
  {id: 'Arial Hebrew', label: 'Arial Hebrew'},
  {id: 'Baskerville', label: 'Baskerville'},
  {id: 'Cochin', label: 'Cochin'},
  {id: 'Copperplate', label: 'Copperplate'},
  {id: 'Courier', label: 'Courier'},
  {id: 'Devanagari Sangam MN', label: 'Devanagari Sangam MN'},
  {id: 'Didot', label: 'Didot'},
  {id: 'Euphemia UCAS', label: 'Euphemia UCAS'},
  {id: 'Geeza Pro', label: 'Geeza Pro'},
  {id: 'Georgia', label: 'Georgia'},
  {id: 'Gill Sans', label: 'Gill Sans'},
  {id: 'Gujarati Sangam MN', label: 'Gujarati Sangam MN'},
  {id: 'Gurmukhi MN', label: 'Gurmukhi MN'},
  {id: 'Helvetica', label: 'Helvetica'},
  {id: 'Helvetica Neue', label: 'Helvetica Neue'},
  {id: 'Kailasa', label: 'Kailasa'},
  {id: 'Kannada Sangam MN', label: 'Kannada Sangam MN'},
  {id: 'Malayalam Sangam MN', label: 'Malayalam Sangam MN'},
  {id: 'Myanmar Sangam MN', label: 'Myanmar Sangam MN'},
  {id: 'Oriya Sangam MN', label: 'Oriya Sangam MN'},
  {id: 'Sinhala Sangam MN', label: 'Sinhala Sangam MN'},
  {id: 'Snell Roundhand', label: 'Snell Roundhand'},
  {id: 'Tamil Sangam MN', label: 'Tamil Sangam MN'},
  {id: 'Thonburi', label: 'Thonburi'},
  {id: 'Trebuchet MS', label: 'Trebuchet MS'},
  {id: 'Verdana', label: 'Verdana'},
]

export default {
  mobileIOS,
  anywhere: mobileIOS,
  mobileAndroid: [
    {id: 'ComingSoon', label: 'casual'},
    {id: 'DancingScript-Regular', label: 'cursive'},
    {id: 'DroidSansMono', label: 'monospace'},
    {id: 'Roboto-Regular', label: 'sans-serif', isDefault: true},
    {id: 'Roboto-Black', label: 'sans-serif-black'},
    {id: 'RobotoCondensed-Regular', label: 'sans-serif-condensed'},
    {id: 'RobotoCondensed-Light', label: 'sans-serif-condensed-light'},
    {id: 'Roboto-Light', label: 'sans-serif-light'},
    {id: 'Roboto-Medium', label: 'sans-serif-medium'},
    {id: 'CarroisGothicSC-Regular', label: 'sans-serif-smallcaps'},
    {id: 'Roboto-Thin', label: 'sans-serif-thin'},
    {id: 'NotoSerif-Regular', label: 'serif'},
    {id: 'CutiveMono', label: 'serif-monospace'},
  ],
}