import styled from 'styled-components';
import { COLORS } from '../../../common/colors';

export const PowerdByContainer = styled.div`
    font-family: "Open Sans";
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PowerdByText = styled.label`
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 4px;
    color: ${(props) => props.isDarkEnabled ? COLORS.WHITE : COLORS.TEXT_HOVER };
`;
