import styled from 'styled-components';

export const StyledLogin = styled.div`
    background-color: #4050C6;
    height: 100%;
    overflow: auto;
    margin-top: 7%;
    font-family: Open Sans;

    .md-login-diamond-top {
      position: absolute;
      top: 60px;
      left: 80px;
    }
    
    .md-login-diamond-bottom {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    
    form {
      margin: 0 auto;
      padding-bottom: 28px;
      position: relative;
      width: 340px;
      z-index:1;
      overflow-x: hidden;
        
      .md-login-form-field {
        box-sizing: border-box;
        padding-left: 10px;
        border: none;
        background-color: white;
        outline: none;
        width: 100%;
        font-size: 16px;
        height: 48px;
        border-radius: 6px;
        margin-top: 28px;
        
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #908F8F;
        }
        
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #908F8F;
        }
        
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: #908F8F;
        }
      }
  
      .md-login-btn {
        color: #000000;
        background-color: #4cd2c0;
        border-radius: 6px;
        cursor: pointer;
        font-weight: 400;
        margin-top: 28px;
        margin-bottom: 28px;
        height: 48px;
        border: none;
        font-size: 16px;
        padding: 15px 18px 15px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        
        &:focus {
          outline: -webkit-focus-ring-color auto 5px !important; /* to override styles css*/
        }
        
        .md-login-spinner {
          margin-right: 15px;
          width: 24px;
          margin-top: -2px;
        }
      }
      
      .md-login-step-btn {
        background: transparent;
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
      
      .md-login-step {
        padding-right: 0px;
        float: right;
      }
      
      .md-forgot-step {
        padding-left: 0px;
        float: left;
        
        .md-back-arrow {
          padding-right: 5px;
        }
      }
      
      .md-sub-title {
        margin-top: 27px;
        margin-bottom: 13px;
      }
      
      .md-forgot-confirmation {
        margin-bottom: 40px;
      }
    }
    
    .md-sans-semibold {
      font-family: Open Sans;
      color: white;
      font-weight: 600;
    }
    
    .md-login-error {
      position: absolute;
      text-align: center;
      font-size: 14px;
      background-color: #F57585;
      line-height: 34px;
      width: 100%;
      top: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
    
`;