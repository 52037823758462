import React from 'react';
import Select from '@mdigital/components/dist/components/Select';
import { StyledLabelsRow, StyledTitleSpan, selectStyle } from './StyledQuestionScoreSelection';
import QuestionMarkTooltip from '../../common/QuestionMarkTooltip';
import SCORES from './scores';
import { COLORS } from '../../../common/colors';

const InfoTooltip = ({ text }) => <QuestionMarkTooltip text={text} style={{ color: COLORS.TOOLTIP }} />;
const getScoreObj = (scoreValue) => SCORES.find(({ value }) => scoreValue === value);
const TOOLTIP_TEXT = 'Set the question score. Use this score for advanced anywhere use cases such as the questions randomized display order, etc';

const QuestionScoreSelection = ({ selectedScore, onSelect }) => (
  <React.Fragment>
    <StyledLabelsRow>
      <StyledTitleSpan>
        Question Score <InfoTooltip text={TOOLTIP_TEXT} />
      </StyledTitleSpan>
    </StyledLabelsRow>
    <Select
      selected={getScoreObj(selectedScore)}
      styles={selectStyle}
      maxMenuHeight={120}
      options={SCORES}
      onSelect={onSelect} />
  </React.Fragment>
);

export default QuestionScoreSelection;