import styled from 'styled-components';

export const StyledThankYouPromptLivePreviewBody = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${(props) => props.promptContentFontType};

    .thank-you-prompt-body-button{
        min-width: ${(props) => props.buttonWidth};
        height: ${(props) => props.buttonHeight};
        font-size : 16px;
        font-weight: 500;
        background-color: ${(props) => props.buttonColor};
        font-family: ${(props) => props.promptContentFontType};
        color: ${(props) => props.buttonTextColor};
        margin-top: 20px;
        border-radius: 5px;
        border : none;
    }
    .thank-you-prompt-body-button:active{
        background-color: ${(props) => props.buttonColor};
        color: ${(props) => props.buttonTextColor};
    }
`; 

export const StyledThankYouPromptLivePreviewHeaderContent = styled.span`
    max-width: 302px;
    font-size: 17px;
    font-weight: 500;
    color: ${(props) => props.promptContentFontColor};
    margin-top: 20px;
    line-break: anywhere;
`;   

export const StyledThankYouPromptLivePreviewBodyContent = styled.span`
    max-width: 271px;
    font-size: 15px;
    font-weight: 400;
    color: ${(props) => props.promptContentFontColor};
    margin-top: 5px;
    line-break: anywhere;
`;  

export const StyledThankYouPromptLivePreviewBodyImage = styled.img`
    margin-top: 10px;
    width : 137px;
    height: 88px;
    object-fit: contain;
`; 