export default {
  TOP:{
    Drawer: {
      BodyWrapper:{
        right: '143px',
        top: '66px',
        width: '389px',
        height: '110px',
        borderRadius : '0px',
      },
      previewTitle:{
        left: '53px',
        top: '33px',
      },
      previewBody:{
        top: '35px',
        left: '45px',
      },
      iconClose :{
        top: '40px',
        right: '25px',
      },
      buttonWrapper:{
        height : '50px',
        bottom : '0px',
      },
    },
    ParitalDrawer :{
      BodyWrapper:{
        right: '161px',
        top: '114px',
        width: '347px',
        height: '84px',
        borderRadius : '0px',
      },
      previewTitle:{
        left: '29px',
        top: '9px',
      },
      previewBody:{
        top: '11px',
        left: '23px',
      },
      iconClose :{
        top: '14px',
        right: '13px',
      },
      buttonWrapper:{
        height : '50px',
        bottom : '0px',
      },
    }, 
  },
  BOTTOM:{
    Drawer: {
      BodyWrapper:{
        right: '141px',
        bottom: '72px',
        width: '389px',
        height: '87px',
        borderRadius : '0px',
      },
      previewTitle:{
        left: '53px',
        top: '8px',
      },
      previewBody:{
        top: '10px',
        left: '45px',
      },
      iconClose :{
        top: '14px',
        right: '23px',
      },
      buttonWrapper:{
        height : '50px',
        bottom : '0px',
      },
    },
    ParitalDrawer :{
      BodyWrapper:{
        right: '162px',
        bottom: '90px',
        width: '348px',
        height: '84px',
        borderRadius : '0px',
      },
      previewTitle:{
        left: '29px',
        top: '8px',
      },
      previewBody:{
        top: '10px',
        left: '23px',
      },
      iconClose :{
        top: '14px',
        right: '12px',
      },
      buttonWrapper:{
        height : '50px',
        bottom : '0px',
      },
    }, 
  },
};