import React from 'react';

const DarkIcon = ({ isActive }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="Dark_mode" transform="translate(-3559 -1167)" clipPath="url(#clip-path)">
        <path id="Path_87" data-name="Path 87" d="M10,3a7,7,0,1,0,7,7,7.873,7.873,0,0,0-.078-1.058,4.2,4.2,0,1,1-5.864-5.864A7.873,7.873,0,0,0,10,3Z" 
          transform="translate(3559 1167)" 
          fill={`${isActive ? '#fff' : '#a8aaaa'}`}/>
      </g>
    </svg>
  );
};

export default DarkIcon;