import styled from 'styled-components';

export const LabelWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;

  .required-text {
    position: absolute;
    top: -4px;
    right: -12px;
    font-size: 16px;
    color: #f00;
  }
`;

export const RadioButtonWrapper = styled.div`
  margin-top: ${({ index }) => index === 0 ? '32px' : '16px'};
  margin-bottom: ${({ index }) => index === 0 ? '0' : '5px'};
`;
