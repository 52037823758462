import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { isPositiveNumber } from '../../../../common/utils/fieldValidators';
import Checkbox from '@mdigital/components/dist/components/Checkbox';
import StyledDuration from '../../../MobileEngagementDesign/Banner/SettingsExpandableList/Duration/StyledDuration';

const ThankYouPromptDuration = ({ disabled }) => {
  const handleDurationChanged = (event, input) => {
    const { value } = event.currentTarget;
    const minVal = value > 0 ? value : 1;
    input.onChange(minVal);
  };

  return(
    <StyledDuration>
      <div className="duration-input">
        <FormSpy subscription={{ values: true }} render={({ values }) => (
          <Field validate={isPositiveNumber} name="trigger.promptDuration" render={({ input }) => (
            <input min="1" type="number" {...input} onChange={(event) => {
              handleDurationChanged(event, input);
            }} disabled={values.trigger.isStickyPrompt} />
          )} />
        )} />
        <label>Sec</label>
      </div>
      <div className="is-sticky-cb">
        <span>
          <p>Or</p>
        </span>
        <span>
          <Field name="trigger.isStickyPrompt" render={({ input }) => (
            <Checkbox label="Sticky" isDisabled={disabled} digIsChecked={input.value} handleCheckboxChange={input.onChange} />
          )} />
        </span>
      </div>
    </StyledDuration>
  );
};

export default ThankYouPromptDuration;
