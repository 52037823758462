import { Select } from '@m/alchemy-ui';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import React, { useContext } from 'react';
import { TextPathPrefix, TriggerByTargetingAppMode } from '../../..';
import { TriggerContext } from '../../../../..';
import { getSingleText } from '../../../../../../../../common/utils';
import { SubHeader, Subtitle, TextLink } from '../../../../../StyledTriggeringModal';
import TargetingField from '../../../../Shared/TargetingField';
import { TargetingActions } from '../../../Actions';
import { StyledInviteTypeText, StyledTargetingSettingsContainer } from '../../../StyledTriggerByTargeting';
import TriggerTypes , { TARGETING_TYPES } from '../../../TargetingTypes';
import { checkProvision } from '../../../../../../../../redux/store/store-helper';
import { Links } from '../../../../../enumns/links.enumn';

const POSITION_OPTIONS = [
  { label: 'Banner', value: TARGETING_TYPES.BANNER },
  { label: 'Native alert', value: TARGETING_TYPES.ALERT },
  { label: 'Custom invite', value: TARGETING_TYPES.CUSTOM_INTERCEPT },
  { label: 'Push notification', value: TARGETING_TYPES.PUSH_NOTIFICATION }
];
const localNotifications =  { label: 'Local notification', value: TARGETING_TYPES.LOCAL_NOTIFICATION };

export const TargetingFieldBasicSettings = () =>{
  const { triggerState , triggerDispatch, setHasErrors, isReadOnly } = useContext(TriggerContext);
  const isLocalNotificationsEnabled = checkProvision(DigProvisions.MOBILE_SDKV2_LOCAL_Notifications);

  const getInviteTypeOptions = () => {
    const triggerScope = getNestedProperty(triggerState, 'trigger.triggerScope');
    if(isLocalNotificationsEnabled){
      if(triggerScope === TriggerByTargetingAppMode.FOREGROUND && !POSITION_OPTIONS.includes(localNotifications)){
        POSITION_OPTIONS.push(localNotifications);
      }else if(triggerScope === TriggerByTargetingAppMode.BACKGROUND){
        return [localNotifications];
      }
    }
    return POSITION_OPTIONS;
  };

  const onChangeInviteType = (option) => {
    setHasErrors({}); //initialize the validation object that contain errors
    triggerDispatch({ type: TargetingActions.SET_INVITATION_TYPE , payload : option.value });
  };

  return (
    triggerState && triggerState.trigger ?
      <StyledTargetingSettingsContainer>
        <SubHeader>{getSingleText(`${TextPathPrefix}.inviteType`)}</SubHeader>
        <TargetingField 
          className="custom-field-margin"
          render = {
            <React.Fragment>
              <Select
                isFullWidth
                value={getNestedProperty(triggerState, 'trigger.invitationType')}
                options={getInviteTypeOptions()}
                disabled={isReadOnly}
                onChange={ (option) => onChangeInviteType(option) }/>
              <StyledInviteTypeText>
                <Subtitle>{getSingleText(`${TextPathPrefix}.inviteSelectionTypeDescription.${triggerState.trigger.invitationType}`)}
                  <TextLink target="_blank" href={Links.INVITE_TYPES[triggerState.trigger.invitationType]}>{getSingleText(`${TextPathPrefix}.learnMore`)}</TextLink>
                </Subtitle>
              </StyledInviteTypeText>
            </React.Fragment>
          }
        />
        <TriggerTypes/>
      </StyledTargetingSettingsContainer> 
      : null
  );
};

export default TargetingFieldBasicSettings;