import { color, Dialog, typeStyle, units } from '@m/alchemy-ui';
import styled from 'styled-components';
import { Z_INDEX_MAP } from '../../../common/enums';

export const CustomDialog = styled(Dialog)`
  z-index: ${Z_INDEX_MAP.MODAL};
`;

export const StyledCustomDialogHeader = styled.div`
  ${typeStyle('headerS')};

  h2 {
    padding-left: ${units(3)};
  }
`;
export const StyledCustomDialogBody = styled.div`
  max-height: 74vh;
`;

export const StyledCustomDialogFooter = styled.div`
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.12);
  padding: ${units(3)} ${units(3)} 0px ${units(3)};

  .md-custom-footer-buttons {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-flow: row-reverse nowrap;
  }
  .md-custom-footer-alert {
    margin-left: 12px;
    flex-grow: 1;
  }
`;

export const Header = styled.h3`
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX_MAP.FIRST_LAYER};
  background: ${color('inverse')};
  ${typeStyle('headerM')};
  padding: ${units(2)} ${units(3)} ${units(1.5)} ${units(3)};
  border-bottom: 1px solid ${color('line')};
`;

export const SubHeader = styled.div`
  ${typeStyle('subHeaderM')};
  margin-bottom: ${({ marginBottom }) => marginBottom ? units(marginBottom) : units(1)};
`;

export const PanelSubHeader = styled.label`
  ${typeStyle('labelL')};
  color: ${color('typeBody')};
  margin-bottom: ${units(1)};
`;

export const PanelTooltip = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin: 0 ${units(0.5)} ${units(0.75)} ${units(0.5)};
    ${typeStyle('labelL')};
  }
`;

export const PanelSubTooltip = styled.div`
  display: flex;
  align-items: center;
    & svg {
      margin: ${units(1)} 0 ${units(0.75)} ${units(0.5)};
      ${typeStyle('labelM')};
    }
`;

export const PanelLabel = styled.label`
  ${typeStyle('labelM')};

  svg {
    margin: 0 ${units(0.5)} ${units(0.5)} 0;
  }
`;

export const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${units(3)};
`;
export const PanelColumn = styled.div`
  max-height: calc(95vh - 320px);
  display: flex;
  flex-direction: row;
`;
export const PanelRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const PanelRowContent = styled(PanelRow)`
  margin-right: ${units(3)};
  max-width: 500px;
  overflow: auto;
`;
export const PanelRowPreview = styled(PanelRow)`
  overflow: auto;
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX_MAP.SECOND_LAYER};
  align-items: center;
  margin-right: ${units(1)};
  justify-content: center;
`;
export const Title = styled.h4`
  ${typeStyle('headerS')};
  padding-bottom: ${units(1)};
  padding-top: ${({ addMargin }) => addMargin ? units(1.5) : units(0.5)};
`;

export const Subtitle = styled.div`
  ${typeStyle('bodyS')};
  color: ${({ fontColor }) => fontColor ? color(fontColor) : color('typeMeta')};
  margin-top: ${({ marginTop }) => marginTop ? units(marginTop) : ''};
  margin-bottom: ${({ marginBottom }) => marginBottom ? units(marginBottom) : ''};
  margin-right: ${({ marginRight }) => marginRight ? units(marginRight) : ''};
  margin-left: ${({ marginLeft }) => marginLeft ? units(marginLeft) : ''};
`;

export const TextLink = styled.a`
  ${typeStyle('bodyS')};
  color: ${color('typeLink')};
`;
