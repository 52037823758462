import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldContent, Checkbox, Tooltip, HelpIcon, FieldStatus } from '@m/alchemy-ui';
import { StyledTargetingBlockRow, StyledTargetingCheckbox, StyledTargetingInputField } from './StyledTargetingBlockRow';

const TargetingBlockRow = ({ checkboxLabel, checked, onCheckboxChange, isFullWidth, tooltipText, children, isQuarantineRule, disabled, errorMessage, hasError }) => (
  <StyledTargetingBlockRow>
    <StyledTargetingCheckbox isQuarantineRule={isQuarantineRule}>
      <Checkbox
        label={checkboxLabel}
        checked={checked || false}
        onChange={onCheckboxChange}
        disabled={disabled}
      />
      {tooltipText && <Tooltip
        trigger={<HelpIcon size="12" palette="structural" />}
        contents={tooltipText}
      />}
    </StyledTargetingCheckbox>
    <StyledTargetingInputField disabled={!checked || disabled}>
      <Field
        isFullWidth={isFullWidth}
        layoutVariant="inline">
        <FieldContent className='targeting-field-content'>
          {children}
        </FieldContent>
        {hasError && <div className="targeting-field-error-message"><FieldStatus level="error" message={errorMessage} /> </div> }
      </Field>
    </StyledTargetingInputField>
  </StyledTargetingBlockRow>
);

TargetingBlockRow.propTypes = {
  checkboxLabel: PropTypes.string,
  checked: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  isFullWidth: PropTypes.bool,
  tooltipText: PropTypes.string,
};

TargetingBlockRow.defaultProps = {
  checkboxLabel: '',
  checked: false,
  isFullWidth: false,
  tooltipText: '',
};

export default TargetingBlockRow;
