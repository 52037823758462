import React, { Component } from 'react';
import DigTable from '@mdigital/components/dist/components/Table';
import './digLangListStyle.css';
import { getSingleText } from '../../common/utils';
import { bind } from '@mdigital/components/dist/decorators';

export function sortDefaultLanguageFirst(a = { id : '' }, b = { id: '' }) {

  const languageName_a = typeof a.id === 'string' ? a.id.toLowerCase() : '';
  const languageName_b = typeof b.id === 'string' ? b.id.toLowerCase() : '';

  // if one of the languages is the default language then place it first
  if (a.isLocked) {
    return -1;
  } else if(b.isLocked) {
    return 1;
  }

  // else order by alphabet
  if (languageName_a < languageName_b) {
    return -1;
  } else if (languageName_b < languageName_a) {
    return 1;
  }

  return 0;
}

export default class DigLangsList extends Component{

    @bind
  getRows(){
    return this.props.languages.map((language) => ({
      id: language.id,
      isLocked: language.label === this.props.selectedLanguage,
      lockTooltip: getSingleText('app.pages.forms.localization.trashLockTooltip'),
      values: [{ value: language.id }, { value: language.label }],
      rowObject: language,
      actions: this.props.isReadOnly ? [] : [
        {
          icon: 'trash',
          isShowOnLock: false,
          callback: this.props.onDelete,
          autLabel: 'dig-row-delete',
        }
      ],
    }));
  }

    @bind
    getColumns(){
      return [
        { label: getSingleText('app.pages.forms.localization.languageColumnTitle') },
        { label: getSingleText('app.pages.forms.localization.localColumnTitle') }
      ];
    }

    render(){
      const rows = this.getRows().sort(sortDefaultLanguageFirst);
      const columns = this.getColumns();
      return(
        <div className="dig-list-wrapper">
          <DigTable digRows={rows} digColumns={columns}/>
        </div>
      );
    }
}
