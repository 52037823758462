import * as React from 'react';
import { Toast } from '@m/alchemy-ui';
import styled from 'styled-components';

const ToasterWrapper = styled.div`
  position: relative;
  [data-testid="ToastMessageContent"] span {
    margin-right: 6px;
  }
  [data-testid="ToastMessageContent"]:focus {
    outline: none;
  }
`;

const createMessageElement = (content, link) => (
  <div>
    <span>{content}</span>
    {link && <a href={link}>Download error log</a>}
  </div>
);

const AlchemyToast = (props) => {
  const [alerts, setAlerts] = React.useState([]);

  React.useEffect(() => {
    setAlerts(props.messages);
  }, [props.messages]);
  return (
    <React.Fragment>
      {!alerts.length ? null :
        <ToasterWrapper>
          <Toast messages={alerts.map((m) => ({ ...m, content: createMessageElement(m.content, m.link) }))} />
        </ToasterWrapper>
      }
    </React.Fragment>);
};
export default AlchemyToast;