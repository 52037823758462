import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { isPositiveNumber } from '../../../../../common/utils/fieldValidators';
import Checkbox from '@mdigital/components/dist/components/Checkbox';
import StyledDuration from './StyledDuration';

const Duration = () => {
  const handleDurationChanged = (event, input) => {
    const { value } = event.target;
    const minVal = value > 0 ? value : 1;
    input.onChange(minVal);
  };
  return(
    <StyledDuration>
      <div className="duration-input">
        <FormSpy subscription={{ values: true }} render={({ values }) => (
          <Field validate={isPositiveNumber} name="trigger.triggerCustomBannerContract.invitationTimeout" render={({ input }) => (
            <input min="1" type="number" {...input}
              onChange={(event) => handleDurationChanged(event, input)}
									 disabled={values.trigger.triggerCustomBannerContract.isSticky} />
          )} />
        )} />
        <label>Sec</label>
      </div>
      <div className="is-sticky-cb">
        <span>
          <p>Or</p>
        </span>
        <span>
          <Field name="trigger.triggerCustomBannerContract.isSticky" render={({ input }) => (
            <Checkbox label="Sticky" digIsChecked={input.value} handleCheckboxChange={input.onChange} />
          )} />
        </span>
      </div>
    </StyledDuration>
  );
}
;

export default Duration;
