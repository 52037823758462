import styled from 'styled-components';
import COLORS from '../../common/colors';
export const StyledDescriptionText = styled.div`
	flex: 1;
	padding-top: 25px;
`;

export const StyledIllustrationImageContainer = styled.div`flex: 1;`;

export const StyledHelpLinkText = styled.div`
	flex: 1;
	padding-top: 15px;
`;

export const StyledDescriptionContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
export const StyledCheckBox = styled.div`
	padding-top: 1vh;
	color: ${COLORS.PRIMARY_TEXT};
`;

export const PreloadedStyled = styled.div`
	padding-top: 6vh;
	display: flex;
	flex-direction: column;
`;
export const NoticePreloadedStyled = styled.div`
	width: 68%;
	flex-flow: column wrap;
`;

export const StyledPreloadUnavailableMessage = styled.div`
  width: 360px;
  padding-top: 12px;
`;

export default styled.div`
	display: flex;
	color: ${COLORS.TEXT_SUBTITLE};
	padding-top: 3vh;
`;
