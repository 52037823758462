import React from 'react';
import { ModalsService } from '@mdigital/components/dist/utils/modals';
import { getSingleText } from '../../../common/utils';
import PropTypes from 'prop-types';
import { StyledFormPublishApproverDialogBody } from './StyledFormPublishApproverDialogBody';


const FormPublishApproverDialog = (props) => {
  const texts = 'app';
  
  return props.render({
    size: 'small',
    title: props.title,
    CustomBody: StyledFormPublishApproverDialogBody,
    body: (
      <React.Fragment>
        {
          props.description && <p dangerouslySetInnerHTML={
            { __html: props.description }
          }></p>
        }
        {
          <a onClick={props.exportCallback}>
            <img src="assets/images/DownloadIcon.svg" alt="download"/><b>{props.downloadLinkText}</b>
          </a> 
        }
      </React.Fragment>
    ),
    close: {
      title: getSingleText(`${texts}.cancel`),
      priority: 'tertiary',
    },
    submit: {
      title: props.confirmText,
      callback: () => props.confirmCallback(),
      priority: 'success',
    },
  });
};

export const openFormPublishApproverDialog = ({ confirmCallback, title, description, confirmText, exportCallback, downloadLinkText }) => {
  ModalsService.showCustomModal(FormPublishApproverDialog, {
    title,
    description,
    confirmText,
    confirmCallback,
    exportCallback,
    downloadLinkText,
  });
};

FormPublishApproverDialog.propTypes = {
  title: PropTypes.string,
  confirmCallback: PropTypes.func,
  errorCallback: PropTypes.func,
  downloadLinkText: PropTypes.string,
};