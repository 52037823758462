import React from 'react';
import { Field } from 'react-final-form';
import StyledDestination from './StyledDestination';
import { isRequired } from '../../../../../common/utils/fieldValidators';
import { PROPERTY_TYPES } from '../../../../../../common/constants';
import { ModalGlobalDataConsumer } from '../../../index';
import { DestinationPageBackground } from '../../../../../assets/AppRating/base64';

const VALUE_TYPES = {
  APP_STORE_BASE_URL: 'APP_STORE_BASE_URL',
  APP_STORE_NAME: 'APP_STORE_NAME',
  APP_ID_EXAMPLE: 'APP_ID_EXAMPLE',
};

const VALUES_MAP = {
  [VALUE_TYPES.APP_STORE_NAME]: {
    [PROPERTY_TYPES.ANDROID]: 'Google Play',
    [PROPERTY_TYPES.IOS]: 'App Store',
  },
  [VALUE_TYPES.APP_STORE_BASE_URL]: {
    [PROPERTY_TYPES.ANDROID]: 'https://play.google.com/store/apps/details?id=',
    [PROPERTY_TYPES.IOS]: 'https://apps.apple.com/us/app/medallia-for-digital/',
  },
  [VALUE_TYPES.APP_ID_EXAMPLE]: {
    [PROPERTY_TYPES.ANDROID]: 'com.medalliadigital.app',
    [PROPERTY_TYPES.IOS]: 'id1295958601',
  },
};

function getValueByPropertyType(valueType, propertyType) {
  return VALUES_MAP[valueType][propertyType];
}

function getAppStoreName(propertyType) {
  return getValueByPropertyType(VALUE_TYPES.APP_STORE_NAME, propertyType);
}

function getExampleID(propertyType) {
  return getValueByPropertyType(VALUE_TYPES.APP_ID_EXAMPLE, propertyType);
}

function getAppStoreBaseURL(propertyType) {
  return getValueByPropertyType(VALUE_TYPES.APP_STORE_BASE_URL, propertyType);
}

const Destination = () => (
  <ModalGlobalDataConsumer>{({ propertyType }) => (
    <StyledDestination>
      <div className="fields-container">
        <p className="page-title">Insert your App ID.</p>
        <p>The prompt will redirect the in-app user to rate your app in the {getAppStoreName(propertyType)}.</p>
        <br />
        <br />
        <div className="app-id-input-container">
          <label htmlFor="appRatingUrlInput">App ID</label>
          <Field name="appRatingUrl" validate={isRequired} render={({ input, meta }) => (
            <span className={meta.error && meta.touched && 'has-error'}>
              <input {...input} id="appRatingUrlInput" placeholder={`E.g., ${getExampleID(propertyType)}`} />
              <span className="error-message">App ID is Required</span>
            </span>
          )} />
        </div>
        <div>
          <br />
          <br />
          <p>
            To determine your App ID open your app page in {getAppStoreName(propertyType)}<br />from a web browser and extract the ID from URL
          </p>
          <br />
          <p>Example: {getAppStoreBaseURL(propertyType)}<span style={{ color: 'black' }}>{getExampleID(propertyType)}</span></p>
        </div>
      </div>
      <img src={DestinationPageBackground} className="destination-image" />
    </StyledDestination>
  )}</ModalGlobalDataConsumer>
);

export default Destination;
