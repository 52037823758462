import styled from 'styled-components';
import { units, color } from '@m/alchemy-ui';

export const StyledTargetingSettings = styled.div`
  width: 100%;
`;

export const StyledTargetingSettingsBody = styled.div`
  height 68vh;
  padding: ${units(3)};
  overflow-x: auto;
`;

export const Separator = styled.div`
     border-top: 1px solid ${color('line')};
     margin: ${units(3)} 0 ${units(3)} 0;
`;