import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';

export const StyledSdkIntegrationV2Footer = styled.div `
    display: flex;
    justify-content: space-between;
    height: 64px;
    max-height: 64px;
    padding: 0px 22px 15px 22px;
    align-items: center;
    margin-top: 10px;
}

.sdk-integration-v2-help{
    display: flex;
    justify-content: space-between;
    width: 50px;
    cursor : pointer;
    color: ${COLORS.DARK_BLUE};

    &:hover{
        color: ${COLORS.ON_HOVER_PRIMARY_BUTTON};
    }

    .sdk-integration-v2-footer-modal-icon{
        font-size: 17px;
        margin-top: 2px;
    }

    .sdk-integration-v2-footer-help{
        font-weight: 400;
    }

}
`; 
export const StyledSdkIntegrationV2FooterButtons = styled.div `
    display : flex;

    .sdk-integration-v2-footer-copy-button{
        font-weight: 400;
        min-width: 69px;
    }
`;

    
