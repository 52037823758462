import { getLocalStorageValue, setLocalStorageValue } from '../../../../../common/utils';

const TRIGGER_CARD_FROM_HISTORY = 'triggerCardFormHistory';

export const UseCardFormHistory = () => {
  const triggerMap = JSON.parse(getLocalStorageValue(TRIGGER_CARD_FROM_HISTORY)) || {};

  const getForm = (formId) => triggerMap[formId];

  const addForm = (formID, formState) => {
    triggerMap[formID] = formState;
    saveForm(triggerMap);
  };

  const saveForm = (triggerMap) => {
    setLocalStorageValue(TRIGGER_CARD_FROM_HISTORY, JSON.stringify(triggerMap));
  };

  const removeForm = (formId) => {
    delete triggerMap[formId];
    saveForm(triggerMap);
  };

  return {
    getForm,
    addForm,
    removeForm,
  };
};
