import styled from 'styled-components';
import { typeStyle, color, units } from '@m/alchemy-ui';

export const StyledBannerDarkMode = styled.div`
  margin-top: ${units(3)};
  max-width: 370px;
  & button {
    ${typeStyle('subHeaderM')}
    color: ${color('typeHeader')};
    text-decoration: none;
    padding-bottom: 0;
  }

  & svg {
    color: ${color('base')};
  }
`;