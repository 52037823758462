import styled from 'styled-components';
import { COLORS } from '../../../common/colors';

const TABLE_SPACING = `
&:nth-child(1) { // placeholder column for DHH warning sign
  i{
    display: inline-block; 
  }
  svg {
    display: block;
    margin: auto;
  }
  text-align: center;
  flex: 3%;
}
&:nth-child(2) { // File Name column
  [data-tooltipped] {
    display: unset !important; // use important to override inline style of tooltip component
  }
  flex: 37%;
}
&:nth-child(3) { // Type column
  flex: 10%;
}
&:nth-child(4) { // Last Modified column
  flex: 15%;
}
&:nth-child(5) { // Size column
  flex: 10%;
}
&:nth-child(6) { // Used In column
  flex: 10%;
}
&:nth-child(7) { // Empty column, placeholder for actions
  flex: 15%;
}
`;

export default styled.div`
padding: 15px 30px 30px 7px;
height: calc(100vh - 116px);
overflow-y: auto;
.dig-columns-container > * {
  border: none;
  ${TABLE_SPACING}
}
.dig-rows-container > div {
  display: flex;
  align-items: center;
  height: 52px;
  border: 1px solid ${COLORS.TABLE.ROW.BORDER};
  margin-bottom: 5px;
  > * {
    ${TABLE_SPACING}
  }
}
`;