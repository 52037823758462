import React, { useEffect, useContext } from 'react';
import { TriggerContext } from '../../../../../../..';
import TargetingBlockRow from '../../../TargetingBlockRow';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { NumberInput, Select } from '@m/alchemy-ui';
import { TargetingActions } from '../../../../../Actions';
import {
  newTriggerModalComparisonOptions
} from '../../../../../../../../../common/dropdownOptions';
import { Subtitle } from '../../../../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../../../../common/utils';
import { TextPathPrefix } from '../../../../..';
import { isRuleExists, formatInputNumberNullValue } from '../../../../../../../helpers';

const TargetByTimeInForeground = () => {
  const { triggerState, triggerDispatch, isReadOnly } = useContext(TriggerContext);
  const defaultValue = getNestedProperty(triggerState, 'trigger.rules.MobileTimeInForeground.params.seconds');
  const [value, setValue] = React.useState(defaultValue || 30);

  const timeInForegroundComparisonOptions = newTriggerModalComparisonOptions.slice(0, -1);

  useEffect(() => {
    if (isRuleExists(triggerState, 'trigger.rules.MobileTimeInForeground.id')) {
      triggerDispatch({ type: TargetingActions.SET_TARGET_BY_TIME_IN_FOREGROUND_IS_ACTIVE , payload : true });
      triggerDispatch({ type: TargetingActions.SET_TARGET_TIME_IN_FOREGROUND , payload : value });
    } else {
      triggerDispatch({ type: TargetingActions.SET_TARGET_BY_TIME_IN_FOREGROUND_IS_ACTIVE , payload : false });
      triggerDispatch({ type: TargetingActions.SET_TARGET_BY_TIME_IN_FOREGROUND_COMPARE_STRING , payload : timeInForegroundComparisonOptions[0].value });
    }
  },[]);

  const handleCheckboxChange = (e) => {
    triggerDispatch({ type: TargetingActions.SET_TARGET_BY_TIME_IN_FOREGROUND_IS_ACTIVE , payload : e.target.checked } );
    onNumberChanged(value);
  };

  const onNumberChanged = (val) => {
    setValue(val);
    triggerDispatch({ type: TargetingActions.SET_TARGET_TIME_IN_FOREGROUND , payload : val });
  };


  return (
    <TargetingBlockRow
      checkboxLabel="Time in live session"
      checked={getNestedProperty(triggerState, 'trigger.rules.MobileTimeInForeground.isActive')}
      disabled={isReadOnly}
      onCheckboxChange={ handleCheckboxChange }>
      <React.Fragment>
        <Select
          options={timeInForegroundComparisonOptions}
          value={getNestedProperty(triggerState, 'trigger.rules.MobileTimeInForeground.params.compareString')}
          onChange={(option) => triggerDispatch({ type: TargetingActions.SET_TARGET_BY_TIME_IN_FOREGROUND_COMPARE_STRING , payload : option.value } )} />
        <NumberInput
          className='number-of-session-input'
          min="1"
          formatValue={formatInputNumberNullValue}
          value={value}
          onNumberChange={ onNumberChanged } />
        <Subtitle marginLeft={1.5}>{getSingleText(`${TextPathPrefix}.rulesTexts.duration`)}</Subtitle>
      </React.Fragment>
    </TargetingBlockRow>
  );
};

export default TargetByTimeInForeground;