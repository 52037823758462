export const TargetingActions = {
  INIT: 'init',
  INIT_THEME: 'initTheme',
  //Banner Actions
  SET_INVITATION_TYPE: 'setInvitationType',
  SET_TITLE_TEXT: 'setTitleText',
  SET_DESCRIPTION_TEXT: 'setDescriptionText',
  SET_FONT: 'setFont',
  SET_TEXT_COLOR: 'setTextColor',
  SET_BACKGROUND: 'setBackGround',
  SET_TRIGGER_SCOPE: 'setTriggerScope',
  SET_BANNER_POSITION: 'setBannerPosition',
  SET_BUTTON_DISPLAY: 'setButtonDisplay',
  SET_ACCEPT_BUTTON_TEXT: 'setAcceptButtonText',
  SET_ACCEPT_BUTTON_TEXT_COLOR: 'setAcceptButtonTextColor',
  SET_ACCEPT_BUTTON_BACKGROUND_COLOR : 'setAcceptButtonBackgroundColor',
  SET_CLOSE_BUTTON_COLOR : 'setCloseButtonColor',
  SET_IS_BANNER_STICKY: 'setIsBannerSticky',
  SET_BANNER_INVITATION_TIMEOUT: 'setBannerInvitationTimeout',
  SET_DARK_MODE_BACKGROUND: 'setDarkModeBackground',
  SET_DARK_MODE_TEXT: 'setDarkModeText',
  SET_DARK_MODE_BUTTON_BACKGROUND: 'setDarkModeButtonBackground',
  SET_DARK_MODE_BUTTON_TEXT: 'setDarkModeButtonText',
  SET_DARK_MODE_CLOSE_BUTTON: 'setDarkModeCloseButton',
  //Custom Invite Actions
  SET_CUSTOM_PROVIDE_FEEDBACK_BUTTON_TEXT: 'setCustomProvideButtonText',
  SET_CUSTOM_LATER_BUTTON_TEXT: 'setCustomLaterButtonText',
  SET_CUSTOM_DECLINE_BUTTON_TEXT: 'setCustomDeclineButtonText',
  //Alert Actions
  SET_PROVIDE_BUTTON_TEXT: 'setProvideButtonText',
  SET_LATER_BUTTON_TEXT: 'setLaterButtonText',
  SET_DECLINE_BUTTON_TEXT: 'setDeclineButtonText',
  //Local Notification
  SET_LARGE_ICON: 'setThumbnailLogo',
  SET_IS_EXPIRED: 'setISExpired',
  SET_EXPIRATION_UNIT: 'setExpirationUnit',
  SET_EXPIRATION_VALUE: 'setExpirationUnitValue',
  SET_APP_NAME: 'setAppName',
  SET_APP_LOGO: 'setAppIcon',
  //Rules Actions
  SET_MOBILE_FREQUENCY_IS_ACTIVE: 'setMobileFrequencyIsActive',
  SET_MOBILE_FREQUENCY_RULE: 'setMobileFrequencyRule',
  SET_MOBILE_FREQUENCY_TYPE: 'setMobileFrequencyType',
  SET_TARGET_BY_NUMBER_OF_SESSION_IS_ACTIVE: 'setTargetByNumberOfSessionIsActive',
  SET_TARGET_BY_NUMBER_OF_SESSION_COMPARE_STRING: 'setTargetByNumberOfSessionCompareString',
  SET_TARGET_BY_NUMBER_OF_SESSION: 'setTargetByNumberOfSession',
  SET_TARGET_BY_TIME_IN_FOREGROUND_IS_ACTIVE: 'setTargetByTimeInForegroundIsActive',
  SET_TARGET_BY_TIME_IN_FOREGROUND_COMPARE_STRING: 'setTargetByTimeInForegroundCompareString',
  SET_TARGET_TIME_IN_FOREGROUND: 'setTargetByTimeInForeground',
  SET_TARGET_BY_APP_VERSION_IS_ACTIVE: 'setTargetByAppVersionIsActive',
  SET_TARGET_BY_APP_VERSION: 'setTargetByAppVersion',
  INIT_TARGET_BY_APP_VERSION_PARAMS: 'initTargetByAppVersionParam',
  SET_TARGET_BY_OS_VERSION_IS_ACTIVE: 'setTargetByOSVersionIsActive',
  SET_TARGET_BY_OS_VERSION: 'setTargetByOSVersion',
  INIT_TARGET_BY_OS_VERSION_PARAMS: 'initTargetByOSVersionParam',
  SET_TARGET_BY_CUSTOM_RULE_IS_ACTIVE: 'setTargetByCustomRuleIsActive',
  INIT_TARGET_BY_CUSTOM_RULE_PARAMS: 'initTargetByCustomRuleParams',
  SET_TARGET_BY_CUSTOM_RULE_ID: 'setTargetByCustomRuleID',
  SET_TARGET_BY_CUSTOM_RULE_NAME: 'setTargetByCustomRuleName',
  INIT_MOBILE_DONT_INVITE_BY_DECLINED: 'initMobileDontInviteOnDeclined',
  SET_MOBILE_DONT_INVITE_BY_DECLINED_IS_ACTIVE: 'setMobileDontInviteOnDeclinedIsActive',
  SET_MOBILE_DONT_INVITE_BY_DECLINED_DAYS: 'setMobileDontInviteOnDeclinedDays',
  INIT_MOBILE_DONT_INVITE_BY_SUBMITTED: 'initMobileDontInviteOnSubmitted',
  SET_MOBILE_DONT_INVITE_BY_SUBMITTED_IS_ACTIVE: 'setMobileDontInviteOnSubmittedIsActive',
  SET_MOBILE_DONT_INVITE_BY_SUBMITTED_DAYS: 'setMobileDontInviteOnSubmittedDays',
  INIT_MOBILE_LAST_TIME_IN_FOREGROUND: 'initMobileLastTimeINForeground',
  SET_MOBILE_LAST_TIME_IN_FOREGROUND_IS_ACTIVE: 'setMobileLastTimeINForegroundIsActive',
  SET_MOBILE_LAST_TIME_IN_FOREGROUND_COMPARE_STRING: 'setMobileLastTimeINForegroundCompareString',
  SET_MOBILE_LAST_TIME_IN_FOREGROUND_SECONDS: 'setMobileLastTimeINForegroundSeconds',
  INIT_MOBILE_APPROXIMATE_DELAY: 'initMobileApproximateDelay',
  SET_MOBILE_APPROXIMATE_IS_ACTIVE: 'setMobileApproximateIsActive',
  SET_MOBILE_APPROXIMATE_SECONDS: 'setMobileApproximateIsSeconds',
  INIT_MOBILE_DIDNT_VISIT_YOUR_APP: 'initMobileDidntVisitYourApp',
  SET_MOBILE_DIDNT_VISIT_YOUR_APP_IS_ACTIVE: 'setMobileDidntVisitYourAppIsActive',
  SET_MOBILE_DIDNT_VISIT_YOUR_APP_SECONDS: 'setMobileDidntVisitYourAppIsSeconds',
  INIT_TARGET_BY_OCQ_RULES: 'initTargetByOCQRules',
  SET_TARGET_BY_OCQ_RULES_IS_ACTIVE: 'setTargetByOCQRuleActive',
  SET_TARGET_BY_OCQ_RULES: 'setTargetByOCQRule',

};
