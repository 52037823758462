import styled from 'styled-components';
import { units } from '@m/alchemy-ui';

export const StyledDurationHeader = styled.div`
  display: flex;
  align-items: center;
  & svg {
    margin: 0 0 ${units(0.75)} ${units(0.5)};
  }
`;

export const StyledDurationRadio = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${units(0.5)};
  & input{
    width: 70px;
    margin: 0 ${units(1)} 0 ${units(1)};
    min-width: 70px;
  }
`;