import React from 'react';
import { StyledLoginHeader } from './StyledLoginHeader';
import IconSvg from '@mdigital/components/dist/components/IconSvg';
import MedalliaLogo from '../../../assets/Login/MedalliaLogo.svg';
import { getSingleText } from '../../../../common/utils';

export function LoginHeader(){
  return (
    <StyledLoginHeader>
      <a href="https://www.medallia.com/">
        <IconSvg icon={MedalliaLogo} width={200} height={45} isStatic={true} color={'white'}/>
      </a>
      <div className="login-title md-sans-semibold">
        {getSingleText('app.pages.mdLogin.title')}
      </div>
    </StyledLoginHeader>
  );
}

