import React from 'react';
import withModal from '@mdigital/components/dist/hoc/ModalHoc';
import DigLanguageDropDown from '../DigLanguageDropdown';
import { find } from 'lodash';
import { bind } from '@mdigital/components/dist/decorators';
import { getSingleText } from '../../../common/utils';
import { connect } from 'react-redux';
import './digAddLang.css';

const basicModalProps = {
  title: getSingleText('app.pages.forms.localization.modalTitle'),
  okButtonText: getSingleText('app.pages.forms.localization.okButtonText'),
  cancelButtonText: getSingleText('app.cancel'),
};
@connect((store) => store)
@withModal(basicModalProps)
export default class DigAddLanguageModal extends React.Component {

  @bind
  getCurrentLanguage(item){
    return !!find(this.props.selectedLanguages,(lang) => lang.label === item.label);
  }

  @bind
  onLanguageChange() {
    this.props.onChange('label', null);
  }

    @bind
  onDuplicateChange(value) {
    this.props.onChange('duplicated', value === '' ? value : null);
  }

  @bind
    filterer(language){
      return !find(this.props.selectedLanguages,(lang) => lang.label === language.label);
    }

  render() {
    return (
      <div className="add-lang-wrapper">
        <label>{ getSingleText('app.pages.forms.localization.formLanguageLabel') }</label>
        <DigLanguageDropDown placeholder={getSingleText('app.pages.forms.localization.formLanguagePlaceholder')} onSelected={this.props.onLangSelect} defaultLanguage={this.props.newDefaultLanguage} filterer={this.filterer}/>
        <label className="duplicate-translation">{ getSingleText('app.pages.forms.localization.duplicateTranslationsLabel') }</label>
        <DigLanguageDropDown placeholder={getSingleText('app.pages.forms.localization.duplicateTranslationsPlaceholder')} onSelected={this.props.onDuplicateLangSelect} filterer={this.getCurrentLanguage} />
      </div>
    );
  }
}





