import React from 'react';
import Input from '@mdigital/components/dist/components/Input';
import FieldRow from '../../../MobileEngagementDesign/Banner/SettingsExpandableList/shared/FieldRow';
import { StyledThankYouPromptAccessibility } from '../StyledThankYouPromptSettings';

const ThankYouPromptAccessibility = () => (
  <StyledThankYouPromptAccessibility>
    <FieldRow title="Image Alt Text" name="trigger.mobileThankYouAccessibilitySectionContract.imageAltText" render={({ input }) => (
      <Input maxLength={60} enableCounter digPlaceholder="Insert here" digValue={input.value} digOnChange={input.onChange} />
    )} />
    <FieldRow title="X Button Alt Text" name="trigger.mobileThankYouAccessibilitySectionContract.closeButtonAltText" render={({ input }) => (
      <Input maxLength={60} enableCounter digPlaceholder="Insert here" digValue={input.value} digOnChange={input.onChange} />
    )} />
  </StyledThankYouPromptAccessibility>
);
export default ThankYouPromptAccessibility;