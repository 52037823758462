import styled from 'styled-components';
import { units } from '@m/alchemy-ui';
import { PROPERTY_TYPES } from '../../../../../../../../common/constants';
import { Z_INDEX_MAP } from '../../../../../../../../common/enums';
import { TriggerByTargetingAppMode } from '../../..';
import { DARK_BACKGROUND, WHITE, BLACK } from '../../../../../../../common/colors';

export const StyledLNPreviewContainer = styled.div `
  font-family: ${({ propertyType }) => propertyType === PROPERTY_TYPES.IOS ? '-apple-system, BlinkMacSystemFont' : 'Roboto-Regular' };
  position: absolute;
  width : ${({ propertyType }) => propertyType === PROPERTY_TYPES.IOS ? '346px' : '340px' };
  left: 28px;
  top : ${({ triggerScope }) => triggerScope === TriggerByTargetingAppMode.FOREGROUND ? '65px' : '263px' };
  border-radius: ${({ propertyType }) => propertyType === PROPERTY_TYPES.IOS ? units(2) : units(1.25) };
  padding ${units(2)} ${units(4)} ${units(2)} ${units(4)};
  box-shadow: 0 5px 13px rgb(0 0 0 / 30%);
  z-index: ${Z_INDEX_MAP.SECOND_LAYER};
  background-color: ${({ isDarkEnabled })=> isDarkEnabled ? DARK_BACKGROUND : WHITE };
  color: ${({ isDarkEnabled })=> isDarkEnabled ? WHITE : BLACK };
`;

export const StyledLNAppNameContainer = styled.div `
  display: flex;
`;

export const StyledLNPreviewImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: ${units(0.5)};
`;

export const StyledLNPreviewAppName = styled.span`
  display: block;
  font-size: 13px;
  line-height : 18px;
  width: 260px;
  word-wrap: break-word;
  margin: ${units(0.75)} 0 0 ${units(0.5)};
`;

export const StyledLNPreviewTitle = styled.span`
  display: block;
  font-size: 15px;
  font-weight: 600;
  line-height : 20px;
  margin-top: ${units(1.25)};
`;

export const StyledLNPreviewBody = styled.span`
  display: block;
  font-size: 13px;
  line-height : 18px;
  margin-top: ${units(0.5)};
`;