export default [{
  value: 1,
  label: '1 Extremely low',
},{
  value: 2,
  label: '2 Very low',
},{
  value: 3,
  label: '3 Low',
},{
  value: 4,
  label: '4 Moderately low',
},{
  value: 5,
  label: '5 Average',
},{
  value: 6,
  label: '6 Normal',
},{
  value: 7,
  label: '7 Moderately high',
},{
  value: 8,
  label: '8 High',
},{
  value: 9,
  label: '9 Very high',
},{
  value: 10,
  label: '10 Extremely high',
}];