import React, { useContext, useState, useEffect } from 'react';
import { Field } from '@m/alchemy-ui';
import { StyledFieldContainer, StyledInputCounter } from './StyledTargetingField';
import { TriggerContext } from '../../..';
import { nonSpacingValidator } from '../../../../../utils/validationUtils';

const smallLabelWidth = '100px';
const largeLabelWidth = '200px';

const TargetingField = ({ label, layoutVariant, required, render, labelStyle, className, errorMessage, inputMaxLength, inputCounter, disabled }) => {
  const [status, setStatus] = useState();
  const { hasErrors, setHasErrors } = useContext(TriggerContext);
  const defaultErrorMessage = 'This option is required';

  useEffect(() => {
    const { value } = render.props;
    required && !value && onError();
  }, []);
  
  const onError = () => {
    setStatus({ level: 'error', message: errorMessage ? errorMessage : defaultErrorMessage  });
    setHasErrors({ ...hasErrors , [label] : errorMessage });
  };
  const handleOnBlur = (event) => {
    if (required) {
      const { value } = event.target;
      nonSpacingValidator({
        value,
        onError,
      });
    }
  };

  const handleOnChange = (e) => {
    if (e.target.value !== '' && status) {
      setStatus(null);
      delete hasErrors[label];
    }
  };
  return (
    <StyledFieldContainer labelStyle={labelStyle} className={className} required={required}>
      <Field
        isFullWidth
        label={label}
        labelWidth={layoutVariant === 'inline' ? smallLabelWidth : largeLabelWidth}
        required={required}
        layoutVariant={layoutVariant}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        status={status}
        input={render}
        disabled={disabled}
      />
      {inputMaxLength && <StyledInputCounter>{`${inputCounter} / ${inputMaxLength}`}</StyledInputCounter>}
    </StyledFieldContainer>
  
  );
};
export default TargetingField;
