import styled from 'styled-components';
import { COLORS } from '../../../common/colors';

export default styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
`;

export const StyledMobilePreviewModalHeader = styled.div`
  position: relative;
  display: flex;
  border-bottom: 1px solid ${COLORS.SEPARATOR};
`;

export const StyledMobilePreviewModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 32px;
`;

export const StyledMobilePreviewModalQRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledModalTitle = styled.h2`
  font-family: sans-serif;
  font-size: 25px;
  font-weight: normal;
  padding: 18px 20px;
  margin: 0;
  color: ${COLORS.TEXT_SUBTITLE};
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  border: none;
  background: transparent;
  color: ${COLORS.SEPARATOR};
  font-size: 20px;
  font-weight: normal;
  top: -2px;
  right: 3px;
`;

export const StyledPreviewURLField = styled.div`
  display: flex;

  span {
    background: ${COLORS.LIGHT_GREY};
    flex: 1;
    border-radius: 3px;
    padding: 8px 0;
    height: 34px;
    max-height: 34px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    color: ${COLORS.PRIMARY_TEXT};
    border-right: 16px solid transparent;
    border-left: 16px solid transparent;
  }

  button.mobile-preview-modal-copy-link-button {
    font-weight: normal;
  }
`;

export const StyledMobilePreviewModalDescription = styled.span`
  color: ${COLORS.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 32px;
`;

export const StyledModalBodySplitter = styled.div`
  display: flex;
  padding: 16px 0;
  flex: 1;
`;

export const StyledSplitterTitle = styled.span`
  color: ${COLORS.PRIMARY_TEXT};
  padding: 0 24px;
`;

export const StyledHorizontalLine = styled.span`
  background: ${COLORS.SEPARATOR};
  height: 1px;
  flex: 1;
  margin-top: 8px;
`;
