import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import ExpandableList from '@mdigital/components/dist/components/ExpandableList';
import AutoSuggest from '@mdigital/components/dist/components/AutoSuggest';
import StyledBannerSettingsExpandableList, {
  StyledPositionDropdown
} from './StyledBannerSettingsExpandableList';
import GeneralSettings from './GeneralSettings';
import DurationSettings from './Duration';
import ButtonSettings from './ButtonSettings';
import { GlobalDataConsumer, FLAVORS } from '../../index';
import { autoSuggestLabelByIdFinder } from '../../../../common/utils';
import DarkMode from './DarkMode';

const BANNER_POSITIONS = [{
  id: 'Top partial drawer',
  label: 'TOP-true',
},{
  id: 'Bottom partial drawer',
  label: 'BOTTOM-true',
},{
  id: 'Top drawer',
  label: 'TOP-false',
},{
  id: 'Bottom drawer',
  label: 'BOTTOM-false',
}
];

const bannerPositionsAutoSuggestIdFinder = autoSuggestLabelByIdFinder(BANNER_POSITIONS);

function formatPositionValue(values) {
  const { trigger: { triggerCustomBannerContract: { position, isPartial } } } = values;
  return bannerPositionsAutoSuggestIdFinder(`${position}-${isPartial}`);
}

const BannerSettings = ({ mutators: { mutateMobileBannerPosition } }) => (
  <StyledBannerSettingsExpandableList>
    <GlobalDataConsumer>{({ propertyType, flavor , isDarkModeEnabled }) => (
      <ExpandableList height={`${flavor === FLAVORS.APP_RATING ? 440 : 470}px`}>
        <ExpandableList.Item label="General Settings">
          <GeneralSettings propertyType={propertyType} />
        </ExpandableList.Item>
        <ExpandableList.Item label="Position" nonExpandable>
          <StyledPositionDropdown>
            <FormSpy subscription={{ values: true }} render={({ values }) =>(
              <Field name="position" render={() => (
                <AutoSuggest
                  digDisableSuggest
                  suggestions={BANNER_POSITIONS}
                  onSuggestionSelected={(value) => mutateMobileBannerPosition(value)}
                  value={formatPositionValue(values)}
                />
              )} />
            )} />
          </StyledPositionDropdown>
        </ExpandableList.Item>
        <ExpandableList.Item label="Button Settings">
          <ButtonSettings />
        </ExpandableList.Item>
        <ExpandableList.Item label="Duration" nonExpandable info="Define how many seconds the banner will stay on screen.<br />Sticky mode will pin the banner to screen">
          <DurationSettings />
        </ExpandableList.Item>
        {isDarkModeEnabled && 
          <ExpandableList.Item label="Dark Mode Support" info="The provided dark mode customization will be automatically applied when device/app is in dark mode. Switching back to light mode is also automatic.">
            <DarkMode/>
          </ExpandableList.Item>
        }
      </ExpandableList>
    )}</GlobalDataConsumer>
  </StyledBannerSettingsExpandableList>
);

export default BannerSettings;
