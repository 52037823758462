import React from 'react';
import PropTypes from 'prop-types';
import FormSettingsField from './FormSettingsField';
import Input from '@mdigital/components/dist/components/Input';
import RadioButtons from '@mdigital/components/dist/components/RadioButtons';
import { StyledFormNameContainer , StyledFormID } from './StyledFormBasicSettings';
import { getSingleText } from '../../../common/utils';

const BasicSettings = ({ values , mutators, isPublished }) => (
  <React.Fragment>
    <FormSettingsField
      name="formSettings.name"
      title="Form Name"
      required
      errorMessage="Form Name is Required"
      isDisabled={isPublished}
      render={({ input }) => (
        <StyledFormNameContainer>
          <Input digValue={input.value} digOnChange={input.onChange}/>
          <StyledFormID>{getSingleText('app.pages.forms.formBasicSettings.formId')}{values.formSettings.id}</StyledFormID>
        </StyledFormNameContainer>
      )}/>
    <FormSettingsField
      name="formSettings.settings.formMobileSettingsContract.formViewType"
      title="Form View"
      errorMessage="Form View is Required"
      isDisabled={isPublished}
      render={({ input }) => (
        <RadioButtons digCurrentValue={values.formSettings.settings.formMobileSettingsContract.formViewType} 
          digValue={input.value} digRadioButtons={radioButtonsOptions} 
          digOnChange={ () => mutators.setFormViewType(input.value === 'modal' ? 'full' : 'modal')} 
          layoutOrientation="horizontal"/>
      )}/>
  </React.Fragment>
);

const radioButtonsOptions = [
  {
    digLabel: 'Partial Screen',
    digValue: 'modal',
  },
  {
    digLabel: 'Full Screen',
    digValue: 'full',
  }
];

export default BasicSettings; 

BasicSettings.propTypes = {
  values: PropTypes.object,
  mutators: PropTypes.object,
  isPublished: PropTypes.bool,
};

BasicSettings.defaultProps = {
  values: {},
  mutators: {},
  isPublished: false,
};