import React from 'react';
import { StyleLocalNotificationContainer } from './StyleLocalNotification';
import { LogoImage } from './LogoImage';
import { Separator } from '../../StyledTriggerByTargeting';
import { Expiration } from './Expiration';
import { AppName } from './AppName';
import { LocalNotificationBasicFields } from './LocalNotificationBasicFields';

export const TextPathPrefixLocalNotification = 'app.pages.forms.triggeringModal.triggerByTargeting.localNotification';

export const LocalNotification = () => {
  return (
    <StyleLocalNotificationContainer>
      <LocalNotificationBasicFields />
      <LogoImage />
      <Separator />
      <Expiration />
      <AppName />
    </StyleLocalNotificationContainer>
  );
};
