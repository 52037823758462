import styled from 'styled-components';
import { units } from '@m/alchemy-ui';

export const StyledTargetingBlockRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${units(2)};
`;

export const StyledTargetingCheckbox = styled.div`
  width: ${({ isQuarantineRule }) => isQuarantineRule ? '500px' : '350px'};
  display: flex;
  align-items: center;
  & svg {
    margin-left: ${units(0.5)};
  }
`;

export const StyledTargetingInputField = styled.div`
    display: flex;
    align-items: center;
    ${({ disabled }) => disabled ? 'cursor: not-allowed' : ''};
    .targeting-field-content{
      display: flex;
      align-items: center;
      ${({ disabled }) => disabled ? 'pointer-events: none; opacity: 0.4;' : ''}
    }
    & input {
      width: 100px;
    }
    .targeting-field-error-message {
      margin-left: ${units(1.5)};
    }
    .number-of-session-input{
      margin-left: ${units(1.5)};
    }
    .targeting-large-field{
      width: 250px;
    }
`;