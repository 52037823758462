"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDomNode = void 0;
function createDomNode(type, attributes) {
    const newEl = document.createElement(type);
    Object.keys(attributes)
        .forEach(attrName => {
        if (attrName === 'innerHTML') {
            newEl[attrName] = attributes[attrName];
        }
        else {
            newEl.setAttribute(attrName, attributes[attrName]);
        }
    });
    return newEl;
}
exports.createDomNode = createDomNode;
