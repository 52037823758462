import React from 'react';
import { FileUploader, useFileUploader } from '@m/alchemy-ui';
import HttpManager from '../../../services/HttpManager';
import styled from 'styled-components';
import { StyledFileUploaderContainer } from './FormV2EnablementUpgradeModalStyled';

const DisableContainer = styled.div`
  width: 100%;
  height: 70px;
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  opacity: ${({ disabled }) => disabled ? '0.3' : '1'};
  margin-top: 10px;
`;

// using enum like convention
const Statuses = {
  Pending: 'pending',
  Success: 'success',
  Error: 'error',
};

export default function FormV2EnablementFileUploader({ disabled, onFileUpload }) {
  const { files, addFiles, updateFile, removeFile } = useFileUploader([]);

  function uploadFile(addedFiles) {
    const file = addedFiles[0];

    if (file.status !== Statuses.Error) {
      addFiles(addedFiles, { status: Statuses.Pending });

      const formData = new FormData();
      formData.append('file', file);

      HttpManager.post('kma/api/upload', formData)
        .then(function(fileData) {
          updateFile(file, { status: Statuses.Success });
          onFileUpload(fileData);
        })
        .catch(function() {
          updateFile(file, { status: Statuses.Error });
        });
    } else {
      addFiles(addedFiles, { status: Statuses.Error });
    }
  }

  const config = {
    ...FileUploader.defaultStringMap,
    fileCountReached: '', // hide default message when max files was uploaded
    fileTypeError: 'Please upload valid file format.',
  };

  function handleFileRemove(fileData) {
    removeFile(fileData);
    onFileUpload(null);
  }

  if (disabled && files.length) {
    removeFile(files[0]); // clear uploaded file when changing upgrade option
  }

  return (
    <DisableContainer disabled={disabled}>
      <StyledFileUploaderContainer>
        <FileUploader files={files}
          accept='.css'
          layout="compact"
          stringMap={config}
          maxFileCount={1}
          onRemove={handleFileRemove}
          onCancel={handleFileRemove}
          onAdd={uploadFile}/>
      </StyledFileUploaderContainer>
    </DisableContainer>
  );
}
