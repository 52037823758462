import styled from 'styled-components';
import { COLORS } from '../../../../common/colors';

export const StyledApiUrlPrefixContent = styled.textarea `
    background: ${COLORS.LIGHT_GREY}; 
    height: 50px;
    margin-top: 7px;
    font-family: monospace;
    width: 452px;
    font-size: 12px;
    border: none;
    border-radius: 3px;
    resize: none;
    padding: 10px;
`;
