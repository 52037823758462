import React, { useContext, useEffect, useState } from 'react';
import { StyledTriggerByCodeContainer , StyledLeftPanel , StyledRightContainer } from '../Shared/SharedMobileTriggeringStyling';
import CodeLeftPanel from './CodeLeftPanel';
import CodeTrigger from '../../../../assets/TriggeringModal/TriggerTypes/CodeTrigger.svg';
import withProvider from '../../../../hoc/ProviderHoc/ProviderHoc';
import { connect, useSelector } from 'react-redux';
import { TriggerContext } from '../..';
import { TargetingActions } from '../TriggerByTargeting/Actions';
import CodeRightPanel from './CodeRightPanel';

const TriggerByCodeTypes = {
  CODE: 'Code Trigger',
};

const TriggerByCodeType = [
  {
    label: TriggerByCodeTypes.CODE,
    Icon: CodeTrigger,
    value: 'code',
  }
];

const codeContract = '.CodeContract';

const TriggerByCode = () => {
  const [selection, setSelection] = useState(TriggerByCodeType[0].value);
  const { triggerState, triggerDispatch } = useContext(TriggerContext);
  const triggeringData = useSelector((state) => state.forms.triggeringData);

  useEffect(() => {
    triggeringData && triggeringData.triggerType === codeContract && triggerDispatch({ type : TargetingActions.INIT , payload : triggeringData });
  },[triggeringData]);

  return (
    <StyledTriggerByCodeContainer>
      {triggerState && triggerState.trigger && triggerState.trigger.triggerType === codeContract &&
    <React.Fragment>
      <StyledLeftPanel>
        <CodeLeftPanel TriggerByCodeType={TriggerByCodeType} selection={selection} setSelection={setSelection}/>
      </StyledLeftPanel>
      <StyledRightContainer>
        <CodeRightPanel/>
      </StyledRightContainer>
    </React.Fragment>
      }
    </StyledTriggerByCodeContainer>
  );
};

export default withProvider()(
  connect()(TriggerByCode)
);