import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logOutAndRedirect } from '../../../services/HttpManager';
import withProvider from '../../hoc/ProviderHoc';
import SystemUpdateModal from './SystemUpdateModal';
import { LOGOUT_BUTTON } from '../../../common/constants';
import { setFreezeModeModal } from '../../../redux/actions/app.actions';


@withProvider()
class SystemUpdateContainer extends Component {

  logoutHandler = () => {
    this.props.setFreezeModeModal(false);
    logOutAndRedirect();
  }

  render() {
    const dynamicButtonsProps = {
      [LOGOUT_BUTTON]:{
        digOnClick:this.logoutHandler,
      },
    };
    return <SystemUpdateModal dynamicButtonsProps={dynamicButtonsProps} isShown={this.props.isShowSystemModal}  />;
  }
}

function mapStateToProps({ app }){
  return{
    isShowSystemModal: app.isShowSystemModal,
  };
}

export default withProvider()(connect(mapStateToProps,{ setFreezeModeModal })(SystemUpdateContainer));

