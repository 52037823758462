import React from 'react';

export const ErrorSign = () =>(
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <path style={{ fill:'#d41b25' }} className="a" d="M9,18a9,9,0,1,1,9-9A9.01,9.01,0,0,1,9,18Zm0-3.767h.049a1.074,1.074,0,0,0,1.073-1.121,1.113,1.113,0,0,0-.312-.814,1.088,1.088,0,0,0-.767-.315h-.09a1.082,1.082,0,0,0-1.08,1.13,1.08,1.08,0,0,0,1.086,1.121ZM9,3.77a.7.7,0,0,0-.769.585l.131,5.639a.465.465,0,0,0,.305.448.811.811,0,0,0,.664,0,.465.465,0,0,0,.306-.448l.131-5.639A.7.7,0,0,0,9,3.77Z"/>
  </svg>
);

export const WarningSign = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <path style={{ fill:'#f3d124' }} className="a" d="M9,18a9,9,0,1,1,9-9A9.01,9.01,0,0,1,9,18Zm0-3.767h.049a1.074,1.074,0,0,0,1.073-1.121,1.113,1.113,0,0,0-.312-.814,1.088,1.088,0,0,0-.767-.315h-.09a1.082,1.082,0,0,0-1.08,1.13,1.08,1.08,0,0,0,1.086,1.121ZM9,3.77a.7.7,0,0,0-.769.585l.131,5.639a.465.465,0,0,0,.305.448.811.811,0,0,0,.664,0,.465.465,0,0,0,.306-.448l.131-5.639A.7.7,0,0,0,9,3.77Z"/>
  </svg>
);