import React, { Component } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../common/colors';
import DigInput from '@mdigital/components/dist/components/Input';
import { NAME_MAX_CHARACTERS } from '../../../common/constants';
import './modal.scss';

const StyledCommonModal = styled.div`
	height: 120px;
	.title-input-container {
		display: flex;
		flex-direction: column;
		margin: 8px 15px 28px 5px;
		.title-container {
			font-size: 13px;
			> span {
			    color: ${COLORS.REQUIRE_MARK};
    			font-size: medium;
    			vertical-align: top;
			}
		}
		.input-design {
			width: 100%;
			outline: none;
			border-radius: 4px !important;

		}
	}
`;

export class CommonModal extends Component {

  static defaultProps = {
    /** folderName - the folder's name */
    name: '',
    /** input value callback */
    onInputChange: Function,
    /**Dynamic error message for the dig input*/
    errorMessage: '',
    /** return a boolean for validation criteria */
    validateCallBack: null,
    label: 'Enter new name',
    inputClassName: 'input-design',
    wrapperClassName: 'title-input-container',
    isRequired: true,
    isEnableCounter: true,
    labelDirection: 'TOP',
    requiredMessage: 'Name can\'t be empty',
    customErrorMsg: 'Invalid name',
    placeholder: 'Enter new name',
    maxCharacters: NAME_MAX_CHARACTERS,
  };

  render(){
    return (
      <StyledCommonModal>
        <div className={this.props.wrapperClassName}>
          <div>
            <DigInput
              digClass={this.props.inputClassName}
              digLabel={this.props.label}
              digRequired={this.props.isRequired}
              digValue={this.props.name}
              digOnChange={this.props.onInputChange}
              enableCounter={this.props.isEnableCounter}
              labelDirection={this.props.labelDirection}
              requiredMessage={this.props.requiredMessage}
              customErrorMsg = {this.props.customErrorMsg}
              digPlaceholder={this.props.placeholder}
              maxLength={this.props.maxCharacters}
              validationCallback={this.props.validateCallBack}
            />
          </div>
        </div>
      </StyledCommonModal>
    );
  }
}
