import React from 'react';
import withProvider from '../../hoc/ProviderHoc';
import Login from './Login';

@withProvider()
class LoginWrapper extends React.Component{
  render(){
    return <Login />;
  }
}
export default LoginWrapper;