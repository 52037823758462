import styled from 'styled-components';
import { REQUIRED_RED, APP_RATING_MODAL_INPUT_TEXT_COLOR } from '../../../common/colors';

export const StyledAlertInput = styled.div`
  display: flex;
  
  &.alert-revamp{
    flex-direction: column;
    input, textarea{
      width: 100%;
    }
    .input-wrapper{
      width: 100%;
    }
    .alert-revamp-textarea{
      div{
        width: 100%;
      }
    }
  }
  &.required-field {
    position: relative;
    span.error-message {
      color: ${REQUIRED_RED};
      position: absolute;
      left: 0;
      bottom: -20px;
    }

    textarea + span.error-message {
      bottom: -15px;
    }

    label {
      position: relative;
      &::after {
        content: '*';
        position: absolute;
        top: -2px;
        margin-left: 4px;
        color: ${REQUIRED_RED};
        font-size: 14px;
      }
    }
  }
`;

export const StyledAlertPromptField = styled.span`
  ${(props) => props.disabled ? 'pointer-events: none; opacity: 0.4;' : ''};
  flex: 4;
  .mobile-engagement & {
    flex: 7;
  }
  position: relative;

  span.error-message {
    display: none;
  }

  &.has-error {
    input, textarea {
      border-color: ${REQUIRED_RED};
    }

    span.error-message {
      display: block;
    }
  }

  input {
    height: 34px;
  }

  input, textarea {
    width: 300px;
  }

  textarea {
    height: 5em;
  }

  input, textarea {
    &:not(.color-picker-input) {
      padding: 5px 8px;
    }
  }
  
  input, textarea, select {
    font-size: 14px;
    color: ${APP_RATING_MODAL_INPUT_TEXT_COLOR};
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    resize: none;
  }
`;

export const StyledAlertPromptLabel = styled.span`
  flex: 1;
  .mobile-engagement & {
    flex: 3;
  }
  padding-top: 5px;
`;

export default styled.div`

  width: 100%;

`;