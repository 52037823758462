import { units, color } from '@m/alchemy-ui';
import styled from 'styled-components';


export const StyledLogoImage = styled.div`
  width: 363px;
  margin: ${units(1)} 0px ${units(1)} ${units(1)};
  & div {
    width: 100%;
  }
`;

export const AlchemyFiledRequiredDot = styled.span`
  height: ${units(0.5)};
  width: ${units(0.5)};
  background-color: ${color('base', { palette : 'interactiveConstructive' })};
  border-radius: 50%;
  display: inline-block;
  margin: 0px 3px 6px 0px;
`;