"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormComponents = void 0;
var FormComponents;
(function (FormComponents) {
    FormComponents["TEXT_INPUT"] = "textInput";
    FormComponents["TEXT_AREA"] = "textArea";
    FormComponents["EMAIL_INPUT"] = "emailInput";
    FormComponents["NPS"] = "nps";
    FormComponents["GRADING1TO5"] = "grading";
    FormComponents["GRADING1TO7"] = "grading1to7";
    FormComponents["GRADING1TO10"] = "grading1to10";
    FormComponents["GRADING0TO10"] = "grading0to10";
    FormComponents["RADIO"] = "radio";
    FormComponents["CHECKBOX"] = "checkbox";
    FormComponents["SELECT"] = "select";
    FormComponents["LABEL"] = "label";
    FormComponents["IMAGE"] = "image";
    FormComponents["SECTION_BREAK"] = "sectionBreak";
    FormComponents["SCREEN_CAPTURE"] = "screenCaptureComponent";
    FormComponents["MEDIA_CAPTURE"] = "mediaCapture";
    FormComponents["DATE"] = "date";
})(FormComponents = exports.FormComponents || (exports.FormComponents = {}));
