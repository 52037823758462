import React, { useEffect } from 'react';
import { LoadingIndicator } from '@m/alchemy-ui';
import HttpManager from '../../../services/HttpManager';

function sleep(seconds = 1) {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
}

export function FormV2EnablementLoadingModal(props) {
  const { render, propertyId, submit } = props;

  useEffect(() => {
    (async function startPolling() {
      let status = '';
      do {
        try {
          if (status) {
            // if the upgrade was fast, no need to make the user wait,
            // empty string represents the first iteration, so first time we skip the delay, after that it will be populated with the response from status call
            await sleep(5);
          }
          const url = `/kma/api/property/${propertyId}/upgradeToV2Form/status`;
          const { resultMessage } = await HttpManager.get(url);
          status = resultMessage;
        } catch (err) {
          status = 'FAILURE';
        }
      } while (!status || status === 'IN_PROGRESS');

      submit(status);
    })();
  }, []);

  return render({
    body: (
      <React.Fragment>
        <LoadingIndicator inline/>
        <label style={{ fontSize: '18px', fontWeight: '700', color: '#1B2437' }}>Upgrading... It might take some
					time</label>
      </React.Fragment>
    ),
    size: 'small',
    isCentered: true,
    close: {
      disableOutsideClick: true,
      disableEscapeKey: true,
      hideButton: true,
      hideCloseIcon: true,
    },
  });
}
