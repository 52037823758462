import React, { useContext } from 'react';
import { PanelSubHeader } from '../../../../StyledTriggeringModal';
import { Separator, StyledContentContainer } from '../../StyledTriggerByTargeting';
import TargetingField from '../../../Shared/TargetingField';
import { Input } from '@m/alchemy-ui';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { TargetingActions } from '../../Actions';
import { TriggerContext } from '../../../..';
import { getSingleText } from '../../../../../../../common/utils';
import { TextPathPrefix } from '../..';
import { CustomInviteBasicFields } from './CustomInviteBasicFields';

const CustomInvite = () => {
  const { triggerState , triggerDispatch, isReadOnly } = useContext(TriggerContext);
  return (
    <StyledContentContainer>
      <CustomInviteBasicFields />
      <Separator/>
      <PanelSubHeader>{getSingleText(`${TextPathPrefix}.buttonsHeader`)}</PanelSubHeader>
      <TargetingField
        placeholder="Provide Feedback"
        label="Provide Feedback"
        className="custom-field-margin"
        disabled={isReadOnly}
        render={<Input 
          placeholder='Provide Feedback'
          value={getNestedProperty(triggerState, 'trigger.triggerCustomInterceptContract.customProvideButtonText') || ''} 
          onChange={ (e) => triggerDispatch({ type: TargetingActions.SET_CUSTOM_PROVIDE_FEEDBACK_BUTTON_TEXT , payload : e.target.value })} />}
      />
      <TargetingField
        label="Decline"
        disabled={isReadOnly}
        render={<Input
          placeholder='No Thanks'
          value={getNestedProperty(triggerState, 'trigger.triggerCustomInterceptContract.customDeclineButtonText') || ''}
          onChange={ (e) => triggerDispatch({ type: TargetingActions.SET_CUSTOM_DECLINE_BUTTON_TEXT , payload : e.target.value })} />}
      />
      <TargetingField
        label="Maybe Later"
        disabled={isReadOnly}
        render={<Input 
          placeholder='Maybe Later'
          value={getNestedProperty(triggerState, 'trigger.triggerCustomInterceptContract.customLaterButtonText') || ''}
          onChange={ (e) => triggerDispatch({ type: TargetingActions.SET_CUSTOM_LATER_BUTTON_TEXT , payload : e.target.value })} />}
      />
    </StyledContentContainer>);
};
export default CustomInvite;
