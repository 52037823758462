import React from 'react';
import PropTypes from 'prop-types';
import { Column, Table, AutoSizer } from 'react-virtualized';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import './DigTable.scss';

// documentation here https://github.com/bvaughn/react-virtualized/blob/master/docs/Table.md
export default class DigTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sortBy: this.props.sortBy,
      sortDirection: this.props.sortDirection,
      rows : _.clone(this.props.rows),
    };
    this.timeout = null;
    this.state.rows.sort(this.comparer(this.state.sortBy,this.state.sortDirection));
  }

  UNSAFE_componentWillReceiveProps({ rows, sortBy, sortDirection }) {
    const isRowsUpdated = _.difference(rows,this.state.rows).length > 0; // if two arrays are equals will return empty array,
    // otherwise will return new array containing the unique props/values
    if(isRowsUpdated) {
      rows.sort(this.comparer(sortBy, sortDirection));
      this.setState({ rows, sortBy, sortDirection });
    }
  }

    comparer = (sortBy,sortDirection) => (a,b)=>{
      a = a[sortBy];
      b = b[sortBy];

      a = typeof a === 'string' ? a.toLowerCase() : a;
      b = typeof b === 'string' ? b.toLowerCase() : b;

      if (sortDirection === 'ASC') {
        return (a > b) ? 1 : -1;
      } else if (sortDirection === 'DESC') {
        return (a > b) ? -1 : 1;
      }
    };

    sort = ({ sortBy, sortDirection }) => {
      const rows = _.clone(this.state.rows).sort(this.comparer(sortBy,sortDirection));
      this.setState({ rows, sortBy, sortDirection });
    };

    rowGetter = ({ index }) => this.state.rows[index];

    renderColumns = (width) => this.props.columns.map((column,index)=>
      <Column key={index}
        width={width / this.props.columns.length }
        {...column}
      />
    );

    componentDidUpdate(){
      clearTimeout(this.timeout);
      this.timeout = setTimeout(ReactTooltip.rebuild,250);
    }

    render(){
      return (
        <div className="AutoSizerWrapper">
          <ReactTooltip place="top" type="dark" effect="solid"/>
          <AutoSizer>
            {({ width }) =>
              <Table sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                rowGetter={this.rowGetter}
                sort={this.sort}
                width={width}
                {...this.props.config}>
                {
                  this.renderColumns(width)
                }
              </Table>
            }
          </AutoSizer>
        </div>
      );
    }
}

DigTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  config : PropTypes.object,
  sortBy : PropTypes.string,
  sortDirection : PropTypes.string,
};
