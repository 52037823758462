import React  from 'react';
import withProvider from '../../hoc/ProviderHoc';
import { connect } from 'react-redux';
import { StyledBannerContainer, StyledUpgradeButton, StyledBannerText, StyledLearnMoreText } from './StyledEnablementFormNotification';
import { getSingleText } from '../../../common/utils';
import IconSvg from '@mdigital/components/dist/components/IconSvg';
import icon from '../../assets/actions/info.svg';
import FormV2Enablement from '../FormV2Enablement';

function EnablementFormNotification() {

  function onMoreInfoClick() {
    window.open('https://docs.medallia.com/?resourceId=mdw-form-v2-upgrade');
  }

  return (
    <StyledBannerContainer>
      <IconSvg icon={icon} color={'#2E78D0'} width={18} height={18}/>
      <StyledBannerText>{getSingleText('app.topMenu.sdkV2.announcementBanner.title')}</StyledBannerText>
      <StyledUpgradeButton>
        <FormV2Enablement/>
      </StyledUpgradeButton>
      <StyledLearnMoreText onClick={onMoreInfoClick}>{getSingleText('app.topMenu.sdkV2.announcementBanner.moreInfo')}</StyledLearnMoreText>
    </StyledBannerContainer>
  );
}

function mapStateToProps({ property }) {
  return {
    propertyType: property.propertyType,
  };
}

export default withProvider()(
  connect(mapStateToProps)(EnablementFormNotification)
);
