"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderConditionSyntax = void 0;
const operators_1 = require("../operators");
const renderConditionSyntax = (condition, components) => {
    if (condition.type === 'custom-param') {
        return [`'${condition.customParamName}'`, ...condition.operator ? [condition.operator] : [], ...condition.value ? [`'${condition.value}'`] : []].join(' ');
    }
    else if (condition.type === 'component') {
        const component = components.filter(item => condition.nebUuid === item.nebUuid)[0];
        let value = undefined;
        if (component != null && condition.value != null) {
            const options = component.options;
            if (options != null && options.length) {
                const values = condition
                    .value
                    .toString()
                    .split(',');
                value = values
                    .map(item => { var _a; return (_a = options.filter(option => option.id === item)[0]) === null || _a === void 0 ? void 0 : _a.label; })
                    .join(', ');
            }
            else {
                value = condition.value;
            }
        }
        return [...component != null ? [`'${component.unique_name}'`] : [], ...condition.operator ? [(0, operators_1.getOperatorLabel)(condition.operator)] : [], ...value != null ? [`'${value}'`] : []].join(' ');
    }
    else {
        return '';
    }
};
exports.renderConditionSyntax = renderConditionSyntax;
