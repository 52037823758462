import ConfirmationModal from '../../modals/ConfirmationModal';
import React, { Component } from 'react';
import { getSingleText } from '../../../common/utils';

export default class ConfirmExtensionUpdate extends Component{ 
  render(){
    const textProps = {
      title: getSingleText('app.modal.extension.unfreeze.bodyQuestion'),
      description: <React.Fragment>{getSingleText('app.modal.extension.unfreeze.warningStart')} 
        <span style={{ marginLeft:5, display: 'inline-block', color: '#D43F3A' }}>{getSingleText('app.modal.extension.unfreeze.warningEnd')}</span>
      </React.Fragment>,
    };

    const modalProps = {
      title: getSingleText('app.modal.extension.unfreeze.header'),
      okButtonText: getSingleText('app.modal.extension.unfreeze.approve'),
    };

    return (
      <ConfirmationModal {...textProps} modalProps={modalProps} {...this.props} />
    );
  }
}
