import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import DigCard from '@mdigital/components/dist/components/Card';

function renderCard(card, i) {
  const cardClass = 'col-sm-6 col-md-4 col-lg-3 col-xl-2';
  return (
    <span key={i} className={cardClass}>
      <DigCard
        cardTitle={card.name}
        labels={card.labels}
        switchState={card.published}
        onSwitchToggle={this.onSwitchToggle || card.onPublishToggle}
        maxActionsInRow={4}
        actionPanelCollection={card.actionPanelCollection}
        isSpinnerActive={card.isSpinnerActive}
        cardId={card.id}
        cardObject={card}
      />
    </span>
  );
}

export default function DigFormCardCollection({ cards = [], onSwitchToggle }) {
  return (
    <div>
      <ReactTooltip place="top" type="dark" effect="solid" />
      {cards.map(renderCard, { onSwitchToggle })}
    </div>
  );
}

DigFormCardCollection.propTypes = {
  cards: PropTypes.array,
  onSwitchToggle: PropTypes.func,
};
