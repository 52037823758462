import withModal from '@mdigital/components/dist/hoc/ModalHoc';
import { getSingleText } from '../../../common/utils';
import { CommonModal } from '../common';

const basicModalProps = {
  title: getSingleText('app.pages.forms.folders.modals.rename.title'),
  okButtonText: getSingleText('app.ok'),
  cancelButtonText: getSingleText('app.cancel'),
  okButtonClass: 'btn-success',
  modalClassName: 'modal-design',
};

@withModal(basicModalProps)
export default class RenameFolderModal extends CommonModal {
  constructor(props) {
    super(props);
  }
}