import React, { useEffect, useState, useReducer, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalsService } from '@mdigital/components/dist/utils/modals';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import ModalSubtitle from './ModalSubtitle';
import SelectionPage from './SelectionPage';
import { StyledCustomDialogHeader , StyledCustomDialogBody , StyledCustomDialogFooter, CustomDialog } from './StyledTriggeringModal';
import { SwitchTriggerTypeModal } from './SwitchTriggerTypeModal';
import { TargetingNavigationStep } from './MobileTriggering/TriggerByTargeting';
import { UseCardFormHistory } from './SelectionPage/hooks/useCardFormHistory';
import { DiscardChangesModal } from './DiscardChangesModal';
import { usePrevious } from './SelectionPage/hooks';
import { TargetingLeftPanelType } from './MobileTriggering/TriggerByTargeting/TargetingPanel/TargetingRightPanel';
import { getSingleText } from '../../../common/utils';
import TriggerTypeRenderer from './TriggerTypeRenderer';
import { TargetingReducer } from './MobileTriggering/TriggerByTargeting/Reducers';
import { prepareRulesList, updateLightTheme } from './helpers';
import { checkProvision, getPropertyType } from '../../../redux/store/store-helper';
import { TARGETING_TYPES } from './MobileTriggering/TriggerByTargeting/TargetingTypes';
import { ShownMode } from '../../../common/enums';
import { changeFormTargeting, fetchFormTriggeringData, setFormTriggeringData } from '../../../redux/actions/form.actions';
import { TriggerByCodeInitialValues, InitialFormTriggeringData } from '../../assets/TriggeringModal/InitialFormTriggeringData';
import withProvider from '../../hoc/ProviderHoc/ProviderHoc';


export const TRIGGER_TYPE = {
  TRIGGER_BY_CODE: 'triggerByCode',
  TRIGGER_BY_TARGETING: 'triggerByTargeting',
};

export const TRIGGER_CONTRACT = {
  '.CodeContract': TRIGGER_TYPE.TRIGGER_BY_CODE,
  '.MobileInvitationContract': TRIGGER_TYPE.TRIGGER_BY_TARGETING,
};

export const VIEW_TYPE = {
  SELECTION_PAGE: 'selectionPage',
  TRIGGER_PAGE: 'triggerPage',
};

export const TriggerContext = React.createContext();

const TriggeringModal = withProvider()(connect()(({ formId, formName, render, saveTriggerModal, hasDarkMode, version, publishedId, draftId, isWithToggle, triggeringData }) => {
  // redux
  const dispatch = useDispatch();
  const propertyType = getPropertyType();
  const triggeringDataRedux = useSelector((state) => state.forms.triggeringData);
  const [triggerState, triggerDispatch] = useReducer(TargetingReducer , {});
  const isSurveyDraftEnabled =  checkProvision(DigProvisions.ENABLE_SURVEY_DRAFTS);
  // modal UI state
  const [isOpen, setIsOpen] = useState(true);
  const [viewType, setViewType] = useState();
  const [hasErrors, setHasErrors] = useState();
  const [currentTab , setCurrentTab] = useState(0);
  const [targetingViewType, setTargetingViewType] = useState(TargetingLeftPanelType.INVITE_DISPLAY);
  // derived data-state
  const triggerType = triggeringDataRedux && TRIGGER_CONTRACT[triggeringDataRedux.triggerType];
  const prevTriggerType = usePrevious(triggerType);
  const ViewByType = {
    [VIEW_TYPE.SELECTION_PAGE] : SelectionPage,
    [VIEW_TYPE.TRIGGER_PAGE] : TriggerTypeRenderer(triggerType),
  };
  const [draftVersion, setDraftVersion] = useState(version);
  const [isDarkMode, setIsDarkMode] = useState(hasDarkMode);
  const isDarkModeEnabled = checkProvision(DigProvisions.MOBILE_SDKV2_NATIVE_DARK_MODE) && isDarkMode;
  const isReadOnly = isSurveyDraftEnabled && draftVersion === ShownMode.PUBLISHED;
  const formID = draftVersion === null ? formId : draftVersion === ShownMode.DRAFT ? draftId : publishedId;
  const oppositeVersionId = !isWithToggle ? null : draftVersion === ShownMode.DRAFT ? publishedId : draftId;
  const invitationType = getNestedProperty(triggerState, 'trigger.invitationType');
  // history
  const { getForm, removeForm } = UseCardFormHistory();

  useEffect(() => {
    triggeringData ? dispatch(setFormTriggeringData(triggeringData)) : dispatch(fetchFormTriggeringData(formID));
  }, []);

  useEffect(() => {
    const historyFormData = getForm(formID);
    const historyOVFormData = getForm(oppositeVersionId);
    if (!triggeringDataRedux) {dispatch(fetchFormTriggeringData(formID)); return;}
    if (historyFormData && historyFormData.triggerType || historyOVFormData && historyOVFormData.triggerType) {
      setViewType(VIEW_TYPE.TRIGGER_PAGE);
    } else {
      setViewType(VIEW_TYPE.SELECTION_PAGE);
    }
  }, [triggeringDataRedux]);

  const renderBodyByViewType = () => {
    const ViewTypeComponent = ViewByType[viewType] || SelectionPage;
    return <ViewTypeComponent/>;
  };

  const onClickSwitchTriggerType = () => {
    SwitchTriggerTypeModal(setViewType, setHasErrors);
    removeForm(formID);
  };

  const onClickSwitchDraftVersion = (chosenVersion) => {
    dispatch(fetchFormTriggeringData(oppositeVersionId));
    setDraftVersion(chosenVersion);
  };

  const onClickChooseNewTriggerType = (chosenTriggerType) => {
    if (chosenTriggerType === TRIGGER_TYPE.TRIGGER_BY_CODE) {
      dispatch(changeFormTargeting(TriggerByCodeInitialValues(formID)));
    } else if (chosenTriggerType === TRIGGER_TYPE.TRIGGER_BY_TARGETING) {
      dispatch(changeFormTargeting(InitialFormTriggeringData(formID , propertyType)));
      if (chosenTriggerType === prevTriggerType) {
        setCurrentTab(TargetingNavigationStep.SAVE);
      } else {
        setCurrentTab(TargetingNavigationStep.INVITE_DISPLAY);
      }
    }
  };

  const getFooterPrimaryButtonText = () => {
    const save = getSingleText('app.pages.forms.triggeringModal.save');
    const next = getSingleText('app.pages.forms.triggeringModal.next');
    switch(true){
      case isReadOnly:
        return save;
      case invitationType === TARGETING_TYPES.PUSH_NOTIFICATION:
        return save;
      case triggerType === TRIGGER_TYPE.TRIGGER_BY_TARGETING:
        return currentTab < TargetingNavigationStep.QUARANTINE ? next : save;
      case triggerType === TRIGGER_TYPE.TRIGGER_BY_CODE:
        return save;
      default:
        return save;
    }
  };

  const onClickFooterPrimaryButton = useCallback(() => {
    const formTargetingData = { ...triggerState };
    const isPushNotification = invitationType === TARGETING_TYPES.PUSH_NOTIFICATION;
    const isTriggerByTargeting = triggerType === TRIGGER_TYPE.TRIGGER_BY_TARGETING;
    if(!isPushNotification && isTriggerByTargeting && currentTab < TargetingNavigationStep.QUARANTINE){
      const values = Object.values(TargetingLeftPanelType);
      const nextIndex = values.indexOf(targetingViewType) + 1;
      const nextItem = values[nextIndex];
      setCurrentTab(nextIndex);
      setTargetingViewType(nextItem);
    } else{
      updateLightTheme(formTargetingData);
      prepareRulesList(formTargetingData);
      saveTriggerModal(formTargetingData);
      setIsOpen(false);
    }
  }, [triggerState, triggerType, currentTab, TargetingLeftPanelType]);

  const isPrimaryButtonValid = () => {
    return !(hasErrors && Object.keys(hasErrors).length > 0  || isReadOnly);
  };

  const basicProps = {
    title: 'Trigger Settings',
    description: 
    <ModalSubtitle 
      formName={formName} 
      draftVersion={draftVersion} 
      toggleDraft={onClickSwitchDraftVersion}
      isWithToggle={isWithToggle}
    />,
    size: 'fullpage',
    padding: '24px 0px 24px 0px',
    close: {
      hideButton: true,
      callback: ()=> !isReadOnly && DiscardChangesModal(setIsOpen , false),
    },
    CustomHeader: StyledCustomDialogHeader,
    body: renderBodyByViewType(),
    isOpen: isOpen,
    CustomDialog: CustomDialog,
  };

  const  TriggeringModalProps = {
    selectionPage: {
      ...basicProps,
    },
    triggerPage:{
      ...basicProps,
      additionalActions: [
        {
          title: 'Switch Trigger Type',
          callback: onClickSwitchTriggerType,
          priority: 'secondary',
          shouldAutoClose: false,
          isValid: !(isReadOnly || isWithToggle),
        }
      ],
      submit: {
        title: getFooterPrimaryButtonText(),
        callback: onClickFooterPrimaryButton,
        shouldAutoClose: false,
        isValid: isPrimaryButtonValid(),
      },
      CustomBody: StyledCustomDialogBody,
      CustomFooter: StyledCustomDialogFooter,
      modalFooterAlert: {
        show: !isReadOnly && isWithToggle,
        title: getSingleText('app.pages.forms.triggeringModal.changeTriggerTypeBlocked'),
      },
    },
  };

  return (
    <TriggerContext.Provider value ={{ triggerType , onClickChooseNewTriggerType, setViewType, formID, prevTriggerType, hasErrors, setHasErrors,
      currentTab, targetingViewType, setTargetingViewType, setCurrentTab, triggerState, triggerDispatch, setIsOpen, isDarkModeEnabled, setIsDarkMode,
      isReadOnly }}>
      {render (TriggeringModalProps[viewType])}
    </TriggerContext.Provider>
  );
}));

export const configureTriggeringModal = (saveTriggerModal, formId, formName, isDarkModeEnabled, version, publishedId, draftId, isWithToggle, triggeringData) => {
  ModalsService.showCustomModal(TriggeringModal, {
    saveTriggerModal: saveTriggerModal,
    formId: formId,
    formName: formName,
    hasDarkMode: isDarkModeEnabled,
    version: version,
    publishedId: publishedId,
    draftId: draftId,
    isWithToggle: isWithToggle,
    triggeringData: triggeringData,
  });
};

TriggeringModal.propTypes = {
  saveTriggerModal: PropTypes.func,
  formId: PropTypes.number,
  formName: PropTypes.string,
  isDarkModeEnabled: PropTypes.bool,
  version: PropTypes.oneOf(['published', 'draft']),
  publishedId: PropTypes.number,
  draftId: PropTypes.number,
  isWithToggle: PropTypes.bool,
  triggeringData: PropTypes.array,
};

TriggeringModal.defaultProps = {
  formId: 0,
  formName: '',
  isDarkModeEnabled: false,
  version: null,
  publishedId: null,
  draftId: null,
  isWithToggle: false,
  triggeringData: null,
};