import { useState, useEffect, useContext } from 'react';
import { TriggerContext } from '../../../../../../..';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
const useVersionValidation = (rulePath) => {
  const { triggerState, setHasErrors, hasErrors } = useContext(TriggerContext);
  const [hasError, setHasError] = useState(false);

  const isContainsFalsyValue = (versions) => {
    return versions && versions.some(function(v) { return !v; });
  };

  useEffect(() => {
    const isRuleActive = getNestedProperty(triggerState, `${rulePath}.isActive`);
    if (!isRuleActive) {
      setHasError(false);
      return;
    }
    const ruleParams = getNestedProperty(triggerState, `${rulePath}.params`);
    const hasValue = ruleParams && (ruleParams.versions || ruleParams.ruleId);
    const hasVersionsError = !ruleParams || !hasValue || isContainsFalsyValue(ruleParams.versions);
    setHasError(hasVersionsError);

  }, [triggerState, rulePath]);

  useEffect(() => {
    const errorKey = `${rulePath.replace(/\./g, '_')}Error`;
    if (hasError) {
      if (!hasErrors || !hasErrors[errorKey]) {
        setHasErrors({ ...hasErrors, [errorKey]: 'errorMessage' });
      }
    } else {
      if (hasErrors && hasErrors[errorKey]) {
        const updatedErrors = { ...hasErrors };
        delete updatedErrors[errorKey];
        setHasErrors(updatedErrors);
      }
    }
  }, [hasError, hasErrors, setHasErrors]);

  return [hasError, setHasError];
};
export default useVersionValidation;