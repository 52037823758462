import styled from 'styled-components';
import {
  REQUIRED_RED,
  APP_RATING_LABEL_COLOR,
  APP_RATING_MODAL_INPUT_TEXT_COLOR,
  TITLE_TEXT_COLOR,SEPARATOR
} from '../../../common/colors';

export const StyledAppRatingWizardModal = styled.span `
  font-family: 'Open Sans';
  form {
    width: 100%;
    height: 100%;
  }

  .required-asterix {
    color: ${REQUIRED_RED};
    &::before {
      content: '*';
    }
  }

  p.page-title {
    font-size: 14px;
    font-weight: bold;
    color: ${TITLE_TEXT_COLOR};
  }

  label, label.dig-container {
    color: ${APP_RATING_LABEL_COLOR};
  }

  

  input, textarea {
    &:not(.color-picker-input) {
      padding: 5px 8px;
    }
    font-size: 14px;
    color: ${APP_RATING_MODAL_INPUT_TEXT_COLOR};
  }

`;
export const AppRatingHeaderSpeartor = styled.div `
  border-bottom : 1px solid ${SEPARATOR};
`;