import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import List from '@mdigital/components/dist/components/List';
import { getSingleText, pushNotification } from '../../../common/utils';
import { getSortedFolders, nameValidationObject } from './foldersSelectors';
import { CONTEXT_MENU_OPTIONS } from '../../../common/enums';
import { isValidNameFormat } from '../../../common/utils';
import PlusIcon from '../../../resources/assets/neb-icons/svg/PlusIcon.svg';
import BinDeletion from '../../../resources/assets/neb-icons/svg/BinDeletion.svg';
import trashSvgIcon from '../../../assets/images/trash.svg';
import Rename from '../../../resources/assets/neb-icons/svg/Rename.svg';
import RenameFolderModal from '../../modals/folder/RenameFolder';
import CreateFolderModal from '../../modals/folder/CreateFolder';
import DeleteFolderModal from '../../modals/folder/DeleteFolder';
import { isFolderInDeleteStatus,getPercentage } from '../FormView/FormViewContainer';
import CircularProgressBar from '@mdigital/components/dist/components/CircularProgressBar';
import * as folderActions from '../../../redux/actions/folder.actions';
import * as formActions from '../../../redux/actions/form.actions';

const NO_FOLDER = -1;
const INITIAL_MODAL_STATE = {
  showCreationModal: false,
  newFolderName: '',
  validationCriteria: { hasName: false, hasNoDuplicates: true },
  isShowRenameModal: false,
  folderId: NO_FOLDER,
  isShowDeleteModal: false,
  isShowDeleteWarningMessage: false,
};

export class FoldersContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = INITIAL_MODAL_STATE;
    this.headerData = {
      label: getSingleText('app.pages.forms.folders.title'),
      actions: [
        {
          onClick: this.showCreateFolderModal,
          tooltip: getSingleText('app.pages.forms.folders.tooltips.addFolder'),
          icon: {
            iconSvg: PlusIcon,
            width: 14,
            height: 14,
          },
          autLabel: 'create-folder',
        }
      ],
    };

  }

  componentDidUpdate() {
    if (this.props.formsBeforeDeletion) {
      const isShowDeleteWarningMessage = this.props.formsBeforeDeletion.some((form) => form.isLocked || form.published);
      const isShowDeleteModal = this.props.propertyRole >= UserRoles.ROLE_USER_GROUP_MANAGER || !isShowDeleteWarningMessage;

      if (!isShowDeleteModal) {
        pushNotification({
          type: NOTIFICATION_TYPES.FAILURE,
          message: getSingleText('app.pages.forms.folders.alerts.deletionNotAllowed'),
        });
      } else {
        this.setState({
          isShowDeleteWarningMessage,
          isShowDeleteModal,
        });
      }

      this.props.setFormsBeforeDeletion(null);
    }
  }

  getFolderProcessComponent() {
    const imageSettings = {
      src: trashSvgIcon,
      width: 12,
      height: 12,
      positionY: 6.5,
      positionX: 6.5,

    };
    return <CircularProgressBar imageSettings={imageSettings} size={25} percentage={getPercentage(this)}
      strokeWidth={1.5}/>;
  }

  composeListData = (folders = []) => folders.map((folder) => {
    const isFolderInProcess = isFolderInDeleteStatus(folder);
    const shouldHaveActions = !folder.root && !isFolderInProcess;
    return {
      autLabel: folder.name,
      id: folder.id,
      label: folder.name,
      numberOfContent: (folder.numberOfForms || 0),
      actions: shouldHaveActions ? [
        {
          label: CONTEXT_MENU_OPTIONS.RENAME,
          icon: { iconSvg: Rename, width: 16, height: 18 },
          onClick: this.showRenameFolderModal,
          autLabel: 'rename-folder',
        },
        {
          label: CONTEXT_MENU_OPTIONS.DELETE,
          icon: { iconSvg: BinDeletion, width: 14, height: 16 },
          onClick: this.showDeleteFolderModal,
          autLabel: 'delete-folder',
        }
      ] : [],
      CustomSideComponent: isFolderInProcess ? this.getFolderProcessComponent.bind(folder) : null,
    };
  });

  onFolderClick = (id) => {
    const selectedFolder = this.props.allFolders.find((folder) => folder.id === id);
    if (this.props.selectedFolder.id !== id) {
      this.props.setSelectedFolder(selectedFolder);
    }
  };

  objectValidationBuilder = (value) => {
    const validationCriteria = {
      hasName: value.trim().length > 0,
      hasNoDuplicates: !this.props.existingFoldersNames[value.toLowerCase().trim()],
    };
    this.setState({ validationCriteria: validationCriteria, newFolderName: value });
  };

  resetModalState = () => this.setState(INITIAL_MODAL_STATE);

  modalCreationConfirmation = () => {
    this.props.createNewFolder(this.state.newFolderName.trim());
    this.setState(INITIAL_MODAL_STATE);
  };

  componentDidMount() {
    this.props.startPolling();
  }

  componentWillUnmount() {
    this.props.stopPolling();
  }

  checkInputValidation = () => {
    const { validationCriteria: { hasName, hasNoDuplicates }, newFolderName } = this.state;
    return hasName && hasNoDuplicates && isValidNameFormat(newFolderName);
  };

  setDynamicErrMessage = () => {
    let errMessage = '';
    const { validationCriteria: { hasName, hasNoDuplicates } } = this.state;

    if (!hasName) {
      errMessage = getSingleText('app.pages.forms.folders.modals.requireMessage');
    } else if (!hasNoDuplicates) {
      errMessage = getSingleText('app.pages.forms.folders.modals.duplicateMessage');
    } else {
      errMessage = getSingleText('app.pages.forms.folders.modals.wrongFormat');
    }

    return errMessage;
  };

  showRenameFolderModal = (folderData, e) => {
    const { id, label } = folderData;
    e.stopPropagation();
    this.setState({
      isShowRenameModal: true,
      newFolderName: label,
      folderId: id,
    });
  }

  showCreateFolderModal = () => this.setState({ showCreationModal : true });

  showDeleteFolderModal = (folderData, e) => {
    const { id } = folderData;
    e.stopPropagation();
    this.setState({ folderId: id }, this.props.getFormsBeforeFolderDeletion.bind(this, id));
  }

  deleteFolder = () => {
    this.setState(INITIAL_MODAL_STATE, this.props.deleteFolderDispatcher.bind(this, this.state.folderId));
  }

  renameFolder = () => {
    this.props.renameFolder(this.state.folderId, this.state.newFolderName.trim());
    this.resetModalState();
  };

  render() {
    const { allFolders, selectedFolder } = this.props;
    const {
      newFolderName,
      showCreationModal,
      isShowRenameModal,
      isShowDeleteModal,
      isShowDeleteWarningMessage,
    } = this.state;

    const isInputValid = this.checkInputValidation();
    const errorMessage = this.setDynamicErrMessage();
    return (
      <React.Fragment>
        <List listItems={ this.composeListData(allFolders) }
          header= {this.headerData}
          selectedKey={ selectedFolder ? selectedFolder.id : null }
          onClick={this.onFolderClick} />

        <CreateFolderModal isShown={showCreationModal}
          isOkButtonDisabled={!isInputValid}
          onInputChange={this.objectValidationBuilder}
          onCancelClicked={this.resetModalState}
          onOkClicked={this.modalCreationConfirmation}
          validateCallBack={this.checkInputValidation}
          customErrorMsg={errorMessage}
          name={newFolderName}
          placeholder={getSingleText('app.pages.forms.folders.modals.placeHolder')}
          label={getSingleText('app.pages.forms.folders.modals.create.newFolderCreationTitle')}
        />

        <RenameFolderModal isShown={isShowRenameModal}
          isOkButtonDisabled={!isInputValid}
          onInputChange={this.objectValidationBuilder}
          onCancelClicked={this.resetModalState}
          onOkClicked={this.renameFolder}
          validateCallBack={this.checkInputValidation}
          customErrorMsg={errorMessage}
          name={newFolderName}
          label={getSingleText('app.pages.forms.folders.modals.rename.label')}
        />

        <DeleteFolderModal isShown={isShowDeleteModal}
          onCancelClicked={this.resetModalState}
          onOkClicked={this.deleteFolder}
          isShowDeleteWarningMessage={isShowDeleteWarningMessage} />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setFolders: (allFolders) => dispatch(folderActions.setFolders(allFolders)),
    setSelectedFolder: (folder) => dispatch(folderActions.setSelectedFolder(folder)),
    createNewFolder: (value) => dispatch(folderActions.createFolder(value)),
    renameFolder: (id, name) => dispatch(folderActions.renameFolder(id, name)),
    getFormsBeforeFolderDeletion: (id) => dispatch(formActions.getFormsBeforeFolderDeletion(id)),
    setFormsBeforeDeletion: (forms) => dispatch(formActions.setFormsBeforeDeletion(forms)),
    deleteFolderDispatcher: (id) => dispatch(folderActions.deleteFolder(id)),
    startPolling: () => dispatch(folderActions.startPolling()),
    stopPolling: () => dispatch(folderActions.stopPolling()),
  };
}

function mapStateToProps(state) {
  const { profile: { userRole }, folders, forms: { formsBeforeDeletion } } = state;
  const propertyRole = UserRoles[userRole];

  return {
    allFolders: getSortedFolders(state),
    selectedFolder: folders.selectedFolder,
    existingFoldersNames: nameValidationObject(state),
    formsBeforeDeletion,
    propertyRole,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FoldersContainer);

FoldersContainer.propTypes = {
  id: PropTypes.number,
  allFolders: PropTypes.array,
  selectedFolder: PropTypes.object,
  getFormsBeforeFolderDeletion: PropTypes.func,
  deleteFolderDispatcher: PropTypes.func,
  startPolling: PropTypes.func,
  renameFolder: PropTypes.func,
  existingFoldersNames: PropTypes.object,
  stopPolling: PropTypes.func,
  setSelectedFolder: PropTypes.func,
  createNewFolder: PropTypes.func,
  setFormsBeforeDeletion: PropTypes.func,
  formsBeforeDeletion: PropTypes.array,
  propertyRole: PropTypes.number,
};

