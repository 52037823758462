import { color, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const StyledButtonSwitchContainer = styled.div`
    .custom-button-display{
        & input {
            outline: none;
        }
        & span{
            color: ${color('typeBody')};
        }
    }
`;

export const StyledButtonHeader = styled.div`
  display: flex;
  align-items: center;
  & svg {
    margin: ${units(2.5)} 0 ${units(0.75)} ${units(0.5)};
  }
`;