import React from 'react';
import withModal from '@mdigital/components/dist/hoc/ModalHoc';
import DigButton from '@mdigital/components/dist/components/Button';
import { StyledModalContent,StyledTextContainer,StyledSign,StyledText,StyledBodyText,StyledWarningText } from './StyledCustomNoticeModal';
import { getSingleText } from '../../../common/utils';
import { ErrorSign } from './sign';

const basicModalProps = {
  modalClassName:'publish-error-notice-container sans-md-regular',
  title: getSingleText('app.pages.forms.customNoticeModals.publishTargetingError.title'),
  footerButtons:[{
    id:getSingleText('app.pages.forms.customNoticeModals.publishTargetingError.buttonId'),
    button:<DigButton data-aut="close" digClassName="primary close-error-btn">{getSingleText('app.close')}</DigButton>,
  }],
};


function PublishErrorModal() {

  return(
    <StyledModalContent>
      <StyledSign>
        <ErrorSign/>
      </StyledSign>
      <StyledTextContainer>
        <StyledText>{getSingleText('app.pages.forms.customNoticeModals.publishTargetingError.subTitle')}</StyledText>
        <StyledBodyText>{getSingleText('app.pages.forms.customNoticeModals.publishTargetingError.the_form')}
          {' '}
          <StyledWarningText>{getSingleText('app.pages.forms.customNoticeModals.publishTargetingError.red_content')}</StyledWarningText>
        </StyledBodyText>
        <StyledText>{getSingleText('app.pages.forms.customNoticeModals.publishTargetingError.content')}</StyledText>
      </StyledTextContainer>
    </StyledModalContent>
  );
}
export default withModal(basicModalProps)(PublishErrorModal);
