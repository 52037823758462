import React, { useEffect, useContext } from 'react';
import { TriggerContext } from '../../../../../../..';
import TargetingBlockRow from '../../../TargetingBlockRow';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { NumberInput, Select } from '@m/alchemy-ui';
import { TargetingActions } from '../../../../../Actions';
import { newTriggerModalComparisonOptions } from '../../../../../../../../../common/dropdownOptions';
import { Subtitle } from '../../../../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../../../../common/utils';
import { TextPathPrefix } from '../../../../..';
import { isRuleExists, formatInputNumberNullValue } from '../../../../../../../helpers';

const TargetByLastTimeInForeground = () => {
  const { triggerState, triggerDispatch, isReadOnly } = useContext(TriggerContext);
  useEffect(() => {
    isRuleExists(triggerState, 'trigger.rules.MobileLastTimeInForeground.isActive') ?
      triggerDispatch({ type: TargetingActions.SET_MOBILE_LAST_TIME_IN_FOREGROUND_IS_ACTIVE , payload : true } ) :
      triggerDispatch({ type: TargetingActions.INIT_MOBILE_LAST_TIME_IN_FOREGROUND , payload : { compareString : 'smallerThan' , seconds : 60 } } );
  },[]);

  return (
    <TargetingBlockRow
      checkboxLabel="When last duration time in foreground"
      checked={getNestedProperty(triggerState, 'trigger.rules.MobileLastTimeInForeground.isActive')}
      disabled={isReadOnly}
      onCheckboxChange={ (e) => triggerDispatch({ type: TargetingActions.SET_MOBILE_LAST_TIME_IN_FOREGROUND_IS_ACTIVE , payload : e.target.checked } ) }>
      <React.Fragment>
        <Select 
          options={newTriggerModalComparisonOptions}
          value={getNestedProperty(triggerState, 'trigger.rules.MobileLastTimeInForeground.params.compareString')}
          onChange={(option) => triggerDispatch({ type: TargetingActions.SET_MOBILE_LAST_TIME_IN_FOREGROUND_COMPARE_STRING , payload : option.value })} />
        <NumberInput
          className='number-of-session-input'
          min="1"
          formatValue={formatInputNumberNullValue}
          value={getNestedProperty(triggerState , 'trigger.rules.MobileLastTimeInForeground.params.seconds') || 60}
          onNumberChange={ (value) => triggerDispatch({ type: TargetingActions.SET_MOBILE_LAST_TIME_IN_FOREGROUND_SECONDS , payload : value } )} />
        <Subtitle marginLeft={1.5}>{getSingleText(`${TextPathPrefix}.rulesTexts.duration`)}</Subtitle>
      </React.Fragment>
    </TargetingBlockRow>
  );
};

export default TargetByLastTimeInForeground;
