import styled from 'styled-components';
import { COLORS } from '../../../common/colors';

export default styled.div`
  background-color: ${COLORS.GRAY_BACKGROUND};
  padding: 10px;
  margin: 10px 7px;
  border-radius: 8px;
`;

export const StyledTextsRow = styled.div`
  display: flex;
  padding: 8px 4px;
`;

export const StyleTitle = styled.span`
  flex: 1;
  font-weight: 600;
`;

export const StyleCounter = styled.span`
  b {
    font-weight: 600;
    color: ${({ counterAtMax }) => counterAtMax ? 'inherit' : COLORS.BLUE};
  }
`;

export const ProgressBarContainer = styled.div`
  padding: 1px 4px 8px;
`;

export const ComponentsBankBlocker = styled.div`
  display: ${({ shouldBlock }) => (shouldBlock ? 'block' : 'none')};
  position: absolute;
  top: 80px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, .5);
  backdrop-filter: blur(1px);
  cursor: not-allowed;
`;
