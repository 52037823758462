import React from 'react';
import PropTypes from 'prop-types';
import { PowerdByContainer , PowerdByText } from './StyledPoweredByFooter';
import IconSvg from '@mdigital/components/dist/components/IconSvg';
import MedalliaLogo from '../../assets/ThankYouPromptAssets/Medallia_logo_powered_by.svg';
import DarkModePoweredBy from '../../assets/ThankYouPromptAssets/DarkModePoweredBy.svg';

const medalliaLink = 'https://www.medallia.com/';

const PoweredByFooter = ({ logoWidth, logoHeight , isDarkEnabled }) => (
  <React.Fragment>
    <PowerdByContainer>
      <PowerdByText isDarkEnabled={isDarkEnabled}>Powered by</PowerdByText>
      <a href={medalliaLink} target="_blank">
        <IconSvg icon={isDarkEnabled ? DarkModePoweredBy : MedalliaLogo} width={logoWidth} height={logoHeight}/>
      </a>
    </PowerdByContainer>
  </React.Fragment>
);

PoweredByFooter.propTypes = {
  logoWidth: PropTypes.number,
  logoHeight: PropTypes.number,
};


export default PoweredByFooter;